import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {BuildTypeId} from '../../types'
import IconButton from '../common/IconButton/IconButton'
import {initDslOptions, showDsl, hideDsl} from '../Dsl/Dsl.actions'
import {getDslOptions} from '../Dsl/Dsl.selectors'
import HelpLink from '../HelpLink/HelpLink'

import {useIsDslLoaded} from './ShowDsl.hooks'

type Props = {
  helpUrl: string
  controlId: string
  isFragment?: boolean
  buildTypeOrTemplateId?: BuildTypeId
  onShowDsl?: () => unknown
  onHideDsl?: () => unknown
}

const ShowDsl: React.FC<Props> = ({
  helpUrl,
  controlId,
  isFragment,
  buildTypeOrTemplateId,
  onShowDsl,
  onHideDsl,
}) => {
  const dispatch = useDispatch()

  const isDslMode = useSelector(state => getDslOptions(state, controlId)?.show ?? false)
  const isDslLoaded = useIsDslLoaded(controlId, buildTypeOrTemplateId, isFragment)
  const title = isDslMode ? 'Edit in UI' : 'View as code'

  const toggleDslMode = React.useCallback(() => {
    if (isDslMode) {
      dispatch(hideDsl(controlId))
      onHideDsl?.()
      return
    }

    dispatch(showDsl(controlId))
    onShowDsl?.()
  }, [dispatch, isDslMode, controlId, onHideDsl, onShowDsl])

  React.useEffect(() => {
    dispatch(initDslOptions(controlId))
  }, [dispatch, controlId])

  return (
    <span>
      <Button onClick={toggleDslMode} disabled={isDslMode && !isDslLoaded}>
        {title}
      </Button>
      <HelpLink url={helpUrl} Component={IconButton} icon="help" />
    </span>
  )
}

export default ShowDsl
