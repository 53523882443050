import {PayloadAction} from '@reduxjs/toolkit'
import memoize from 'memoize-one'
import type {Dispatch} from 'redux'

import type {MiddlewareAPI} from '../store/types'

const loadStatisticsChunk = memoize(
  () =>
    import(
      /* webpackChunkName: "statistics", webpackPrefetch: true */
      '.'
    ),
)
export default (store: MiddlewareAPI) =>
  (next: Dispatch<PayloadAction<unknown>>) =>
  (action: PayloadAction<unknown>): PayloadAction<unknown> => {
    const state = store.getState()
    loadStatisticsChunk().then(({track}) => track(state, action))
    return next(action)
  }
