import type {AppThunk} from '../../actions/types'
import {createFetchAction} from '../../reducers/fetchable'
import {getExtensionEndpoint} from '../../selectors'
import type {BuildId} from '../../types'
import {base_uri} from '../../types/BS_types'
import {ActionThrottlerWithArrayCollect} from '../../utils/actionThrottler'

import requestBuildLogMessages, {requestTimeline} from './BuildLog.rest'
import {getBuildLogTimeline, getFullLogState, getMessagesLoadStates} from './BuildLog.selectors'
import {settings} from './BuildLog.slices'
import {LogView} from './BuildLog.types'
import type {
  BuildLogMessagesResponse,
  BuildLogMessagesResponseAnchor,
  FetchMessagesParams,
} from './BuildLog.types'
import {getKeyForBuildLogData, getLogViewUrlParam} from './BuildLog.utils'

type FetchBuildLogMessagesPayload = {
  data: BuildLogMessagesResponse
  focusLine?: number | null
  logView?: LogView
}

export const fetchBuildLogMessagesAction = createFetchAction(
  'fetchBuildLogMessages',
  async (
    {buildId, options = {}, invalidate}: FetchMessagesParams,
    {getState},
  ): Promise<FetchBuildLogMessagesPayload> => {
    const extension = getExtensionEndpoint(getState(), 'fetchExpandedBuildLogMessage')! // checked in condition

    const response: BuildLogMessagesResponse = await requestBuildLogMessages(
      extension.serverUrl ?? base_uri,
      extension.endpoint,
      buildId,
      options,
    )
    let focusLine: number | null | undefined
    let logView: LogView

    if (
      options.stageKey != null &&
      response.stageTarget != null &&
      response.stageTarget.key === options.stageKey
    ) {
      const anchor: BuildLogMessagesResponseAnchor | null | undefined =
        response.stageTarget.anchors.find(item => item.type === 'BUILD_STAGE_START')

      if (anchor != null) {
        focusLine = anchor.position
      }
    } else if (invalidate === true && options.logAnchor === 0) {
      const firstVisible = response.messages.find(message => message.level > 0)

      if (firstVisible != null) {
        focusLine = firstVisible.id
      }
    }

    if (options.logAnchor === -1) {
      const last = response.messages[response.messages.length - 1]

      if (last != null) {
        focusLine = last.id
      }
    }

    if (response.view != null) {
      logView = response.view
    }
    if (response.focusIndex != null) {
      focusLine = response.focusIndex
    }

    return {
      data: response,
      focusLine,
      logView,
    }
  },
  {
    condition(_, {getState}) {
      const extension = getExtensionEndpoint(getState(), 'fetchExpandedBuildLogMessage')
      return extension && !window.GLOBAL_FETCH_DISABLED
    },
    getPendingMeta: ({arg}) => ({invalidate: arg.invalidate}),
  },
)

export const fetchBuildLogMessages =
  (params: FetchMessagesParams): AppThunk<any> =>
  (dispatch, getState) => {
    const {target, options} = params
    const filter = target != null ? getFullLogState(getState(), target).filter : null
    const defaultOptions = {
      filter,
    }
    const allOptions = {...defaultOptions, ...options}
    dispatch(fetchBuildLogMessagesAction({...params, options: allOptions}))
  }

export const fetchBuildLogTimeline = createFetchAction(
  'fetchBuildLogTimeline',
  (buildId: BuildId, {getState}) => {
    const state = getState()
    const extension = getExtensionEndpoint(state, 'fetchBuildLogTimeline')! // checked in condition
    return requestTimeline(
      extension.serverUrl ?? base_uri,
      extension.endpoint,
      buildId,
      getLogViewUrlParam(),
    )
  },
  {
    condition(buildId, {getState}) {
      const state = getState()
      const extension = getExtensionEndpoint(state, 'fetchBuildLogTimeline')
      const timeline = getBuildLogTimeline(state, buildId)

      return extension != null && !window.GLOBAL_FETCH_DISABLED && !timeline?.loading
    },
  },
)

const fetchBuildLogTimelines =
  (buildIds: ReadonlyArray<BuildId>): AppThunk<any> =>
  dispatch =>
    [...new Set(buildIds)].forEach(buildId => dispatch(fetchBuildLogTimeline(buildId)))

const fetchTimelineThrottleTime = 200
const fetchBuildLogTimelineThrottler = new ActionThrottlerWithArrayCollect(
  fetchBuildLogTimelines,
  [],
  fetchTimelineThrottleTime,
)
export const fetchBuildLogTimelineWithThrottle = (buildId: BuildId): AppThunk<void> =>
  fetchBuildLogTimelineThrottler.fetch([buildId])
export const fetchBuildLogPreview =
  (buildId: BuildId, messagesCount: number): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState()
    const buildLogKey = getKeyForBuildLogData({
      type: 'preview',
      id: buildId,
    })
    const loadState = getMessagesLoadStates(state, buildLogKey)?.tail

    if (loadState?.loading === true) {
      return
    }

    dispatch(
      fetchBuildLogMessages({
        options: {
          target: 'tail',
          count: [0, -messagesCount * 2],
          //Load more lines because some may be empty
          expandAll: true,
        },
        buildLogKey,
        buildId,
      }),
    )
  }
export const setSoftWrapLinesInBuildlog = settings.actions.setSoftWrapLines
export const setDarkThemeBuildlog = settings.actions.setDarkTheme
