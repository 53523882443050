import processResponse from '../../rest/processResponse'
import request from '../../rest/request'
import {base_uri} from '../../types/BS_types'

import type {
  CleanupHolderNodeType,
  CreateRuleAnswerType,
  FetchRulesOptons,
  GetRulesResponseType,
  RuleId,
  RuleType,
} from './Cleanup.types'
import {getHolderParams} from './Cleanup.utils'

const serverUrl = `${base_uri}/admin`

/**
 *
 * Pay attention, that we have JSend format here,
 * which contains 'data' and 'error' fields,
 * which could be used to handle any sort of errors
 * in a specific way, but now it's not used
 *
 * Look more: https://github.com/omniti-labs/jsend
 *
 * */
export const fetchRules = (
  holderNode: CleanupHolderNodeType,
  options?: FetchRulesOptons | null | undefined,
): Promise<GetRulesResponseType> => {
  const {hierarchy = 'effective', getChildrensRules = 'withOwnBuildTypes'} = options || {}
  const {holder, holderId} = getHolderParams(holderNode)
  return request(
    serverUrl,
    `cleanupRules.html?holder=${holder}&holderId=${holderId}&rulesHierarchy=${hierarchy}&getForProjectChildren=${String(
      getChildrensRules,
    )}`,
  ).then<GetRulesResponseType>(processResponse)
}
export const deleteRule = (
  holderNode: CleanupHolderNodeType,
  ruleId: string,
): Promise<GetRulesResponseType> => {
  const {holder, holderId} = getHolderParams(holderNode)
  return request(serverUrl, `cleanupRules.html`, {
    method: 'POST',
    body: JSON.stringify({
      holder,
      holderId,
      type: 'delete',
      ruleId,
    }),
  }).then<GetRulesResponseType>(processResponse)
}
export const createRule = (
  holderNode: CleanupHolderNodeType,
  rule: RuleType,
  ruleId?: RuleId,
): Promise<CreateRuleAnswerType> => {
  const {holder, holderId} = getHolderParams(holderNode)
  const {ruleDisabled, ...restRule} = rule
  return request(serverUrl, `cleanupRules.html`, {
    method: 'POST',
    body: JSON.stringify({
      holder,
      holderId,
      ruleId: ruleId ?? null,
      ruleDisabled,
      type: 'create',
      rule: ruleDisabled ? null : restRule,
    }),
  }).then<CreateRuleAnswerType>(processResponse)
}
export const updateRule = (
  holderNode: CleanupHolderNodeType,
  rule: RuleType,
): Promise<CreateRuleAnswerType> => {
  const {holder, holderId} = getHolderParams(holderNode)
  const {ruleId, ruleDisabled, ...restRule} = rule
  return request(serverUrl, `cleanupRules.html`, {
    method: 'POST',
    body: JSON.stringify({
      holder,
      holderId,
      type: 'update',
      ruleId: ruleId ?? null,
      ruleDisabled,
      rule: restRule,
    }),
  }).then<CreateRuleAnswerType>(processResponse)
}
