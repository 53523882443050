import {configureStore, isFulfilled, isPending, isRejected, PayloadAction} from '@reduxjs/toolkit'
import type {QueryThunkArg} from '@reduxjs/toolkit/dist/query/core/buildThunks'
import {setupListeners} from '@reduxjs/toolkit/query'

import reducer from '../reducers'
import {getStateToPersist} from '../selectors/toPersist'
import {restApi} from '../services/rest'
import statistics from '../statistics/middleware'

import {loadState, saveState} from './persistState'
import {MiddlewareAPI} from './types'

const logMe =
  (store: MiddlewareAPI) =>
  (next: (action: PayloadAction<unknown>) => PayloadAction<unknown>) =>
  (action: PayloadAction<unknown>) => {
    if (process.env.NODE_ENV === 'development') {
      const castedAction = action as PayloadAction<unknown, string, {arg: QueryThunkArg}, unknown>
      const {meta, payload, error} = castedAction
      if (meta?.arg?.endpointName != null) {
        const {endpointName, originalArgs} = meta.arg
        let type = ''
        if (isPending(castedAction)) {
          type = 'pending'
        } else if (isFulfilled(castedAction)) {
          type = 'fulfilled'
        } else if (isRejected(castedAction)) {
          type = 'rejected'
        }
        // eslint-disable-next-line no-console
        console.log(`${type}:`, endpointName, originalArgs, payload, error)
      }
      // eslint-disable-next-line no-console
      console.log('dispatching:', action, store.getState())
    }

    return next(action)
  }

const persistentState = loadState()
const store = configureStore({
  reducer,
  preloadedState: persistentState && {
    ...persistentState,
    currentUser: {
      ...persistentState.currentUser,
      // don't prevent request for up-to-date user info
      inited: false,
    },
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
    return defaultMiddleware.concat(
      [
        IS_STORYBOOK && require('./getLogicMiddleware').getLogicMiddleware(),
        logMe,
        statistics,
        restApi.middleware,
      ].filter(Boolean),
    )
  },
})
store.subscribe(() => {
  saveState(getStateToPersist(store.getState()))
})
setupListeners(store.dispatch)
export default store
