import * as ReactRedux from 'react-redux'

import type {State} from '../../../reducers/types'
import {getAgent, getIsExperimentalUI} from '../../../selectors'
import type {NormalizedAgentType} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'

import AgentLink from './AgentLink'
import type {AgentLinkOwnProps, AgentProps, AgentLinkStateProps} from './AgentLink.types'

export const mapAgentToProps = (agent: NormalizedAgentType | null | undefined): AgentProps => {
  if (agent == null) {
    // https://github.com/facebook/flow/issues/2977#issuecomment-385169799
    return Object.freeze({})
  }

  const {name, osType, typeId, id: agentId} = agent
  return {
    name,
    typeId,
    agentId,
    href: resolveWebEntityLink(agent),
    osType,
  }
}

const mapStateToProps = (state: State, props: AgentLinkOwnProps): AgentLinkStateProps => {
  const isExperimentalUI = getIsExperimentalUI(state)
  const agent = getAgent(state, props.agentId)
  return {...mapAgentToProps(agent), isExperimentalUI}
}

export default ReactRedux.connect(mapStateToProps)(AgentLink)
