/**
 * @generated SignedSource<<fc00ef39f6e1c9fd973bb1651112bde0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OSType = "FreeBSD" | "Linux" | "Solaris" | "Unix" | "Unknown" | "Windows" | "macOS" | "%future added value";
export type AgentsScreenAgentTypesQuery$variables = {};
export type AgentsScreenAgentTypesQuery$data = {
  readonly agentTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly agentPool: {
          readonly name: string | null;
          readonly rawId: string;
        };
        readonly cloudImage: {
          readonly name: string | null;
        } | null;
        readonly environment: {
          readonly os: {
            readonly type: OSType;
          } | null;
        } | null;
        readonly isCloud: boolean;
        readonly name: string | null;
        readonly rawId: string;
      };
    }>;
  } | null;
};
export type AgentsScreenAgentTypesQuery = {
  response: AgentsScreenAgentTypesQuery$data;
  variables: AgentsScreenAgentTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCloud",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentEnvironment",
  "kind": "LinkedField",
  "name": "environment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OS",
      "kind": "LinkedField",
      "name": "os",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsScreenAgentTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentTypesConnection",
        "kind": "LinkedField",
        "name": "agentTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "agentPool",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CloudImage",
                    "kind": "LinkedField",
                    "name": "cloudImage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgentsScreenAgentTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentTypesConnection",
        "kind": "LinkedField",
        "name": "agentTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "agentPool",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CloudImage",
                    "kind": "LinkedField",
                    "name": "cloudImage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3330afc08c74cf7b7593091870409369",
    "id": null,
    "metadata": {},
    "name": "AgentsScreenAgentTypesQuery",
    "operationKind": "query",
    "text": "query AgentsScreenAgentTypesQuery {\n  agentTypes {\n    edges {\n      node {\n        rawId\n        name\n        isCloud\n        agentPool {\n          __typename\n          rawId\n          name\n          id\n        }\n        cloudImage {\n          name\n          id\n        }\n        environment {\n          os {\n            type\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cd70e1a90247bd642deae8ac5dd87e7";

export default node;
