import ArtifactsIcon from '@jetbrains/icons/artifacts.svg'
import NoArtifactsIcon from '@jetbrains/icons/no-artifacts.svg'
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import composeRefs from '@jetbrains/ring-ui/components/global/composeRefs'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import * as React from 'react'
import type {ContentRect} from 'react-measure'

import Measure from '../../../containers/Measure'
import useUID from '../../../hooks/useUID'
import BuildArtifactsTree from '../BuildArtifactsTree/BuildArtifactsTree'
import IconButton from '../IconButton/IconButton'
import Link from '../Link/Link'
import Popup from '../Popup/Popup.lazy'

import type {Props} from './BuildArtifacts.types'

import styles from './BuildArtifacts.css'

const Z_INDEX_INCREMENT = 5

const handleAnchorClick = (isPlainLeftClick: boolean, e: React.SyntheticEvent<any>) => {
  if (isPlainLeftClick) {
    e.preventDefault()
  } else {
    e.stopPropagation()
  }
}

export const MIN_POPUP_WIDTH = 320
const emptyDirections: Directions[] = []
export function BuildArtifacts({
  className,
  hasArtifacts,
  buildId,
  href,
  popupDirection,
  isLoading,
  isVisible,
  currentZindex,
  isLoadingHiddenArtifacts,
  wrapperProps,
}: Props) {
  const labelId = useUID('artifacts-tree-label-')
  // Popup can grow but cannot shrink
  const [treeMinWidth, setTreeMinWidth] = React.useReducer(
    (state: number, newWidth: number) => Math.max(state, newWidth),
    MIN_POPUP_WIDTH,
  )
  const ref = React.useRef<HTMLDivElement>(null)

  const setRefs = composeRefs(ref, wrapperProps?.ref)

  const onResize = (rect: ContentRect) => setTreeMinWidth(rect.offset?.width ?? 0)

  const anchor = React.useMemo(() => {
    const clickProps =
      href != null
        ? {
            onConditionalClick: handleAnchorClick,
          }
        : Object.freeze({})
    return (
      <IconButton
        className={className}
        title="Artifacts"
        href={href}
        target="_blank"
        rel="noreferrer"
        primary={hasArtifacts === true}
        loader={isVisible === true && isLoading}
        icon={hasArtifacts === false ? NoArtifactsIcon : ArtifactsIcon}
        {...clickProps}
      />
    )
  }, [className, hasArtifacts, href, isLoading, isVisible])
  const popupContent = React.useMemo(() => {
    const noArtifacts = hasArtifacts === false
    return (
      // redraw popup whenever loading state changes
      <div data-loading={isLoadingHiddenArtifacts} className={styles.popupContent}>
        {!noArtifacts && href != null && (
          <Link href={href} className={styles.titleLink} id={labelId}>
            {'Artifacts'}
          </Link>
        )}
        <Measure offset onResize={onResize}>
          {({measureRef}) => (
            <div
              style={{
                minWidth: `${treeMinWidth}px`,
              }}
              ref={measureRef}
            >
              <BuildArtifactsTree
                showToggleHidden={noArtifacts}
                buildId={buildId}
                labelledBy={labelId}
              />
            </div>
          )}
        </Measure>
      </div>
    )
  }, [buildId, hasArtifacts, href, isLoadingHiddenArtifacts, labelId, treeMinWidth])

  const popup = React.useMemo(
    () => (
      <Popup
        className={styles.popup}
        directions={emptyDirections.concat(popupDirection ?? [])}
        autoCorrectTopOverflow={false}
        left={-12}
        style={
          currentZindex != null
            ? {
                zIndex: currentZindex + Z_INDEX_INCREMENT,
              }
            : undefined
        }
      >
        {popupContent}
      </Popup>
    ),
    [currentZindex, popupContent, popupDirection],
  )
  const dropdown = React.useMemo(
    () => (
      <Dropdown anchor={anchor} hoverShowTimeOut={300} hoverHideTimeOut={300} hoverMode clickMode>
        {popup}
      </Dropdown>
    ),
    [anchor, popup],
  )
  return (
    <div className={styles.wrapper} {...wrapperProps} ref={setRefs}>
      {dropdown}
    </div>
  )
}
BuildArtifacts.defaultProps = {
  popupDirection: [Directions.LEFT_BOTTOM, Directions.LEFT_TOP],
}
export default React.memo<Props>(BuildArtifacts)
