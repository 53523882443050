import {checkIfNonValidResponse} from '../actions/utils'
import type {RunnerType} from '../components/App/SelectBuildRunners/SelectBuildRunners.types'
import {GITHUB_REPOSITORIES_ENDPOINT} from '../components/pages/PipelinesPages/CreateProjectPage/CreateProjectPage.constants'
import {subscribeOnServiceWorkerMessage} from '../rest/caching/events'
import {
  normalizeAgentPreviews,
  normalizeAgents,
  normalizeBuild,
  normalizeBuilds,
  normalizeBuildsTestOccurrencesCount,
  normalizeBuildType,
  normalizeBuildTypeDescription,
  normalizeBuildTypeLinks,
  normalizeBuildTypeLinksFromAgentsAndBuilds,
  normalizeBuildTypeParameters,
  normalizeBuildTypePauseComment,
  normalizeChanges,
  Normalized,
  NormalizedAgents,
  NormalizedBuild,
  NormalizedBuilds,
  NormalizedChanges,
  NormalizedSingleBuildType,
  normalizeBuildFirstBuildWithSameChanges,
  normalizeModificationsOfChange,
} from '../rest/schemata'
import type {ChangeId, ProjectId, Tab} from '../types'
import {RequestOptionsParams} from '../types'
import {base_uri} from '../types/BS_types'

import {CACHE_TABS, REVALIDATION_HEADER_NAME} from '../workers/sw.constants'

import {restBaseApi as api} from './restBase'

export const getIdFromLocator = (locator: string) => {
  const [_, id] = locator.match(/^id:([^,]*)$/) ?? []
  return id
}

export const processBuildType = (data: BuildType) => {
  const buildType = normalizeBuildType(data)
  return {
    result: buildType.result,
    entities: {
      ...buildType.entities,
      ...normalizeBuildTypeLinks(data).entities,
      ...normalizeBuildTypeDescription(data).entities,
      ...normalizeBuildTypeParameters(data).entities,
      ...normalizeBuildTypePauseComment(data).entities,
    },
  }
}

export const processAgents = (data: readonly Agent[]) => {
  const agents = normalizeAgents(data)
  Object.assign(
    agents.entities,
    normalizeAgentPreviews(data).entities,
    normalizeBuildTypeLinksFromAgentsAndBuilds(data).entities,
  )
  return agents
}

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    /* Start of Non REST-API controllers */
    getAvailableRunners: build.query<GetAvailableRunnersResponse, GetAvailableRunnersArgs>({
      query: queryArg => ({url: `/app/availableRunners?projectId=${queryArg.projectId}`}),
    }),
    getGeneratedId: build.query<string, string>({
      query: name => ({
        url: 'generateId.html',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: `object=project&parentId=_Root&name=${encodeURIComponent(name)}`,
        responseHandler: 'text',
      }),
    }),
    getGitHubRepositories: build.query<string, void>({
      query: () => ({
        url: GITHUB_REPOSITORIES_ENDPOINT,
        responseHandler: 'text',
      }),
    }),
    getTabs: build.query<Tab[], string>({
      query: key => ({
        url: `/app/tabs?${key}`,
        headers: {
          [REVALIDATION_HEADER_NAME]: CACHE_TABS,
        },
      }),
      async onCacheEntryAdded(key, {updateCachedData, cacheDataLoaded, cacheEntryRemoved}) {
        await cacheDataLoaded
        const unsubscribe = subscribeOnServiceWorkerMessage<Tab[]>({
          url: `${base_uri}/app/tabs?${key}`,
          handler: response => {
            if (!checkIfNonValidResponse(response)) {
              updateCachedData(() => response.payload)
            }
          },
        })
        await cacheEntryRemoved
        unsubscribe()
      },
    }),
    getChangesGraph: build.query<GetChangesGraphResponse, GetChangesGraphArgs>({
      query: ({queryParams}) => ({url: `/app/changeLogGraph?${queryParams}`}),
    }),
    /* End of non REST-API controllers */

    getRootEndpointsOfRoot: build.query<
      GetRootEndpointsOfRootApiResponse,
      GetRootEndpointsOfRootApiArg
    >({
      query: () => ({url: `/app/rest`}),
    }),
    getAllAgentPools: build.query<GetAllAgentPoolsApiResponse, GetAllAgentPoolsApiArg>({
      query: queryArg => ({
        url: `/app/rest/agentPools`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    createAgentPool: build.mutation<CreateAgentPoolApiResponse, CreateAgentPoolApiArg>({
      query: queryArg => ({url: `/app/rest/agentPools`, method: 'POST', body: queryArg.agentPool}),
    }),
    getAgentPoolOfAgentPool: build.query<
      GetAgentPoolOfAgentPoolApiResponse,
      GetAgentPoolOfAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteAgentPool: build.mutation<DeleteAgentPoolApiResponse, DeleteAgentPoolApiArg>({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}`,
        method: 'DELETE',
      }),
    }),
    getAllAgentsFromAgentPool: build.query<
      GetAllAgentsFromAgentPoolApiResponse,
      GetAllAgentsFromAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/agents`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addAgentToAgentPool: build.mutation<AddAgentToAgentPoolApiResponse, AddAgentToAgentPoolApiArg>({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/agents`,
        method: 'POST',
        body: queryArg.agent,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllProjectsFromAgentPool: build.query<
      GetAllProjectsFromAgentPoolApiResponse,
      GetAllProjectsFromAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/projects`,
        params: {fields: queryArg.fields},
      }),
    }),
    addProjectToAgentPool: build.mutation<
      AddProjectToAgentPoolApiResponse,
      AddProjectToAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/projects`,
        method: 'POST',
        body: queryArg.project,
      }),
    }),
    setAgentPoolProjects: build.mutation<
      SetAgentPoolProjectsApiResponse,
      SetAgentPoolProjectsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/projects`,
        method: 'PUT',
        body: queryArg.projects,
      }),
    }),
    deleteAllProjectsFromAgentPool: build.mutation<
      DeleteAllProjectsFromAgentPoolApiResponse,
      DeleteAllProjectsFromAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/projects`,
        method: 'DELETE',
      }),
    }),
    deleteProjectFromAgentPool: build.mutation<
      DeleteProjectFromAgentPoolApiResponse,
      DeleteProjectFromAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/projects/${queryArg.projectLocator}`,
        method: 'DELETE',
      }),
    }),
    getFieldFromAgentPool: build.query<
      GetFieldFromAgentPoolApiResponse,
      GetFieldFromAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/${queryArg.field}`,
      }),
    }),
    setAgentPoolField: build.mutation<SetAgentPoolFieldApiResponse, SetAgentPoolFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/agentPools/${queryArg.agentPoolLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getAllAgents: build.query<GetAllAgentsApiResponse, GetAllAgentsApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getAllAgentsNormalized: build.query<NormalizedAgents, GetAllAgentsApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
      transformResponse: (data: Agents) => processAgents(data.agent ?? []),
    }),
    getAllAgentPreviewsNormalized: build.query<NormalizedAgents, GetAllAgentsApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
      transformResponse: (data: Agents) => normalizeAgentPreviews(data.agent ?? []),
    }),
    getAgent: build.query<GetAgentApiResponse, GetAgentApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteAgent: build.mutation<DeleteAgentApiResponse, DeleteAgentApiArg>({
      query: queryArg => ({url: `/app/rest/agents/${queryArg.agentLocator}`, method: 'DELETE'}),
    }),
    getAuthorizedInfo: build.query<GetAuthorizedInfoApiResponse, GetAuthorizedInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/authorizedInfo`,
        params: {fields: queryArg.fields},
      }),
    }),
    setAuthorizedInfo: build.mutation<SetAuthorizedInfoApiResponse, SetAuthorizedInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/authorizedInfo`,
        method: 'PUT',
        body: queryArg.authorizedInfo,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildConfigurationRunPolicy: build.query<
      GetBuildConfigurationRunPolicyApiResponse,
      GetBuildConfigurationRunPolicyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/compatibilityPolicy`,
        params: {fields: queryArg.fields},
      }),
    }),
    setBuildConfigurationRunPolicy: build.mutation<
      SetBuildConfigurationRunPolicyApiResponse,
      SetBuildConfigurationRunPolicyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/compatibilityPolicy`,
        method: 'PUT',
        body: queryArg.compatibilityPolicy,
        params: {fields: queryArg.fields},
      }),
    }),
    getCompatibleBuildTypes: build.query<
      GetCompatibleBuildTypesApiResponse,
      GetCompatibleBuildTypesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/compatibleBuildTypes`,
        params: {fields: queryArg.fields},
      }),
    }),
    getEnabledInfo: build.query<GetEnabledInfoApiResponse, GetEnabledInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/enabledInfo`,
        params: {fields: queryArg.fields},
      }),
    }),
    setEnabledInfo: build.mutation<SetEnabledInfoApiResponse, SetEnabledInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/enabledInfo`,
        method: 'PUT',
        body: queryArg.enabledInfo,
        params: {fields: queryArg.fields},
      }),
    }),
    getIncompatibleBuildTypes: build.query<
      GetIncompatibleBuildTypesApiResponse,
      GetIncompatibleBuildTypesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/incompatibleBuildTypes`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAgentPool: build.query<GetAgentPoolApiResponse, GetAgentPoolApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/pool`,
        params: {fields: queryArg.fields},
      }),
    }),
    setAgentPool: build.mutation<SetAgentPoolApiResponse, SetAgentPoolApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/pool`,
        method: 'PUT',
        body: queryArg.agentPool,
        params: {fields: queryArg.fields},
      }),
    }),
    getAgentField: build.query<GetAgentFieldApiResponse, GetAgentFieldApiArg>({
      query: queryArg => ({url: `/app/rest/agents/${queryArg.agentLocator}/${queryArg.field}`}),
    }),
    setAgentField: build.mutation<SetAgentFieldApiResponse, SetAgentFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({url: `/app/rest/apiVersion`}),
    }),
    getAllAuditEvents: build.query<GetAllAuditEventsApiResponse, GetAllAuditEventsApiArg>({
      query: queryArg => ({
        url: `/app/rest/audit`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getAuditEvent: build.query<GetAuditEventApiResponse, GetAuditEventApiArg>({
      query: queryArg => ({
        url: `/app/rest/audit/${queryArg.auditEventLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    putAvatar: build.mutation<PutAvatarApiResponse, PutAvatarApiArg>({
      query: queryArg => {
        const formData = new FormData()
        if (queryArg.body.avatar != null) {
          formData.append('avatar', queryArg.body.avatar)
        }
        return {
          url: `/app/rest/avatars/${queryArg.userLocator}`,
          method: 'PUT',
          body: formData,
        }
      },
      invalidatesTags: (_, __, arg) => [{type: 'User', id: getIdFromLocator(arg.userLocator)}],
    }),
    deleteAvatar: build.mutation<DeleteAvatarApiResponse, DeleteAvatarApiArg>({
      query: queryArg => ({url: `/app/rest/avatars/${queryArg.userLocator}`, method: 'DELETE'}),
      invalidatesTags: (_, __, arg) => [{type: 'User', id: getIdFromLocator(arg.userLocator)}],
    }),
    getAvatar: build.query<GetAvatarApiResponse, GetAvatarApiArg>({
      query: queryArg => ({
        url: `/app/rest/avatars/${queryArg.userLocator}/${queryArg.size}/avatar.png`,
      }),
    }),
    getAvatarWithHash: build.query<GetAvatarWithHashApiResponse, GetAvatarWithHashApiArg>({
      query: queryArg => ({
        url: `/app/rest/avatars/${queryArg.userLocator}/${queryArg.size}/avatar.${queryArg.hash}.png`,
      }),
    }),
    getAllQueuedBuilds: build.query<GetAllQueuedBuildsApiResponse, GetAllQueuedBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addBuildToQueue: build.mutation<AddBuildToQueueApiResponse, AddBuildToQueueApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue`,
        method: 'POST',
        body: queryArg.build,
        params: {moveToTop: queryArg.moveToTop},
      }),
    }),
    deleteAllQueuedBuilds: build.mutation<
      DeleteAllQueuedBuildsApiResponse,
      DeleteAllQueuedBuildsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildQueue`,
        method: 'DELETE',
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    setQueuedBuildsOrder: build.mutation<
      SetQueuedBuildsOrderApiResponse,
      SetQueuedBuildsOrderApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildQueue/order`,
        method: 'PUT',
        body: queryArg.builds,
        params: {fields: queryArg.fields},
      }),
    }),
    getQueuedBuildPosition: build.query<
      GetQueuedBuildPositionApiResponse,
      GetQueuedBuildPositionApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildQueue/order/${queryArg.queuePosition}`,
        params: {fields: queryArg.fields},
      }),
    }),
    setQueuedBuildPosition: build.mutation<
      SetQueuedBuildPositionApiResponse,
      SetQueuedBuildPositionApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildQueue/order/${queryArg.queuePosition}`,
        method: 'PUT',
        body: queryArg.build,
        params: {fields: queryArg.fields},
      }),
    }),
    getApprovalInfo: build.query<GetApprovalInfoApiResponse, GetApprovalInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.buildLocator}/approvalInfo`,
        params: {fields: queryArg.fields},
      }),
    }),
    approveQueuedBuild: build.mutation<ApproveQueuedBuildApiResponse, ApproveQueuedBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.buildLocator}/approve`,
        method: 'POST',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
    }),
    getQueuedBuildTags: build.query<GetQueuedBuildTagsApiResponse, GetQueuedBuildTagsApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.buildLocator}/tags`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addTagsToBuildOfBuildQueue: build.mutation<
      AddTagsToBuildOfBuildQueueApiResponse,
      AddTagsToBuildOfBuildQueueApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.buildLocator}/tags`,
        method: 'POST',
        body: queryArg.tags,
      }),
    }),
    getQueuedBuild: build.query<GetQueuedBuildApiResponse, GetQueuedBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.queuedBuildLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    cancelQueuedBuild: build.mutation<CancelQueuedBuildApiResponse, CancelQueuedBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.queuedBuildLocator}`,
        method: 'POST',
        body: queryArg.buildCancelRequest,
      }),
    }),
    deleteQueuedBuild: build.mutation<DeleteQueuedBuildApiResponse, DeleteQueuedBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.queuedBuildLocator}`,
        method: 'DELETE',
      }),
    }),
    getCompatibleAgentsForBuild: build.query<
      GetCompatibleAgentsForBuildApiResponse,
      GetCompatibleAgentsForBuildApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildQueue/${queryArg.queuedBuildLocator}/compatibleAgents`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllBuildTypes: build.query<GetAllBuildTypesApiResponse, GetAllBuildTypesApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    createBuildType: build.mutation<CreateBuildTypeApiResponse, CreateBuildTypeApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes`,
        method: 'POST',
        body: queryArg.buildType,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildType: build.query<GetBuildTypeApiResponse, GetBuildTypeApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildTypeNormalized: build.query<NormalizedSingleBuildType, GetBuildTypeApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: processBuildType,
    }),
    deleteBuildType: build.mutation<DeleteBuildTypeApiResponse, DeleteBuildTypeApiArg>({
      query: queryArg => ({url: `/app/rest/buildTypes/${queryArg.btLocator}`, method: 'DELETE'}),
    }),
    getAllAgentRequirements: build.query<
      GetAllAgentRequirementsApiResponse,
      GetAllAgentRequirementsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements`,
        params: {fields: queryArg.fields},
      }),
    }),
    addAgentRequirementToBuildType: build.mutation<
      AddAgentRequirementToBuildTypeApiResponse,
      AddAgentRequirementToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements`,
        method: 'POST',
        body: queryArg.agentRequirement,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllAgentRequirements: build.mutation<
      ReplaceAllAgentRequirementsApiResponse,
      ReplaceAllAgentRequirementsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements`,
        method: 'PUT',
        body: queryArg.agentRequirements,
        params: {fields: queryArg.fields},
      }),
    }),
    getAgentRequirement: build.query<GetAgentRequirementApiResponse, GetAgentRequirementApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements/${queryArg.agentRequirementLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAgentRequirement: build.mutation<
      ReplaceAgentRequirementApiResponse,
      ReplaceAgentRequirementApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements/${queryArg.agentRequirementLocator}`,
        method: 'PUT',
        body: queryArg.agentRequirement,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteAgentRequirement: build.mutation<
      DeleteAgentRequirementApiResponse,
      DeleteAgentRequirementApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements/${queryArg.agentRequirementLocator}`,
        method: 'DELETE',
      }),
    }),
    getAgentRequirementParameter: build.query<
      GetAgentRequirementParameterApiResponse,
      GetAgentRequirementParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements/${queryArg.agentRequirementLocator}/${queryArg.fieldName}`,
      }),
    }),
    setAgentRequirementParameter: build.mutation<
      SetAgentRequirementParameterApiResponse,
      SetAgentRequirementParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/agent-requirements/${queryArg.agentRequirementLocator}/${queryArg.fieldName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getAliases: build.query<GetAliasesApiResponse, GetAliasesApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/aliases`,
        params: {field: queryArg.field},
      }),
    }),
    getAllArtifactDependencies: build.query<
      GetAllArtifactDependenciesApiResponse,
      GetAllArtifactDependenciesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies`,
        params: {fields: queryArg.fields},
      }),
    }),
    addArtifactDependencyToBuildType: build.mutation<
      AddArtifactDependencyToBuildTypeApiResponse,
      AddArtifactDependencyToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies`,
        method: 'POST',
        body: queryArg.artifactDependency,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllArtifactDependencies: build.mutation<
      ReplaceAllArtifactDependenciesApiResponse,
      ReplaceAllArtifactDependenciesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies`,
        method: 'PUT',
        body: queryArg.artifactDependencies,
        params: {fields: queryArg.fields},
      }),
    }),
    getArtifactDependency: build.query<
      GetArtifactDependencyApiResponse,
      GetArtifactDependencyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies/${queryArg.artifactDepLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceArtifactDependency: build.mutation<
      ReplaceArtifactDependencyApiResponse,
      ReplaceArtifactDependencyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies/${queryArg.artifactDepLocator}`,
        method: 'PUT',
        body: queryArg.artifactDependency,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteArtifactDependency: build.mutation<
      DeleteArtifactDependencyApiResponse,
      DeleteArtifactDependencyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies/${queryArg.artifactDepLocator}`,
        method: 'DELETE',
      }),
    }),
    getArtifactDependencyParameter: build.query<
      GetArtifactDependencyParameterApiResponse,
      GetArtifactDependencyParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies/${queryArg.artifactDepLocator}/${queryArg.fieldName}`,
      }),
    }),
    setArtifactDependencyParameter: build.mutation<
      SetArtifactDependencyParameterApiResponse,
      SetArtifactDependencyParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/artifact-dependencies/${queryArg.artifactDepLocator}/${queryArg.fieldName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getAllBranchesOfBuildType: build.query<
      GetAllBranchesOfBuildTypeApiResponse,
      GetAllBranchesOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/branches`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getBuildTypeBuildTags: build.query<
      GetBuildTypeBuildTagsApiResponse,
      GetBuildTypeBuildTagsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/buildTags`,
        params: {field: queryArg.field},
      }),
    }),
    getBuildTypeBuilds: build.query<GetBuildTypeBuildsApiResponse, GetBuildTypeBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/builds`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllBuildFeatures: build.query<GetAllBuildFeaturesApiResponse, GetAllBuildFeaturesApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features`,
        params: {fields: queryArg.fields},
      }),
    }),
    addBuildFeatureToBuildType: build.mutation<
      AddBuildFeatureToBuildTypeApiResponse,
      AddBuildFeatureToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features`,
        method: 'POST',
        body: queryArg.feature,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllBuildFeatures: build.mutation<
      ReplaceAllBuildFeaturesApiResponse,
      ReplaceAllBuildFeaturesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features`,
        method: 'PUT',
        body: queryArg.features,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildFeature: build.query<GetBuildFeatureApiResponse, GetBuildFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceBuildFeature: build.mutation<ReplaceBuildFeatureApiResponse, ReplaceBuildFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}`,
        method: 'PUT',
        body: queryArg.feature,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteFeatureOfBuildType: build.mutation<
      DeleteFeatureOfBuildTypeApiResponse,
      DeleteFeatureOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}`,
        method: 'DELETE',
      }),
    }),
    getAllBuildFeatureParameters: build.query<
      GetAllBuildFeatureParametersApiResponse,
      GetAllBuildFeatureParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}/parameters`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceBuildFeatureParameters: build.mutation<
      ReplaceBuildFeatureParametersApiResponse,
      ReplaceBuildFeatureParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}/parameters`,
        method: 'PUT',
        body: queryArg.properties,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildFeatureParameter: build.query<
      GetBuildFeatureParameterApiResponse,
      GetBuildFeatureParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}/parameters/${queryArg.parameterName}`,
      }),
    }),
    addParameterToBuildFeature: build.mutation<
      AddParameterToBuildFeatureApiResponse,
      AddParameterToBuildFeatureApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}/parameters/${queryArg.parameterName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildFeatureSetting: build.query<
      GetBuildFeatureSettingApiResponse,
      GetBuildFeatureSettingApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}/${queryArg.name}`,
      }),
    }),
    setBuildFeatureParameter: build.mutation<
      SetBuildFeatureParameterApiResponse,
      SetBuildFeatureParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/features/${queryArg.featureId}/${queryArg.name}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getAllInvestigationsOfBuildType: build.query<
      GetAllInvestigationsOfBuildTypeApiResponse,
      GetAllInvestigationsOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/investigations`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildParametersOfBuildType: build.query<
      GetBuildParametersOfBuildTypeApiResponse,
      GetBuildParametersOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    createBuildParameterOfBuildType: build.mutation<
      CreateBuildParameterOfBuildTypeApiResponse,
      CreateBuildParameterOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters`,
        method: 'POST',
        body: queryArg.property,
        params: {fields: queryArg.fields},
      }),
    }),
    updateBuildParametersOfBuildType: build.mutation<
      UpdateBuildParametersOfBuildTypeApiResponse,
      UpdateBuildParametersOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters`,
        method: 'PUT',
        body: queryArg.properties,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuildParametersOfBuildType: build.mutation<
      DeleteBuildParametersOfBuildTypeApiResponse,
      DeleteBuildParametersOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters`,
        method: 'DELETE',
      }),
    }),
    getBuildParameterOfBuildType: build.query<
      GetBuildParameterOfBuildTypeApiResponse,
      GetBuildParameterOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}`,
        params: {fields: queryArg.fields},
      }),
    }),
    updateBuildParameterOfBuildType: build.mutation<
      UpdateBuildParameterOfBuildTypeApiResponse,
      UpdateBuildParameterOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}`,
        method: 'PUT',
        body: queryArg.property,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuildParameterOfBuildType: build.mutation<
      DeleteBuildParameterOfBuildTypeApiResponse,
      DeleteBuildParameterOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getBuildParameterTypeOfBuildType: build.query<
      GetBuildParameterTypeOfBuildTypeApiResponse,
      GetBuildParameterTypeOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}/type`,
      }),
    }),
    updateBuildParameterTypeOfBuildType: build.mutation<
      UpdateBuildParameterTypeOfBuildTypeApiResponse,
      UpdateBuildParameterTypeOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}/type`,
        method: 'PUT',
        body: queryArg.type,
      }),
    }),
    getBuildParameterSpecificationOfBuildType: build.query<
      GetBuildParameterSpecificationOfBuildTypeApiResponse,
      GetBuildParameterSpecificationOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}/type/rawValue`,
      }),
    }),
    updateBuildParameterSpecificationOfBuildType: build.mutation<
      UpdateBuildParameterSpecificationOfBuildTypeApiResponse,
      UpdateBuildParameterSpecificationOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}/type/rawValue`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildParameterValueOfBuildType: build.query<
      GetBuildParameterValueOfBuildTypeApiResponse,
      GetBuildParameterValueOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}/value`,
      }),
    }),
    updateBuildParameterValueOfBuildType: build.mutation<
      UpdateBuildParameterValueOfBuildTypeApiResponse,
      UpdateBuildParameterValueOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/parameters/${queryArg.name}/value`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildTypeSettingsFile: build.query<
      GetBuildTypeSettingsFileApiResponse,
      GetBuildTypeSettingsFileApiArg
    >({
      query: queryArg => ({url: `/app/rest/buildTypes/${queryArg.btLocator}/settingsFile`}),
    }),
    getAllSnapshotDependencies: build.query<
      GetAllSnapshotDependenciesApiResponse,
      GetAllSnapshotDependenciesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/snapshot-dependencies`,
        params: {fields: queryArg.fields},
      }),
    }),
    addSnapshotDependencyToBuildType: build.mutation<
      AddSnapshotDependencyToBuildTypeApiResponse,
      AddSnapshotDependencyToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/snapshot-dependencies`,
        method: 'POST',
        body: queryArg.snapshotDependency,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllSnapshotDependencies: build.mutation<
      ReplaceAllSnapshotDependenciesApiResponse,
      ReplaceAllSnapshotDependenciesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/snapshot-dependencies`,
        method: 'PUT',
        body: queryArg.snapshotDependencies,
        params: {fields: queryArg.fields},
      }),
    }),
    getSnapshotDependency: build.query<
      GetSnapshotDependencyApiResponse,
      GetSnapshotDependencyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/snapshot-dependencies/${queryArg.snapshotDepLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceSnapshotDependency: build.mutation<
      ReplaceSnapshotDependencyApiResponse,
      ReplaceSnapshotDependencyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/snapshot-dependencies/${queryArg.snapshotDepLocator}`,
        method: 'PUT',
        body: queryArg.snapshotDependency,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteSnapshotDependency: build.mutation<
      DeleteSnapshotDependencyApiResponse,
      DeleteSnapshotDependencyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/snapshot-dependencies/${queryArg.snapshotDepLocator}`,
        method: 'DELETE',
      }),
    }),
    getAllBuildSteps: build.query<GetAllBuildStepsApiResponse, GetAllBuildStepsApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps`,
        params: {fields: queryArg.fields},
      }),
    }),
    addBuildStepToBuildType: build.mutation<
      AddBuildStepToBuildTypeApiResponse,
      AddBuildStepToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps`,
        method: 'POST',
        body: queryArg.step,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllBuildSteps: build.mutation<
      ReplaceAllBuildStepsApiResponse,
      ReplaceAllBuildStepsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps`,
        method: 'PUT',
        body: queryArg.steps,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildStep: build.query<GetBuildStepApiResponse, GetBuildStepApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceBuildStep: build.mutation<ReplaceBuildStepApiResponse, ReplaceBuildStepApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}`,
        method: 'PUT',
        body: queryArg.step,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuildStep: build.mutation<DeleteBuildStepApiResponse, DeleteBuildStepApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}`,
        method: 'DELETE',
      }),
    }),
    getAllBuildStepParameters: build.query<
      GetAllBuildStepParametersApiResponse,
      GetAllBuildStepParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}/parameters`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuildStepParameters: build.mutation<
      DeleteBuildStepParametersApiResponse,
      DeleteBuildStepParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}/parameters`,
        method: 'PUT',
        body: queryArg.properties,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildStepParameter: build.query<
      GetBuildStepParameterApiResponse,
      GetBuildStepParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}/parameters/${queryArg.parameterName}`,
      }),
    }),
    addParameterToBuildStep: build.mutation<
      AddParameterToBuildStepApiResponse,
      AddParameterToBuildStepApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}/parameters/${queryArg.parameterName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildStepSetting: build.query<GetBuildStepSettingApiResponse, GetBuildStepSettingApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}/${queryArg.fieldName}`,
      }),
    }),
    setBuildStepParameter: build.mutation<
      SetBuildStepParameterApiResponse,
      SetBuildStepParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/steps/${queryArg.stepId}/${queryArg.fieldName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getAllBuildTemplates: build.query<GetAllBuildTemplatesApiResponse, GetAllBuildTemplatesApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/templates`,
        params: {fields: queryArg.fields},
      }),
    }),
    addBuildTemplate: build.mutation<AddBuildTemplateApiResponse, AddBuildTemplateApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/templates`,
        method: 'POST',
        body: queryArg.buildType,
        params: {optimizeSettings: queryArg.optimizeSettings, fields: queryArg.fields},
      }),
    }),
    setBuildTypeTemplates: build.mutation<
      SetBuildTypeTemplatesApiResponse,
      SetBuildTypeTemplatesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/templates`,
        method: 'PUT',
        body: queryArg.buildTypes,
        params: {optimizeSettings: queryArg.optimizeSettings, fields: queryArg.fields},
      }),
    }),
    removeAllTemplates: build.mutation<RemoveAllTemplatesApiResponse, RemoveAllTemplatesApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/templates`,
        method: 'DELETE',
        params: {inlineSettings: queryArg.inlineSettings},
      }),
    }),
    getBuildTemplate: build.query<GetBuildTemplateApiResponse, GetBuildTemplateApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/templates/${queryArg.templateLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    removeTemplate: build.mutation<RemoveTemplateApiResponse, RemoveTemplateApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/templates/${queryArg.templateLocator}`,
        method: 'DELETE',
        params: {inlineSettings: queryArg.inlineSettings},
      }),
    }),
    getAllTriggers: build.query<GetAllTriggersApiResponse, GetAllTriggersApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers`,
        params: {fields: queryArg.fields},
      }),
    }),
    addTriggerToBuildType: build.mutation<
      AddTriggerToBuildTypeApiResponse,
      AddTriggerToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers`,
        method: 'POST',
        body: queryArg.trigger,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllTriggers: build.mutation<ReplaceAllTriggersApiResponse, ReplaceAllTriggersApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers`,
        method: 'PUT',
        body: queryArg.triggers,
        params: {fields: queryArg.fields},
      }),
    }),
    getTrigger: build.query<GetTriggerApiResponse, GetTriggerApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers/${queryArg.triggerLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceTrigger: build.mutation<ReplaceTriggerApiResponse, ReplaceTriggerApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers/${queryArg.triggerLocator}`,
        method: 'PUT',
        body: queryArg.trigger,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteTrigger: build.mutation<DeleteTriggerApiResponse, DeleteTriggerApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers/${queryArg.triggerLocator}`,
        method: 'DELETE',
      }),
    }),
    getTriggerParameter: build.query<GetTriggerParameterApiResponse, GetTriggerParameterApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers/${queryArg.triggerLocator}/${queryArg.fieldName}`,
      }),
    }),
    setTriggerParameter: build.mutation<SetTriggerParameterApiResponse, SetTriggerParameterApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/triggers/${queryArg.triggerLocator}/${queryArg.fieldName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getAllVcsRootsOfBuildType: build.query<
      GetAllVcsRootsOfBuildTypeApiResponse,
      GetAllVcsRootsOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries`,
        params: {fields: queryArg.fields},
      }),
    }),
    addVcsRootToBuildType: build.mutation<
      AddVcsRootToBuildTypeApiResponse,
      AddVcsRootToBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries`,
        method: 'POST',
        body: queryArg.vcsRootEntry,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceAllVcsRoots: build.mutation<ReplaceAllVcsRootsApiResponse, ReplaceAllVcsRootsApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries`,
        method: 'PUT',
        body: queryArg.vcsRootEntries,
        params: {fields: queryArg.fields},
      }),
    }),
    getVcsRoot: build.query<GetVcsRootApiResponse, GetVcsRootApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries/${queryArg.vcsRootLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    updateBuildTypeVcsRoot: build.mutation<
      UpdateBuildTypeVcsRootApiResponse,
      UpdateBuildTypeVcsRootApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries/${queryArg.vcsRootLocator}`,
        method: 'PUT',
        body: queryArg.vcsRootEntry,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteVcsRootOfBuildType: build.mutation<
      DeleteVcsRootOfBuildTypeApiResponse,
      DeleteVcsRootOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries/${queryArg.vcsRootLocator}`,
        method: 'DELETE',
      }),
    }),
    getVcsRootCheckoutRules: build.query<
      GetVcsRootCheckoutRulesApiResponse,
      GetVcsRootCheckoutRulesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries/${queryArg.vcsRootLocator}/checkout-rules`,
      }),
    }),
    updateBuildTypeVcsRootCheckoutRules: build.mutation<
      UpdateBuildTypeVcsRootCheckoutRulesApiResponse,
      UpdateBuildTypeVcsRootCheckoutRulesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs-root-entries/${queryArg.vcsRootLocator}/checkout-rules`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getFilesListOfBuildType: build.query<
      GetFilesListOfBuildTypeApiResponse,
      GetFilesListOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs/files/latest`,
        params: {
          basePath: queryArg.basePath,
          locator: queryArg.locator,
          fields: queryArg.fields,
          resolveParameters: queryArg.resolveParameters,
        },
      }),
    }),
    getZippedFileOfBuildType: build.query<
      GetZippedFileOfBuildTypeApiResponse,
      GetZippedFileOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs/files/latest/archived${queryArg.path}`,
        params: {
          basePath: queryArg.basePath,
          locator: queryArg.locator,
          name: queryArg.name,
          resolveParameters: queryArg.resolveParameters,
        },
      }),
    }),
    downloadFileOfBuildType: build.query<
      DownloadFileOfBuildTypeApiResponse,
      DownloadFileOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs/files/latest/files${queryArg.path}`,
        params: {resolveParameters: queryArg.resolveParameters},
      }),
    }),
    getFileMetadataOfBuildType: build.query<
      GetFileMetadataOfBuildTypeApiResponse,
      GetFileMetadataOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs/files/latest/metadata${queryArg.path}`,
        params: {fields: queryArg.fields, resolveParameters: queryArg.resolveParameters},
      }),
    }),
    getFilesListForSubpathOfBuildType: build.query<
      GetFilesListForSubpathOfBuildTypeApiResponse,
      GetFilesListForSubpathOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcs/files/latest/${queryArg.path}`,
        params: {
          basePath: queryArg.basePath,
          locator: queryArg.locator,
          fields: queryArg.fields,
          resolveParameters: queryArg.resolveParameters,
        },
      }),
    }),
    getVcsRootInstancesOfBuildType: build.query<
      GetVcsRootInstancesOfBuildTypeApiResponse,
      GetVcsRootInstancesOfBuildTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/vcsRootInstances`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildTypeField: build.query<GetBuildTypeFieldApiResponse, GetBuildTypeFieldApiArg>({
      query: queryArg => ({url: `/app/rest/buildTypes/${queryArg.btLocator}/${queryArg.field}`}),
    }),
    setBuildTypeField: build.mutation<SetBuildTypeFieldApiResponse, SetBuildTypeFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
        responseHandler: 'text',
      }),
    }),
    getAllBuilds: build.query<GetAllBuildsApiResponse, GetAllBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getAllBuildsNormalized: build.query<NormalizedBuilds, GetAllBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
      transformResponse: (data: Builds) => normalizeBuilds(data.build ?? []),
    }),
    getAggregatedBuildStatus: build.query<
      GetAggregatedBuildStatusApiResponse,
      GetAggregatedBuildStatusApiArg
    >({
      query: queryArg => ({url: `/app/rest/builds/aggregated/${queryArg.buildLocator}/status`}),
    }),
    getAggregatedBuildStatusIcon: build.query<
      GetAggregatedBuildStatusIconApiResponse,
      GetAggregatedBuildStatusIconApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/aggregated/${queryArg.buildLocator}/statusIcon${queryArg.suffix}`,
      }),
    }),
    getMultipleBuilds: build.query<GetMultipleBuildsApiResponse, GetMultipleBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    cancelMultiple: build.mutation<CancelMultipleApiResponse, CancelMultipleApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}`,
        method: 'POST',
        body: queryArg.buildCancelRequest,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteMultipleBuilds: build.mutation<
      DeleteMultipleBuildsApiResponse,
      DeleteMultipleBuildsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}`,
        method: 'DELETE',
        params: {fields: queryArg.fields},
      }),
    }),
    setMultipleBuildComments: build.mutation<
      SetMultipleBuildCommentsApiResponse,
      SetMultipleBuildCommentsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/comment`,
        method: 'PUT',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteMultipleBuildComments: build.mutation<
      DeleteMultipleBuildCommentsApiResponse,
      DeleteMultipleBuildCommentsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/comment`,
        method: 'DELETE',
        params: {fields: queryArg.fields},
      }),
    }),
    pinMultipleBuilds: build.mutation<PinMultipleBuildsApiResponse, PinMultipleBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/pinInfo`,
        method: 'PUT',
        body: queryArg.pinInfo,
        params: {fields: queryArg.fields},
      }),
    }),
    addTagsToMultipleBuilds: build.mutation<
      AddTagsToMultipleBuildsApiResponse,
      AddTagsToMultipleBuildsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/tags`,
        method: 'POST',
        body: queryArg.tags,
        params: {fields: queryArg.fields},
      }),
    }),
    removeMultipleBuildTags: build.mutation<
      RemoveMultipleBuildTagsApiResponse,
      RemoveMultipleBuildTagsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/tags`,
        method: 'DELETE',
        body: queryArg.tags,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuild: build.query<GetBuildApiResponse, GetBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildNormalized: build.query<NormalizedBuild, GetBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: (data: Build) => {
        const normalizedBuild = normalizeBuild(data)
        return {
          result: normalizedBuild.result,
          entities: {
            ...normalizedBuild.entities,
            ...normalizeBuildFirstBuildWithSameChanges(data).entities,
          },
        }
      },
    }),
    getBuildNormalizedAsList: build.query<NormalizedBuilds, GetBuildNormalizedAsListApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: (data: Build, _, options) => {
        const builds = normalizeBuilds([data])
        let entities = {...builds.entities}
        if (options?.withTestOccurrencesCount === true) {
          entities = {...entities, ...normalizeBuildsTestOccurrencesCount([data]).entities}
        }
        return {
          result: builds.result,
          entities,
        }
      },
      providesTags: data => [{type: 'Build', id: data?.result[0]}],
    }),
    cancelBuild: build.mutation<CancelBuildApiResponse, CancelBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        method: 'POST',
        body: queryArg.buildCancelRequest,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuild: build.mutation<DeleteBuildApiResponse, DeleteBuildApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}`, method: 'DELETE'}),
    }),
    getArtifactDependencyChanges: build.query<
      GetArtifactDependencyChangesApiResponse,
      GetArtifactDependencyChangesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/artifactDependencyChanges`,
        params: {fields: queryArg.fields},
      }),
    }),
    getFilesListOfBuild: build.query<GetFilesListOfBuildApiResponse, GetFilesListOfBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/artifacts`,
        params: {
          basePath: queryArg.basePath,
          locator: queryArg.locator,
          fields: queryArg.fields,
          resolveParameters: queryArg.resolveParameters,
          logBuildUsage: queryArg.logBuildUsage,
        },
      }),
    }),
    getZippedFileOfBuild: build.query<GetZippedFileOfBuildApiResponse, GetZippedFileOfBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/artifacts/archived${queryArg.path}`,
        params: {
          basePath: queryArg.basePath,
          locator: queryArg.locator,
          name: queryArg.name,
          resolveParameters: queryArg.resolveParameters,
          logBuildUsage: queryArg.logBuildUsage,
        },
      }),
    }),
    downloadFileOfBuild: build.query<DownloadFileOfBuildApiResponse, DownloadFileOfBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/artifacts/files${queryArg.path}`,
        params: {
          resolveParameters: queryArg.resolveParameters,
          logBuildUsage: queryArg.logBuildUsage,
        },
      }),
    }),
    getFileMetadataOfBuild: build.query<
      GetFileMetadataOfBuildApiResponse,
      GetFileMetadataOfBuildApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/artifacts/metadata${queryArg.path}`,
        params: {
          fields: queryArg.fields,
          resolveParameters: queryArg.resolveParameters,
          logBuildUsage: queryArg.logBuildUsage,
        },
      }),
    }),
    getFilesListForSubpathOfBuild: build.query<
      GetFilesListForSubpathOfBuildApiResponse,
      GetFilesListForSubpathOfBuildApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/artifacts/${queryArg.path}`,
        params: {
          basePath: queryArg.basePath,
          locator: queryArg.locator,
          fields: queryArg.fields,
          resolveParameters: queryArg.resolveParameters,
          logBuildUsage: queryArg.logBuildUsage,
        },
      }),
    }),
    getArtifactsDirectory: build.query<
      GetArtifactsDirectoryApiResponse,
      GetArtifactsDirectoryApiArg
    >({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/artifactsDirectory`}),
    }),
    resetBuildFinishProperties: build.mutation<
      ResetBuildFinishPropertiesApiResponse,
      ResetBuildFinishPropertiesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/caches/finishProperties`,
        method: 'DELETE',
      }),
    }),
    getCanceledInfo: build.query<GetCanceledInfoApiResponse, GetCanceledInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/canceledInfo`,
        params: {fields: queryArg.fields},
      }),
    }),
    setBuildComment: build.mutation<SetBuildCommentApiResponse, SetBuildCommentApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/comment`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteBuildComment: build.mutation<DeleteBuildCommentApiResponse, DeleteBuildCommentApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/comment`,
        method: 'DELETE',
      }),
    }),
    setFinishedTime: build.mutation<SetFinishedTimeApiResponse, SetFinishedTimeApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/finish`, method: 'PUT'}),
    }),
    getBuildFinishDate: build.query<GetBuildFinishDateApiResponse, GetBuildFinishDateApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/finishDate`}),
    }),
    setBuildFinishDate: build.mutation<SetBuildFinishDateApiResponse, SetBuildFinishDateApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/finishDate`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    addLogMessageToBuild: build.mutation<
      AddLogMessageToBuildApiResponse,
      AddLogMessageToBuildApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/log`,
        method: 'POST',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildNumber: build.query<GetBuildNumberApiResponse, GetBuildNumberApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/number`}),
    }),
    setBuildNumber: build.mutation<SetBuildNumberApiResponse, SetBuildNumberApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/number`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildPinInfo: build.query<GetBuildPinInfoApiResponse, GetBuildPinInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/pinInfo`,
        params: {fields: queryArg.fields},
      }),
    }),
    setBuildPinInfo: build.mutation<SetBuildPinInfoApiResponse, SetBuildPinInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/pinInfo`,
        method: 'PUT',
        body: queryArg.pinInfo,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildProblems: build.query<GetBuildProblemsApiResponse, GetBuildProblemsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/problemOccurrences`,
        params: {fields: queryArg.fields},
      }),
    }),
    addProblemToBuild: build.mutation<AddProblemToBuildApiResponse, AddProblemToBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/problemOccurrences`,
        method: 'POST',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildRelatedIssues: build.query<
      GetBuildRelatedIssuesApiResponse,
      GetBuildRelatedIssuesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/relatedIssues`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildResolved: build.query<GetBuildResolvedApiResponse, GetBuildResolvedApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/resolved/${queryArg.value}`,
      }),
    }),
    getBuildActualParameters: build.query<
      GetBuildActualParametersApiResponse,
      GetBuildActualParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/resulting-properties`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildResultingProperties: build.query<
      GetBuildResultingPropertiesApiResponse,
      GetBuildResultingPropertiesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/resulting-properties/${queryArg.propertyName}`,
      }),
    }),
    markBuildAsRunning: build.mutation<MarkBuildAsRunningApiResponse, MarkBuildAsRunningApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/runningData`,
        method: 'PUT',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildSourceFile: build.query<GetBuildSourceFileApiResponse, GetBuildSourceFileApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/sources/files/${queryArg.fileName}`,
      }),
    }),
    getBuildStatisticValues: build.query<
      GetBuildStatisticValuesApiResponse,
      GetBuildStatisticValuesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/statistics`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildStatisticValue: build.query<
      GetBuildStatisticValueApiResponse,
      GetBuildStatisticValueApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/statistics/${queryArg.name}`,
      }),
    }),
    getBuildStatus: build.query<GetBuildStatusApiResponse, GetBuildStatusApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/status`}),
    }),
    setBuildStatus: build.mutation<SetBuildStatusApiResponse, SetBuildStatusApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/status`,
        method: 'POST',
        body: queryArg.buildStatusUpdate,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildStatusIcon: build.query<GetBuildStatusIconApiResponse, GetBuildStatusIconApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/statusIcon${queryArg.suffix}`,
      }),
    }),
    getBuildStatusText: build.query<GetBuildStatusTextApiResponse, GetBuildStatusTextApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/statusText`}),
    }),
    setBuildStatusText: build.mutation<SetBuildStatusTextApiResponse, SetBuildStatusTextApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/statusText`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildTags: build.query<GetBuildTagsApiResponse, GetBuildTagsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/tags`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addTagsToBuild: build.mutation<AddTagsToBuildApiResponse, AddTagsToBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/tags`,
        method: 'POST',
        body: queryArg.tags,
        params: {fields: queryArg.fields},
      }),
    }),
    setBuildTags: build.mutation<SetBuildTagsApiResponse, SetBuildTagsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/tags`,
        method: 'PUT',
        body: queryArg.tags,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getBuildTestOccurrences: build.query<
      GetBuildTestOccurrencesApiResponse,
      GetBuildTestOccurrencesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/testOccurrences`,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildVcsLabels: build.query<GetBuildVcsLabelsApiResponse, GetBuildVcsLabelsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/vcsLabels`,
        params: {fields: queryArg.fields},
      }),
    }),
    addBuildVcsLabel: build.mutation<AddBuildVcsLabelApiResponse, AddBuildVcsLabelApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/vcsLabels`,
        method: 'POST',
        body: queryArg.body,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getBuildField: build.query<GetBuildFieldApiResponse, GetBuildFieldApiArg>({
      query: queryArg => ({url: `/app/rest/builds/${queryArg.buildLocator}/${queryArg.field}`}),
    }),
    getAllChanges: build.query<GetAllChangesApiTransformedResponse, GetAllChangesApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
      transformResponse: ({change, ...rest}: GetAllChangesApiResponse) => ({
        changes: change && normalizeChanges(change),
        ...rest,
      }),
    }),
    getChange: build.query<GetChangeApiResponse, GetChangeApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getModificationsOfChange: build.query<Normalized<ChangeId>, GetChangeApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: normalizeModificationsOfChange,
    }),
    getChangeAttributes: build.query<GetChangeAttributesApiResponse, GetChangeAttributesApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}/attributes`,
        params: {fields: queryArg.fields},
      }),
    }),
    getChangeDuplicates: build.query<GetChangeDuplicatesApiResponse, GetChangeDuplicatesApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}/duplicates`,
        params: {fields: queryArg.fields},
      }),
    }),
    getChangeIssue: build.query<GetChangeIssueApiResponse, GetChangeIssueApiArg>({
      query: queryArg => ({url: `/app/rest/changes/${queryArg.changeLocator}/issues`}),
    }),
    getChangeParentChanges: build.query<
      GetChangeParentChangesApiResponse,
      GetChangeParentChangesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}/parentChanges`,
        params: {fields: queryArg.fields},
      }),
    }),
    getChangeParentRevisions: build.query<
      GetChangeParentRevisionsApiResponse,
      GetChangeParentRevisionsApiArg
    >({
      query: queryArg => ({url: `/app/rest/changes/${queryArg.changeLocator}/parentRevisions`}),
    }),
    getChangeVcsRoot: build.query<GetChangeVcsRootApiResponse, GetChangeVcsRootApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}/vcsRootInstance`,
        params: {fields: queryArg.fields},
      }),
    }),
    getChangeField: build.query<GetChangeFieldApiResponse, GetChangeFieldApiArg>({
      query: queryArg => ({url: `/app/rest/changes/${queryArg.changeLocator}/${queryArg.field}`}),
    }),
    getAllCloudImages: build.query<GetAllCloudImagesApiResponse, GetAllCloudImagesApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/images`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getCloudImage: build.query<GetCloudImageApiResponse, GetCloudImageApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/images/${queryArg.imageLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllCloudInstances: build.query<GetAllCloudInstancesApiResponse, GetAllCloudInstancesApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/instances`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    startInstance: build.mutation<StartInstanceApiResponse, StartInstanceApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/instances`,
        method: 'POST',
        body: queryArg.cloudInstance,
        params: {fields: queryArg.fields},
      }),
    }),
    getCloudInstance: build.query<GetCloudInstanceApiResponse, GetCloudInstanceApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/instances/${queryArg.instanceLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    stopInstance: build.mutation<StopInstanceApiResponse, StopInstanceApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/instances/${queryArg.instanceLocator}`,
        method: 'DELETE',
      }),
    }),
    getAllCloudProfiles: build.query<GetAllCloudProfilesApiResponse, GetAllCloudProfilesApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/profiles`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getCloudProfile: build.query<GetCloudProfileApiResponse, GetCloudProfileApiArg>({
      query: queryArg => ({
        url: `/app/rest/cloud/profiles/${queryArg.profileLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getHealthItems: build.query<GetHealthItemsApiResponse, GetHealthItemsApiArg>({
      query: queryArg => ({
        url: `/app/rest/health`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getCategories: build.query<GetCategoriesApiResponse, GetCategoriesApiArg>({
      query: queryArg => ({
        url: `/app/rest/health/category`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getSingleCategory: build.query<GetSingleCategoryApiResponse, GetSingleCategoryApiArg>({
      query: queryArg => ({
        url: `/app/rest/health/category/${queryArg.locator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getSingleHealthItem: build.query<GetSingleHealthItemApiResponse, GetSingleHealthItemApiArg>({
      query: queryArg => ({
        url: `/app/rest/health/${queryArg.locator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getPluginInfo: build.query<GetPluginInfoApiResponse, GetPluginInfoApiArg>({
      query: queryArg => ({url: `/app/rest/info`, params: {fields: queryArg.fields}}),
    }),
    getAllInvestigations: build.query<GetAllInvestigationsApiResponse, GetAllInvestigationsApiArg>({
      query: queryArg => ({
        url: `/app/rest/investigations`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addInvestigation: build.mutation<AddInvestigationApiResponse, AddInvestigationApiArg>({
      query: queryArg => ({
        url: `/app/rest/investigations`,
        method: 'POST',
        body: queryArg.investigation,
        params: {fields: queryArg.fields},
      }),
    }),
    addMultipleInvestigations: build.mutation<
      AddMultipleInvestigationsApiResponse,
      AddMultipleInvestigationsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/investigations/multiple`,
        method: 'POST',
        body: queryArg.investigations,
        params: {fields: queryArg.fields},
      }),
    }),
    getInvestigation: build.query<GetInvestigationApiResponse, GetInvestigationApiArg>({
      query: queryArg => ({
        url: `/app/rest/investigations/${queryArg.investigationLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    replaceInvestigation: build.mutation<
      ReplaceInvestigationApiResponse,
      ReplaceInvestigationApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/investigations/${queryArg.investigationLocator}`,
        method: 'PUT',
        body: queryArg.investigation,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteInvestigation: build.mutation<DeleteInvestigationApiResponse, DeleteInvestigationApiArg>({
      query: queryArg => ({
        url: `/app/rest/investigations/${queryArg.investigationLocator}`,
        method: 'DELETE',
      }),
    }),
    getAllMutedTests: build.query<GetAllMutedTestsApiResponse, GetAllMutedTestsApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    muteTest: build.mutation<MuteTestApiResponse, MuteTestApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes`,
        method: 'POST',
        body: queryArg.mute,
        params: {fields: queryArg.fields},
      }),
    }),
    muteMultipleTests: build.mutation<MuteMultipleTestsApiResponse, MuteMultipleTestsApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes/multiple`,
        method: 'POST',
        body: queryArg.mutes,
        params: {fields: queryArg.fields},
      }),
    }),
    getMutedTest: build.query<GetMutedTestApiResponse, GetMutedTestApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes/${queryArg.muteLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    unmuteTest: build.mutation<UnmuteTestApiResponse, UnmuteTestApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes/${queryArg.muteLocator}`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    getAllBuildProblemOccurrences: build.query<
      GetAllBuildProblemOccurrencesApiResponse,
      GetAllBuildProblemOccurrencesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/problemOccurrences`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getBuildProblemOccurrence: build.query<
      GetBuildProblemOccurrenceApiResponse,
      GetBuildProblemOccurrenceApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/problemOccurrences/${queryArg.problemLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllBuildProblems: build.query<GetAllBuildProblemsApiResponse, GetAllBuildProblemsApiArg>({
      query: queryArg => ({
        url: `/app/rest/problems`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getBuildProblem: build.query<GetBuildProblemApiResponse, GetBuildProblemApiArg>({
      query: queryArg => ({
        url: `/app/rest/problems/${queryArg.problemLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllProjects: build.query<GetAllProjectsApiResponse, GetAllProjectsApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addProject: build.mutation<AddProjectApiResponse, AddProjectApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects`,
        method: 'POST',
        body: queryArg.newProjectDescription,
      }),
    }),
    getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteProject: build.mutation<DeleteProjectApiResponse, DeleteProjectApiArg>({
      query: queryArg => ({url: `/app/rest/projects/${queryArg.projectLocator}`, method: 'DELETE'}),
    }),
    getAgentPoolsProject: build.query<GetAgentPoolsProjectApiResponse, GetAgentPoolsProjectApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/agentPools`,
        params: {fields: queryArg.fields},
      }),
    }),
    addAgentPoolsProject: build.mutation<
      AddAgentPoolsProjectApiResponse,
      AddAgentPoolsProjectApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/agentPools`,
        method: 'POST',
        body: queryArg.agentPool,
      }),
    }),
    setAgentPoolsProject: build.mutation<
      SetAgentPoolsProjectApiResponse,
      SetAgentPoolsProjectApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/agentPools`,
        method: 'PUT',
        body: queryArg.agentPools,
        params: {fields: queryArg.fields},
      }),
    }),
    removeProjectFromAgentPool: build.mutation<
      RemoveProjectFromAgentPoolApiResponse,
      RemoveProjectFromAgentPoolApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/agentPools/${queryArg.agentPoolLocator}`,
        method: 'DELETE',
      }),
    }),
    getAllBranches: build.query<GetAllBranchesApiResponse, GetAllBranchesApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/branches`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addBuildType: build.mutation<AddBuildTypeApiResponse, AddBuildTypeApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/buildTypes`,
        method: 'POST',
        body: queryArg.newBuildTypeDescription,
        params: {fields: queryArg.fields},
      }),
    }),
    getDefaultTemplate: build.query<GetDefaultTemplateApiResponse, GetDefaultTemplateApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/defaultTemplate`,
        params: {fields: queryArg.fields},
      }),
    }),
    setDefaultTemplate: build.mutation<SetDefaultTemplateApiResponse, SetDefaultTemplateApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/defaultTemplate`,
        method: 'PUT',
        body: queryArg.buildType,
        params: {fields: queryArg.fields},
      }),
    }),
    removeDefaultTemplate: build.mutation<
      RemoveDefaultTemplateApiResponse,
      RemoveDefaultTemplateApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/defaultTemplate`,
        method: 'DELETE',
        params: {fields: queryArg.fields},
      }),
    }),
    getAllBuildTypesOrdered: build.query<
      GetAllBuildTypesOrderedApiResponse,
      GetAllBuildTypesOrderedApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/order/buildTypes`,
        params: {field: queryArg.field},
      }),
    }),
    setBuildTypesOrder: build.mutation<SetBuildTypesOrderApiResponse, SetBuildTypesOrderApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/order/buildTypes`,
        method: 'PUT',
        body: queryArg.buildTypes,
        params: {field: queryArg.field},
      }),
    }),
    getAllSubprojectsOrdered: build.query<
      GetAllSubprojectsOrderedApiResponse,
      GetAllSubprojectsOrderedApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/order/projects`,
        params: {field: queryArg.field},
      }),
    }),
    setSubprojectsOrder: build.mutation<SetSubprojectsOrderApiResponse, SetSubprojectsOrderApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/order/projects`,
        method: 'PUT',
        body: queryArg.projects,
        params: {field: queryArg.field},
      }),
    }),
    getBuildParameters: build.query<GetBuildParametersApiResponse, GetBuildParametersApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    createBuildParameter: build.mutation<
      CreateBuildParameterApiResponse,
      CreateBuildParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters`,
        method: 'POST',
        body: queryArg.property,
        params: {fields: queryArg.fields},
      }),
    }),
    updateBuildParameters: build.mutation<
      UpdateBuildParametersApiResponse,
      UpdateBuildParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters`,
        method: 'PUT',
        body: queryArg.properties,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuildParameters: build.mutation<
      DeleteBuildParametersApiResponse,
      DeleteBuildParametersApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters`,
        method: 'DELETE',
      }),
    }),
    getBuildParameter: build.query<GetBuildParameterApiResponse, GetBuildParameterApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}`,
        params: {fields: queryArg.fields},
      }),
    }),
    updateBuildParameter: build.mutation<
      UpdateBuildParameterApiResponse,
      UpdateBuildParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}`,
        method: 'PUT',
        body: queryArg.property,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteBuildParameter: build.mutation<
      DeleteBuildParameterApiResponse,
      DeleteBuildParameterApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getBuildParameterType: build.query<
      GetBuildParameterTypeApiResponse,
      GetBuildParameterTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}/type`,
      }),
    }),
    updateBuildParameterType: build.mutation<
      UpdateBuildParameterTypeApiResponse,
      UpdateBuildParameterTypeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}/type`,
        method: 'PUT',
        body: queryArg.type,
      }),
    }),
    getBuildParameterSpecification: build.query<
      GetBuildParameterSpecificationApiResponse,
      GetBuildParameterSpecificationApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}/type/rawValue`,
      }),
    }),
    updateBuildParameterSpecification: build.mutation<
      UpdateBuildParameterSpecificationApiResponse,
      UpdateBuildParameterSpecificationApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}/type/rawValue`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getBuildParameterValue: build.query<
      GetBuildParameterValueApiResponse,
      GetBuildParameterValueApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}/value`,
      }),
    }),
    updateBuildParameterValue: build.mutation<
      UpdateBuildParameterValueApiResponse,
      UpdateBuildParameterValueApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parameters/${queryArg.name}/value`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getProjectParentProject: build.query<
      GetProjectParentProjectApiResponse,
      GetProjectParentProjectApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parentProject`,
        params: {fields: queryArg.fields},
      }),
    }),
    setParentProject: build.mutation<SetParentProjectApiResponse, SetParentProjectApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/parentProject`,
        method: 'PUT',
        body: queryArg.project,
        params: {fields: queryArg.fields},
      }),
    }),
    getFeatures: build.query<GetFeaturesApiResponse, GetFeaturesApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addFeature: build.mutation<AddFeatureApiResponse, AddFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures`,
        method: 'POST',
        body: queryArg.projectFeature,
        params: {fields: queryArg.fields},
      }),
    }),
    updateFeatures: build.mutation<UpdateFeaturesApiResponse, UpdateFeaturesApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures`,
        method: 'PUT',
        body: queryArg.projectFeatures,
        params: {fields: queryArg.fields},
      }),
    }),
    getFeature: build.query<GetFeatureApiResponse, GetFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures/${queryArg.featureLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    updateFeature: build.mutation<UpdateFeatureApiResponse, UpdateFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures/${queryArg.featureLocator}`,
        method: 'PUT',
        body: queryArg.projectFeature,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteFeature: build.mutation<DeleteFeatureApiResponse, DeleteFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures/${queryArg.featureLocator}`,
        method: 'DELETE',
      }),
    }),
    addSecureToken: build.mutation<AddSecureTokenApiResponse, AddSecureTokenApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/secure/tokens`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getSecureValue: build.query<GetSecureValueApiResponse, GetSecureValueApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/secure/values/${queryArg.token}`,
      }),
    }),
    getProjectSettingsFile: build.query<
      GetProjectSettingsFileApiResponse,
      GetProjectSettingsFileApiArg
    >({
      query: queryArg => ({url: `/app/rest/projects/${queryArg.projectLocator}/settingsFile`}),
    }),
    getProjectTemplates: build.query<GetProjectTemplatesApiResponse, GetProjectTemplatesApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/templates`,
        params: {fields: queryArg.fields},
      }),
    }),
    addTemplate: build.mutation<AddTemplateApiResponse, AddTemplateApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/templates`,
        method: 'POST',
        body: queryArg.newBuildTypeDescription,
        params: {fields: queryArg.fields},
      }),
    }),
    getProjectField: build.query<GetProjectFieldApiResponse, GetProjectFieldApiArg>({
      query: queryArg => ({url: `/app/rest/projects/${queryArg.projectLocator}/${queryArg.field}`}),
    }),
    setProjectField: build.mutation<SetProjectFieldApiResponse, SetProjectFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
        responseHandler: 'text',
      }),
    }),
    getServerInfo: build.query<GetServerInfoApiResponse, GetServerInfoApiArg>({
      query: queryArg => ({url: `/app/rest/server`, params: {fields: queryArg.fields}}),
    }),
    getBackupStatus: build.query<GetBackupStatusApiResponse, GetBackupStatusApiArg>({
      query: () => ({url: `/app/rest/server/backup`}),
    }),
    startBackup: build.mutation<StartBackupApiResponse, StartBackupApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/backup`,
        method: 'POST',
        params: {
          fileName: queryArg.fileName,
          addTimestamp: queryArg.addTimestamp,
          includeConfigs: queryArg.includeConfigs,
          includeDatabase: queryArg.includeDatabase,
          includeBuildLogs: queryArg.includeBuildLogs,
          includePersonalChanges: queryArg.includePersonalChanges,
          includeRunningBuilds: queryArg.includeRunningBuilds,
          includeSupplimentaryData: queryArg.includeSupplimentaryData,
        },
      }),
    }),
    getFilesListOfServer: build.query<GetFilesListOfServerApiResponse, GetFilesListOfServerApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/files/${queryArg.areaId}`,
        params: {basePath: queryArg.basePath, locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getZippedFileOfServer: build.query<
      GetZippedFileOfServerApiResponse,
      GetZippedFileOfServerApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/server/files/${queryArg.areaId}/archived${queryArg.path}`,
        params: {basePath: queryArg.basePath, locator: queryArg.locator, name: queryArg.name},
      }),
    }),
    downloadFileOfServer: build.query<DownloadFileOfServerApiResponse, DownloadFileOfServerApiArg>({
      query: queryArg => ({url: `/app/rest/server/files/${queryArg.areaId}/files${queryArg.path}`}),
    }),
    getFileMetadataOfServer: build.query<
      GetFileMetadataOfServerApiResponse,
      GetFileMetadataOfServerApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/server/files/${queryArg.areaId}/metadata${queryArg.path}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getFilesListForSubpathOfServer: build.query<
      GetFilesListForSubpathOfServerApiResponse,
      GetFilesListForSubpathOfServerApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/server/files/${queryArg.areaId}/${queryArg.path}`,
        params: {basePath: queryArg.basePath, locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getLicensingData: build.query<GetLicensingDataApiResponse, GetLicensingDataApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/licensingData`,
        params: {fields: queryArg.fields},
      }),
    }),
    getLicenseKeys: build.query<GetLicenseKeysApiResponse, GetLicenseKeysApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/licensingData/licenseKeys`,
        params: {fields: queryArg.fields},
      }),
    }),
    addLicenseKeys: build.mutation<AddLicenseKeysApiResponse, AddLicenseKeysApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/licensingData/licenseKeys`,
        method: 'POST',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
    }),
    getLicenseKey: build.query<GetLicenseKeyApiResponse, GetLicenseKeyApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/licensingData/licenseKeys/${queryArg.licenseKey}`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteLicenseKey: build.mutation<DeleteLicenseKeyApiResponse, DeleteLicenseKeyApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/licensingData/licenseKeys/${queryArg.licenseKey}`,
        method: 'DELETE',
      }),
    }),
    getAllMetrics: build.query<GetAllMetricsApiResponse, GetAllMetricsApiArg>({
      query: queryArg => ({url: `/app/rest/server/metrics`, params: {fields: queryArg.fields}}),
    }),
    nodes: build.query<NodesApiResponse, NodesApiArg>({
      query: queryArg => ({url: `/app/rest/server/nodes`, params: {fields: queryArg.fields}}),
    }),
    getNode: build.query<GetNodeApiResponse, GetNodeApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/nodes/${queryArg.nodeId}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllPlugins: build.query<GetAllPluginsApiResponse, GetAllPluginsApiArg>({
      query: queryArg => ({url: `/app/rest/server/plugins`, params: {fields: queryArg.fields}}),
    }),
    getServerField: build.query<GetServerFieldApiResponse, GetServerFieldApiArg>({
      query: queryArg => ({url: `/app/rest/server/${queryArg.field}`}),
    }),
    getAllTestOccurrences: build.query<
      GetAllTestOccurrencesApiResponse,
      GetAllTestOccurrencesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/testOccurrences`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getTestOccurrence: build.query<GetTestOccurrenceApiResponse, GetTestOccurrenceApiArg>({
      query: queryArg => ({
        url: `/app/rest/testOccurrences/${queryArg.testLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getTests: build.query<GetTestsApiResponse, GetTestsApiArg>({
      query: queryArg => ({
        url: `/app/rest/tests`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getTest: build.query<GetTestApiResponse, GetTestApiArg>({
      query: queryArg => ({
        url: `/app/rest/tests/${queryArg.testLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllGroups: build.query<GetAllGroupsApiResponse, GetAllGroupsApiArg>({
      query: queryArg => ({url: `/app/rest/userGroups`, params: {fields: queryArg.fields}}),
    }),
    addGroup: build.mutation<AddGroupApiResponse, AddGroupApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups`,
        method: 'POST',
        body: queryArg.group,
        params: {fields: queryArg.fields},
      }),
    }),
    getUserGroupOfGroup: build.query<GetUserGroupOfGroupApiResponse, GetUserGroupOfGroupApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteGroup: build.mutation<DeleteGroupApiResponse, DeleteGroupApiArg>({
      query: queryArg => ({url: `/app/rest/userGroups/${queryArg.groupLocator}`, method: 'DELETE'}),
    }),
    getGroupParentGroups: build.query<GetGroupParentGroupsApiResponse, GetGroupParentGroupsApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/parent-groups`,
        params: {fields: queryArg.fields},
      }),
    }),
    setGroupParentGroups: build.mutation<
      SetGroupParentGroupsApiResponse,
      SetGroupParentGroupsApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/parent-groups`,
        method: 'PUT',
        body: queryArg.groups,
        params: {fields: queryArg.fields},
      }),
    }),
    getGroupProperties: build.query<GetGroupPropertiesApiResponse, GetGroupPropertiesApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/properties`,
        params: {fields: queryArg.fields},
      }),
    }),
    getGroupProperty: build.query<GetGroupPropertyApiResponse, GetGroupPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/properties/${queryArg.name}`,
      }),
    }),
    setGroupProperty: build.mutation<SetGroupPropertyApiResponse, SetGroupPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/properties/${queryArg.name}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    removeGroupProperty: build.mutation<RemoveGroupPropertyApiResponse, RemoveGroupPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/properties/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getGroupRoles: build.query<GetGroupRolesApiResponse, GetGroupRolesApiArg>({
      query: queryArg => ({url: `/app/rest/userGroups/${queryArg.groupLocator}/roles`}),
    }),
    addRoleToGroup: build.mutation<AddRoleToGroupApiResponse, AddRoleToGroupApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/roles`,
        method: 'POST',
        body: queryArg.role,
      }),
    }),
    setGroupRoles: build.mutation<SetGroupRolesApiResponse, SetGroupRolesApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/roles`,
        method: 'PUT',
        body: queryArg.roles,
      }),
    }),
    getGroupRoleAtScope: build.query<GetGroupRoleAtScopeApiResponse, GetGroupRoleAtScopeApiArg>({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/roles/${queryArg.roleId}/${queryArg.scope}`,
      }),
    }),
    addRoleAtScopeToGroup: build.mutation<
      AddRoleAtScopeToGroupApiResponse,
      AddRoleAtScopeToGroupApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/roles/${queryArg.roleId}/${queryArg.scope}`,
        method: 'POST',
      }),
    }),
    removeRoleAtScopeFromGroup: build.mutation<
      RemoveRoleAtScopeFromGroupApiResponse,
      RemoveRoleAtScopeFromGroupApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/userGroups/${queryArg.groupLocator}/roles/${queryArg.roleId}/${queryArg.scope}`,
        method: 'DELETE',
      }),
    }),
    getAllUsers: build.query<GetAllUsersApiResponse, GetAllUsersApiArg>({
      query: queryArg => ({
        url: `/app/rest/users`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addUser: build.mutation<AddUserApiResponse, AddUserApiArg>({
      query: queryArg => ({
        url: `/app/rest/users`,
        method: 'POST',
        body: queryArg.user,
        params: {fields: queryArg.fields},
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}`,
        params: {fields: queryArg.fields},
      }),
      providesTags: result => [{type: 'User', id: result?.id}],
    }),
    replaceUser: build.mutation<ReplaceUserApiResponse, ReplaceUserApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}`,
        method: 'PUT',
        body: queryArg.user,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: queryArg => ({url: `/app/rest/users/${queryArg.userLocator}`, method: 'DELETE'}),
    }),
    removeUserRememberMe: build.mutation<
      RemoveUserRememberMeApiResponse,
      RemoveUserRememberMeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/debug/rememberMe`,
        method: 'DELETE',
      }),
    }),
    getAllUserGroups: build.query<GetAllUserGroupsApiResponse, GetAllUserGroupsApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/groups`,
        params: {fields: queryArg.fields},
      }),
    }),
    setUserGroups: build.mutation<SetUserGroupsApiResponse, SetUserGroupsApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/groups`,
        method: 'PUT',
        body: queryArg.groups,
        params: {fields: queryArg.fields},
      }),
    }),
    getUserGroup: build.query<GetUserGroupApiResponse, GetUserGroupApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/groups/${queryArg.groupLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    removeUserFromGroup: build.mutation<RemoveUserFromGroupApiResponse, RemoveUserFromGroupApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/groups/${queryArg.groupLocator}`,
        method: 'DELETE',
        params: {fields: queryArg.fields},
      }),
    }),
    getUserPermissions: build.query<GetUserPermissionsApiResponse, GetUserPermissionsApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/permissions`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getUserProperties: build.query<GetUserPropertiesApiResponse, GetUserPropertiesApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/properties`,
        params: {fields: queryArg.fields},
      }),
    }),
    getUserProperty: build.query<GetUserPropertyApiResponse, GetUserPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/properties/${queryArg.name}`,
      }),
    }),
    setUserProperty: build.mutation<SetUserPropertyApiResponse, SetUserPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/properties/${queryArg.name}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    removeUserProperty: build.mutation<RemoveUserPropertyApiResponse, RemoveUserPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/properties/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getAllUserRoles: build.query<GetAllUserRolesApiResponse, GetAllUserRolesApiArg>({
      query: queryArg => ({url: `/app/rest/users/${queryArg.userLocator}/roles`}),
    }),
    addRoleToUser: build.mutation<AddRoleToUserApiResponse, AddRoleToUserApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/roles`,
        method: 'POST',
        body: queryArg.role,
      }),
    }),
    setUserRoles: build.mutation<SetUserRolesApiResponse, SetUserRolesApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/roles`,
        method: 'PUT',
        body: queryArg.roles,
      }),
    }),
    getUserRolesAtScope: build.query<GetUserRolesAtScopeApiResponse, GetUserRolesAtScopeApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/roles/${queryArg.roleId}/${queryArg.scope}`,
      }),
    }),
    addRoleToUserAtScope: build.mutation<
      AddRoleToUserAtScopeApiResponse,
      AddRoleToUserAtScopeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/roles/${queryArg.roleId}/${queryArg.scope}`,
        method: 'PUT',
      }),
    }),
    removeUserRoleAtScope: build.mutation<
      RemoveUserRoleAtScopeApiResponse,
      RemoveUserRoleAtScopeApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/roles/${queryArg.roleId}/${queryArg.scope}`,
        method: 'DELETE',
      }),
    }),
    getUserTokens: build.query<GetUserTokensApiResponse, GetUserTokensApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/tokens`,
        params: {fields: queryArg.fields},
      }),
    }),
    addUserToken: build.mutation<AddUserTokenApiResponse, AddUserTokenApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/tokens`,
        method: 'POST',
        body: queryArg.token,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteUserToken: build.mutation<DeleteUserTokenApiResponse, DeleteUserTokenApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/tokens/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getUserField: build.query<GetUserFieldApiResponse, GetUserFieldApiArg>({
      query: queryArg => ({url: `/app/rest/users/${queryArg.userLocator}/${queryArg.field}`}),
    }),
    setUserField: build.mutation<SetUserFieldApiResponse, SetUserFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteUserField: build.mutation<DeleteUserFieldApiResponse, DeleteUserFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/users/${queryArg.userLocator}/${queryArg.field}`,
        method: 'DELETE',
      }),
    }),
    getAllVcsRootInstances: build.query<
      GetAllVcsRootInstancesApiResponse,
      GetAllVcsRootInstancesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    requestPendingChangesCheck: build.mutation<
      RequestPendingChangesCheckApiResponse,
      RequestPendingChangesCheckApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/checkingForChangesQueue`,
        method: 'POST',
        params: {locator: queryArg.locator, requestor: queryArg.requestor, fields: queryArg.fields},
      }),
    }),
    triggerCommitHookNotification: build.mutation<
      TriggerCommitHookNotificationApiResponse,
      TriggerCommitHookNotificationApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/commitHookNotification`,
        method: 'POST',
        params: {locator: queryArg.locator, okOnNothingFound: queryArg.okOnNothingFound},
      }),
    }),
    getVcsRootInstance: build.query<GetVcsRootInstanceApiResponse, GetVcsRootInstanceApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getFilesList: build.query<GetFilesListApiResponse, GetFilesListApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/files/latest`,
        params: {basePath: queryArg.basePath, locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getZippedFile: build.query<GetZippedFileApiResponse, GetZippedFileApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/files/latest/archived${queryArg.path}`,
        params: {basePath: queryArg.basePath, locator: queryArg.locator, name: queryArg.name},
      }),
    }),
    downloadFile: build.query<DownloadFileApiResponse, DownloadFileApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/files/latest/files${queryArg.path}`,
      }),
    }),
    getFileMetadata: build.query<GetFileMetadataApiResponse, GetFileMetadataApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/files/latest/metadata${queryArg.path}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getFilesListForSubpath: build.query<
      GetFilesListForSubpathApiResponse,
      GetFilesListForSubpathApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/files/latest/${queryArg.path}`,
        params: {basePath: queryArg.basePath, locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    getVcsRootInstanceProperties: build.query<
      GetVcsRootInstancePropertiesApiResponse,
      GetVcsRootInstancePropertiesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/properties`,
        params: {fields: queryArg.fields},
      }),
    }),
    getVcsRootInstanceRepositoryState: build.query<
      GetVcsRootInstanceRepositoryStateApiResponse,
      GetVcsRootInstanceRepositoryStateApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/repositoryState`,
        params: {fields: queryArg.fields},
      }),
    }),
    setVcsRootInstanceRepositoryState: build.mutation<
      SetVcsRootInstanceRepositoryStateApiResponse,
      SetVcsRootInstanceRepositoryStateApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/repositoryState`,
        method: 'PUT',
        body: queryArg.entries,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteVcsRootInstanceRepositoryState: build.mutation<
      DeleteVcsRootInstanceRepositoryStateApiResponse,
      DeleteVcsRootInstanceRepositoryStateApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/repositoryState`,
        method: 'DELETE',
      }),
    }),
    getVcsRootInstanceCreationDate: build.query<
      GetVcsRootInstanceCreationDateApiResponse,
      GetVcsRootInstanceCreationDateApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/repositoryState/creationDate`,
      }),
    }),
    getVcsRootInstanceField: build.query<
      GetVcsRootInstanceFieldApiResponse,
      GetVcsRootInstanceFieldApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/${queryArg.field}`,
      }),
    }),
    setVcsRootInstanceField: build.mutation<
      SetVcsRootInstanceFieldApiResponse,
      SetVcsRootInstanceFieldApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteVcsRootInstanceField: build.mutation<
      DeleteVcsRootInstanceFieldApiResponse,
      DeleteVcsRootInstanceFieldApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-root-instances/${queryArg.vcsRootInstanceLocator}/${queryArg.field}`,
        method: 'DELETE',
      }),
    }),
    getAllVcsRoots: build.query<GetAllVcsRootsApiResponse, GetAllVcsRootsApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots`,
        params: {locator: queryArg.locator, fields: queryArg.fields},
      }),
    }),
    addVcsRoot: build.mutation<AddVcsRootApiResponse, AddVcsRootApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots`,
        method: 'POST',
        body: queryArg.vcsRoot,
        params: {fields: queryArg.fields},
      }),
    }),
    getRootEndpoints: build.query<GetRootEndpointsApiResponse, GetRootEndpointsApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteVcsRoot: build.mutation<DeleteVcsRootApiResponse, DeleteVcsRootApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}`,
        method: 'DELETE',
      }),
    }),
    getVcsRootInstances: build.query<GetVcsRootInstancesApiResponse, GetVcsRootInstancesApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/instances`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllVcsRootProperties: build.query<
      GetAllVcsRootPropertiesApiResponse,
      GetAllVcsRootPropertiesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/properties`,
        params: {fields: queryArg.fields},
      }),
    }),
    setVcsRootProperties: build.mutation<
      SetVcsRootPropertiesApiResponse,
      SetVcsRootPropertiesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/properties`,
        method: 'PUT',
        body: queryArg.properties,
        params: {fields: queryArg.fields},
      }),
    }),
    deleteAllVcsRootProperties: build.mutation<
      DeleteAllVcsRootPropertiesApiResponse,
      DeleteAllVcsRootPropertiesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/properties`,
        method: 'DELETE',
      }),
    }),
    getVcsRootProperty: build.query<GetVcsRootPropertyApiResponse, GetVcsRootPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/properties/${queryArg.name}`,
      }),
    }),
    setVcsRootProperty: build.mutation<SetVcsRootPropertyApiResponse, SetVcsRootPropertyApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/properties/${queryArg.name}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteVcsRootProperty: build.mutation<
      DeleteVcsRootPropertyApiResponse,
      DeleteVcsRootPropertyApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/properties/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getVcsRootSettingsFile: build.query<
      GetVcsRootSettingsFileApiResponse,
      GetVcsRootSettingsFileApiArg
    >({
      query: queryArg => ({url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/settingsFile`}),
    }),
    getVcsRootField: build.query<GetVcsRootFieldApiResponse, GetVcsRootFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/${queryArg.field}`,
      }),
    }),
    setVcsRootField: build.mutation<SetVcsRootFieldApiResponse, SetVcsRootFieldApiArg>({
      query: queryArg => ({
        url: `/app/rest/vcs-roots/${queryArg.vcsRootLocator}/${queryArg.field}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
      query: () => ({url: `/app/rest/version`}),
    }),
  }),
  overrideExisting: false,
})
export {injectedRtkApi as restApi}

export type GetAvailableRunnersResponse = {[key: string]: Array<RunnerType>}
export type GetAvailableRunnersArgs = {projectId: ProjectId}
export type GetChangesGraphResponse = unknown
export type GetChangesGraphArgs = {queryParams: string}
export type GetRootEndpointsOfRootApiResponse = unknown
export type GetRootEndpointsOfRootApiArg = void
export type GetAllAgentPoolsApiResponse = /** status 200 successful operation */ AgentPools
export type GetAllAgentPoolsApiArg = {
  locator?: string
  fields?: string
}
export type CreateAgentPoolApiResponse = /** status 200 successful operation */ AgentPool
export type CreateAgentPoolApiArg = {
  agentPool: AgentPool
}
export type GetAgentPoolOfAgentPoolApiResponse = /** status 200 successful operation */ AgentPool
export type GetAgentPoolOfAgentPoolApiArg = {
  agentPoolLocator: string
  fields?: string
}
export type DeleteAgentPoolApiResponse = unknown
export type DeleteAgentPoolApiArg = {
  agentPoolLocator: string
}
export type GetAllAgentsFromAgentPoolApiResponse = /** status 200 successful operation */ Agents
export type GetAllAgentsFromAgentPoolApiArg = {
  agentPoolLocator: string
  locator?: string
  fields?: string
}
export type AddAgentToAgentPoolApiResponse = /** status 200 successful operation */ Agent
export type AddAgentToAgentPoolApiArg = {
  agentPoolLocator: string
  fields?: string
  agent: Agent
}
export type GetAllProjectsFromAgentPoolApiResponse = /** status 200 successful operation */ Projects
export type GetAllProjectsFromAgentPoolApiArg = {
  agentPoolLocator: string
  fields?: string
}
export type AddProjectToAgentPoolApiResponse = /** status 200 successful operation */ Project
export type AddProjectToAgentPoolApiArg = {
  agentPoolLocator: string
  project: Project
}
export type SetAgentPoolProjectsApiResponse = /** status 200 successful operation */ Projects
export type SetAgentPoolProjectsApiArg = {
  agentPoolLocator: string
  projects: Projects
}
export type DeleteAllProjectsFromAgentPoolApiResponse = unknown
export type DeleteAllProjectsFromAgentPoolApiArg = {
  agentPoolLocator: string
}
export type DeleteProjectFromAgentPoolApiResponse = unknown
export type DeleteProjectFromAgentPoolApiArg = {
  agentPoolLocator: string
  projectLocator: string
}
export type GetFieldFromAgentPoolApiResponse = unknown
export type GetFieldFromAgentPoolApiArg = {
  agentPoolLocator: string
  field: string
}
export type SetAgentPoolFieldApiResponse = unknown
export type SetAgentPoolFieldApiArg = {
  agentPoolLocator: string
  field: string
  body: string
}
export type GetAllAgentsApiResponse = /** status 200 successful operation */ Agents
export type GetAllAgentsApiArg = {
  locator?: string
  fields?: string
}
export type GetAgentApiResponse = /** status 200 successful operation */ Agent
export type GetAgentApiArg = {
  agentLocator: string
  fields?: string
}
export type DeleteAgentApiResponse = unknown
export type DeleteAgentApiArg = {
  agentLocator: string
}
export type GetAuthorizedInfoApiResponse = /** status 200 successful operation */ AuthorizedInfo
export type GetAuthorizedInfoApiArg = {
  agentLocator: string
  fields?: string
}
export type SetAuthorizedInfoApiResponse = /** status 200 successful operation */ AuthorizedInfo
export type SetAuthorizedInfoApiArg = {
  agentLocator: string
  fields?: string
  authorizedInfo: AuthorizedInfo
}
export type GetBuildConfigurationRunPolicyApiResponse =
  /** status 200 successful operation */ CompatibilityPolicy
export type GetBuildConfigurationRunPolicyApiArg = {
  agentLocator: string
  fields?: string
}
export type SetBuildConfigurationRunPolicyApiResponse =
  /** status 200 successful operation */ CompatibilityPolicy
export type SetBuildConfigurationRunPolicyApiArg = {
  agentLocator: string
  fields?: string
  compatibilityPolicy: CompatibilityPolicy
}
export type GetCompatibleBuildTypesApiResponse = /** status 200 successful operation */ BuildTypes
export type GetCompatibleBuildTypesApiArg = {
  agentLocator: string
  fields?: string
}
export type GetEnabledInfoApiResponse = /** status 200 successful operation */ EnabledInfo
export type GetEnabledInfoApiArg = {
  agentLocator: string
  fields?: string
}
export type SetEnabledInfoApiResponse = /** status 200 successful operation */ EnabledInfo
export type SetEnabledInfoApiArg = {
  agentLocator: string
  fields?: string
  enabledInfo: EnabledInfo
}
export type GetIncompatibleBuildTypesApiResponse =
  /** status 200 successful operation */ Compatibilities
export type GetIncompatibleBuildTypesApiArg = {
  agentLocator: string
  fields?: string
}
export type GetAgentPoolApiResponse = /** status 200 successful operation */ AgentPool
export type GetAgentPoolApiArg = {
  agentLocator: string
  fields?: string
}
export type SetAgentPoolApiResponse = /** status 200 successful operation */ AgentPool
export type SetAgentPoolApiArg = {
  agentLocator: string
  fields?: string
  agentPool: AgentPool
}
export type GetAgentFieldApiResponse = unknown
export type GetAgentFieldApiArg = {
  agentLocator: string
  field: string
}
export type SetAgentFieldApiResponse = unknown
export type SetAgentFieldApiArg = {
  agentLocator: string
  field: string
  body: string
}
export type GetApiVersionApiResponse = unknown
export type GetApiVersionApiArg = void
export type GetAllAuditEventsApiResponse = /** status 200 successful operation */ AuditEvents
export type GetAllAuditEventsApiArg = {
  locator?: string
  fields?: string
}
export type GetAuditEventApiResponse = /** status 200 successful operation */ AuditEvent
export type GetAuditEventApiArg = {
  auditEventLocator: string
  fields?: string
}
export type PutAvatarApiResponse = unknown
export type PutAvatarApiArg = {
  userLocator: string
  body: {
    avatar?: Blob
  }
}
export type DeleteAvatarApiResponse = unknown
export type DeleteAvatarApiArg = {
  userLocator: string
}
export type GetAvatarApiResponse = unknown
export type GetAvatarApiArg = {
  userLocator: string
  /** avatar's size */
  size: number
}
export type GetAvatarWithHashApiResponse = unknown
export type GetAvatarWithHashApiArg = {
  userLocator: string
  /** avatar's size */
  size: number
  hash: string
}
export type GetAllQueuedBuildsApiResponse = /** status 200 successful operation */ Builds
export type GetAllQueuedBuildsApiArg = {
  locator?: string
  fields?: string
}
export type AddBuildToQueueApiResponse = /** status 200 successful operation */ Build
export type AddBuildToQueueApiArg = {
  moveToTop?: boolean
  build: Build
}
export type DeleteAllQueuedBuildsApiResponse = unknown
export type DeleteAllQueuedBuildsApiArg = {
  locator?: string
  fields?: string
}
export type SetQueuedBuildsOrderApiResponse = /** status 200 successful operation */ Builds
export type SetQueuedBuildsOrderApiArg = {
  fields?: string
  builds: Builds
}
export type GetQueuedBuildPositionApiResponse = /** status 200 successful operation */ Build
export type GetQueuedBuildPositionApiArg = {
  queuePosition: string
  fields?: string
}
export type SetQueuedBuildPositionApiResponse = /** status 200 successful operation */ Build
export type SetQueuedBuildPositionApiArg = {
  queuePosition: string
  fields?: string
  build: Build
}
export type GetApprovalInfoApiResponse = /** status 200 successful operation */ ApprovalInfo
export type GetApprovalInfoApiArg = {
  buildLocator: string
  fields?: string
}
export type ApproveQueuedBuildApiResponse = /** status 200 successful operation */ ApprovalInfo
export type ApproveQueuedBuildApiArg = {
  buildLocator: string
  fields?: string
  body: string
}
export type GetQueuedBuildTagsApiResponse = /** status 200 successful operation */ Tags
export type GetQueuedBuildTagsApiArg = {
  buildLocator: string
  locator?: string
  fields?: string
}
export type AddTagsToBuildOfBuildQueueApiResponse = unknown
export type AddTagsToBuildOfBuildQueueApiArg = {
  buildLocator: string
  tags: Tags
}
export type GetQueuedBuildApiResponse = /** status 200 successful operation */ Build
export type GetQueuedBuildApiArg = {
  queuedBuildLocator: string
  fields?: string
}
export type CancelQueuedBuildApiResponse = /** status 200 successful operation */ Build
export type CancelQueuedBuildApiArg = {
  queuedBuildLocator: string
  buildCancelRequest: BuildCancelRequest
}
export type DeleteQueuedBuildApiResponse = unknown
export type DeleteQueuedBuildApiArg = {
  queuedBuildLocator: string
}
export type GetCompatibleAgentsForBuildApiResponse = /** status 200 successful operation */ Agents
export type GetCompatibleAgentsForBuildApiArg = {
  queuedBuildLocator: string
  fields?: string
}
export type GetAllBuildTypesApiResponse = /** status 200 successful operation */ BuildTypes
export type GetAllBuildTypesApiArg = {
  locator?: string
  fields?: string
}
export type CreateBuildTypeApiResponse = /** status 200 successful operation */ BuildType
export type CreateBuildTypeApiArg = {
  fields?: string
  buildType: BuildType
}
export type GetBuildTypeApiResponse = /** status 200 successful operation */ BuildType
export type GetBuildTypeApiArg = {
  btLocator: string
  fields?: string
}
export type DeleteBuildTypeApiResponse = unknown
export type DeleteBuildTypeApiArg = {
  btLocator: string
}
export type GetAllAgentRequirementsApiResponse =
  /** status 200 successful operation */ AgentRequirements
export type GetAllAgentRequirementsApiArg = {
  btLocator: string
  fields?: string
}
export type AddAgentRequirementToBuildTypeApiResponse =
  /** status 200 successful operation */ AgentRequirement
export type AddAgentRequirementToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  agentRequirement: AgentRequirement
}
export type ReplaceAllAgentRequirementsApiResponse =
  /** status 200 successful operation */ AgentRequirements
export type ReplaceAllAgentRequirementsApiArg = {
  btLocator: string
  fields?: string
  agentRequirements: AgentRequirements
}
export type GetAgentRequirementApiResponse = /** status 200 successful operation */ AgentRequirement
export type GetAgentRequirementApiArg = {
  btLocator: string
  agentRequirementLocator: string
  fields?: string
}
export type ReplaceAgentRequirementApiResponse =
  /** status 200 successful operation */ AgentRequirement
export type ReplaceAgentRequirementApiArg = {
  btLocator: string
  agentRequirementLocator: string
  fields?: string
  agentRequirement: AgentRequirement
}
export type DeleteAgentRequirementApiResponse = unknown
export type DeleteAgentRequirementApiArg = {
  btLocator: string
  agentRequirementLocator: string
}
export type GetAgentRequirementParameterApiResponse = unknown
export type GetAgentRequirementParameterApiArg = {
  btLocator: string
  agentRequirementLocator: string
  fieldName: string
}
export type SetAgentRequirementParameterApiResponse = unknown
export type SetAgentRequirementParameterApiArg = {
  btLocator: string
  agentRequirementLocator: string
  fieldName: string
  body: string
}
export type GetAliasesApiResponse = /** status 200 successful operation */ Items
export type GetAliasesApiArg = {
  btLocator: string
  field?: string
}
export type GetAllArtifactDependenciesApiResponse =
  /** status 200 successful operation */ ArtifactDependencies
export type GetAllArtifactDependenciesApiArg = {
  btLocator: string
  fields?: string
}
export type AddArtifactDependencyToBuildTypeApiResponse =
  /** status 200 successful operation */ ArtifactDependency
export type AddArtifactDependencyToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  artifactDependency: ArtifactDependency
}
export type ReplaceAllArtifactDependenciesApiResponse =
  /** status 200 successful operation */ ArtifactDependencies
export type ReplaceAllArtifactDependenciesApiArg = {
  btLocator: string
  fields?: string
  artifactDependencies: ArtifactDependencies
}
export type GetArtifactDependencyApiResponse =
  /** status 200 successful operation */ ArtifactDependency
export type GetArtifactDependencyApiArg = {
  btLocator: string
  artifactDepLocator: string
  fields?: string
}
export type ReplaceArtifactDependencyApiResponse =
  /** status 200 successful operation */ ArtifactDependency
export type ReplaceArtifactDependencyApiArg = {
  btLocator: string
  artifactDepLocator: string
  fields?: string
  artifactDependency: ArtifactDependency
}
export type DeleteArtifactDependencyApiResponse = unknown
export type DeleteArtifactDependencyApiArg = {
  btLocator: string
  artifactDepLocator: string
}
export type GetArtifactDependencyParameterApiResponse = unknown
export type GetArtifactDependencyParameterApiArg = {
  btLocator: string
  artifactDepLocator: string
  fieldName: string
}
export type SetArtifactDependencyParameterApiResponse = unknown
export type SetArtifactDependencyParameterApiArg = {
  btLocator: string
  artifactDepLocator: string
  fieldName: string
  body: string
}
export type GetAllBranchesOfBuildTypeApiResponse = /** status 200 successful operation */ Branches
export type GetAllBranchesOfBuildTypeApiArg = {
  btLocator: string
  locator?: string
  fields?: string
}
export type GetBuildTypeBuildTagsApiResponse = /** status 200 successful operation */ Tags
export type GetBuildTypeBuildTagsApiArg = {
  btLocator: string
  field?: string
}
export type GetBuildTypeBuildsApiResponse = /** status 200 successful operation */ Builds
export type GetBuildTypeBuildsApiArg = {
  btLocator: string
  fields?: string
}
export type GetAllBuildFeaturesApiResponse = /** status 200 successful operation */ Features
export type GetAllBuildFeaturesApiArg = {
  btLocator: string
  fields?: string
}
export type AddBuildFeatureToBuildTypeApiResponse = /** status 200 successful operation */ Feature
export type AddBuildFeatureToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  feature: Feature
}
export type ReplaceAllBuildFeaturesApiResponse = /** status 200 successful operation */ Features
export type ReplaceAllBuildFeaturesApiArg = {
  btLocator: string
  fields?: string
  features: Features
}
export type GetBuildFeatureApiResponse = /** status 200 successful operation */ Feature
export type GetBuildFeatureApiArg = {
  btLocator: string
  featureId: string
  fields?: string
}
export type ReplaceBuildFeatureApiResponse = /** status 200 successful operation */ Feature
export type ReplaceBuildFeatureApiArg = {
  btLocator: string
  featureId: string
  fields?: string
  feature: Feature
}
export type DeleteFeatureOfBuildTypeApiResponse = unknown
export type DeleteFeatureOfBuildTypeApiArg = {
  btLocator: string
  featureId: string
}
export type GetAllBuildFeatureParametersApiResponse =
  /** status 200 successful operation */ Properties
export type GetAllBuildFeatureParametersApiArg = {
  btLocator: string
  featureId: string
  fields?: string
}
export type ReplaceBuildFeatureParametersApiResponse =
  /** status 200 successful operation */ Properties
export type ReplaceBuildFeatureParametersApiArg = {
  btLocator: string
  featureId: string
  fields?: string
  properties: Properties
}
export type GetBuildFeatureParameterApiResponse = unknown
export type GetBuildFeatureParameterApiArg = {
  btLocator: string
  featureId: string
  parameterName: string
}
export type AddParameterToBuildFeatureApiResponse = unknown
export type AddParameterToBuildFeatureApiArg = {
  btLocator: string
  featureId: string
  parameterName: string
  body: string
}
export type GetBuildFeatureSettingApiResponse = unknown
export type GetBuildFeatureSettingApiArg = {
  btLocator: string
  featureId: string
  name: string
}
export type SetBuildFeatureParameterApiResponse = unknown
export type SetBuildFeatureParameterApiArg = {
  btLocator: string
  featureId: string
  name: string
  body: string
}
export type GetAllInvestigationsOfBuildTypeApiResponse =
  /** status 200 successful operation */ Investigations
export type GetAllInvestigationsOfBuildTypeApiArg = {
  btLocator: string
  fields?: string
}
export type GetBuildParametersOfBuildTypeApiResponse =
  /** status 200 successful operation */ Properties
export type GetBuildParametersOfBuildTypeApiArg = {
  locator?: string
  fields?: string
  btLocator: string
}
export type CreateBuildParameterOfBuildTypeApiResponse =
  /** status 200 successful operation */ Property
export type CreateBuildParameterOfBuildTypeApiArg = {
  fields?: string
  btLocator: string
  property: Property
}
export type UpdateBuildParametersOfBuildTypeApiResponse =
  /** status 200 successful operation */ Properties
export type UpdateBuildParametersOfBuildTypeApiArg = {
  fields?: string
  btLocator: string
  properties: Properties
}
export type DeleteBuildParametersOfBuildTypeApiResponse = unknown
export type DeleteBuildParametersOfBuildTypeApiArg = {
  btLocator: string
}
export type GetBuildParameterOfBuildTypeApiResponse =
  /** status 200 successful operation */ Property
export type GetBuildParameterOfBuildTypeApiArg = {
  name: string
  fields?: string
  btLocator: string
}
export type UpdateBuildParameterOfBuildTypeApiResponse =
  /** status 200 successful operation */ Property
export type UpdateBuildParameterOfBuildTypeApiArg = {
  name: string
  fields?: string
  btLocator: string
  property: Property
}
export type DeleteBuildParameterOfBuildTypeApiResponse = unknown
export type DeleteBuildParameterOfBuildTypeApiArg = {
  name: string
  btLocator: string
}
export type GetBuildParameterTypeOfBuildTypeApiResponse =
  /** status 200 successful operation */ Type
export type GetBuildParameterTypeOfBuildTypeApiArg = {
  name: string
  btLocator: string
}
export type UpdateBuildParameterTypeOfBuildTypeApiResponse =
  /** status 200 successful operation */ Type
export type UpdateBuildParameterTypeOfBuildTypeApiArg = {
  name: string
  btLocator: string
  type: Type
}
export type GetBuildParameterSpecificationOfBuildTypeApiResponse = unknown
export type GetBuildParameterSpecificationOfBuildTypeApiArg = {
  name: string
  btLocator: string
}
export type UpdateBuildParameterSpecificationOfBuildTypeApiResponse = unknown
export type UpdateBuildParameterSpecificationOfBuildTypeApiArg = {
  name: string
  btLocator: string
  body: string
}
export type GetBuildParameterValueOfBuildTypeApiResponse = unknown
export type GetBuildParameterValueOfBuildTypeApiArg = {
  name: string
  btLocator: string
}
export type UpdateBuildParameterValueOfBuildTypeApiResponse = unknown
export type UpdateBuildParameterValueOfBuildTypeApiArg = {
  name: string
  btLocator: string
  body: string
}
export type GetBuildTypeSettingsFileApiResponse = unknown
export type GetBuildTypeSettingsFileApiArg = {
  btLocator: string
}
export type GetAllSnapshotDependenciesApiResponse =
  /** status 200 successful operation */ SnapshotDependencies
export type GetAllSnapshotDependenciesApiArg = {
  btLocator: string
  fields?: string
}
export type AddSnapshotDependencyToBuildTypeApiResponse =
  /** status 200 successful operation */ SnapshotDependency
export type AddSnapshotDependencyToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  snapshotDependency: SnapshotDependency
}
export type ReplaceAllSnapshotDependenciesApiResponse =
  /** status 200 successful operation */ SnapshotDependencies
export type ReplaceAllSnapshotDependenciesApiArg = {
  btLocator: string
  fields?: string
  snapshotDependencies: SnapshotDependencies
}
export type GetSnapshotDependencyApiResponse =
  /** status 200 successful operation */ SnapshotDependency
export type GetSnapshotDependencyApiArg = {
  btLocator: string
  snapshotDepLocator: string
  fields?: string
}
export type ReplaceSnapshotDependencyApiResponse =
  /** status 200 successful operation */ SnapshotDependency
export type ReplaceSnapshotDependencyApiArg = {
  btLocator: string
  snapshotDepLocator: string
  fields?: string
  snapshotDependency: SnapshotDependency
}
export type DeleteSnapshotDependencyApiResponse = unknown
export type DeleteSnapshotDependencyApiArg = {
  btLocator: string
  snapshotDepLocator: string
}
export type GetAllBuildStepsApiResponse = /** status 200 successful operation */ Steps
export type GetAllBuildStepsApiArg = {
  btLocator: string
  fields?: string
}
export type AddBuildStepToBuildTypeApiResponse = /** status 200 successful operation */ Step
export type AddBuildStepToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  step: Step
}
export type ReplaceAllBuildStepsApiResponse = /** status 200 successful operation */ Steps
export type ReplaceAllBuildStepsApiArg = {
  btLocator: string
  fields?: string
  steps: Steps
}
export type GetBuildStepApiResponse = /** status 200 successful operation */ Step
export type GetBuildStepApiArg = {
  btLocator: string
  stepId: string
  fields?: string
}
export type ReplaceBuildStepApiResponse = /** status 200 successful operation */ Step
export type ReplaceBuildStepApiArg = {
  btLocator: string
  stepId: string
  fields?: string
  step: Step
}
export type DeleteBuildStepApiResponse = unknown
export type DeleteBuildStepApiArg = {
  btLocator: string
  stepId: string
}
export type GetAllBuildStepParametersApiResponse = /** status 200 successful operation */ Properties
export type GetAllBuildStepParametersApiArg = {
  btLocator: string
  stepId: string
  fields?: string
}
export type DeleteBuildStepParametersApiResponse = /** status 200 successful operation */ Properties
export type DeleteBuildStepParametersApiArg = {
  btLocator: string
  stepId: string
  fields?: string
  properties: Properties
}
export type GetBuildStepParameterApiResponse = unknown
export type GetBuildStepParameterApiArg = {
  btLocator: string
  stepId: string
  parameterName: string
}
export type AddParameterToBuildStepApiResponse = unknown
export type AddParameterToBuildStepApiArg = {
  btLocator: string
  stepId: string
  parameterName: string
  body: string
}
export type GetBuildStepSettingApiResponse = unknown
export type GetBuildStepSettingApiArg = {
  btLocator: string
  stepId: string
  fieldName: string
}
export type SetBuildStepParameterApiResponse = unknown
export type SetBuildStepParameterApiArg = {
  btLocator: string
  stepId: string
  fieldName: string
  body: string
}
export type GetAllBuildTemplatesApiResponse = /** status 200 successful operation */ BuildTypes
export type GetAllBuildTemplatesApiArg = {
  btLocator: string
  fields?: string
}
export type AddBuildTemplateApiResponse = /** status 200 successful operation */ BuildType
export type AddBuildTemplateApiArg = {
  btLocator: string
  optimizeSettings?: boolean
  fields?: string
  buildType: BuildType
}
export type SetBuildTypeTemplatesApiResponse = /** status 200 successful operation */ BuildTypes
export type SetBuildTypeTemplatesApiArg = {
  btLocator: string
  optimizeSettings?: boolean
  fields?: string
  buildTypes: BuildTypes
}
export type RemoveAllTemplatesApiResponse = unknown
export type RemoveAllTemplatesApiArg = {
  btLocator: string
  inlineSettings?: boolean
}
export type GetBuildTemplateApiResponse = /** status 200 successful operation */ BuildType
export type GetBuildTemplateApiArg = {
  btLocator: string
  templateLocator: string
  fields?: string
}
export type RemoveTemplateApiResponse = unknown
export type RemoveTemplateApiArg = {
  btLocator: string
  templateLocator: string
  inlineSettings?: boolean
}
export type GetAllTriggersApiResponse = /** status 200 successful operation */ Triggers
export type GetAllTriggersApiArg = {
  btLocator: string
  fields?: string
}
export type AddTriggerToBuildTypeApiResponse = /** status 200 successful operation */ Trigger
export type AddTriggerToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  trigger: Trigger
}
export type ReplaceAllTriggersApiResponse = /** status 200 successful operation */ Triggers
export type ReplaceAllTriggersApiArg = {
  btLocator: string
  fields?: string
  triggers: Triggers
}
export type GetTriggerApiResponse = /** status 200 successful operation */ Trigger
export type GetTriggerApiArg = {
  btLocator: string
  triggerLocator: string
  fields?: string
}
export type ReplaceTriggerApiResponse = /** status 200 successful operation */ Trigger
export type ReplaceTriggerApiArg = {
  btLocator: string
  triggerLocator: string
  fields?: string
  trigger: Trigger
}
export type DeleteTriggerApiResponse = unknown
export type DeleteTriggerApiArg = {
  btLocator: string
  triggerLocator: string
}
export type GetTriggerParameterApiResponse = unknown
export type GetTriggerParameterApiArg = {
  btLocator: string
  triggerLocator: string
  fieldName: string
}
export type SetTriggerParameterApiResponse = unknown
export type SetTriggerParameterApiArg = {
  btLocator: string
  triggerLocator: string
  fieldName: string
  body: string
}
export type GetAllVcsRootsOfBuildTypeApiResponse =
  /** status 200 successful operation */ VcsRootEntries
export type GetAllVcsRootsOfBuildTypeApiArg = {
  btLocator: string
  fields?: string
}
export type AddVcsRootToBuildTypeApiResponse = /** status 200 successful operation */ VcsRootEntry
export type AddVcsRootToBuildTypeApiArg = {
  btLocator: string
  fields?: string
  vcsRootEntry: VcsRootEntry
}
export type ReplaceAllVcsRootsApiResponse = /** status 200 successful operation */ VcsRootEntries
export type ReplaceAllVcsRootsApiArg = {
  btLocator: string
  fields?: string
  vcsRootEntries: VcsRootEntries
}
export type GetVcsRootApiResponse = /** status 200 successful operation */ VcsRootEntry
export type GetVcsRootApiArg = {
  btLocator: string
  vcsRootLocator: string
  fields?: string
}
export type UpdateBuildTypeVcsRootApiResponse = /** status 200 successful operation */ VcsRootEntry
export type UpdateBuildTypeVcsRootApiArg = {
  btLocator: string
  vcsRootLocator: string
  fields?: string
  vcsRootEntry: VcsRootEntry
}
export type DeleteVcsRootOfBuildTypeApiResponse = unknown
export type DeleteVcsRootOfBuildTypeApiArg = {
  btLocator: string
  vcsRootLocator: string
}
export type GetVcsRootCheckoutRulesApiResponse = unknown
export type GetVcsRootCheckoutRulesApiArg = {
  btLocator: string
  vcsRootLocator: string
}
export type UpdateBuildTypeVcsRootCheckoutRulesApiResponse = unknown
export type UpdateBuildTypeVcsRootCheckoutRulesApiArg = {
  btLocator: string
  vcsRootLocator: string
  body: string
}
export type GetFilesListOfBuildTypeApiResponse = /** status 200 successful operation */ Files
export type GetFilesListOfBuildTypeApiArg = {
  basePath?: string
  locator?: string
  fields?: string
  btLocator: string
  resolveParameters?: boolean
}
export type GetZippedFileOfBuildTypeApiResponse = unknown
export type GetZippedFileOfBuildTypeApiArg = {
  path: string
  basePath?: string
  locator?: string
  name?: string
  btLocator: string
  resolveParameters?: boolean
}
export type DownloadFileOfBuildTypeApiResponse = unknown
export type DownloadFileOfBuildTypeApiArg = {
  path: string
  btLocator: string
  resolveParameters?: boolean
}
export type GetFileMetadataOfBuildTypeApiResponse = /** status 200 successful operation */ File
export type GetFileMetadataOfBuildTypeApiArg = {
  path: string
  fields?: string
  btLocator: string
  resolveParameters?: boolean
}
export type GetFilesListForSubpathOfBuildTypeApiResponse =
  /** status 200 successful operation */ Files
export type GetFilesListForSubpathOfBuildTypeApiArg = {
  path: string
  basePath?: string
  locator?: string
  fields?: string
  btLocator: string
  resolveParameters?: boolean
}
export type GetVcsRootInstancesOfBuildTypeApiResponse =
  /** status 200 successful operation */ VcsRootInstances
export type GetVcsRootInstancesOfBuildTypeApiArg = {
  btLocator: string
  fields?: string
}
export type GetBuildTypeFieldApiResponse = unknown
export type GetBuildTypeFieldApiArg = {
  btLocator: string
  field: string
}
export type SetBuildTypeFieldApiResponse = string
export type SetBuildTypeFieldApiArg = {
  btLocator: string
  field: string
  body: string
}
export type GetAllBuildsApiResponse = /** status 200 successful operation */ Builds
export type GetAllBuildsApiArg = {
  locator?: string
  fields?: string
}
export type GetAggregatedBuildStatusApiResponse = /** status 200 successful operation */ string
export type GetAggregatedBuildStatusApiArg = {
  buildLocator: string
}
export type GetAggregatedBuildStatusIconApiResponse = unknown
export type GetAggregatedBuildStatusIconApiArg = {
  buildLocator: string
  suffix: string
}
export type GetMultipleBuildsApiResponse = /** status 200 successful operation */ Builds
export type GetMultipleBuildsApiArg = {
  buildLocator: string
  fields?: string
}
export type CancelMultipleApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type CancelMultipleApiArg = {
  buildLocator: string
  fields?: string
  buildCancelRequest: BuildCancelRequest
}
export type DeleteMultipleBuildsApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type DeleteMultipleBuildsApiArg = {
  buildLocator: string
  fields?: string
}
export type SetMultipleBuildCommentsApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type SetMultipleBuildCommentsApiArg = {
  buildLocator: string
  fields?: string
  body: string
}
export type DeleteMultipleBuildCommentsApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type DeleteMultipleBuildCommentsApiArg = {
  buildLocator: string
  fields?: string
}
export type PinMultipleBuildsApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type PinMultipleBuildsApiArg = {
  buildLocator: string
  fields?: string
  pinInfo: PinInfo
}
export type AddTagsToMultipleBuildsApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type AddTagsToMultipleBuildsApiArg = {
  buildLocator: string
  fields?: string
  tags: Tags
}
export type RemoveMultipleBuildTagsApiResponse =
  /** status 200 successful operation */ MultipleOperationResult
export type RemoveMultipleBuildTagsApiArg = {
  buildLocator: string
  fields?: string
  tags: Tags
}
export type GetBuildApiResponse = /** status 200 successful operation */ Build
export type GetBuildApiArg = {
  buildLocator: string
  fields?: string
} & RequestOptionsParams
export type GetBuildNormalizedAsListApiArg = GetBuildApiArg & {
  locators: string[]
}
export type CancelBuildApiResponse = /** status 200 successful operation */ Build
export type CancelBuildApiArg = {
  buildLocator: string
  fields?: string
  buildCancelRequest: BuildCancelRequest
}
export type DeleteBuildApiResponse = unknown
export type DeleteBuildApiArg = {
  buildLocator: string
}
export type GetArtifactDependencyChangesApiResponse =
  /** status 200 successful operation */ BuildChanges
export type GetArtifactDependencyChangesApiArg = {
  buildLocator: string
  fields?: string
}
export type GetFilesListOfBuildApiResponse = /** status 200 successful operation */ Files
export type GetFilesListOfBuildApiArg = {
  basePath?: string
  locator?: string
  fields?: string
  buildLocator: string
  resolveParameters?: boolean
  logBuildUsage?: boolean
}
export type GetZippedFileOfBuildApiResponse = unknown
export type GetZippedFileOfBuildApiArg = {
  path: string
  basePath?: string
  locator?: string
  name?: string
  buildLocator: string
  resolveParameters?: boolean
  logBuildUsage?: boolean
}
export type DownloadFileOfBuildApiResponse = unknown
export type DownloadFileOfBuildApiArg = {
  path: string
  buildLocator: string
  resolveParameters?: boolean
  logBuildUsage?: boolean
}
export type GetFileMetadataOfBuildApiResponse = /** status 200 successful operation */ File
export type GetFileMetadataOfBuildApiArg = {
  path: string
  fields?: string
  buildLocator: string
  resolveParameters?: boolean
  logBuildUsage?: boolean
}
export type GetFilesListForSubpathOfBuildApiResponse = /** status 200 successful operation */ Files
export type GetFilesListForSubpathOfBuildApiArg = {
  path: string
  basePath?: string
  locator?: string
  fields?: string
  buildLocator: string
  resolveParameters?: boolean
  logBuildUsage?: boolean
}
export type GetArtifactsDirectoryApiResponse = unknown
export type GetArtifactsDirectoryApiArg = {
  buildLocator: string
}
export type ResetBuildFinishPropertiesApiResponse = unknown
export type ResetBuildFinishPropertiesApiArg = {
  buildLocator: string
}
export type GetCanceledInfoApiResponse = /** status 200 successful operation */ Comment
export type GetCanceledInfoApiArg = {
  buildLocator: string
  fields?: string
}
export type SetBuildCommentApiResponse = unknown
export type SetBuildCommentApiArg = {
  buildLocator: string
  body: string
}
export type DeleteBuildCommentApiResponse = unknown
export type DeleteBuildCommentApiArg = {
  buildLocator: string
}
export type SetFinishedTimeApiResponse = unknown
export type SetFinishedTimeApiArg = {
  buildLocator: string
}
export type GetBuildFinishDateApiResponse = unknown
export type GetBuildFinishDateApiArg = {
  buildLocator: string
}
export type SetBuildFinishDateApiResponse = unknown
export type SetBuildFinishDateApiArg = {
  buildLocator: string
  body: string
}
export type AddLogMessageToBuildApiResponse = unknown
export type AddLogMessageToBuildApiArg = {
  buildLocator: string
  fields?: string
  body: string
}
export type GetBuildNumberApiResponse = unknown
export type GetBuildNumberApiArg = {
  buildLocator: string
}
export type SetBuildNumberApiResponse = unknown
export type SetBuildNumberApiArg = {
  buildLocator: string
  body: string
}
export type GetBuildPinInfoApiResponse = /** status 200 successful operation */ PinInfo
export type GetBuildPinInfoApiArg = {
  buildLocator: string
  fields?: string
}
export type SetBuildPinInfoApiResponse = /** status 200 successful operation */ PinInfo
export type SetBuildPinInfoApiArg = {
  buildLocator: string
  fields?: string
  pinInfo: PinInfo
}
export type GetBuildProblemsApiResponse = /** status 200 successful operation */ ProblemOccurrences
export type GetBuildProblemsApiArg = {
  buildLocator: string
  fields?: string
}
export type AddProblemToBuildApiResponse = /** status 200 successful operation */ ProblemOccurrence
export type AddProblemToBuildApiArg = {
  buildLocator: string
  fields?: string
  body: string
}
export type GetBuildRelatedIssuesApiResponse = /** status 200 successful operation */ IssuesUsages
export type GetBuildRelatedIssuesApiArg = {
  buildLocator: string
  fields?: string
}
export type GetBuildResolvedApiResponse = unknown
export type GetBuildResolvedApiArg = {
  buildLocator: string
  value: string
}
export type GetBuildActualParametersApiResponse = /** status 200 successful operation */ Properties
export type GetBuildActualParametersApiArg = {
  buildLocator: string
  fields?: string
}
export type GetBuildResultingPropertiesApiResponse = unknown
export type GetBuildResultingPropertiesApiArg = {
  buildLocator: string
  propertyName: string
}
export type MarkBuildAsRunningApiResponse = /** status 200 successful operation */ Build
export type MarkBuildAsRunningApiArg = {
  buildLocator: string
  fields?: string
  body: string
}
export type GetBuildSourceFileApiResponse = unknown
export type GetBuildSourceFileApiArg = {
  buildLocator: string
  fileName: string
}
export type GetBuildStatisticValuesApiResponse = /** status 200 successful operation */ Properties
export type GetBuildStatisticValuesApiArg = {
  buildLocator: string
  fields?: string
}
export type GetBuildStatisticValueApiResponse = unknown
export type GetBuildStatisticValueApiArg = {
  buildLocator: string
  name: string
}
export type GetBuildStatusApiResponse = unknown
export type GetBuildStatusApiArg = {
  buildLocator: string
}
export type SetBuildStatusApiResponse = unknown
export type SetBuildStatusApiArg = {
  buildLocator: string
  fields?: string
  buildStatusUpdate: BuildStatusUpdate
}
export type GetBuildStatusIconApiResponse = unknown
export type GetBuildStatusIconApiArg = {
  buildLocator: string
  suffix: string
}
export type GetBuildStatusTextApiResponse = unknown
export type GetBuildStatusTextApiArg = {
  buildLocator: string
}
export type SetBuildStatusTextApiResponse = unknown
export type SetBuildStatusTextApiArg = {
  buildLocator: string
  body: string
}
export type GetBuildTagsApiResponse = /** status 200 successful operation */ Tags
export type GetBuildTagsApiArg = {
  buildLocator: string
  locator?: string
  fields?: string
}
export type AddTagsToBuildApiResponse = /** status 200 successful operation */ Tags
export type AddTagsToBuildApiArg = {
  buildLocator: string
  fields?: string
  tags: Tags
}
export type SetBuildTagsApiResponse = /** status 200 successful operation */ Tags
export type SetBuildTagsApiArg = {
  buildLocator: string
  locator?: string
  fields?: string
  tags: Tags
}
export type GetBuildTestOccurrencesApiResponse =
  /** status 200 successful operation */ TestOccurrences
export type GetBuildTestOccurrencesApiArg = {
  buildLocator: string
  fields?: string
}
export type GetBuildVcsLabelsApiResponse = /** status 200 successful operation */ VcsLabels
export type GetBuildVcsLabelsApiArg = {
  buildLocator: string
  fields?: string
}
export type AddBuildVcsLabelApiResponse = /** status 200 successful operation */ VcsLabels
export type AddBuildVcsLabelApiArg = {
  buildLocator: string
  locator?: string
  fields?: string
  body: string
}
export type GetBuildFieldApiResponse = unknown
export type GetBuildFieldApiArg = {
  buildLocator: string
  field: string
}
export type GetAllChangesApiResponse = /** status 200 successful operation */ Changes
export type GetAllChangesApiTransformedResponse = Omit<Changes, 'change'> & {
  changes: NormalizedChanges | undefined
}
export type GetAllChangesApiArg = {
  locator?: string
  fields?: string
  withUpdatePager?: boolean
}
export type GetChangeApiResponse = /** status 200 successful operation */ Change
export type GetChangeApiArg = {
  changeLocator: string
  fields?: string
}
export type GetChangeAttributesApiResponse = /** status 200 successful operation */ Entries
export type GetChangeAttributesApiArg = {
  changeLocator: string
  fields?: string
}
export type GetChangeDuplicatesApiResponse = /** status 200 successful operation */ Changes
export type GetChangeDuplicatesApiArg = {
  changeLocator: string
  fields?: string
}
export type GetChangeIssueApiResponse = /** status 200 successful operation */ Issues
export type GetChangeIssueApiArg = {
  changeLocator: string
}
export type GetChangeParentChangesApiResponse = /** status 200 successful operation */ Changes
export type GetChangeParentChangesApiArg = {
  changeLocator: string
  fields?: string
}
export type GetChangeParentRevisionsApiResponse = /** status 200 successful operation */ Items
export type GetChangeParentRevisionsApiArg = {
  changeLocator: string
}
export type GetChangeVcsRootApiResponse = /** status 200 successful operation */ VcsRootInstance
export type GetChangeVcsRootApiArg = {
  changeLocator: string
  fields?: string
}
export type GetChangeFieldApiResponse = unknown
export type GetChangeFieldApiArg = {
  changeLocator: string
  field: string
}
export type GetAllCloudImagesApiResponse = /** status 200 successful operation */ CloudImages
export type GetAllCloudImagesApiArg = {
  locator?: string
  fields?: string
}
export type GetCloudImageApiResponse = /** status 200 successful operation */ CloudImage
export type GetCloudImageApiArg = {
  imageLocator: string
  fields?: string
}
export type GetAllCloudInstancesApiResponse = /** status 200 successful operation */ CloudInstances
export type GetAllCloudInstancesApiArg = {
  locator?: string
  fields?: string
}
export type StartInstanceApiResponse = unknown
export type StartInstanceApiArg = {
  fields?: string
  cloudInstance: CloudInstance
}
export type GetCloudInstanceApiResponse = /** status 200 successful operation */ CloudInstance
export type GetCloudInstanceApiArg = {
  instanceLocator: string
  fields?: string
}
export type StopInstanceApiResponse = unknown
export type StopInstanceApiArg = {
  instanceLocator: string
}
export type GetAllCloudProfilesApiResponse = /** status 200 successful operation */ CloudProfiles
export type GetAllCloudProfilesApiArg = {
  locator?: string
  fields?: string
}
export type GetCloudProfileApiResponse = /** status 200 successful operation */ CloudProfile
export type GetCloudProfileApiArg = {
  profileLocator: string
  fields?: string
}
export type GetHealthItemsApiResponse = /** status 200 successful operation */ HealthStatusItems
export type GetHealthItemsApiArg = {
  locator?: string
  fields?: string
}
export type GetCategoriesApiResponse = /** status 200 successful operation */ HealthCategories
export type GetCategoriesApiArg = {
  locator?: string
  fields?: string
}
export type GetSingleCategoryApiResponse = /** status 200 successful operation */ HealthCategory
export type GetSingleCategoryApiArg = {
  locator: string
  fields?: string
}
export type GetSingleHealthItemApiResponse = /** status 200 successful operation */ HealthItem
export type GetSingleHealthItemApiArg = {
  locator: string
  fields?: string
}
export type GetPluginInfoApiResponse = unknown
export type GetPluginInfoApiArg = {
  fields?: string
}
export type GetAllInvestigationsApiResponse = /** status 200 successful operation */ Investigations
export type GetAllInvestigationsApiArg = {
  locator?: string
  fields?: string
}
export type AddInvestigationApiResponse = /** status 200 successful operation */ Investigation
export type AddInvestigationApiArg = {
  fields?: string
  investigation: Investigation
}
export type AddMultipleInvestigationsApiResponse =
  /** status 200 successful operation */ Investigations
export type AddMultipleInvestigationsApiArg = {
  fields?: string
  investigations: Investigations
}
export type GetInvestigationApiResponse = /** status 200 successful operation */ Investigation
export type GetInvestigationApiArg = {
  investigationLocator: string
  fields?: string
}
export type ReplaceInvestigationApiResponse = /** status 200 successful operation */ Investigation
export type ReplaceInvestigationApiArg = {
  investigationLocator: string
  fields?: string
  investigation: Investigation
}
export type DeleteInvestigationApiResponse = unknown
export type DeleteInvestigationApiArg = {
  investigationLocator: string
}
export type GetAllMutedTestsApiResponse = /** status 200 successful operation */ Mutes
export type GetAllMutedTestsApiArg = {
  locator?: string
  fields?: string
}
export type MuteTestApiResponse = /** status 200 successful operation */ Mute
export type MuteTestApiArg = {
  fields?: string
  mute: Mute
}
export type MuteMultipleTestsApiResponse = /** status 200 successful operation */ Mutes
export type MuteMultipleTestsApiArg = {
  fields?: string
  mutes: Mutes
}
export type GetMutedTestApiResponse = /** status 200 successful operation */ Mute
export type GetMutedTestApiArg = {
  muteLocator: string
  fields?: string
}
export type UnmuteTestApiResponse = unknown
export type UnmuteTestApiArg = {
  muteLocator: string
  body: string
}
export type GetAllBuildProblemOccurrencesApiResponse =
  /** status 200 successful operation */ ProblemOccurrences
export type GetAllBuildProblemOccurrencesApiArg = {
  locator?: string
  fields?: string
}
export type GetBuildProblemOccurrenceApiResponse =
  /** status 200 successful operation */ ProblemOccurrence
export type GetBuildProblemOccurrenceApiArg = {
  problemLocator: string
  fields?: string
}
export type GetAllBuildProblemsApiResponse = /** status 200 successful operation */ Problems
export type GetAllBuildProblemsApiArg = {
  locator?: string
  fields?: string
}
export type GetBuildProblemApiResponse = /** status 200 successful operation */ Problem
export type GetBuildProblemApiArg = {
  problemLocator: string
  fields?: string
}
export type GetAllProjectsApiResponse = /** status 200 successful operation */ Projects
export type GetAllProjectsApiArg = {
  locator?: string
  fields?: string
}
export type AddProjectApiResponse = /** status 200 successful operation */ Project
export type AddProjectApiArg = {
  newProjectDescription: NewProjectDescription
}
export type GetProjectApiResponse = /** status 200 successful operation */ Project
export type GetProjectApiArg = {
  projectLocator: string
  fields?: string
}
export type DeleteProjectApiResponse = unknown
export type DeleteProjectApiArg = {
  projectLocator: string
}
export type GetAgentPoolsProjectApiResponse = /** status 200 successful operation */ AgentPools
export type GetAgentPoolsProjectApiArg = {
  projectLocator: string
  fields?: string
}
export type AddAgentPoolsProjectApiResponse = /** status 200 successful operation */ AgentPool
export type AddAgentPoolsProjectApiArg = {
  projectLocator: string
  agentPool: AgentPool
}
export type SetAgentPoolsProjectApiResponse = /** status 200 successful operation */ AgentPools
export type SetAgentPoolsProjectApiArg = {
  projectLocator: string
  fields?: string
  agentPools: AgentPools
}
export type RemoveProjectFromAgentPoolApiResponse = unknown
export type RemoveProjectFromAgentPoolApiArg = {
  projectLocator: string
  agentPoolLocator: string
}
export type GetAllBranchesApiResponse = /** status 200 successful operation */ Branches
export type GetAllBranchesApiArg = {
  projectLocator: string
  locator?: string
  fields?: string
}
export type AddBuildTypeApiResponse = /** status 200 successful operation */ BuildType
export type AddBuildTypeApiArg = {
  projectLocator: string
  fields?: string
  newBuildTypeDescription: NewBuildTypeDescription
}
export type GetDefaultTemplateApiResponse = /** status 200 successful operation */ BuildType
export type GetDefaultTemplateApiArg = {
  projectLocator: string
  fields?: string
}
export type SetDefaultTemplateApiResponse = /** status 200 successful operation */ BuildType
export type SetDefaultTemplateApiArg = {
  projectLocator: string
  fields?: string
  buildType: BuildType
}
export type RemoveDefaultTemplateApiResponse = unknown
export type RemoveDefaultTemplateApiArg = {
  projectLocator: string
  fields?: string
}
export type GetAllBuildTypesOrderedApiResponse = /** status 200 successful operation */ BuildTypes
export type GetAllBuildTypesOrderedApiArg = {
  projectLocator: string
  field?: string
}
export type SetBuildTypesOrderApiResponse = /** status 200 successful operation */ BuildTypes
export type SetBuildTypesOrderApiArg = {
  projectLocator: string
  field?: string
  buildTypes: BuildTypes
}
export type GetAllSubprojectsOrderedApiResponse = /** status 200 successful operation */ Projects
export type GetAllSubprojectsOrderedApiArg = {
  projectLocator: string
  field?: string
}
export type SetSubprojectsOrderApiResponse = /** status 200 successful operation */ Projects
export type SetSubprojectsOrderApiArg = {
  projectLocator: string
  field?: string
  projects: Projects
}
export type GetBuildParametersApiResponse = /** status 200 successful operation */ Properties
export type GetBuildParametersApiArg = {
  locator?: string
  fields?: string
  projectLocator: string
}
export type CreateBuildParameterApiResponse = /** status 200 successful operation */ Property
export type CreateBuildParameterApiArg = {
  fields?: string
  projectLocator: string
  property: Property
}
export type UpdateBuildParametersApiResponse = /** status 200 successful operation */ Properties
export type UpdateBuildParametersApiArg = {
  fields?: string
  projectLocator: string
  properties: Properties
}
export type DeleteBuildParametersApiResponse = unknown
export type DeleteBuildParametersApiArg = {
  projectLocator: string
}
export type GetBuildParameterApiResponse = /** status 200 successful operation */ Property
export type GetBuildParameterApiArg = {
  name: string
  fields?: string
  projectLocator: string
}
export type UpdateBuildParameterApiResponse = /** status 200 successful operation */ Property
export type UpdateBuildParameterApiArg = {
  name: string
  fields?: string
  projectLocator: string
  property: Property
}
export type DeleteBuildParameterApiResponse = unknown
export type DeleteBuildParameterApiArg = {
  name: string
  projectLocator: string
}
export type GetBuildParameterTypeApiResponse = /** status 200 successful operation */ Type
export type GetBuildParameterTypeApiArg = {
  name: string
  projectLocator: string
}
export type UpdateBuildParameterTypeApiResponse = /** status 200 successful operation */ Type
export type UpdateBuildParameterTypeApiArg = {
  name: string
  projectLocator: string
  type: Type
}
export type GetBuildParameterSpecificationApiResponse = unknown
export type GetBuildParameterSpecificationApiArg = {
  name: string
  projectLocator: string
}
export type UpdateBuildParameterSpecificationApiResponse = unknown
export type UpdateBuildParameterSpecificationApiArg = {
  name: string
  projectLocator: string
  body: string
}
export type GetBuildParameterValueApiResponse = unknown
export type GetBuildParameterValueApiArg = {
  name: string
  projectLocator: string
}
export type UpdateBuildParameterValueApiResponse = unknown
export type UpdateBuildParameterValueApiArg = {
  name: string
  projectLocator: string
  body: string
}
export type GetProjectParentProjectApiResponse = /** status 200 successful operation */ Project
export type GetProjectParentProjectApiArg = {
  projectLocator: string
  fields?: string
}
export type SetParentProjectApiResponse = /** status 200 successful operation */ Project
export type SetParentProjectApiArg = {
  projectLocator: string
  fields?: string
  project: Project
}
export type GetFeaturesApiResponse = /** status 200 successful operation */ object
export type GetFeaturesApiArg = {
  locator?: string
  fields?: string
  projectLocator: string
}
export type AddFeatureApiResponse = /** status 200 successful operation */ object
export type AddFeatureApiArg = {
  fields?: string
  projectLocator: string
  projectFeature: ProjectFeature
}
export type UpdateFeaturesApiResponse = /** status 200 successful operation */ object
export type UpdateFeaturesApiArg = {
  fields?: string
  projectLocator: string
  projectFeatures: ProjectFeatures
}
export type GetFeatureApiResponse = /** status 200 successful operation */ {
  properties?: Properties
}
export type GetFeatureApiArg = {
  featureLocator: string
  fields?: string
  projectLocator: string
}
export type UpdateFeatureApiResponse = /** status 200 successful operation */ object
export type UpdateFeatureApiArg = {
  featureLocator: string
  fields?: string
  projectLocator: string
  projectFeature: ProjectFeature
}
export type DeleteFeatureApiResponse = unknown
export type DeleteFeatureApiArg = {
  featureLocator: string
  projectLocator: string
}
export type AddSecureTokenApiResponse = unknown
export type AddSecureTokenApiArg = {
  projectLocator: string
  body: string
}
export type GetSecureValueApiResponse = unknown
export type GetSecureValueApiArg = {
  projectLocator: string
  token: string
}
export type GetProjectSettingsFileApiResponse = unknown
export type GetProjectSettingsFileApiArg = {
  projectLocator: string
}
export type GetProjectTemplatesApiResponse = /** status 200 successful operation */ BuildTypes
export type GetProjectTemplatesApiArg = {
  projectLocator: string
  fields?: string
}
export type AddTemplateApiResponse = /** status 200 successful operation */ BuildType
export type AddTemplateApiArg = {
  projectLocator: string
  fields?: string
  newBuildTypeDescription: NewBuildTypeDescription
}
export type GetProjectFieldApiResponse = unknown
export type GetProjectFieldApiArg = {
  projectLocator: string
  field: string
}
export type SetProjectFieldApiResponse = string
export type SetProjectFieldApiArg = {
  projectLocator: string
  field: string
  body: string
}
export type GetServerInfoApiResponse = /** status 200 successful operation */ Server
export type GetServerInfoApiArg = {
  fields?: string
}
export type GetBackupStatusApiResponse = unknown
export type GetBackupStatusApiArg = void
export type StartBackupApiResponse = unknown
export type StartBackupApiArg = {
  fileName?: string
  addTimestamp?: boolean
  includeConfigs?: boolean
  includeDatabase?: boolean
  includeBuildLogs?: boolean
  includePersonalChanges?: boolean
  includeRunningBuilds?: boolean
  includeSupplimentaryData?: boolean
}
export type GetFilesListOfServerApiResponse = /** status 200 successful operation */ Files
export type GetFilesListOfServerApiArg = {
  basePath?: string
  locator?: string
  fields?: string
  areaId: string
}
export type GetZippedFileOfServerApiResponse = unknown
export type GetZippedFileOfServerApiArg = {
  path: string
  basePath?: string
  locator?: string
  name?: string
  areaId: string
}
export type DownloadFileOfServerApiResponse = unknown
export type DownloadFileOfServerApiArg = {
  path: string
  areaId: string
}
export type GetFileMetadataOfServerApiResponse = /** status 200 successful operation */ File
export type GetFileMetadataOfServerApiArg = {
  path: string
  fields?: string
  areaId: string
}
export type GetFilesListForSubpathOfServerApiResponse = /** status 200 successful operation */ Files
export type GetFilesListForSubpathOfServerApiArg = {
  path: string
  basePath?: string
  locator?: string
  fields?: string
  areaId: string
}
export type GetLicensingDataApiResponse = /** status 200 successful operation */ LicensingData
export type GetLicensingDataApiArg = {
  fields?: string
}
export type GetLicenseKeysApiResponse = /** status 200 successful operation */ LicenseKeys
export type GetLicenseKeysApiArg = {
  fields?: string
}
export type AddLicenseKeysApiResponse = /** status 200 successful operation */ LicenseKeys
export type AddLicenseKeysApiArg = {
  fields?: string
  body: string
}
export type GetLicenseKeyApiResponse = /** status 200 successful operation */ LicenseKey
export type GetLicenseKeyApiArg = {
  licenseKey: string
  fields?: string
}
export type DeleteLicenseKeyApiResponse = unknown
export type DeleteLicenseKeyApiArg = {
  licenseKey: string
}
export type GetAllMetricsApiResponse = /** status 200 successful operation */ Metrics
export type GetAllMetricsApiArg = {
  fields?: string
}
export type NodesApiResponse = /** status 200 successful operation */ Nodes
export type NodesApiArg = {
  fields?: string
}
export type GetNodeApiResponse = /** status 200 successful operation */ Node
export type GetNodeApiArg = {
  nodeId: string
  fields?: string
}
export type GetAllPluginsApiResponse = /** status 200 successful operation */ Plugins
export type GetAllPluginsApiArg = {
  fields?: string
}
export type GetServerFieldApiResponse = unknown
export type GetServerFieldApiArg = {
  field: string
}
export type GetAllTestOccurrencesApiResponse =
  /** status 200 successful operation */ TestOccurrences
export type GetAllTestOccurrencesApiArg = {
  locator?: string
  fields?: string
}
export type GetTestOccurrenceApiResponse = /** status 200 successful operation */ TestOccurrence
export type GetTestOccurrenceApiArg = {
  testLocator: string
  fields?: string
}
export type GetTestsApiResponse = /** status 200 successful operation */ Tests
export type GetTestsApiArg = {
  locator?: string
  fields?: string
}
export type GetTestApiResponse = /** status 200 successful operation */ Test
export type GetTestApiArg = {
  testLocator: string
  fields?: string
}
export type GetAllGroupsApiResponse = /** status 200 successful operation */ Groups
export type GetAllGroupsApiArg = {
  fields?: string
}
export type AddGroupApiResponse = /** status 200 successful operation */ Group
export type AddGroupApiArg = {
  fields?: string
  group: Group
}
export type GetUserGroupOfGroupApiResponse = /** status 200 successful operation */ Group
export type GetUserGroupOfGroupApiArg = {
  groupLocator: string
  fields?: string
}
export type DeleteGroupApiResponse = unknown
export type DeleteGroupApiArg = {
  groupLocator: string
}
export type GetGroupParentGroupsApiResponse = /** status 200 successful operation */ Groups
export type GetGroupParentGroupsApiArg = {
  groupLocator: string
  fields?: string
}
export type SetGroupParentGroupsApiResponse = /** status 200 successful operation */ Groups
export type SetGroupParentGroupsApiArg = {
  groupLocator: string
  fields?: string
  groups: Groups
}
export type GetGroupPropertiesApiResponse = /** status 200 successful operation */ Properties
export type GetGroupPropertiesApiArg = {
  groupLocator: string
  fields?: string
}
export type GetGroupPropertyApiResponse = unknown
export type GetGroupPropertyApiArg = {
  groupLocator: string
  name: string
}
export type SetGroupPropertyApiResponse = unknown
export type SetGroupPropertyApiArg = {
  groupLocator: string
  name: string
  body: string
}
export type RemoveGroupPropertyApiResponse = unknown
export type RemoveGroupPropertyApiArg = {
  groupLocator: string
  name: string
}
export type GetGroupRolesApiResponse = /** status 200 successful operation */ Roles
export type GetGroupRolesApiArg = {
  groupLocator: string
}
export type AddRoleToGroupApiResponse = /** status 200 successful operation */ Role
export type AddRoleToGroupApiArg = {
  groupLocator: string
  role: Role
}
export type SetGroupRolesApiResponse = /** status 200 successful operation */ Roles
export type SetGroupRolesApiArg = {
  groupLocator: string
  roles: Roles
}
export type GetGroupRoleAtScopeApiResponse = /** status 200 successful operation */ Role
export type GetGroupRoleAtScopeApiArg = {
  groupLocator: string
  roleId: string
  scope: string
}
export type AddRoleAtScopeToGroupApiResponse = /** status 200 successful operation */ Role
export type AddRoleAtScopeToGroupApiArg = {
  groupLocator: string
  roleId: string
  scope: string
}
export type RemoveRoleAtScopeFromGroupApiResponse = unknown
export type RemoveRoleAtScopeFromGroupApiArg = {
  groupLocator: string
  roleId: string
  scope: string
}
export type GetAllUsersApiResponse = /** status 200 successful operation */ Users
export type GetAllUsersApiArg = {
  locator?: string
  fields?: string
}
export type AddUserApiResponse = /** status 200 successful operation */ User
export type AddUserApiArg = {
  fields?: string
  user: User
}
export type GetUserApiResponse = /** status 200 successful operation */ User
export type GetUserApiArg = {
  userLocator: string
  fields?: string
}
export type ReplaceUserApiResponse = /** status 200 successful operation */ User
export type ReplaceUserApiArg = {
  userLocator: string
  fields?: string
  user: User
}
export type DeleteUserApiResponse = unknown
export type DeleteUserApiArg = {
  userLocator: string
}
export type RemoveUserRememberMeApiResponse = unknown
export type RemoveUserRememberMeApiArg = {
  userLocator: string
}
export type GetAllUserGroupsApiResponse = /** status 200 successful operation */ Groups
export type GetAllUserGroupsApiArg = {
  userLocator: string
  fields?: string
}
export type SetUserGroupsApiResponse = /** status 200 successful operation */ Groups
export type SetUserGroupsApiArg = {
  userLocator: string
  fields?: string
  groups: Groups
}
export type GetUserGroupApiResponse = /** status 200 successful operation */ Group
export type GetUserGroupApiArg = {
  userLocator: string
  groupLocator: string
  fields?: string
}
export type RemoveUserFromGroupApiResponse = unknown
export type RemoveUserFromGroupApiArg = {
  userLocator: string
  groupLocator: string
  fields?: string
}
export type GetUserPermissionsApiResponse =
  /** status 200 successful operation */ PermissionAssignments
export type GetUserPermissionsApiArg = {
  userLocator: string
  locator?: string
  fields?: string
}
export type GetUserPropertiesApiResponse = /** status 200 successful operation */ Properties
export type GetUserPropertiesApiArg = {
  userLocator: string
  fields?: string
}
export type GetUserPropertyApiResponse = unknown
export type GetUserPropertyApiArg = {
  userLocator: string
  name: string
}
export type SetUserPropertyApiResponse = unknown
export type SetUserPropertyApiArg = {
  userLocator: string
  name: string
  body: string
}
export type RemoveUserPropertyApiResponse = unknown
export type RemoveUserPropertyApiArg = {
  userLocator: string
  name: string
}
export type GetAllUserRolesApiResponse = /** status 200 successful operation */ Roles
export type GetAllUserRolesApiArg = {
  userLocator: string
}
export type AddRoleToUserApiResponse = /** status 200 successful operation */ Role
export type AddRoleToUserApiArg = {
  userLocator: string
  role: Role
}
export type SetUserRolesApiResponse = /** status 200 successful operation */ Roles
export type SetUserRolesApiArg = {
  userLocator: string
  roles: Roles
}
export type GetUserRolesAtScopeApiResponse = /** status 200 successful operation */ Role
export type GetUserRolesAtScopeApiArg = {
  userLocator: string
  roleId: string
  scope: string
}
export type AddRoleToUserAtScopeApiResponse = /** status 200 successful operation */ Role
export type AddRoleToUserAtScopeApiArg = {
  userLocator: string
  roleId: string
  scope: string
}
export type RemoveUserRoleAtScopeApiResponse = unknown
export type RemoveUserRoleAtScopeApiArg = {
  userLocator: string
  roleId: string
  scope: string
}
export type GetUserTokensApiResponse = /** status 200 successful operation */ Tokens
export type GetUserTokensApiArg = {
  userLocator: string
  fields?: string
}
export type AddUserTokenApiResponse = /** status 200 successful operation */ Token
export type AddUserTokenApiArg = {
  userLocator: string
  fields?: string
  token: Token
}
export type DeleteUserTokenApiResponse = unknown
export type DeleteUserTokenApiArg = {
  userLocator: string
  name: string
}
export type GetUserFieldApiResponse = unknown
export type GetUserFieldApiArg = {
  userLocator: string
  field: string
}
export type SetUserFieldApiResponse = unknown
export type SetUserFieldApiArg = {
  userLocator: string
  field: string
  body: string
}
export type DeleteUserFieldApiResponse = unknown
export type DeleteUserFieldApiArg = {
  userLocator: string
  field: string
}
export type GetAllVcsRootInstancesApiResponse =
  /** status 200 successful operation */ VcsRootInstances
export type GetAllVcsRootInstancesApiArg = {
  locator?: string
  fields?: string
}
export type RequestPendingChangesCheckApiResponse =
  /** status 200 successful operation */ VcsRootInstances
export type RequestPendingChangesCheckApiArg = {
  locator?: string
  requestor?: string
  fields?: string
}
export type TriggerCommitHookNotificationApiResponse = unknown
export type TriggerCommitHookNotificationApiArg = {
  locator?: string
  okOnNothingFound?: boolean
}
export type GetVcsRootInstanceApiResponse = /** status 200 successful operation */ VcsRootInstance
export type GetVcsRootInstanceApiArg = {
  vcsRootInstanceLocator: string
  fields?: string
}
export type GetFilesListApiResponse = /** status 200 successful operation */ Files
export type GetFilesListApiArg = {
  basePath?: string
  locator?: string
  fields?: string
  vcsRootInstanceLocator: string
}
export type GetZippedFileApiResponse = unknown
export type GetZippedFileApiArg = {
  path: string
  basePath?: string
  locator?: string
  name?: string
  vcsRootInstanceLocator: string
}
export type DownloadFileApiResponse = unknown
export type DownloadFileApiArg = {
  path: string
  vcsRootInstanceLocator: string
}
export type GetFileMetadataApiResponse = /** status 200 successful operation */ File
export type GetFileMetadataApiArg = {
  path: string
  fields?: string
  vcsRootInstanceLocator: string
}
export type GetFilesListForSubpathApiResponse = /** status 200 successful operation */ Files
export type GetFilesListForSubpathApiArg = {
  path: string
  basePath?: string
  locator?: string
  fields?: string
  vcsRootInstanceLocator: string
}
export type GetVcsRootInstancePropertiesApiResponse =
  /** status 200 successful operation */ Properties
export type GetVcsRootInstancePropertiesApiArg = {
  vcsRootInstanceLocator: string
  fields?: string
}
export type GetVcsRootInstanceRepositoryStateApiResponse =
  /** status 200 successful operation */ Entries
export type GetVcsRootInstanceRepositoryStateApiArg = {
  vcsRootInstanceLocator: string
  fields?: string
}
export type SetVcsRootInstanceRepositoryStateApiResponse =
  /** status 200 successful operation */ Entries
export type SetVcsRootInstanceRepositoryStateApiArg = {
  vcsRootInstanceLocator: string
  fields?: string
  entries: Entries
}
export type DeleteVcsRootInstanceRepositoryStateApiResponse = unknown
export type DeleteVcsRootInstanceRepositoryStateApiArg = {
  vcsRootInstanceLocator: string
}
export type GetVcsRootInstanceCreationDateApiResponse = unknown
export type GetVcsRootInstanceCreationDateApiArg = {
  vcsRootInstanceLocator: string
}
export type GetVcsRootInstanceFieldApiResponse = unknown
export type GetVcsRootInstanceFieldApiArg = {
  vcsRootInstanceLocator: string
  field: string
}
export type SetVcsRootInstanceFieldApiResponse = unknown
export type SetVcsRootInstanceFieldApiArg = {
  vcsRootInstanceLocator: string
  field: string
  body: string
}
export type DeleteVcsRootInstanceFieldApiResponse = unknown
export type DeleteVcsRootInstanceFieldApiArg = {
  vcsRootInstanceLocator: string
  field: string
}
export type GetAllVcsRootsApiResponse = /** status 200 successful operation */ VcsRoots
export type GetAllVcsRootsApiArg = {
  locator?: string
  fields?: string
}
export type AddVcsRootApiResponse = /** status 200 successful operation */ VcsRoot
export type AddVcsRootApiArg = {
  fields?: string
  vcsRoot: VcsRoot
}
export type GetRootEndpointsApiResponse = /** status 200 successful operation */ VcsRoot
export type GetRootEndpointsApiArg = {
  vcsRootLocator: string
  fields?: string
}
export type DeleteVcsRootApiResponse = unknown
export type DeleteVcsRootApiArg = {
  vcsRootLocator: string
}
export type GetVcsRootInstancesApiResponse = /** status 200 successful operation */ VcsRootInstances
export type GetVcsRootInstancesApiArg = {
  vcsRootLocator: string
  fields?: string
}
export type GetAllVcsRootPropertiesApiResponse = /** status 200 successful operation */ Properties
export type GetAllVcsRootPropertiesApiArg = {
  vcsRootLocator: string
  fields?: string
}
export type SetVcsRootPropertiesApiResponse = /** status 200 successful operation */ Properties
export type SetVcsRootPropertiesApiArg = {
  vcsRootLocator: string
  fields?: string
  properties: Properties
}
export type DeleteAllVcsRootPropertiesApiResponse = unknown
export type DeleteAllVcsRootPropertiesApiArg = {
  vcsRootLocator: string
}
export type GetVcsRootPropertyApiResponse = unknown
export type GetVcsRootPropertyApiArg = {
  vcsRootLocator: string
  name: string
}
export type SetVcsRootPropertyApiResponse = unknown
export type SetVcsRootPropertyApiArg = {
  vcsRootLocator: string
  name: string
  body: string
}
export type DeleteVcsRootPropertyApiResponse = unknown
export type DeleteVcsRootPropertyApiArg = {
  vcsRootLocator: string
  name: string
}
export type GetVcsRootSettingsFileApiResponse = unknown
export type GetVcsRootSettingsFileApiArg = {
  vcsRootLocator: string
}
export type GetVcsRootFieldApiResponse = unknown
export type GetVcsRootFieldApiArg = {
  vcsRootLocator: string
  field: string
}
export type SetVcsRootFieldApiResponse = unknown
export type SetVcsRootFieldApiArg = {
  vcsRootLocator: string
  field: string
  body: string
}
export type GetVersionApiResponse = unknown
export type GetVersionApiArg = void
export type Link = {
  type?: string
  url?: string
  relativeUrl?: string
}
export type Links = {
  count?: number
  link?: Link[]
}
export type StateField = {
  value?: boolean
  inherited?: boolean
}
export type BuildTypes = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  buildType?: BuildType[]
}
export type Type = {
  rawValue?: string
}
export type Property = {
  name?: string
  value?: string
  inherited?: boolean
  type?: Type
}
export type Properties = {
  count?: number
  href?: string
  property?: Property[]
}
export type VcsCheckStatus = {
  status?: string
  requestorType?: string
  timestamp?: string
}
export type VcsStatus = {
  current?: VcsCheckStatus
  previous?: VcsCheckStatus
}
export type Role = {
  roleId?: string
  scope?: string
  href?: string
}
export type Roles = {
  role?: Role[]
}
export type Users = {
  count?: number
  user?: User[]
}
export type Group = {
  key?: string
  name?: string
  href?: string
  description?: string
  'parent-groups'?: Groups
  'child-groups'?: Groups
  users?: Users
  roles?: Roles
  properties?: Properties
}
export type Groups = {
  count?: number
  group?: Group[]
}
export type UserAvatars = {
  urlToSize20?: string
  urlToSize28?: string
  urlToSize32?: string
  urlToSize40?: string
  urlToSize56?: string
  urlToSize64?: string
  urlToSize80?: string
}
export type User = {
  username?: string
  name?: string
  id?: number
  email?: string
  lastLogin?: string
  password?: string
  hasPassword?: boolean
  realm?: string
  href?: string
  properties?: Properties
  roles?: Roles
  groups?: Groups
  locator?: string
  avatars?: UserAvatars
  enabled2FA?: boolean
}
export type Comment = {
  timestamp?: string
  text?: string
  user?: User
}
export type Tag = {
  name?: string
  owner?: User
  private?: boolean
}
export type Tags = {
  count?: number
  tag?: Tag[]
}
export type EnabledInfo = {
  status?: boolean
  comment?: Comment
  statusSwitchTime?: string
}
export type AuthorizedInfo = {
  status?: boolean
  comment?: Comment
}
export type CloudImages = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  cloudImage?: CloudImage[]
}
export type CloudProfile = {
  id?: string
  name?: string
  cloudProviderId?: string
  href?: string
  project?: Project
  images?: CloudImages
}
export type CloudInstances = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  cloudInstance?: CloudInstance[]
}
export type CloudImage = {
  id?: string
  name?: string
  href?: string
  profile?: CloudProfile
  instances?: CloudInstances
  errorMessage?: string
  agentTypeId?: number
  agentPoolId?: number
  operatingSystemName?: string
  locator?: string
}
export type CloudInstance = {
  id?: string
  name?: string
  state?: string
  startDate?: string
  networkAddress?: string
  href?: string
  image?: CloudImage
  agent?: Agent
  errorMessage?: string
}
export type Environment = {
  osType?: string
  osName?: string
}
export type CompatibilityPolicy = {
  policy?: string
  buildTypes?: BuildTypes
}
export type Requirements = {
  description?: string
}
export type Compatibility = {
  compatible?: boolean
  agent?: Agent
  buildType?: BuildType
  unmetRequirements?: Requirements
}
export type Compatibilities = {
  count?: number
  compatibility?: Compatibility[]
}
export type Agent = {
  id?: number
  name?: string
  typeId?: number
  connected?: boolean
  enabled?: boolean
  authorized?: boolean
  uptodate?: boolean
  outdated?: boolean
  pluginsOutdated?: boolean
  javaOutdated?: boolean
  ip?: string
  protocol?: 'unidirectional' | 'bidirectional'
  version?: string
  currentAgentVersion?: string
  lastActivityTime?: string
  idleSinceTime?: string
  disconnectionComment?: string
  registrationTimestamp?: string
  host?: string
  cpuRank?: number
  port?: number
  href?: string
  webUrl?: string
  build?: Build
  links?: Links
  enabledInfo?: EnabledInfo
  authorizedInfo?: AuthorizedInfo
  properties?: Properties
  cloudInstance?: CloudInstance
  cloudImage?: CloudImage
  environment?: Environment
  pool?: AgentPool
  compatibilityPolicy?: CompatibilityPolicy
  compatibleBuildTypes?: BuildTypes
  incompatibleBuildTypes?: Compatibilities
  builds?: Builds
  locator?: string
}
export type UserApprovalRule = {
  approved?: boolean
  user?: User
}
export type UserApprovals = {
  count?: number
  userApproval?: UserApprovalRule[]
}
export type GroupApprovalRule = {
  group?: Group
  requiredApprovalsCount?: number
  currentlyApprovedBy?: Users
}
export type GroupApprovals = {
  count?: number
  groupApproval?: GroupApprovalRule[]
}
export type ApprovalInfo = {
  timeoutTimestamp?: string
  configurationValid?: boolean
  canBeApprovedByCurrentUser?: boolean
  userApprovals?: UserApprovals
  groupApprovals?: GroupApprovals
  status?: 'waitingForApproval' | 'approved' | 'timedOut' | 'canceled'
}
export type ProgressInfo = {
  percentageComplete?: number
  elapsedSeconds?: number
  estimatedTotalSeconds?: number
  leftSeconds?: number
  currentStageText?: string
  outdated?: boolean
  probablyHanging?: boolean
  lastActivityTime?: string
  outdatedReasonBuild?: Build
}
export type TriggeredBy = {
  type?: string
  details?: string
  date?: string
  displayText?: string
  rawValue?: string
  user?: User
  build?: Build
  buildType?: BuildType
  properties?: Properties
}
export type SnapshotDependencyLink = {
  build?: Build
  buildType?: BuildType
  buildTypeBranch?: string
}
export type FileChange = {
  'before-revision'?: string
  'after-revision'?: string
  changeType?: string
  changeTypeComment?: string
  file?: string
  'relative-file'?: string
  directory?: boolean
}
export type FileChanges = {
  count?: number
  file?: FileChange[]
}
export type Items = {
  item?: string[]
}
export type ChangeStatus = {
  finishedBuilds?: number
  successfulBuilds?: number
  runningBuilds?: number
  failedBuilds?: number
  cancelledBuilds?: number
  criticalBuilds?: Builds
  notCriticalBuilds?: Builds
  newTestsFailedBuilds?: Builds
  compilationErrorBuilds?: Builds
  runningSuccessfullyBuilds?: number
  pendingBuildTypes?: number
  totalProblems?: number
  newFailedTests?: number
  otherFailedTests?: number
  queuedBuildsCount?: number
}
export type Commiter = {
  vcsUsername?: string
  users?: Users
}
export type Change = {
  id?: number
  version?: string
  internalVersion?: string
  username?: string
  date?: string
  registrationDate?: string
  personal?: boolean
  href?: string
  webUrl?: string
  comment?: string
  user?: User
  type?: string
  snapshotDependencyLink?: SnapshotDependencyLink
  files?: FileChanges
  vcsRootInstance?: VcsRootInstance
  parentChanges?: Changes
  parentRevisions?: Items
  attributes?: Properties
  storesProjectSettings?: boolean
  status?: ChangeStatus
  commiter?: Commiter
  canEditComment?: boolean
  locator?: string
}
export type Changes = {
  href?: string
  change?: Change[]
  nextHref?: string
  prevHref?: string
  count?: number
}
export type Revision = {
  version?: string
  internalVersion?: string
  vcsBranchName?: string
  'vcs-root-instance'?: VcsRootInstance
  'checkout-rules'?: string
}
export type Revisions = {
  count?: number
  revision?: Revision[]
}
export type BuildChange = {
  nextBuild?: Build
  prevBuild?: Build
}
export type BuildChanges = {
  count?: number
  buildChange?: BuildChange[]
}
export type Agents = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  agent?: Agent[]
}
export type ProblemScope = {
  project?: Project
  buildTypes?: BuildTypes
  buildType?: BuildType
}
export type TestCounters = {
  ignored?: number
  failed?: number
  muted?: number
  success?: number
  all?: number
  newFailed?: number
  duration?: number
}
export type Tests = {
  count?: number
  myTestCounters?: TestCounters
  nextHref?: string
  prevHref?: string
  test?: Test[]
}
export type Resolution = {
  type?: 'manually' | 'whenFixed' | 'atTime'
  time?: string
}
export type Investigation = {
  id?: string
  state?: 'TAKEN' | 'FIXED' | 'GIVEN_UP' | 'NONE'
  href?: string
  assignee?: User
  assignment?: Comment
  scope?: ProblemScope
  target?: ProblemTarget
  resolution?: Resolution
  responsible?: User
}
export type Investigations = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  investigation?: Investigation[]
}
export type ProblemOccurrence = {
  id?: string
  type?: string
  identity?: string
  href?: string
  muted?: boolean
  currentlyInvestigated?: boolean
  currentlyMuted?: boolean
  logAnchor?: string
  newFailure?: boolean
  details?: string
  additionalData?: string
  problem?: Problem
  mute?: Mute
  build?: Build
}
export type ProblemOccurrences = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  problemOccurrence?: ProblemOccurrence[]
  passed?: number
  failed?: number
  newFailed?: number
  ignored?: number
  muted?: number
}
export type Problem = {
  id?: string
  type?: string
  identity?: string
  href?: string
  description?: string
  mutes?: Mutes
  investigations?: Investigations
  problemOccurrences?: ProblemOccurrences
  locator?: string
}
export type Problems = {
  count?: number
  nextHref?: string
  prevHref?: string
  problem?: Problem[]
}
export type ProblemTarget = {
  anyProblem?: boolean
  tests?: Tests
  problems?: Problems
}
export type Mute = {
  id?: number
  href?: string
  assignment?: Comment
  scope?: ProblemScope
  target?: ProblemTarget
  resolution?: Resolution
}
export type Mutes = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  mute?: Mute[]
}
export type ParsedTestName = {
  testPackage?: string
  testSuite?: string
  testClass?: string
  testShortName?: string
  testNameWithoutPrefix?: string
  testMethodName?: string
  testNameWithParameters?: string
}
export type Test = {
  id?: string
  name?: string
  mutes?: Mutes
  investigations?: Investigations
  testOccurrences?: TestOccurrences
  parsedTestName?: ParsedTestName
  href?: string
  locator?: string
}
export type TypedValue = {
  name?: string
  type?: string
  value?: string
}
export type TestRunMetadata = {
  count?: number
  typedValues?: TypedValue[]
}
export type TestOccurrence = {
  id?: string
  name?: string
  status?: 'UNKNOWN' | 'NORMAL' | 'WARNING' | 'FAILURE' | 'ERROR'
  ignored?: boolean
  duration?: number
  runOrder?: string
  newFailure?: boolean
  muted?: boolean
  currentlyMuted?: boolean
  currentlyInvestigated?: boolean
  href?: string
  ignoreDetails?: string
  details?: string
  test?: Test
  mute?: Mute
  build?: Build
  firstFailed?: TestOccurrence
  nextFixed?: TestOccurrence
  invocations?: TestOccurrences
  metadata?: TestRunMetadata
  logAnchor?: string
}
export type TestOccurrences = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  testOccurrence?: TestOccurrence[]
  testCounters?: TestCounters
  ignored?: number
  newFailed?: number
  passed?: number
  muted?: number
  failed?: number
}
export type Href = {
  href?: string
}
export type File = {
  name?: string
  fullName?: string
  size?: number
  modificationTime?: string
  href?: string
  parent?: File
  content?: Href
  children?: Files
}
export type Files = {
  count?: number
  href?: string
  file?: File[]
}
export type Issue = {
  url?: string
  id?: string
}
export type IssueUsage = {
  changes?: Changes
  issue?: Issue
}
export type IssuesUsages = {
  href?: string
  issueUsage?: IssueUsage[]
  count?: number
}
export type Entry = {
  name?: string
  value?: string
}
export type Entries = {
  count?: number
  entry?: Entry[]
}
export type MetaData = {
  id?: string
  entries?: Entries
}
export type Datas = {
  count?: number
  data?: MetaData[]
}
export type ArtifactDependency = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
  'source-buildType'?: BuildType
}
export type ArtifactDependencies = {
  count?: number
  'artifact-dependency'?: ArtifactDependency[]
  replace?: string
}
export type Related = {
  builds?: Builds
}
export type BuildTriggeringOptions = {
  cleanSources?: boolean
  cleanSourcesInAllDependencies?: boolean
  rebuildAllDependencies?: boolean
  rebuildFailedOrIncompleteDependencies?: boolean
  queueAtTop?: boolean
  freezeSettings?: boolean
  tagDependencies?: boolean
  rebuildDependencies?: BuildTypes
}
export type VcsLabel = {
  text?: string
  failureReason?: string
  status?:
    | 'none'
    | 'set'
    | 'is being set'
    | 'failed'
    | 'disabled for the vcs root'
    | 'not supported yet for the vcs'
  buildId?: number
  'vcs-root-instance'?: VcsRootInstance
}
export type Customizations = {
  parameters?: {
    [key: string]: string
  }
  changes?: {
    [key: string]: string
  }
  artifactDependencies?: {
    [key: string]: string
  }
}
export type ArtifactDownloadInfo = {
  path?: string
  downloadTimestamp?: string
}
export type DownloadInfo = {
  artifactInfo?: ArtifactDownloadInfo[]
  build?: Build
  count?: number
}
export type DownloadedArtifacts = {
  unfilteredCount?: number
  downloadInfo?: DownloadInfo[]
  count?: number
}
export type Build = {
  id?: number
  taskId?: number
  buildTypeId?: string
  buildTypeInternalId?: string
  number?: string
  status?: string
  state?: 'queued' | 'finished' | 'running' | 'deleted' | 'unknown'
  running?: boolean
  composite?: boolean
  failedToStart?: boolean
  personal?: boolean
  percentageComplete?: number
  branchName?: string
  defaultBranch?: boolean
  unspecifiedBranch?: boolean
  history?: boolean
  pinned?: boolean
  href?: string
  webUrl?: string
  queuePosition?: number
  limitedChangesCount?: number
  artifactsDirectory?: string
  links?: Links
  statusText?: string
  buildType?: BuildType
  comment?: Comment
  tags?: Tags
  pinInfo?: Comment
  user?: User
  startEstimate?: string
  waitReason?: string
  finishEstimate?: string
  delayedByBuild?: Build
  plannedAgent?: Agent
  approvalInfo?: ApprovalInfo
  'running-info'?: ProgressInfo
  canceledInfo?: Comment
  queuedDate?: string
  startDate?: string
  finishDate?: string
  triggered?: TriggeredBy
  lastChanges?: Changes
  changes?: Changes
  revisions?: Revisions
  versionedSettingsRevision?: Revision
  artifactDependencyChanges?: BuildChanges
  agent?: Agent
  compatibleAgents?: Agents
  testOccurrences?: TestOccurrences
  problemOccurrences?: ProblemOccurrences
  artifacts?: Files
  relatedIssues?: IssuesUsages
  properties?: Properties
  resultingProperties?: Properties
  attributes?: Entries
  statistics?: Properties
  metadata?: Datas
  'snapshot-dependencies'?: Builds
  'artifact-dependencies'?: Builds
  'custom-artifact-dependencies'?: ArtifactDependencies
  settingsHash?: string
  currentSettingsHash?: string
  modificationId?: string
  chainModificationId?: string
  replacementIds?: Items
  related?: Related
  triggeringOptions?: BuildTriggeringOptions
  usedByOtherBuilds?: boolean
  statusChangeComment?: Comment
  vcsLabels?: VcsLabel[]
  detachedFromAgent?: boolean
  finishOnAgentDate?: string
  customized?: boolean
  customization?: Customizations
  changesCollectingInProgress?: boolean
  queuedWaitReasons?: Properties
  downloadedArtifacts?: DownloadedArtifacts
  locator?: string
  firstBuildWithSameChanges?: Build
}
export type Builds = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  build?: Build[]
}
export type BranchVersion = {
  version?: string
  lastActivity?: string
  groupFlag?: boolean
  unspecified?: boolean
  builds?: Builds
  name?: string
  default?: boolean
  active?: boolean
  internalName?: string
}
export type RepositoryState = {
  timestamp?: string
  count?: number
  branch?: BranchVersion[]
}
export type VcsRootInstance = {
  id?: string
  'vcs-root-id'?: string
  vcsRootInternalId?: string
  name?: string
  vcsName?: string
  modificationCheckInterval?: number
  commitHookMode?: boolean
  lastVersion?: string
  lastVersionInternal?: string
  href?: string
  'vcs-root'?: VcsRoot
  status?: VcsStatus
  repositoryState?: RepositoryState
  properties?: Properties
  repositoryIdStrings?: Items
  projectLocator?: string
}
export type VcsRootInstances = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  'vcs-root-instance'?: VcsRootInstance[]
}
export type VcsRoot = {
  id?: string
  internalId?: string
  uuid?: string
  name?: string
  vcsName?: string
  modificationCheckInterval?: number
  href?: string
  project?: Project
  properties?: Properties
  vcsRootInstances?: VcsRootInstances
  repositoryIdStrings?: Items
  projectLocator?: string
  locator?: string
}
export type VcsRootEntry = {
  id?: string
  inherited?: boolean
  'vcs-root'?: VcsRoot
  'checkout-rules'?: string
}
export type VcsRootEntries = {
  count?: number
  'vcs-root-entry'?: VcsRootEntry[]
}
export type Step = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
export type Steps = {
  count?: number
  step?: Step[]
}
export type Feature = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
export type Features = {
  count?: number
  feature?: Feature[]
}
export type BuildTriggerCustomization = {
  enforceCleanCheckout?: boolean
  enforceCleanCheckoutForDependencies?: boolean
  parameters?: Properties
}
export type Trigger = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
  buildCustomization?: BuildTriggerCustomization
}
export type Triggers = {
  count?: number
  trigger?: Trigger[]
}
export type SnapshotDependency = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
  'source-buildType'?: BuildType
}
export type SnapshotDependencies = {
  count?: number
  'snapshot-dependency'?: SnapshotDependency[]
}
export type AgentRequirement = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
export type AgentRequirements = {
  count?: number
  'agent-requirement'?: AgentRequirement[]
}
export type Branch = {
  name?: string
  internalName?: string
  default?: boolean
  unspecified?: boolean
  active?: boolean
  lastActivity?: string
  groupFlag?: boolean
  builds?: Builds
}
export type Branches = {
  count?: number
  href?: string
  branch?: Branch[]
}
export type BuildType = {
  id?: string
  internalId?: string
  name?: string
  templateFlag?: boolean
  type?: 'regular' | 'composite' | 'deployment'
  paused?: boolean
  uuid?: string
  description?: string
  projectName?: string
  projectId?: string
  projectInternalId?: string
  href?: string
  webUrl?: string
  inherited?: boolean
  links?: Links
  project?: Project
  templates?: BuildTypes
  template?: BuildType
  'vcs-root-entries'?: VcsRootEntries
  settings?: Properties
  parameters?: Properties
  steps?: Steps
  features?: Features
  triggers?: Triggers
  'snapshot-dependencies'?: SnapshotDependencies
  'artifact-dependencies'?: ArtifactDependencies
  'agent-requirements'?: AgentRequirements
  branches?: Branches
  builds?: Builds
  investigations?: Investigations
  compatibleAgents?: Agents
  vcsRootInstances?: VcsRootInstances
  externalStatusAllowed?: boolean
  pauseComment?: Comment
  locator?: string
}
export type VcsRoots = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  'vcs-root'?: VcsRoot[]
}
export type ProjectFeature = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
export type ProjectFeatures = {
  count?: number
  href?: string
  projectFeature?: ProjectFeature[]
}
export type Projects = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  project?: Project[]
}
export type CloudProfiles = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  cloudProfile?: CloudProfile[]
}
export type Project = {
  id?: string
  internalId?: string
  uuid?: string
  name?: string
  parentProjectId?: string
  parentProjectInternalId?: string
  parentProjectName?: string
  archived?: boolean
  virtual?: boolean
  description?: string
  href?: string
  webUrl?: string
  links?: Links
  parentProject?: Project
  readOnlyUI?: StateField
  defaultTemplate?: BuildType
  buildTypes?: BuildTypes
  templates?: BuildTypes
  parameters?: Properties
  vcsRoots?: VcsRoots
  projectFeatures?: ProjectFeatures
  projects?: Projects
  cloudProfiles?: CloudProfiles
  ancestorProjects?: Projects
  locator?: string
}
export type AgentPool = {
  id?: number
  name?: string
  href?: string
  maxAgents?: number
  ownerProject?: Project
  projects?: Projects
  agents?: Agents
  locator?: string
}
export type AgentPools = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  agentPool?: AgentPool[]
}
export type AuditAction = {
  name?: string
  id?: string
  pattern?: string
}
export type RelatedEntity = {
  type?: string
  unknown?: boolean
  internalId?: string
  text?: string
  build?: Build
  buildType?: BuildType
  project?: Project
  user?: User
  group?: Group
  test?: Test
  problem?: Problem
  agent?: Agent
  vcsRoot?: VcsRoot
  change?: Change
  agentPool?: AgentPool
}
export type RelatedEntities = {
  count?: number
  entity?: RelatedEntity[]
}
export type AuditEvent = {
  id?: string
  timestamp?: string
  comment?: string
  action?: AuditAction
  relatedEntities?: RelatedEntities
  user?: User
}
export type AuditEvents = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  auditEvent?: AuditEvent[]
}
export type BuildCancelRequest = {
  comment?: string
  readdIntoQueue?: boolean
}
export type OperationResult = {
  message?: string
  related?: RelatedEntity
}
export type MultipleOperationResult = {
  count?: number
  errorCount?: number
  operationResult?: OperationResult[]
}
export type PinInfo = {
  status?: boolean
  comment?: Comment
}
export type BuildStatusUpdate = {
  status?: string
  comment?: string
}
export type VcsLabels = {
  count?: number
  vcsLabel?: VcsLabel[]
}
export type Issues = {
  issues?: Issue[]
}
export type HealthCategory = {
  id?: string
  name?: string
  description?: string
  helpUrl?: string
}
export type HealthItem = {
  identity?: string
  severity?: 'INFO' | 'WARN' | 'ERROR'
  healthCategory?: HealthCategory
}
export type HealthStatusItems = {
  count?: number
  healthItem?: HealthItem[]
  href?: string
  nextHref?: string
  prevHref?: string
}
export type HealthCategories = {
  count?: number
  healthCategory?: HealthCategory[]
  href?: string
  nextHref?: string
  prevHref?: string
}
export type NewProjectDescription = {
  copyAllAssociatedSettings?: boolean
  projectsIdsMap?: Properties
  buildTypesIdsMap?: Properties
  vcsRootsIdsMap?: Properties
  name?: string
  id?: string
  sourceProjectLocator?: string
  sourceProject?: Project
  parentProject?: Project
}
export type NewBuildTypeDescription = {
  copyAllAssociatedSettings?: boolean
  projectsIdsMap?: Properties
  buildTypesIdsMap?: Properties
  vcsRootsIdsMap?: Properties
  name?: string
  id?: string
  sourceBuildTypeLocator?: string
  sourceBuildType?: BuildType
}
export type Server = {
  version?: string
  versionMajor?: number
  versionMinor?: number
  startTime?: string
  currentTime?: string
  buildNumber?: string
  buildDate?: string
  internalId?: string
  role?: string
  webUrl?: string
  projects?: Href
  vcsRoots?: Href
  builds?: Href
  users?: Href
  userGroups?: Href
  agents?: Href
  buildQueue?: Href
  agentPools?: Href
  investigations?: Href
  mutes?: Href
  artifactsUrl?: string
  nodes?: Href
}
export type LicenseKey = {
  valid?: boolean
  active?: boolean
  expired?: boolean
  obsolete?: boolean
  expirationDate?: string
  maintenanceEndDate?: string
  type?: 'evaluation' | 'eap' | 'open_source' | 'commercial' | 'enterprise' | 'professional'
  servers?: number
  agents?: number
  unlimitedAgents?: boolean
  buildTypes?: number
  unlimitedBuildTypes?: boolean
  errorDetails?: string
  key?: string
  rawType?: string
}
export type LicenseKeys = {
  count?: number
  href?: string
  licenseKey?: LicenseKey[]
}
export type LicensingData = {
  licenseUseExceeded?: boolean
  maxAgents?: number
  unlimitedAgents?: boolean
  maxBuildTypes?: number
  unlimitedBuildTypes?: boolean
  buildTypesLeft?: number
  serverLicenseType?:
    | 'evaluation'
    | 'eap'
    | 'open_source'
    | 'commercial'
    | 'enterprise'
    | 'professional'
  serverEffectiveReleaseDate?: string
  agentsLeft?: number
  licenseKeys?: LicenseKeys
}
export type MetricTag = {
  name?: string
  value?: string
}
export type MetricTags = {
  count?: number
  metricTag?: MetricTag[]
}
export type MetricValue = {
  name?: string
  value?: number
  tags?: MetricTags
}
export type MetricValues = {
  count?: number
  metricValue?: MetricValue[]
}
export type Metric = {
  name?: string
  description?: string
  prometheusName?: string
  metricValues?: MetricValues
  metricTags?: MetricTags
}
export type Metrics = {
  count?: number
  metric?: Metric[]
}
export type Responsibility = {
  name?: string
  description?: string
}
export type EnabledResponsibilities = {
  count?: number
  responsibility?: Responsibility[]
}
export type DisabledResponsibilities = {
  count?: number
  responsibility?: Responsibility[]
}
export type EffectiveResponsibilities = {
  count?: number
  responsibility?: Responsibility[]
}
export type Node = {
  id?: string
  url?: string
  online?: boolean
  role?: string
  current?: boolean
  enabledResponsibilities?: EnabledResponsibilities
  disabledResponsibilities?: DisabledResponsibilities
  effectiveResponsibilities?: EffectiveResponsibilities
}
export type Nodes = {
  count?: number
  node?: Node[]
}
export type Plugin = {
  name?: string
  displayName?: string
  version?: string
  loadPath?: string
  parameters?: Properties
}
export type Plugins = {
  count?: number
  plugin?: Plugin[]
}
export type Permission = {
  id?: string
  name?: string
  global?: boolean
}
export type PermissionAssignment = {
  permission?: Permission
  project?: Project
}
export type PermissionAssignments = {
  count?: number
  permissionAssignment?: PermissionAssignment[]
}
export type PermissionRestriction = {
  isGlobalScope?: boolean
  project?: Project
  permission?: Permission
}
export type PermissionRestrictions = {
  count?: number
  permissionRestriction?: PermissionRestriction[]
}
export type Token = {
  name?: string
  creationTime?: string
  value?: string
  expirationTime?: string
  permissionRestrictions?: PermissionRestrictions
}
export type Tokens = {
  count?: number
  token?: Token[]
}
export const {
  useGetRootEndpointsOfRootQuery,
  useGetAllAgentPoolsQuery,
  useCreateAgentPoolMutation,
  useGetAgentPoolOfAgentPoolQuery,
  useDeleteAgentPoolMutation,
  useGetAllAgentsFromAgentPoolQuery,
  useAddAgentToAgentPoolMutation,
  useGetAllProjectsFromAgentPoolQuery,
  useAddProjectToAgentPoolMutation,
  useSetAgentPoolProjectsMutation,
  useDeleteAllProjectsFromAgentPoolMutation,
  useDeleteProjectFromAgentPoolMutation,
  useGetFieldFromAgentPoolQuery,
  useSetAgentPoolFieldMutation,
  useGetAllAgentsQuery,
  useGetAgentQuery,
  useDeleteAgentMutation,
  useGetAuthorizedInfoQuery,
  useSetAuthorizedInfoMutation,
  useGetBuildConfigurationRunPolicyQuery,
  useSetBuildConfigurationRunPolicyMutation,
  useGetCompatibleBuildTypesQuery,
  useGetEnabledInfoQuery,
  useSetEnabledInfoMutation,
  useGetIncompatibleBuildTypesQuery,
  useGetAgentPoolQuery,
  useSetAgentPoolMutation,
  useGetAgentFieldQuery,
  useSetAgentFieldMutation,
  useGetApiVersionQuery,
  useGetAllAuditEventsQuery,
  useGetAuditEventQuery,
  usePutAvatarMutation,
  useDeleteAvatarMutation,
  useGetAvatarQuery,
  useGetAvatarWithHashQuery,
  useGetAllQueuedBuildsQuery,
  useAddBuildToQueueMutation,
  useDeleteAllQueuedBuildsMutation,
  useSetQueuedBuildsOrderMutation,
  useGetQueuedBuildPositionQuery,
  useSetQueuedBuildPositionMutation,
  useGetApprovalInfoQuery,
  useApproveQueuedBuildMutation,
  useGetQueuedBuildTagsQuery,
  useAddTagsToBuildOfBuildQueueMutation,
  useGetQueuedBuildQuery,
  useCancelQueuedBuildMutation,
  useDeleteQueuedBuildMutation,
  useGetCompatibleAgentsForBuildQuery,
  useGetAllBuildTypesQuery,
  useCreateBuildTypeMutation,
  useGetBuildTypeQuery,
  useDeleteBuildTypeMutation,
  useGetAllAgentRequirementsQuery,
  useAddAgentRequirementToBuildTypeMutation,
  useReplaceAllAgentRequirementsMutation,
  useGetAgentRequirementQuery,
  useReplaceAgentRequirementMutation,
  useDeleteAgentRequirementMutation,
  useGetAgentRequirementParameterQuery,
  useSetAgentRequirementParameterMutation,
  useGetAliasesQuery,
  useGetAllArtifactDependenciesQuery,
  useAddArtifactDependencyToBuildTypeMutation,
  useReplaceAllArtifactDependenciesMutation,
  useGetArtifactDependencyQuery,
  useReplaceArtifactDependencyMutation,
  useDeleteArtifactDependencyMutation,
  useGetArtifactDependencyParameterQuery,
  useSetArtifactDependencyParameterMutation,
  useGetAllBranchesOfBuildTypeQuery,
  useGetBuildTypeBuildTagsQuery,
  useGetBuildTypeBuildsQuery,
  useGetAllBuildFeaturesQuery,
  useAddBuildFeatureToBuildTypeMutation,
  useReplaceAllBuildFeaturesMutation,
  useGetBuildFeatureQuery,
  useReplaceBuildFeatureMutation,
  useDeleteFeatureOfBuildTypeMutation,
  useGetAllBuildFeatureParametersQuery,
  useReplaceBuildFeatureParametersMutation,
  useGetBuildFeatureParameterQuery,
  useAddParameterToBuildFeatureMutation,
  useGetBuildFeatureSettingQuery,
  useSetBuildFeatureParameterMutation,
  useGetAllInvestigationsOfBuildTypeQuery,
  useGetBuildParametersOfBuildTypeQuery,
  useCreateBuildParameterOfBuildTypeMutation,
  useUpdateBuildParametersOfBuildTypeMutation,
  useDeleteBuildParametersOfBuildTypeMutation,
  useGetBuildParameterOfBuildTypeQuery,
  useUpdateBuildParameterOfBuildTypeMutation,
  useDeleteBuildParameterOfBuildTypeMutation,
  useGetBuildParameterTypeOfBuildTypeQuery,
  useUpdateBuildParameterTypeOfBuildTypeMutation,
  useGetBuildParameterSpecificationOfBuildTypeQuery,
  useUpdateBuildParameterSpecificationOfBuildTypeMutation,
  useGetBuildParameterValueOfBuildTypeQuery,
  useUpdateBuildParameterValueOfBuildTypeMutation,
  useGetBuildTypeSettingsFileQuery,
  useGetAllSnapshotDependenciesQuery,
  useAddSnapshotDependencyToBuildTypeMutation,
  useReplaceAllSnapshotDependenciesMutation,
  useGetSnapshotDependencyQuery,
  useReplaceSnapshotDependencyMutation,
  useDeleteSnapshotDependencyMutation,
  useGetAllBuildStepsQuery,
  useAddBuildStepToBuildTypeMutation,
  useReplaceAllBuildStepsMutation,
  useGetBuildStepQuery,
  useReplaceBuildStepMutation,
  useDeleteBuildStepMutation,
  useGetAllBuildStepParametersQuery,
  useDeleteBuildStepParametersMutation,
  useGetBuildStepParameterQuery,
  useAddParameterToBuildStepMutation,
  useGetBuildStepSettingQuery,
  useSetBuildStepParameterMutation,
  useGetAllBuildTemplatesQuery,
  useAddBuildTemplateMutation,
  useSetBuildTypeTemplatesMutation,
  useRemoveAllTemplatesMutation,
  useGetBuildTemplateQuery,
  useRemoveTemplateMutation,
  useGetAllTriggersQuery,
  useAddTriggerToBuildTypeMutation,
  useReplaceAllTriggersMutation,
  useGetTriggerQuery,
  useReplaceTriggerMutation,
  useDeleteTriggerMutation,
  useGetTriggerParameterQuery,
  useSetTriggerParameterMutation,
  useGetAllVcsRootsOfBuildTypeQuery,
  useAddVcsRootToBuildTypeMutation,
  useReplaceAllVcsRootsMutation,
  useGetVcsRootQuery,
  useUpdateBuildTypeVcsRootMutation,
  useDeleteVcsRootOfBuildTypeMutation,
  useGetVcsRootCheckoutRulesQuery,
  useUpdateBuildTypeVcsRootCheckoutRulesMutation,
  useGetFilesListOfBuildTypeQuery,
  useGetZippedFileOfBuildTypeQuery,
  useDownloadFileOfBuildTypeQuery,
  useGetFileMetadataOfBuildTypeQuery,
  useGetFilesListForSubpathOfBuildTypeQuery,
  useGetVcsRootInstancesOfBuildTypeQuery,
  useGetBuildTypeFieldQuery,
  useSetBuildTypeFieldMutation,
  useGetAllBuildsQuery,
  useGetAggregatedBuildStatusQuery,
  useGetAggregatedBuildStatusIconQuery,
  useGetMultipleBuildsQuery,
  useCancelMultipleMutation,
  useDeleteMultipleBuildsMutation,
  useSetMultipleBuildCommentsMutation,
  useDeleteMultipleBuildCommentsMutation,
  usePinMultipleBuildsMutation,
  useAddTagsToMultipleBuildsMutation,
  useRemoveMultipleBuildTagsMutation,
  useGetBuildQuery,
  useCancelBuildMutation,
  useDeleteBuildMutation,
  useGetArtifactDependencyChangesQuery,
  useGetFilesListOfBuildQuery,
  useGetZippedFileOfBuildQuery,
  useDownloadFileOfBuildQuery,
  useGetFileMetadataOfBuildQuery,
  useGetFilesListForSubpathOfBuildQuery,
  useGetArtifactsDirectoryQuery,
  useResetBuildFinishPropertiesMutation,
  useGetCanceledInfoQuery,
  useSetBuildCommentMutation,
  useDeleteBuildCommentMutation,
  useSetFinishedTimeMutation,
  useGetBuildFinishDateQuery,
  useSetBuildFinishDateMutation,
  useAddLogMessageToBuildMutation,
  useGetBuildNumberQuery,
  useSetBuildNumberMutation,
  useGetBuildPinInfoQuery,
  useSetBuildPinInfoMutation,
  useGetBuildProblemsQuery,
  useAddProblemToBuildMutation,
  useGetBuildRelatedIssuesQuery,
  useGetBuildResolvedQuery,
  useGetBuildActualParametersQuery,
  useGetBuildResultingPropertiesQuery,
  useMarkBuildAsRunningMutation,
  useGetBuildSourceFileQuery,
  useGetBuildStatisticValuesQuery,
  useGetBuildStatisticValueQuery,
  useGetBuildStatusQuery,
  useSetBuildStatusMutation,
  useGetBuildStatusIconQuery,
  useGetBuildStatusTextQuery,
  useSetBuildStatusTextMutation,
  useGetBuildTagsQuery,
  useAddTagsToBuildMutation,
  useSetBuildTagsMutation,
  useGetBuildTestOccurrencesQuery,
  useGetBuildVcsLabelsQuery,
  useAddBuildVcsLabelMutation,
  useGetBuildFieldQuery,
  useGetAllChangesQuery,
  useGetChangeQuery,
  useGetChangeAttributesQuery,
  useGetChangeDuplicatesQuery,
  useGetChangeIssueQuery,
  useGetChangeParentChangesQuery,
  useGetChangeParentRevisionsQuery,
  useGetChangeVcsRootQuery,
  useGetChangeFieldQuery,
  useGetAllCloudImagesQuery,
  useGetCloudImageQuery,
  useGetAllCloudInstancesQuery,
  useStartInstanceMutation,
  useGetCloudInstanceQuery,
  useStopInstanceMutation,
  useGetAllCloudProfilesQuery,
  useGetCloudProfileQuery,
  useGetHealthItemsQuery,
  useGetCategoriesQuery,
  useGetSingleCategoryQuery,
  useGetSingleHealthItemQuery,
  useGetPluginInfoQuery,
  useGetAllInvestigationsQuery,
  useAddInvestigationMutation,
  useAddMultipleInvestigationsMutation,
  useGetInvestigationQuery,
  useReplaceInvestigationMutation,
  useDeleteInvestigationMutation,
  useGetAllMutedTestsQuery,
  useMuteTestMutation,
  useMuteMultipleTestsMutation,
  useGetMutedTestQuery,
  useUnmuteTestMutation,
  useGetAllBuildProblemOccurrencesQuery,
  useGetBuildProblemOccurrenceQuery,
  useGetAllBuildProblemsQuery,
  useGetBuildProblemQuery,
  useGetAllProjectsQuery,
  useAddProjectMutation,
  useGetProjectQuery,
  useDeleteProjectMutation,
  useGetAgentPoolsProjectQuery,
  useAddAgentPoolsProjectMutation,
  useSetAgentPoolsProjectMutation,
  useRemoveProjectFromAgentPoolMutation,
  useGetAllBranchesQuery,
  useAddBuildTypeMutation,
  useGetDefaultTemplateQuery,
  useSetDefaultTemplateMutation,
  useRemoveDefaultTemplateMutation,
  useGetAllBuildTypesOrderedQuery,
  useSetBuildTypesOrderMutation,
  useGetAllSubprojectsOrderedQuery,
  useSetSubprojectsOrderMutation,
  useGetBuildParametersQuery,
  useCreateBuildParameterMutation,
  useUpdateBuildParametersMutation,
  useDeleteBuildParametersMutation,
  useGetBuildParameterQuery,
  useUpdateBuildParameterMutation,
  useDeleteBuildParameterMutation,
  useGetBuildParameterTypeQuery,
  useUpdateBuildParameterTypeMutation,
  useGetBuildParameterSpecificationQuery,
  useUpdateBuildParameterSpecificationMutation,
  useGetBuildParameterValueQuery,
  useUpdateBuildParameterValueMutation,
  useGetProjectParentProjectQuery,
  useSetParentProjectMutation,
  useGetFeaturesQuery,
  useAddFeatureMutation,
  useUpdateFeaturesMutation,
  useGetFeatureQuery,
  useUpdateFeatureMutation,
  useDeleteFeatureMutation,
  useAddSecureTokenMutation,
  useGetSecureValueQuery,
  useGetProjectSettingsFileQuery,
  useGetProjectTemplatesQuery,
  useAddTemplateMutation,
  useGetProjectFieldQuery,
  useSetProjectFieldMutation,
  useGetServerInfoQuery,
  useGetBackupStatusQuery,
  useStartBackupMutation,
  useGetFilesListOfServerQuery,
  useGetZippedFileOfServerQuery,
  useDownloadFileOfServerQuery,
  useGetFileMetadataOfServerQuery,
  useGetFilesListForSubpathOfServerQuery,
  useGetLicensingDataQuery,
  useGetLicenseKeysQuery,
  useAddLicenseKeysMutation,
  useGetLicenseKeyQuery,
  useDeleteLicenseKeyMutation,
  useGetAllMetricsQuery,
  useNodesQuery,
  useGetNodeQuery,
  useGetAllPluginsQuery,
  useGetServerFieldQuery,
  useGetAllTestOccurrencesQuery,
  useGetTestOccurrenceQuery,
  useGetTestsQuery,
  useGetTestQuery,
  useGetAllGroupsQuery,
  useAddGroupMutation,
  useGetUserGroupOfGroupQuery,
  useDeleteGroupMutation,
  useGetGroupParentGroupsQuery,
  useSetGroupParentGroupsMutation,
  useGetGroupPropertiesQuery,
  useGetGroupPropertyQuery,
  useSetGroupPropertyMutation,
  useRemoveGroupPropertyMutation,
  useGetGroupRolesQuery,
  useAddRoleToGroupMutation,
  useSetGroupRolesMutation,
  useGetGroupRoleAtScopeQuery,
  useAddRoleAtScopeToGroupMutation,
  useRemoveRoleAtScopeFromGroupMutation,
  useGetAllUsersQuery,
  useAddUserMutation,
  useGetUserQuery,
  useReplaceUserMutation,
  useDeleteUserMutation,
  useRemoveUserRememberMeMutation,
  useGetAllUserGroupsQuery,
  useSetUserGroupsMutation,
  useGetUserGroupQuery,
  useRemoveUserFromGroupMutation,
  useGetUserPermissionsQuery,
  useGetUserPropertiesQuery,
  useGetUserPropertyQuery,
  useSetUserPropertyMutation,
  useRemoveUserPropertyMutation,
  useGetAllUserRolesQuery,
  useAddRoleToUserMutation,
  useSetUserRolesMutation,
  useGetUserRolesAtScopeQuery,
  useAddRoleToUserAtScopeMutation,
  useRemoveUserRoleAtScopeMutation,
  useGetUserTokensQuery,
  useAddUserTokenMutation,
  useDeleteUserTokenMutation,
  useGetUserFieldQuery,
  useSetUserFieldMutation,
  useDeleteUserFieldMutation,
  useGetAllVcsRootInstancesQuery,
  useRequestPendingChangesCheckMutation,
  useTriggerCommitHookNotificationMutation,
  useGetVcsRootInstanceQuery,
  useGetFilesListQuery,
  useGetZippedFileQuery,
  useDownloadFileQuery,
  useGetFileMetadataQuery,
  useGetFilesListForSubpathQuery,
  useGetVcsRootInstancePropertiesQuery,
  useGetVcsRootInstanceRepositoryStateQuery,
  useSetVcsRootInstanceRepositoryStateMutation,
  useDeleteVcsRootInstanceRepositoryStateMutation,
  useGetVcsRootInstanceCreationDateQuery,
  useGetVcsRootInstanceFieldQuery,
  useSetVcsRootInstanceFieldMutation,
  useDeleteVcsRootInstanceFieldMutation,
  useGetAllVcsRootsQuery,
  useAddVcsRootMutation,
  useGetRootEndpointsQuery,
  useDeleteVcsRootMutation,
  useGetVcsRootInstancesQuery,
  useGetAllVcsRootPropertiesQuery,
  useSetVcsRootPropertiesMutation,
  useDeleteAllVcsRootPropertiesMutation,
  useGetVcsRootPropertyQuery,
  useSetVcsRootPropertyMutation,
  useDeleteVcsRootPropertyMutation,
  useGetVcsRootSettingsFileQuery,
  useGetVcsRootFieldQuery,
  useSetVcsRootFieldMutation,
  useGetVersionQuery,
  useGetAvailableRunnersQuery,
} = injectedRtkApi
