import * as React from 'react'

import {AppDispatch} from '../../../actions/types'
import {UpdateResultProps} from '../../../contexts/update'
import {LocationProps} from '../../../routes'

export enum PagerGroup {
  BUILD = 'build',
  TEST = 'test',
  TEST_SCOPE = 'testScope',
  CHANGE = 'change',
}
type OwnProps = {
  readonly warningClassName?: string
  readonly group: PagerGroup
  readonly pagerQueryParamName?: string
  readonly warningComponent?: React.ComponentType<any> | string
  readonly isLoading?: boolean | null | undefined
  readonly hasError?: boolean | null | undefined
}
export type DefaultProps = {
  readonly pagerQueryParamName: string
}
export type PagerHelperProps = OwnProps &
  DefaultProps &
  LocationProps &
  UpdateResultProps & {dispatch: AppDispatch}
export type ReduxOwnProps = OwnProps &
  DefaultProps & {
    [K in PropertyKey]?: number
  }
export type StateProps = {
  readonly currentPage: number
  readonly openTotal: boolean
  readonly pageSize: number
  readonly show?: boolean
  readonly total: number
}
type ActionProps = {
  readonly changeCurrentPage: (page: number) => unknown
}
export type Props = OwnProps & StateProps & ActionProps
export type PagerType = {
  readonly currentPage?: number
  readonly hasMore?: boolean
  readonly loadedLessThanRequested: boolean | null | undefined
  readonly lookupDelta?: number
  readonly lookupLimit?: number
  readonly lookupLimitWarning?: boolean | null | undefined
  readonly openTotal: boolean
  readonly pageSize: number
  readonly precountedPages?: number
  readonly total: number
  readonly show?: boolean
  readonly isLoading?: boolean
}
export type PagerStateType = {
  build: PagerType
  test: PagerType
  testScope: PagerType
  change: PagerType
}
