import * as Redux from 'redux'

import {FetchAction} from '../../actions/types'
import {keyValueFetchable} from '../../reducers/fetchable'
import {keyValueReducer} from '../../reducers/utils'
import {BuildTypeHierarchyType} from '../../types'
import {emptyArray} from '../../utils/empty'

import {
  fetchTestBuildTypeHierarchy,
  fetchTestOccurrencesCounts,
  fetchTestOccurrencesInvocations,
  fetchTestOccurrenceSubtree,
  FetchTestOccurrenceSubTreeParamsType,
  fetchTestOccurrenceTree,
  fetchTestScopes,
  FetchTestScopesArg,
} from './Tests.actions'
import {TestOccurrencesCountsType, TestOccurrencesTreeType, TestsStateType} from './Tests.types'

const testOccurrencesInvocations = keyValueFetchable(
  arg => `${arg.locator}.${arg.invocationsLocator}`,
  fetchTestOccurrencesInvocations,
  emptyArray,
  (_, action) => action.payload.result,
)
const testOccurrencesCounts = keyValueFetchable(
  arg => arg,
  fetchTestOccurrencesCounts,
  null as TestOccurrencesCountsType | null,
  (_, action) => action.payload,
)
const testBuildTypeHierarchy = keyValueFetchable(
  arg => arg,
  fetchTestBuildTypeHierarchy,
  null as BuildTypeHierarchyType | null,
  (_, action) => action.payload,
)
const testScopes = keyValueReducer(
  (action: FetchAction<FetchTestScopesArg>) => action.meta.arg.scope,
  keyValueFetchable(
    arg => arg.locator,
    fetchTestScopes,
    emptyArray,
    (_, action) => action.payload.data,
  ),
  [fetchTestScopes.pending, fetchTestScopes.fulfilled, fetchTestScopes.rejected],
)
const testOccurrencesTree = keyValueFetchable(
  arg => arg.locator,
  fetchTestOccurrenceTree,
  null as TestOccurrencesTreeType | null,
  (_, action) => action.payload.tree,
)
const testOccurrencesSubtree = keyValueFetchable(
  arg => arg.subTreeRootId,
  fetchTestOccurrenceSubtree,
  null as TestOccurrencesTreeType | null,
  (_, action) => action.payload.tree,
)

const testsReducer = Redux.combineReducers<TestsStateType>({
  testOccurrencesInvocations,
  testOccurrencesCounts,
  testBuildTypeHierarchy,
  testScopes,
  testOccurrencesTree,
  testOccurrencesSubtree: keyValueReducer(
    (action: FetchAction<FetchTestOccurrenceSubTreeParamsType>) => action.meta.arg.treeLocator,
    testOccurrencesSubtree,
    [
      fetchTestOccurrenceSubtree.pending,
      fetchTestOccurrenceSubtree.fulfilled,
      fetchTestOccurrenceSubtree.rejected,
    ],
  ),
})
export default testsReducer
