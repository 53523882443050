import {createFetchAction} from '../../../../../reducers/fetchable'
import {BuildTypeHierarchyType} from '../../../../../types'
import {base_uri} from '../../../../../types/BS_types'

import {requestChangeBuildTypeHierarchy} from './ChangeBuildTypeSelect.rest'

export const fetchChangeBuildTypeHierarchy = createFetchAction(
  'fetchChangeBuildTypeHierarchy',
  (locator: string) => requestChangeBuildTypeHierarchy(base_uri, locator),
)

export const receiveChangeBuildTypeHierarchy = (data: BuildTypeHierarchyType, locator: string) =>
  fetchChangeBuildTypeHierarchy.fulfilled(data, '', locator)
