import * as ReactRedux from 'react-redux'

import type {State} from '../../reducers/types'
import {getBuild, getUserDisplayName} from '../../selectors'
import type {BuildId, BuildTypeId} from '../../types'
import BuildCommentByIcon from '../common/BuildCommentByIcon/BuildCommentByIcon'
import type {BuildCommentByIconProps} from '../common/BuildCommentByIcon/BuildCommentByIcon'

type StateProps = {
  readonly text: string | null | undefined
  readonly timestamp: string | null | undefined
  readonly user: string | null | undefined
  readonly buildTypeId: BuildTypeId | null | undefined
}
type Props = Omit<BuildCommentByIconProps, keyof StateProps> & {
  buildId: BuildId
}

const mapStateToProps = (state: State, props: Props) => {
  const {buildType, status = 'UNKNOWN', statusChangeComment} = getBuild(state, props.buildId) || {}
  const {user, timestamp, text} = statusChangeComment || {}
  let markedStatus: string = status

  if (status === 'FAILURE') {
    markedStatus = 'failed'
  }

  if (status === 'SUCCESS') {
    markedStatus = 'successful'
  }

  const commentText =
    text != null
      ? `Marked as ${markedStatus} with comment: ${text}`
      : `Marked as ${markedStatus} manually`
  return {
    timestamp,
    user: getUserDisplayName(state, user, true),
    text: statusChangeComment != null ? commentText : null,
    buildTypeId: buildType,
  }
}

export default ReactRedux.connect(mapStateToProps)(BuildCommentByIcon)
