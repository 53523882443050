import {createFetchAction} from '../reducers/fetchable'
import {getStatusKey, normalizeStatuses} from '../rest/schemata'
import type {StatusKey} from '../rest/schemata'
import requestStatuses from '../rest/statuses'
import {getExtensionEndpoint, getOverviewProject, getOverviewProjectParents} from '../selectors'
import {getStatusRequest} from '../types'
import type {
  BranchType,
  BuildTypeId,
  BuildTypeInternalId,
  ProjectId,
  ProjectInternalId,
  ProjectOrBuildTypeStatus,
  StatusRequest,
} from '../types'
import {base_uri, internalProps} from '../types/BS_types'
import {ActionThrottlerWithObjectCollect} from '../utils/actionThrottler'
import {notNull} from '../utils/guards'
import {objectValues} from '../utils/object'
import type {KeyValue} from '../utils/object'
import {getRandomInt} from '../utils/random'
import {subscribeOnBuildTypeEvents, subscribeOnProjectEvents} from '../utils/subscriber'
import type {Unsubscribe} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'

import type {AppThunk} from './types'

export const fetchStatuses = createFetchAction(
  'fetchStatuses',
  (requests: KeyValue<StatusKey, StatusRequest>, {getState}) => {
    const extension = getExtensionEndpoint(getState(), 'fetchStatuses')! // checked in condition
    return requestStatuses(
      extension.serverUrl ?? base_uri,
      extension.endpoint,
      objectValues(requests).filter(notNull),
    ).then(normalizeStatuses)
  },
  {condition: (_, {getState}) => getExtensionEndpoint(getState(), 'fetchStatuses') != null},
)

export const receiveStatuses = (data: ReadonlyArray<ProjectOrBuildTypeStatus>) =>
  fetchStatuses.fulfilled(normalizeStatuses(data), '', {})

const MIN_THROTTLE_TIME = 500
const MAX_THROTTLE_TIME = 1000
const STATUSES_THROTTLE =
  internalProps['teamcity.ui.statuses.throttleInterval'] !== -1
    ? internalProps['teamcity.ui.statuses.throttleInterval']
    : getRandomInt(MIN_THROTTLE_TIME, MAX_THROTTLE_TIME)

const fetchStatusesThrottler = new ActionThrottlerWithObjectCollect(
  fetchStatuses,
  {},
  STATUSES_THROTTLE,
  {leading: false, trailing: true},
)
export const fetchStatusesWithThrottle = (
  statuses: ReadonlyArray<StatusRequest>,
  force?: boolean,
): AppThunk<any> =>
  fetchStatusesThrottler.fetch(
    Object.fromEntries(statuses.map(status => [getStatusKey(status), status])),
    force,
  )

const cancelFetchStatus = (status: StatusRequest) =>
  fetchStatusesThrottler.cancel(getStatusKey(status))

const cancelFetchStatuses = (statuses: ReadonlyArray<StatusRequest>) => {
  statuses.forEach(status => cancelFetchStatus(status))
}

const projectEventTypes = [
  SubscriptionEvents.BUILD_STARTED,
  SubscriptionEvents.BUILD_CHANGES_LOADED,
  SubscriptionEvents.BUILD_CHANGED_STATUS,
  SubscriptionEvents.BUILD_FINISHED,
  SubscriptionEvents.BUILD_INTERRUPTED,
  SubscriptionEvents.BUILD_TYPE_ACTIVE_STATUS_CHANGED,
  SubscriptionEvents.BUILD_TYPE_ADDED_TO_QUEUE,
  SubscriptionEvents.BUILD_TYPE_REMOVED_FROM_QUEUE,
  SubscriptionEvents.BUILD_TYPE_REGISTERED,
  SubscriptionEvents.BUILD_TYPE_UNREGISTERED,
  SubscriptionEvents.CHANGE_ADDED,
  SubscriptionEvents.PROJECT_PERSISTED,
  SubscriptionEvents.PROJECT_RESTORED,
  SubscriptionEvents.BUILD_TYPE_RESPONSIBILITY_CHANGES,
]
export const subscribeOnBuildTypeStatus =
  (
    buildTypeId: BuildTypeId,
    buildTypeInternalId: BuildTypeInternalId,
    branch: BranchType | null | undefined,
    timeout: number | undefined,
  ): AppThunk<Unsubscribe> =>
  dispatch => {
    const request = getStatusRequest('bt', buildTypeId, branch)
    const unsubscribe = subscribeOnBuildTypeEvents(
      buildTypeInternalId,
      projectEventTypes,
      () => dispatch(fetchStatusesWithThrottle([request], timeout === 0)),
      timeout,
    )
    return () => {
      cancelFetchStatus(request)
      unsubscribe()
    }
  }
export const subscribeOnProjectStatus =
  (
    projectId: ProjectId,
    projectInternalId: ProjectInternalId,
    branch?: BranchType | null | undefined,
    timeout?: number | undefined,
    fetchOverviewParents?: boolean,
  ): AppThunk<Unsubscribe> =>
  (dispatch, getState) => {
    const requests: Array<StatusRequest> = [getStatusRequest('project', projectId, branch)]

    if (fetchOverviewParents === true) {
      const state = getState()
      const parentIds = getOverviewProjectParents(state, projectId)
      parentIds.forEach(id => {
        if (getOverviewProject(state, id) != null) {
          requests.push(getStatusRequest('project', id, branch))
        }
      })
    }

    const handler = () => {
      dispatch(fetchStatusesWithThrottle(requests, timeout === 0))
    }

    const unsubscribe = subscribeOnProjectEvents(
      projectInternalId,
      projectEventTypes,
      handler,
      timeout,
    )
    return () => {
      cancelFetchStatuses(requests)
      unsubscribe()
    }
  }
