import type {NormalizedTestOccurrences} from '../../rest/schemata'
import {
  normalizeTestOccurrences,
  normalizeTestOccurrencesBuild,
  normalizeTestOccurrencesCurrentlyMutes,
  normalizeTestOccurrencesFirstFailed,
  normalizeTestOccurrencesInvestigations,
  normalizeTestOccurrencesInvocationsCounters,
  normalizeTestOccurrencesMetadataCount,
  normalizeTestOccurrencesMute,
  normalizeTestOccurrencesNewFailure,
  normalizeTestOccurrencesNextFixed,
  normalizeTestOccurrencesRunOrder,
} from '../../rest/schemata'
import type {TestOccurrenceId} from '../../types'
import {Fetchable, stringifyId} from '../../types'
import {emptyArray, getEmptyHash} from '../../utils/empty'
import {keyValue, KeyValue, objectEntries} from '../../utils/object'

import {
  ProblemOccurrencesTreeLeaf,
  ProblemOccurrencesTreeType,
} from '../BuildProblems/BuildProblems.types'

import type {
  RequestTestOccurrenceOptionsParams,
  TestOccurrencesTreeLeaf,
  TestOccurrenceType,
  TestScope,
  TestScopeType,
} from './Tests.types'
import {TestOccurrencesTreeType, TestScopesKey} from './Tests.types'

export const getTestOccurrencesEntities = (
  data: ReadonlyArray<TestOccurrenceType>,
  options?: RequestTestOccurrenceOptionsParams,
): NormalizedTestOccurrences => {
  const testOccurrences = normalizeTestOccurrences(data)
  let entities = {...testOccurrences.entities}

  if (options?.withNewFailure === true) {
    entities = {...entities, ...normalizeTestOccurrencesNewFailure(data).entities}
  }

  if (options?.withMetadataCount === true) {
    entities = {...entities, ...normalizeTestOccurrencesMetadataCount(data).entities}
  }

  if (options?.withFirstFailed === true) {
    entities = {...entities, ...normalizeTestOccurrencesFirstFailed(data).entities}
  }

  if (options?.withNextFixed === true) {
    entities = {...entities, ...normalizeTestOccurrencesNextFixed(data).entities}
  }

  if (options?.withRunOrder === true) {
    entities = {...entities, ...normalizeTestOccurrencesRunOrder(data).entities}
  }

  if (options?.withInvestigationInfo === true) {
    entities = {...entities, ...normalizeTestOccurrencesInvestigations(data).entities}
  }

  if (options?.withMuteInfo === true) {
    entities = {
      ...entities,
      ...normalizeTestOccurrencesCurrentlyMutes(data).entities,
      ...normalizeTestOccurrencesMute(data).entities,
    }
  }

  if (options?.withInvocationsCounters === true) {
    entities = {...entities, ...normalizeTestOccurrencesInvocationsCounters(data).entities}
  }

  if (options?.withBuildInfo === true) {
    entities = {...normalizeTestOccurrencesBuild(data).entities, ...entities}
  }

  return {
    entities,
    result: testOccurrences.result,
  }
}

export const trimAndRemoveColon = (value: string): string => {
  const text = value.trim()
  return text[text.length - 1] === ':' ? text.replace(/\:(?=[^:]*$)/g, '') : text
}

export const getTestOccurrenceElementId = (testOccurrenceId: TestOccurrenceId): string =>
  `test-occurrence-${stringifyId(testOccurrenceId)}`

export const getTestScope = (
  scopeKey: string | null | undefined,
  scope: TestScope | TestScopeType,
): TestScope => {
  const defaultScope = {
    suite: null,
    package: null,
    class: null,
  }
  let result = {}

  if (scopeKey === TestScopesKey.suite) {
    result = {...defaultScope, suite: scope.suite}
  } else if (scopeKey === TestScopesKey.package) {
    result = {...defaultScope, suite: scope.suite, package: scope.package}
  } else if (scopeKey === TestScopesKey.class) {
    result = {...defaultScope, suite: scope.suite, package: scope.package, class: scope.class}
  } else {
    return defaultScope
  }

  return objectEntries(result).reduce(
    (acc, [key, data]) => ({...acc, [key]: data ?? null}),
    defaultScope,
  )
}

export function sortByDepthSubtreesKeys(
  nullableSubtreesFetchable:
    | KeyValue<string, Fetchable<TestOccurrencesTreeType | null | undefined>>
    | KeyValue<string, Fetchable<ProblemOccurrencesTreeType | null | undefined>>
    | undefined
    | null,
): ReadonlyArray<string> {
  const subtreesFetchable = nullableSubtreesFetchable ?? {}

  return Object.keys(subtreesFetchable).sort((key1, key2) => {
    const depth1 = subtreesFetchable[key1]?.receiveMeta?.depth ?? 0
    const depth2 = subtreesFetchable[key2]?.receiveMeta?.depth ?? 0
    return depth1 > depth2 ? 1 : depth1 < depth2 ? -1 : 0
  })
}

export const mergeTreeAndSubTrees = (
  tree:
    | Fetchable<TestOccurrencesTreeType | null | undefined>
    | Fetchable<ProblemOccurrencesTreeType | null | undefined>,
  subtreesFetchable:
    | KeyValue<string, Fetchable<TestOccurrencesTreeType | null | undefined>>
    | KeyValue<string, Fetchable<ProblemOccurrencesTreeType | null | undefined>>
    | undefined
    | null,
  sortedByDepthSubtreesKeys: ReadonlyArray<string>,
) => {
  let nodes = [...(tree.data?.node ?? emptyArray)]

  sortedByDepthSubtreesKeys.forEach(subtreeKey => {
    let subtreeRootIndex
    const subtreeNodes = subtreesFetchable?.[subtreeKey]?.data?.node ?? []

    // @ts-ignore //TODO
    const subtreeNodesIds = subtreeNodes.reduce((acc, node) => ({...acc, [node.id]: node}), {})

    nodes.forEach((node, index) => {
      if (node.id === subtreeKey) {
        subtreeRootIndex = index
      }
      if (subtreeNodesIds[node.id]) {
        delete nodes[index]
      }
    })

    if (subtreeRootIndex != null) {
      nodes.splice(subtreeRootIndex, 0, ...subtreeNodes)
    }
    nodes = nodes.filter(Boolean)
  })

  return nodes
}

export const getLeavesHash = (
  tree:
    | Fetchable<TestOccurrencesTreeType | null | undefined>
    | Fetchable<ProblemOccurrencesTreeType | null | undefined>,
  subtreesFetchable:
    | KeyValue<string, Fetchable<TestOccurrencesTreeType | null | undefined>>
    | KeyValue<string, Fetchable<ProblemOccurrencesTreeType | null | undefined>>
    | undefined
    | null,
  sortedByDepthSubtreesKeys: ReadonlyArray<string>,
) => {
  const treeLeaves = tree.data?.leaf ?? emptyArray
  const subtreesLeaves: TestOccurrencesTreeLeaf[] | ProblemOccurrencesTreeLeaf[] = []

  sortedByDepthSubtreesKeys.forEach(subtreeNodeId => {
    const leaves = subtreesFetchable?.[subtreeNodeId]?.data?.leaf ?? []
    subtreesLeaves.push(...leaves)
  })

  return tree.data?.leaf != null
    ? [...treeLeaves, ...subtreesLeaves].reduce(
        (acc, leaf) => ({...acc, ...keyValue(leaf.nodeId, leaf)}),
        {},
      )
    : getEmptyHash()
}
