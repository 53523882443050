import type {ActionCreator} from '../actions/types'

import {base_uri} from './BS_types'

import {
  cloneNode,
  getBuildTypeStatusRequest,
  getProjectStatusRequest,
  stringifyId,
  toFederationServerId,
} from '.'
import type {
  BuildTypeId,
  BuildTypeInternalId,
  BuildTypeTypeType,
  CustomSidebarItemId,
  FederationServerId,
  NodeGroupType,
  ProjectId,
  ProjectInternalId,
  ProjectOrBuildTypeNode,
  StatusRequest,
  TitleId,
} from '.'

export type SearchStringMatch = {
  index: number
  length: number
}
type ProjectsTreeItemCommon = {
  archived?: boolean
  paused?: boolean
  name: string
  type?: BuildTypeTypeType | null | undefined
  level: number
  hasChildren?: boolean
  childrenProjectsIds?: ReadonlyArray<ProjectId>
  childrenBuildTypesIds?: ReadonlyArray<BuildTypeId> | null | undefined
  expanded?: boolean
  readonly group: NodeGroupType
  searchMatches?: ReadonlyArray<SearchStringMatch> | null | undefined
  isFullMatch?: boolean
  isStarred?: boolean | null | undefined
  serverId: FederationServerId
  readonly icon?: string | null | undefined
  readonly editable?: boolean
  readonly focusable?: boolean
  readonly isDisabled?: boolean
  readonly hasReorderedChildren?: boolean
}
export type ProjectsTreeItemBuildType = {
  readonly itemType: 'buildType'
  readonly id: BuildTypeId
  readonly internalId: BuildTypeInternalId | null | undefined
  readonly parentId: ProjectId
  readonly actualParentId?: ProjectId | null | undefined
} & ProjectsTreeItemCommon
type ProjectsTreeItemTemplate = {
  readonly itemType: 'template'
  readonly id: BuildTypeId
  readonly parentId: ProjectId
  readonly actualParentId?: ProjectId | null | undefined
} & ProjectsTreeItemCommon
export type ProjectTreeItemProject = {
  readonly itemType: 'project'
  readonly id: ProjectId
  readonly internalId: ProjectInternalId
  readonly parentId?: ProjectId | null | undefined
  readonly actualParentId?: ProjectId | null | undefined
} & ProjectsTreeItemCommon
type ProjectTreeItemTitle = {
  readonly itemType: 'title'
  readonly id: TitleId
  readonly internalId?: never
  readonly parentId?: never
} & ProjectsTreeItemCommon
export type ProjectsTreeItemType =
  | ProjectsTreeItemBuildType
  | ProjectsTreeItemTemplate
  | ProjectTreeItemProject
  | ProjectTreeItemTitle
  | ({
      readonly itemType: 'server'
      readonly id: FederationServerId
      readonly internalId?: never
      readonly parentId?: never
      readonly authorized: boolean
      readonly loading: boolean
    } & ProjectsTreeItemCommon)
  | ({
      readonly itemType: 'link'
      readonly id: CustomSidebarItemId
      readonly internalId?: never
      readonly parentId?: never
      readonly href: string
    } & ProjectsTreeItemCommon)
  | ({
      readonly itemType: 'action'
      readonly id: CustomSidebarItemId
      readonly internalId?: never
      readonly parentId?: never
      readonly action: ActionCreator | string
    } & ProjectsTreeItemCommon)
export const getProjectTreeItemId = (item: ProjectsTreeItemType): string =>
  `${stringifyId(item.serverId)}_${item.group}_${item.itemType}_${stringifyId(item.id)}`
export const getProjectTreeItemStatusRequest = (
  item: ProjectsTreeItemType,
): StatusRequest | null | undefined => {
  switch (item.itemType) {
    case 'project':
      return getProjectStatusRequest(item.id)

    case 'buildType':
      return getBuildTypeStatusRequest(item.id)

    default:
      return null
  }
}
export type SidebarActiveItem =
  | ProjectOrBuildTypeNode
  | {
      readonly nodeType: 'link'
      readonly id: CustomSidebarItemId
      readonly group?: never
    }
export const cloneActiveItem = (node: SidebarActiveItem): SidebarActiveItem => {
  switch (node.nodeType) {
    case 'link':
      return {
        nodeType: 'link',
        id: node.id,
      }

    default:
      return cloneNode(node)
  }
}
export function getNodeFromItem(
  item: ProjectsTreeItemType,
): ProjectOrBuildTypeNode | null | undefined {
  switch (item.itemType) {
    case 'buildType': {
      const {id, internalId, parentId} = item
      return {
        nodeType: 'bt',
        id,
        internalId,
        parentId,
      }
    }

    case 'project': {
      const {id, internalId, parentId} = item
      return {
        nodeType: 'project',
        id,
        internalId,
        parentId,
      }
    }

    default:
      return null
  }
}
export const currentServerId: FederationServerId = toFederationServerId(base_uri)
