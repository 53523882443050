import * as ReactRedux from 'react-redux'

import {openDialog, closeDialog} from '../actions'
import type {State} from '../reducers/types'
import {isDialogShown, isDialogProcessing, dialogHasError} from '../selectors'
import {stringifyId} from '../types'
import type {BuildId, DialogType} from '../types'

export type DialogOwnProps<P = {}> = P & {
  readonly buildId?: BuildId | null | undefined
}
type StateProps = {
  readonly isOpened: boolean
  readonly isProcessing: boolean
  readonly dialogHasError: boolean
}
type ActionProps = {
  readonly openDialog: (dialogId: string, dialogType: DialogType) => unknown
  readonly closeDialog: () => unknown
}
export type DialogProps<P = {}> = DialogOwnProps<P> & StateProps & ActionProps
const actionCreators: ActionProps = {
  openDialog,
  closeDialog,
}

const mapStateToProps =
  <P>(
    type: DialogType,
    idGenerator: ((arg0: State, arg1: DialogOwnProps<P>) => string) | null | undefined,
  ): ((arg0: State, arg1: DialogOwnProps<P>) => StateProps) =>
  (state, props) => {
    const dialogId =
      idGenerator && typeof idGenerator === 'function'
        ? idGenerator(state, props)
        : stringifyId(props.buildId)
    const isOpened = isDialogShown(state, dialogId, type)
    const isProcessing = isDialogProcessing(state)
    return {
      isOpened,
      isProcessing,
      dialogHasError: dialogHasError(state),
    }
  }

export default <P>(
  type: DialogType,
  idGenerator?: (state: State, props: DialogOwnProps<P>) => string,
) => ReactRedux.connect(mapStateToProps(type, idGenerator), actionCreators)
