import classNames from 'classnames'
import * as React from 'react'

import TransformText from '../../../containers/TransformText'
import {useDistanceToNow} from '../../../hooks/useTimeSelector'
import type {BuildId, BuildTypeId} from '../../../types'
import CommentPopup from '../CommentPopup/CommentPopup'
import IconButton from '../IconButton/IconButton'

import styles from './BuildCommentByIcon.css'

export type BuildCommentByIconProps = {
  readonly text: string | null | undefined
  readonly timestamp?: string | null | undefined
  readonly user?: string | null | undefined
  readonly className?: string
  readonly inline?: boolean
  readonly buildTypeId?: BuildTypeId | null | undefined
  readonly buildId?: BuildId
}

const BuildCommentByIcon = ({
  text,
  timestamp,
  user,
  className,
  inline,
  buildTypeId,
}: BuildCommentByIconProps) => {
  const buttonClasses = classNames(styles.button, className)
  const wrapperClasses = classNames(styles.wrapper, className)
  const timestampFromNow = useDistanceToNow(timestamp)
  if (text == null) {
    return null
  }

  const anchor = (
    <IconButton
      icon="comment"
      title=""
      aria-label="Comment"
      className={buttonClasses}
      iconClassName={styles.icon}
    />
  )
  return (
    <div className={wrapperClasses}>
      {inline !== true && (
        <CommentPopup
          anchor={anchor}
          timestamp={timestamp}
          user={user}
          text={text}
          buildTypeId={buildTypeId}
        />
      )}
      {inline === true && (
        <TransformText
          className={styles.text}
          text={text}
          buildTypeId={buildTypeId}
          lines={3}
          lineHeight={16}
        />
      )}
      {inline === true && user != null && timestampFromNow != null && (
        <span className={styles.info}>
          <span className={styles.user}>{user}</span>
          <span className={styles.inlineCommentText}>{`${timestampFromNow}`}</span>
        </span>
      )}
    </div>
  )
}

export default BuildCommentByIcon
