import type {ExpandState} from '../types'

import processResponse, {processTextResponse} from './processResponse'
import request from './request'

export default (serverUrl: string, endpoint: string): Promise<ReadonlyArray<ExpandState>> =>
  request(serverUrl, `${endpoint}?expandState=true`).then<readonly ExpandState[]>(processResponse)
export const toggleExpandState = (
  serverUrl: string,
  endpoint: string,
  data: ReadonlyArray<ExpandState>,
): Promise<string> =>
  request(serverUrl, `${endpoint}?expandState=true`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(processTextResponse)
