import {BuildId, stringifyId} from '../../../../types'

import {SnapshotDependenciesModes} from './DependenciesTab.modes'

export function getSnapshotDependenciesLocator(
  buildId: BuildId | null | undefined,
  mode: string | null | undefined,
): string {
  const dependenciesLocator = `snapshotDependency(to:(id:${stringifyId(buildId)}))`
  const dependantsLocator = `snapshotDependency(from:(id:${stringifyId(buildId)}))`
  const isChain = mode === SnapshotDependenciesModes.CHAIN
  return isChain
    ? `item(defaultFilter:false,${dependenciesLocator},count:1000),item(defaultFilter:false,${dependantsLocator},count:1000),count:1000`
    : `${dependenciesLocator},count:1000`
}

export const getDeliveredArtifactsKey = (from: BuildId, to: BuildId) => `${from} => ${to}`
