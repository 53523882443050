import {combineReducers} from 'redux'

import {fetchable} from '../../../../reducers/fetchable'

import {InvestigationsCounter, ProjectInvestigations} from './ProjectInvesigations.types'
import {fetchProjectInvestigations} from './ProjectInvestigations.actions'

const testInvestigations = fetchable(
  fetchProjectInvestigations,
  null as InvestigationsCounter | null,
  (_, action) => action.payload,
)
const projectInvestigationsReducers = combineReducers<ProjectInvestigations>({
  testInvestigations,
})

export default projectInvestigationsReducers
