import {fetchOverview} from '../../actions/overview'
import type {AppThunk} from '../../actions/types'
import {createFetchAction} from '../../reducers/fetchable'
import makeRequest from '../../rest/request'
import {
  generateSidebarItemId,
  getDefaultAllProjectsTree,
  getDefaultOverviewTree,
} from '../../selectors/projectTrees'
import {ProjectInternalId, ROOT_PROJECT_ID, stringifyId} from '../../types'
import type {BuildTypeId, ProjectId} from '../../types'
import {base_uri} from '../../types/BS_types'
import {currentServerId} from '../../types/projectTrees'
import type {ProjectsTreeItemBuildType, ProjectTreeItemProject} from '../../types/projectTrees'
import asyncBatch, {asyncBatchById} from '../../utils/asyncBatch'
import type {WritableKeyValue} from '../../utils/object'
import {entriesToQuery} from '../../utils/queryParams'

const requestProjectsReorder = asyncBatch((order: ReadonlyArray<ProjectInternalId>) =>
  makeRequest(base_uri, 'visibleProjects.html', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: entriesToQuery([
      ['save', 'true'],
      ...order.map<[string, string]>(id => ['projects_visible', id]),
      ['projects_order', order.join(',')],
    ]),
  }),
)
export const requestProjectsReorderAction = createFetchAction(
  'requestProjectsReorder',
  async (order: ReadonlyArray<ProjectTreeItemProject>, {dispatch}) => {
    await requestProjectsReorder(order.map(item => item.internalId))
    await dispatch(fetchOverview())
  },
)
type BuildTypesReorderParams = {
  readonly projectId: ProjectId
  readonly order?: ReadonlyArray<ProjectsTreeItemBuildType>
  readonly reset?: boolean
}
const requestBuildTypesReorder = asyncBatchById({
  getId: ({projectId}) => projectId,
  asyncFn: ({projectId, order = [], reset = false}: BuildTypesReorderParams) =>
    makeRequest(base_uri, `visibleBuildTypes.html?projectId=${stringifyId(projectId)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: reset
        ? 'reset=true'
        : entriesToQuery([
            ['save', 'true'],
            ...order.map<[string, string]>(item => ['bt_visible', item.internalId ?? '']),
            ['bt_order', order.join(',')],
          ]),
    }),
})

export const requestBuildTypesReorderAction = createFetchAction(
  'requestBuildTypesReorder',
  async (params: BuildTypesReorderParams, {dispatch}) => {
    await requestBuildTypesReorder(params)
    await dispatch(fetchOverview())
  },
)
export const moveProject =
  (projectId: ProjectId, shiftBy: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const overviewTree = getDefaultOverviewTree(state)

    if (overviewTree == null) {
      return
    }

    const currentItem =
      overviewTree.hash[generateSidebarItemId(currentServerId, 'project', projectId)]

    if (currentItem == null) {
      return
    }

    const siblings = overviewTree.data.filter(
      item => item.itemType === 'project' && item.parentId === currentItem.parentId,
    )
    const currentSiblingIndex = siblings.indexOf(currentItem)
    const nextSibling = siblings[currentSiblingIndex + 1]
    const subtreeStart = overviewTree.data.indexOf(currentItem)
    const subtreeEnd =
      nextSibling != null ? overviewTree.data.indexOf(nextSibling) : overviewTree.data.length
    let newSiblingIndex = currentSiblingIndex + shiftBy

    if (newSiblingIndex < 0) {
      newSiblingIndex = 0
    } else if (newSiblingIndex >= siblings.length) {
      newSiblingIndex = siblings.length - 1
    }

    if (newSiblingIndex === currentSiblingIndex) {
      return
    }

    const siblingToInsertBefore = siblings[shiftBy > 0 ? newSiblingIndex + 1 : newSiblingIndex]
    const insertIndex =
      siblingToInsertBefore != null
        ? overviewTree.data.indexOf(siblingToInsertBefore)
        : overviewTree.data.length
    const newData =
      shiftBy > 0
        ? [
            ...overviewTree.data.slice(0, subtreeStart),
            ...overviewTree.data.slice(subtreeEnd, insertIndex),
            ...overviewTree.data.slice(subtreeStart, subtreeEnd),
            ...overviewTree.data.slice(insertIndex),
          ]
        : [
            ...overviewTree.data.slice(0, insertIndex),
            ...overviewTree.data.slice(subtreeStart, subtreeEnd),
            ...overviewTree.data.slice(insertIndex, subtreeStart),
            ...overviewTree.data.slice(subtreeEnd),
          ]
    const orderedProjects: Array<ProjectTreeItemProject> = []
    // can't use filter, see https://github.com/facebook/flow/issues/1414
    newData.forEach(item => {
      if (item.itemType === 'project') {
        orderedProjects.push(item)
      }
    })
    dispatch(requestProjectsReorderAction(orderedProjects))
  }
export const moveBuildType =
  (buildTypeId: BuildTypeId, shiftBy: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const overviewTree = getDefaultOverviewTree(state)

    if (overviewTree == null) {
      return
    }

    const currentItem =
      overviewTree.hash[generateSidebarItemId(currentServerId, 'buildType', buildTypeId)]

    if (currentItem == null) {
      return
    }

    const projectId = currentItem.parentId

    if (projectId == null) {
      return
    }

    const siblings = overviewTree.data.filter(
      item => item.itemType === 'buildType' && item.parentId === projectId,
    )
    const currentSiblingIndex = siblings.indexOf(currentItem)
    let newSiblingIndex = currentSiblingIndex + shiftBy

    if (newSiblingIndex < 0) {
      newSiblingIndex = 0
    } else if (newSiblingIndex >= siblings.length) {
      newSiblingIndex = siblings.length - 1
    }

    if (newSiblingIndex === currentSiblingIndex) {
      return
    }

    const newData =
      shiftBy > 0
        ? [
            ...siblings.slice(0, currentSiblingIndex),
            ...siblings.slice(currentSiblingIndex + 1, newSiblingIndex + 1),
            currentItem,
            ...siblings.slice(newSiblingIndex + 1),
          ]
        : [
            ...siblings.slice(0, newSiblingIndex),
            currentItem,
            ...siblings.slice(newSiblingIndex, currentSiblingIndex),
            ...siblings.slice(currentSiblingIndex + 1),
          ]
    const orderedBuildTypes: Array<ProjectsTreeItemBuildType> = []
    // can't use filter, see https://github.com/facebook/flow/issues/1414
    newData.forEach(item => {
      if (item.itemType === 'buildType') {
        orderedBuildTypes.push(item)
      }
    })
    dispatch(
      requestBuildTypesReorderAction({
        projectId,
        order: orderedBuildTypes,
      }),
    )
  }
export const resetOrderInProject =
  (projectId: ProjectId): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const overviewTree = getDefaultOverviewTree(state)
    const allProjectsTree = getDefaultAllProjectsTree(state)

    if (overviewTree == null || allProjectsTree == null) {
      return
    }

    const itemId = generateSidebarItemId(currentServerId, 'project', projectId)
    const currentItem = overviewTree.hash[itemId]
    const currentAllProjectsItem = allProjectsTree.hash[itemId]

    if (currentItem == null || currentAllProjectsItem == null) {
      return
    }

    const siblings = overviewTree.data.filter(
      item => item.itemType === 'project' && item.parentId === currentItem.parentId,
    )
    const currentSiblingIndex = siblings.indexOf(currentItem)
    const nextSibling = siblings[currentSiblingIndex + 1]
    const subtreeStart = overviewTree.data.indexOf(currentItem)
    const subtreeEnd =
      nextSibling != null ? overviewTree.data.indexOf(nextSibling) : overviewTree.data.length
    const descendants = overviewTree.data.slice(subtreeStart + 1, subtreeEnd)
    const projectChildrenRanges: WritableKeyValue<
      ProjectId,
      ReadonlyArray<ProjectTreeItemProject>
    > = {}
    let currentRange: Array<ProjectTreeItemProject> | null | undefined = null

    for (const item of descendants) {
      if (item.itemType !== 'project') {
        continue
      }

      if (item.parentId === projectId) {
        currentRange = [item]
        projectChildrenRanges[item.id] = currentRange
      } else {
        currentRange?.push(item)
      }
    }

    const unorderedChildren =
      currentAllProjectsItem.childrenProjectsIds?.flatMap(id => projectChildrenRanges[id] ?? []) ??
      []
    const newData = [
      ...overviewTree.data.slice(0, subtreeStart + 1),
      ...unorderedChildren,
      ...overviewTree.data.slice(subtreeEnd),
    ]
    const projects: Array<ProjectTreeItemProject> = []
    // can't use filter, see https://github.com/facebook/flow/issues/1414
    newData.forEach(item => {
      if (item.itemType === 'project') {
        projects.push(item)
      }
    })
    dispatch(requestProjectsReorderAction(projects))
    dispatch(
      requestBuildTypesReorderAction({
        projectId,
        reset: true,
      }),
    )
  }
export const resetOrderInRootProject = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const overviewTree = getDefaultOverviewTree(state)
  const allProjectsTree = getDefaultAllProjectsTree(state)

  if (overviewTree == null || allProjectsTree == null) {
    return
  }

  const projectChildrenRanges: WritableKeyValue<
    ProjectId,
    ReadonlyArray<ProjectTreeItemProject>
  > = {}
  let currentRange: Array<ProjectTreeItemProject> | null | undefined = null

  for (const item of overviewTree.data) {
    if (item.itemType !== 'project') {
      continue
    }

    if (item.parentId === ROOT_PROJECT_ID) {
      currentRange = [item]
      projectChildrenRanges[item.id] = currentRange
    } else {
      currentRange?.push(item)
    }
  }

  const unorderedChildren =
    allProjectsTree.data.flatMap(item =>
      item.itemType === 'project' && item.parentId === ROOT_PROJECT_ID
        ? projectChildrenRanges[item.id] ?? []
        : [],
    ) ?? []
  const projects: Array<ProjectTreeItemProject> = []
  // can't use filter, see https://github.com/facebook/flow/issues/1414
  unorderedChildren.forEach(item => {
    if (item.itemType === 'project') {
      projects.push(item)
    }
  })
  dispatch(requestProjectsReorderAction(projects))
}
