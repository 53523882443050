import moment from 'moment'
import * as React from 'react'

import {useDispatch} from 'react-redux'

import {BuildId} from '../../../types'

import {approveBuild} from './BuildApproval.actions'
import {UserApprovalRuleStatus} from './BuildApproval.types'

export function useGroupedByStatusApprovals(approvals: ReadonlyArray<UserApprovalRuleStatus>) {
  return React.useMemo(
    () =>
      approvals.reduce(
        (
          accumulator: {
            approved: Array<UserApprovalRuleStatus>
            nonApproved: Array<UserApprovalRuleStatus>
          },
          current,
        ) => {
          if (current.approved) {
            accumulator.approved.push(current)
          } else {
            accumulator.nonApproved.push(current)
          }

          return accumulator
        },
        {approved: [], nonApproved: []},
      ),
    [approvals],
  )
}
export function useBuildApprove(buildId: BuildId) {
  const dispatch = useDispatch()
  const approve = React.useCallback(() => {
    dispatch(approveBuild({buildId}))
  }, [buildId, dispatch])

  return {approve}
}
export function useTimeoutInfo(expiresAt: string | null) {
  const [secondsLeft, setSecondsLeft] = React.useState<number | null>(null)
  const [timeLeft, setTimeLeft] = React.useState<string | null>(null)

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (!expiresAt) {
      return () => {}
    }

    function tick() {
      clearTimeout(timeoutId)
      const now = moment()
      const diff = moment(expiresAt).diff(now)

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      setSecondsLeft(diff / 1000)
      setTimeLeft(moment.utc(diff).format('HH:mm:ss'))

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      timeoutId = setTimeout(tick, 1000)
    }

    tick()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [expiresAt])

  return {secondsLeft, timeLeft}
}
