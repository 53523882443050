import shallowequal from 'shallowequal'

import {SYNC_STORAGE_KEYS} from '../hooks/useStorageSyncValue'
import type {PersistedState} from '../selectors/toPersist'
import {WritableKeyValue} from '../utils/object'
import {safeLocalStorage} from '../utils/safeStorages'

const currentStorageVersion = '0.5'
const oldStorageVersions: ReadonlyArray<string> = ['0.1', '0.2', '0.3', '0.4']
const storageKey: string = 'jb.teamcity.state.v.'
const storageItemName: string = `${storageKey}${currentStorageVersion}`
export const loadState = (): PersistedState => {
  if (window.STORYBOOK_ENV != null) {
    return Object.freeze({})
  }

  oldStorageVersions.forEach((version: string) => {
    safeLocalStorage.removeItem(`${storageKey}${version}`)
  })
  const syncStorageValues: WritableKeyValue<string, string> = {}

  for (const key of Object.values(SYNC_STORAGE_KEYS)) {
    const value = safeLocalStorage.getItem(key)

    if (value != null) {
      syncStorageValues[key] = value
    }
  }

  return {...safeLocalStorage.getItemInJSON(storageItemName), syncStorageValues}
}
let prevState = {}
export const saveState = (state: PersistedState) => {
  if (shallowequal(state, prevState) || window.STORYBOOK_ENV != null) {
    return
  }

  prevState = state
  safeLocalStorage.setItem(storageItemName, JSON.stringify(state))
}
