import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import RingLink from '@jetbrains/ring-ui/components/link/link'
import classNames from 'classnames'
import {memo} from 'react'
import {useSelector} from 'react-redux'

import {getIsExperimentalUI} from '../../../selectors'
import {resolveRelative} from '../../../utils/url'

import styles from './Link.css'

type Props = LinkProps & {
  readonly relative?: boolean
  readonly supressConservative?: boolean
}

function Link({children, className, href, relative, supressConservative, ...restProps}: Props) {
  const isExperimentalUI = useSelector(getIsExperimentalUI)
  const classes = classNames(className, styles.link, {
    [styles.conservative]: !isExperimentalUI && supressConservative !== true,
  })
  return (
    <RingLink
      {...restProps}
      href={relative === true && href != null ? resolveRelative(href) : href}
      className={classes}
    >
      {children}
    </RingLink>
  )
}

export default memo<Props>(Link)
