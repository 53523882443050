import {createFetchAction} from '../../../reducers/fetchable'
import {requestBuildTriggerBuild} from '../../../rest/builds'
import {restRoot} from '../../../rest/consts'
import type {BuildId} from '../../../types'

export const fetchBuildTriggerBuild = createFetchAction(
  'fetchBuildTriggerBuild',
  async (buildId: BuildId) => {
    const data = await requestBuildTriggerBuild(restRoot, buildId)
    return data.triggered.build
  },
  {condition: () => !window.GLOBAL_FETCH_DISABLED},
)
