import type {KeyValue} from './object'

const cssVariables: KeyValue<string, string> = new Proxy(
  {},
  {
    get: (_, prop) =>
      document.documentElement != null && typeof prop === 'string'
        ? getComputedStyle(document.documentElement).getPropertyValue(prop)
        : null,
  },
)
export default cssVariables
