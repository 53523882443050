import processResponse from '../../../../rest/processResponse'
import request from '../../../../rest/request'
import {ProjectId, stringifyId} from '../../../../types'

import {InvestigationsCounter} from './ProjectInvesigations.types'

export const requestCountTestProjectInvestigations = (
  serverUrl: string,
  projectId: ProjectId,
): Promise<InvestigationsCounter> =>
  request(
    serverUrl,
    `testOccurrences?locator=affectedProject:${stringifyId(
      projectId,
    )},currentlyFailing:true,currentlyInvestigated:true,count:1001&fields=count`,
    {
      method: 'GET',
    },
  ).then<InvestigationsCounter>(processResponse)
