import {createSelector} from 'reselect'

import type {State} from '../../reducers/types'
import type {ProblemOccurrenceId} from '../../types'
import {Fetchable, ProblemsTreeNodeId} from '../../types'
import {emptyArray, emptyNullFetchable, getEmptyHash} from '../../utils/empty'
import type {KeyValue} from '../../utils/object'

import {getLeavesHash, mergeTreeAndSubTrees, sortByDepthSubtreesKeys} from '../Tests/Tests.utils'

import type {
  ProblemOccurrencesTreeType,
  ProblemOccurrenceType,
  ProblemOccurrencesTreeLeaf,
} from './BuildProblems.types'
import {ProblemOccurrencesTreeNode} from './BuildProblems.types'

export const getProblemOccurrencesTreeFetchable: (
  state: State,
  locator: string,
) => Fetchable<ProblemOccurrencesTreeType | null | undefined> = (state, locator) =>
  state.buildProblems.problemOccurrencesTree[locator] ?? emptyNullFetchable

const getProblemOccurrencesSubtreesFetchable: (
  state: State,
  locator: string,
) =>
  | KeyValue<string, Fetchable<ProblemOccurrencesTreeType | null | undefined>>
  | undefined
  | null = (state, locator) => state.buildProblems.problemOccurrencesSubtree[locator]

export const getProblemOccurrencesSubtreeFetchable: (
  state: State,
  locator: string,
  subTreeRootId: string,
) => Fetchable<ProblemOccurrencesTreeType | null | undefined> = (state, locator, subTreeRootId) =>
  getProblemOccurrencesSubtreesFetchable(state, locator)?.[subTreeRootId] ?? emptyNullFetchable

const getSortedByDepthSubtreesKeys: (state: State, locator: string) => ReadonlyArray<string> =
  createSelector([getProblemOccurrencesSubtreesFetchable], sortByDepthSubtreesKeys)

export const getProblemOccurrencesTreeNodes: (
  state: State,
  locator: string,
) => ReadonlyArray<ProblemOccurrencesTreeNode> | undefined | null = createSelector(
  [
    getProblemOccurrencesTreeFetchable,
    getProblemOccurrencesSubtreesFetchable,
    getSortedByDepthSubtreesKeys,
  ],
  mergeTreeAndSubTrees,
)

export const getProblemsLeavesHash: (
  state: State,
  locator: string,
) => KeyValue<ProblemsTreeNodeId, ProblemOccurrencesTreeLeaf> = createSelector(
  [
    getProblemOccurrencesTreeFetchable,
    getProblemOccurrencesSubtreesFetchable,
    getSortedByDepthSubtreesKeys,
  ],
  getLeavesHash,
)

export const getProblemsNodesHash: (
  state: State,
  locator: string,
) => KeyValue<ProblemsTreeNodeId, ProblemOccurrencesTreeNode> = createSelector(
  [getProblemOccurrencesTreeNodes],
  (
    nodes: ReadonlyArray<ProblemOccurrencesTreeNode> | undefined | null,
  ): KeyValue<ProblemsTreeNodeId, ProblemOccurrencesTreeNode> =>
    nodes != null ? nodes.reduce((acc, node) => ({...acc, [node.id]: node}), {}) : getEmptyHash(),
)

const getProblemOccurrencesHash: (
  state: State,
) => KeyValue<ProblemOccurrenceId, ProblemOccurrenceType> = state =>
  state.entities.problemOccurrences

export const getProblemOccurrencesById: (
  state: State,
  problemOccurrenceId: ProblemOccurrenceId,
) => ProblemOccurrenceType | null | undefined = (state, problemOccurrenceId) =>
  getProblemOccurrencesHash(state)[problemOccurrenceId]

export const makeGetProblemOccurrencesByProblemOccurranceIds: () => (
  state: State,
  ids: ReadonlyArray<ProblemOccurrenceId>,
) => ReadonlyArray<ProblemOccurrenceType> = () =>
  createSelector(
    [getProblemOccurrencesHash, (state: State, ids: ReadonlyArray<ProblemOccurrenceId>) => ids],
    (
      problemOccurrences: KeyValue<ProblemOccurrenceId, ProblemOccurrenceType>,
      ids: ReadonlyArray<ProblemOccurrenceId>,
    ) =>
      ids.length > 0
        ? ids.map((id: ProblemOccurrenceId) => problemOccurrences[id]).filter(Boolean)
        : emptyArray,
  )
