import {createReducer, isAnyOf} from '@reduxjs/toolkit'

import {setError} from './HttpsConfigurationPage.actions'
import httpsConfigurationApi from './HttpsConfigurationPage.api'
import {HttpsStateType} from './HttpsConfigurationPage.types'

const defaultState: HttpsStateType = {
  certificate: null,
  pendingAcmeOrder: null,
  error: null,
}

const httpsConfigurationPageReducer = createReducer<HttpsStateType>(defaultState, builder => {
  builder.addCase(setError, (state, action) => {
    state.error = action.payload
  })
  builder.addMatcher(
    httpsConfigurationApi.endpoints.getCertificate.matchFulfilled,
    (state, action) => {
      if (action.payload != null) {
        state.certificate = action.payload
      }
    },
  )
  builder.addMatcher(
    isAnyOf(
      httpsConfigurationApi.endpoints.getPendingAcmeOrder.matchFulfilled,
      httpsConfigurationApi.endpoints.fetchCertificateFromAcme.matchFulfilled,
      httpsConfigurationApi.endpoints.triggerAcmeChallenge.matchFulfilled,
    ),
    (state, action) => {
      state.pendingAcmeOrder = action.payload
    },
  )
  builder.addMatcher(httpsConfigurationApi.endpoints.cancelAcmeChallenge.matchFulfilled, state => {
    state.pendingAcmeOrder = null
  })
  builder.addMatcher(httpsConfigurationApi.endpoints.removeCertificate.matchFulfilled, state => {
    state.certificate = null
  })
  builder.addMatcher(
    isAnyOf(
      httpsConfigurationApi.endpoints.fetchCertificateFromAcme.matchPending,
      httpsConfigurationApi.endpoints.triggerAcmeChallenge.matchPending,
      httpsConfigurationApi.endpoints.cancelAcmeChallenge.matchPending,
      httpsConfigurationApi.endpoints.uploadCertificate.matchPending,
    ),
    state => {
      state.error = null
    },
  )
  builder.addMatcher(
    isAnyOf(
      httpsConfigurationApi.endpoints.fetchCertificateFromAcme.matchRejected,
      httpsConfigurationApi.endpoints.triggerAcmeChallenge.matchRejected,
      httpsConfigurationApi.endpoints.cancelAcmeChallenge.matchRejected,
      httpsConfigurationApi.endpoints.uploadCertificate.matchRejected,
    ),
    (state, action) => {
      state.error = action.payload as HttpsStateType['error']
    },
  )
})

export default httpsConfigurationPageReducer
