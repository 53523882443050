import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {ChangeType} from '../../../types'

export const requestChangeStatus = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeType>> =>
  request(
    serverUrl,
    `changes?locator=${encodeURIComponent(
      locator,
    )}&fields=change(id,status(cancelledBuilds,pendingBuildTypes,runningSuccessfullyBuilds,successfulBuilds,compilationErrorBuilds(count),criticalBuilds(count),newTestsFailedBuilds(count),notCriticalBuilds(count)))`,
  )
    .then<{change: ReadonlyArray<ChangeType>}>(processResponse)
    .then(data => data.change)

export const requestChangeStatusBuilds = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeType>> =>
  request(
    serverUrl,
    `changes?locator=${encodeURIComponent(
      locator,
    )}&fields=change(id,status(criticalBuilds(build:(id,buildTypeId)),newTestsFailedBuilds(build:(id,buildTypeId))))`,
  )
    .then<{change: ReadonlyArray<ChangeType>}>(processResponse)
    .then(data => data.change)

export const requestChangeBranches = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeType>> =>
  request(
    serverUrl,
    `changes?locator=${encodeURIComponent(
      locator,
    )}&fields=change(id,mergedInfo(branches(branch(name,default))))`,
  )
    .then<{change: ReadonlyArray<ChangeType>}>(processResponse)
    .then(data => data.change)
