import {$Values} from 'utility-types'

import type {BuildId, BuildStateType, ProjectId} from '../../../types'

export const stopButtonType = Object.freeze({
  defaultButton: 'defaultButton',
  iconButton: 'iconButton',
})
type StopButtonTypeType = $Values<typeof stopButtonType>
export type OwnProps = {
  readonly buildId: BuildId
  readonly withAgent?: boolean
  readonly className?: string
  readonly buttonType?: StopButtonTypeType
  readonly primary?: boolean
  readonly buttonText?: string
  readonly actualStopMessage?: string
}
export type StateProps = {
  readonly projectId?: ProjectId | null | undefined
  readonly isCancellable: boolean
  readonly agentName?: string | null | undefined
  readonly state: BuildStateType | void
  readonly isInterrupted: boolean
  readonly isStopping: boolean
  readonly stoppedWithComment?: string | null | undefined
  readonly stoppedBy?: string | null | undefined
}
export type ActionProps = {
  readonly onStop: (arg0: BuildId) => unknown
}
type UpdateProps = {
  readonly updateResults: () => unknown
}
export type DefaultProps = UpdateProps &
  ActionProps & {
    readonly buttonType: StopButtonTypeType
    readonly actualStopMessage: string
    readonly state: BuildStateType
  }
export type Props = OwnProps & StateProps & DefaultProps
