import {OSType} from '../types'

export const OS_TYPE_WINDOWS = 'Windows'
export const OS_TYPE_MAC = 'macOS'
export const OS_TYPE_LINUX = 'Linux'
export const OS_TYPE_SOLARIS = 'Solaris'
export const OS_TYPE_FREEBSD = 'FreeBSD'
export const OS_TYPE_OTHERUNIX = 'Unix'
export const OS_TYPE_UNKNOWN = 'Unknown'

export function getOSType(osName: string | null | undefined): OSType | null {
  if (osName == null) {
    return null
  }

  const commaIndex = osName.indexOf(',')
  const shortName = (commaIndex >= 0 ? osName.substring(0, commaIndex) : osName)
    .trim()
    .toLowerCase()

  if (shortName.startsWith('win')) {
    return OS_TYPE_WINDOWS
  }
  if (shortName.startsWith('mac')) {
    return OS_TYPE_MAC
  }
  if (shortName.startsWith('linux')) {
    return OS_TYPE_LINUX
  }
  if (shortName.startsWith('sun') || shortName.indexOf('solar') !== -1) {
    return OS_TYPE_SOLARIS
  }
  if (shortName.indexOf('bsd') !== -1) {
    return OS_TYPE_FREEBSD
  }
  if (shortName.indexOf('unix') !== -1 || shortName.indexOf('ux') !== -1) {
    return OS_TYPE_OTHERUNIX
  }

  return OS_TYPE_UNKNOWN
}
