import {createReducer} from '@reduxjs/toolkit'

import {fetchTestScopes} from '../../../Tests/Tests.actions'
import {submitPager} from '../Pager.actions.base'
import type {PagerType} from '../Pager.types'
import {PagerGroup} from '../Pager.types'

const defaultPager: PagerType = {
  hasMore: true,
  loadedLessThanRequested: null,
  openTotal: false,
  pageSize: 100,
  total: 0,
}

const pagerReducer = createReducer(defaultPager, builder => {
  builder.addCase(submitPager, (state, action) => {
    if (action.meta.group === PagerGroup.TEST_SCOPE) {
      Object.assign(state, action.payload)
    }
  })
  builder.addCase(fetchTestScopes.fulfilled, (state, action) => {
    const {hasMore, loadedLessThanRequested, data} = action.payload

    if (data == null) {
      state.total = 0
      state.openTotal = false
      state.show = false
      return
    }

    state.hasMore = hasMore
    state.total = data.length
    state.loadedLessThanRequested = loadedLessThanRequested
    state.openTotal = hasMore && !loadedLessThanRequested
  })
})

export default pagerReducer
