import processResponse from '../../../../../rest/processResponse'
import request from '../../../../../rest/request'
import {stringifyId} from '../../../../../types'
import type {BuildId} from '../../../../../types'

import type {BuildSnippetType} from './BuildSnippets.types'

export const requestBuildSnippets = (
  serverUrl: string,
  buildId: BuildId,
): Promise<ReadonlyArray<BuildSnippetType>> =>
  request(serverUrl, `app/placeId?buildId=${stringifyId(buildId)}`).then<BuildSnippetType[]>(
    processResponse,
  )
