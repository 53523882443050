import classNames from 'classnames'

import * as React from 'react'

import {closest, focusSelfOrChildLink} from '../../../../utils/dom'
import filesize from '../../../../utils/filesize'
import {resolveRelative} from '../../../../utils/url'
import IconButton from '../../IconButton/IconButton'
import Link from '../../Link/Link'
import SvgIcon from '../../SvgIcon/SvgIcon'

import styles from './FileTreeNode.css'

type Extension = {
  icon: string
  title: string
  href: string
}
type DefaultProps = {
  readonly defaultExpanded: boolean
  readonly level: number
  readonly path: string
  readonly type: string
  readonly expandable: boolean
}
type Props = DefaultProps & {
  readonly className?: string
  readonly name: string
  readonly href?: string
  readonly size?: number | null | undefined
  readonly icon: string
  readonly children?: React.ReactNode
  readonly itemRef?: React.Ref<any> | null | undefined
  readonly extensions?: ReadonlyArray<Extension> | null | undefined
  readonly onSelect?: ((path: string, type: string) => unknown) | null | undefined
  readonly onExpand?:
    | ((path: string, expanded: boolean | null | undefined) => unknown)
    | null
    | undefined
}
type State = {
  readonly expanded?: boolean
}
export const MIN_OFFSET = 16
const ARROW_OFFSET = 16
export const STEP = 16
const TREE_SELECTOR = '[role=tree]'
export const ITEM_SELECTOR = '[role=treeitem]'

function stop(e: React.SyntheticEvent) {
  e.stopPropagation()
}

class FileTreeNode extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    defaultExpanded: false,
    level: 0,
    path: '/',
    type: 'file',
    expandable: false,
  }

  state: State = {
    expanded: this.props.defaultExpanded,
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {onExpand, path} = this.props
    const {expanded} = this.state

    if (onExpand && expanded !== prevState.expanded) {
      onExpand(path, expanded)
    }
  }

  handleToggleClick: (arg0: React.SyntheticEvent) => void = e => {
    this.toggle()
    stop(e)
  }

  handleNameClick: (arg0: React.SyntheticEvent) => void = e => {
    const {onSelect, path, type} = this.props

    if (onSelect) {
      e.preventDefault()
      onSelect(path.slice(1), type)
    }

    stop(e)
  }

  handleKeyDown: (arg0: React.KeyboardEvent) => void = e => {
    const {expandable} = this.props
    const {expanded} = this.state
    const currentTarget = e.currentTarget

    if (e.target !== currentTarget) {
      return
    }

    const item = closest(currentTarget, ITEM_SELECTOR)
    const tree = closest(item, TREE_SELECTOR)

    if (!tree) {
      return
    }

    const items = [...tree.querySelectorAll(ITEM_SELECTOR)]
    const currentIndex = item != null ? items.indexOf(item) : -1
    let itemToFocus: Element | null | undefined

    switch (e.key) {
      case 'Home':
        itemToFocus = items[0]
        break

      case 'ArrowLeft':
        if (expandable && expanded === true) {
          this.setState({
            expanded: false,
          })
        } else {
          // Focus parent
          itemToFocus = closest(item?.parentElement, ITEM_SELECTOR)
        }

        break

      case 'ArrowUp':
        itemToFocus = items[currentIndex - 1]
        break

      case 'ArrowRight':
        if (expandable && expanded !== true) {
          this.setState({
            expanded: true,
          })
          break
        }

        // Focus first child
        itemToFocus = item && item.querySelector(ITEM_SELECTOR)
        break

      case 'ArrowDown':
        itemToFocus = items[currentIndex + 1]
        break

      case 'End':
        itemToFocus = items[items.length - 1]
        break

      case 'Enter':
        if (expandable) {
          this.toggle()
        } else {
          return
        }

        break

      default:
        return
    }

    e.stopPropagation()
    e.preventDefault()
    focusSelfOrChildLink(itemToFocus)
  }

  toggle() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }))
  }

  getOffset(): number {
    return MIN_OFFSET + this.props.level * STEP
  }

  renderIcon(): React.ReactNode {
    return (
      <span
        style={{
          marginLeft: this.getOffset() + ARROW_OFFSET,
        }}
      >
        <SvgIcon className={styles.icon} icon={this.props.icon} />
      </span>
    )
  }

  renderSize(): React.ReactNode {
    const {size} = this.props
    return size != null && <span className={styles.size}>{filesize(size)}</span>
  }

  renderExtensions(): React.ReactNode | null | undefined {
    const {extensions} = this.props
    return (
      extensions &&
      extensions.map<any>(({icon, title, href}) => (
        <IconButton
          key={icon}
          onClick={stop}
          className={styles.extensionIcon}
          icon={icon}
          title={title}
          href={resolveRelative(href)}
          target="_blank"
          rel="noreferrer"
        />
      ))
    )
  }

  render(): React.ReactNode {
    const {className, name, href, expandable, children, itemRef, onSelect} = this.props
    const {expanded} = this.state
    const classes = classNames(styles.item, className)
    return (
      <li
        ref={itemRef}
        role="treeitem"
        aria-expanded={expandable ? expanded : undefined}
        tabIndex={expandable ? 0 : -1}
        className={classes}
        onClick={this.handleToggleClick}
        onKeyDown={expandable ? this.handleKeyDown : undefined}
      >
        {expandable && (
          <span className={styles.heading}>
            {this.renderIcon()}
            {href != null || onSelect ? (
              <Link
                relative
                href={href}
                className={styles.innerLink}
                onClick={this.handleNameClick}
              >
                {name}
              </Link>
            ) : (
              <span className={styles.name}>{name}</span>
            )}
            {this.renderSize()}
            {this.renderExtensions()}
            <SvgIcon
              style={{
                left: this.getOffset(),
              }}
              className={styles.chevronIcon}
              icon={expanded === true ? 'chevron-down' : 'chevron-right'}
            />
          </span>
        )}
        {!expandable && href != null && (
          <Link
            relative
            href={href}
            className={styles.link}
            onClick={this.handleNameClick}
            onKeyDown={this.handleKeyDown}
          >
            {WrapText => (
              <>
                <span>{this.renderIcon()}</span>
                <span className={styles.name}>
                  <WrapText>{name}</WrapText>
                </span>
                {this.renderSize()}
                {this.renderExtensions()}
              </>
            )}
          </Link>
        )}
        {expandable && expanded === true && children}
      </li>
    )
  }
}

export default FileTreeNode
