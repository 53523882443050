import {memo, ReactHTML} from 'react'

import InjectHtml from '../components/common/InjectHtml/InjectHtml'
import type {OwnProps as InjectHtmlProps} from '../components/common/InjectHtml/InjectHtml.types'
import type {BuildTypeId, ChangeId} from '../types'
import getTransformationPath from '../utils/getTransformationPath'

type AddProps = {
  readonly placeholder: string | null | undefined
  readonly path: string | null | undefined
}
type Props<T extends keyof ReactHTML = 'div'> = Omit<InjectHtmlProps<T>, keyof AddProps> & {
  readonly text: string | null | undefined
  readonly buildTypeId?: BuildTypeId | null | undefined
  readonly changeId?: ChangeId | null | undefined
  readonly personal?: boolean
}
export default memo(function TransformText<T extends keyof ReactHTML = 'div'>({
  text,
  buildTypeId,
  changeId,
  personal,
  ...restProps
}: Props<T>) {
  return (
    <InjectHtml
      {...restProps}
      placeholder={text}
      path={getTransformationPath({
        text,
        buildTypeId,
        changeId,
        personal,
      })}
    />
  )
})
