import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {BuildId, stringifyId} from '../../../types'

import {BuildApprovalType} from './BuildApproval.types'

const userFields = 'id,username,email,name,avatars'
const approvalInfo =
  'approvalInfo(' +
  'status,' +
  'canBeApprovedByCurrentUser,' +
  'configurationValid,' +
  'timeoutTimestamp,' +
  `groupApprovals(groupApproval(requiredApprovalsCount,currentlyApprovedBy(user(${userFields})),group(key,name,users(count)))),` +
  `userApprovals(userApproval(user(${userFields}),approved)))`

type BuildApprovalResponseType = {
  approvalInfo: BuildApprovalType
}

export const fetchBuildApprovalData = (
  serverUrl: string,
  buildId: BuildId,
): Promise<BuildApprovalResponseType> =>
  request(
    serverUrl,
    `builds/id:${stringifyId(buildId)}?fields=${approvalInfo}`,
  ).then<BuildApprovalResponseType>(processResponse)

export const requestApproveBuild = (
  serverUrl: string,
  buildId: BuildId,
): Promise<BuildApprovalResponseType> =>
  request(serverUrl, `buildQueue/id:${stringifyId(buildId)}/approve`, {
    method: 'POST',
  }).then<BuildApprovalResponseType>(processResponse)
