import {H2} from '@jetbrains/ring-ui/components/heading/heading'
import type {ContentPanelProps as APIProps} from '@jetbrains/teamcity-api'
import classNames from 'classnames'
import type {ComponentType} from 'react'
import * as React from 'react'

import {useSyncedBooleanQueryParam} from '../../../hooks/routes'
import Link from '../Link/Link'
import RouterLinkWrapper from '../RouterLinkWrapper/RouterLinkWrapper'
import SvgIcon from '../SvgIcon/SvgIcon'

import {Props} from './ContentPanel.types'

import styles from './ContentPanel.css'

const ContentPanel = ({
  className,
  headingClassName,
  subheadingClassName,
  contentClassName,
  panelType,
  heading,
  subheading,
  href,
  headerSnippet,
  content,
  expandable = true,
  withBorder = true,
  expandedByDefault = true,
  ...restProps
}: Props) => {
  const [expanded, setExpanded] = useSyncedBooleanQueryParam({
    param: panelType,
    userProperty: panelType,
    defaultTrue: expandedByDefault,
  })
  const HeadingHtml = React.useMemo(
    () => (
      <H2 className={styles.htmlHeading}>
        {expandable === true && (
          <SvgIcon
            className={styles.chevronIcon}
            title={expanded ? 'Collapse section' : 'Expand section'}
            icon={expanded ? 'chevron-down' : 'chevron-right'}
            onClick={() => setExpanded(!expanded)}
          />
        )}
        {href != null ? (
          <RouterLinkWrapper>
            <Link
              className={classNames(headingClassName, styles.heading)}
              href={href}
              data-test-panel-heading={panelType}
            >
              {heading}
            </Link>
          </RouterLinkWrapper>
        ) : (
          <span
            className={classNames(headingClassName, styles.heading)}
            data-test-panel-heading={panelType}
          >
            {heading}
          </span>
        )}
        {subheading != null && (
          <span
            className={classNames(styles.subheading, subheadingClassName)}
            data-test-panel-subheading={panelType}
          >
            {subheading}
          </span>
        )}
      </H2>
    ),
    [
      expandable,
      expanded,
      href,
      headingClassName,
      panelType,
      heading,
      subheading,
      subheadingClassName,
      setExpanded,
    ],
  )
  return (
    <div
      className={classNames(className, styles.wrapper, {
        [styles.expandable]: expandable === true,
        [styles.expanded]: expanded === true,
      })}
      {...restProps}
    >
      <div className={styles.header}>
        {HeadingHtml}
        {expanded && headerSnippet}
      </div>
      <div
        className={classNames(contentClassName, styles.content, {
          [styles.border]: withBorder,
        })}
        data-test-panel-content={panelType}
        style={{
          display: expanded ? 'block' : 'none',
        }}
      >
        {content}
      </div>
    </div>
  )
}

const ContentPanelMemo = React.memo<Props>(ContentPanel)
export default ContentPanelMemo
export const ContentPanelAPI = ContentPanelMemo as ComponentType<APIProps>
