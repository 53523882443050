import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {BuildTypeId, ChangeFilesType, ChangeId, stringifyId} from '../../../types'

// TODO: migrate to RTK Query when TW-76918 is fixed
export const requestChangeFiles = (
  serverUrl: string,
  changeId: ChangeId,
  buildTypeId: BuildTypeId | null | undefined,
  personal?: boolean | undefined,
): Promise<ChangeFilesType> => {
  const locator = `id:${stringifyId(changeId)}${personal === true ? `,personal:true` : ''}`
  const buildTypeIdParam = buildTypeId != null ? `&buildTypeId=${stringifyId(buildTypeId)}` : ''
  return request(
    serverUrl,
    `changes/${encodeURIComponent(
      locator,
    )}/files?fields=count,file(file,directory,changeType)${buildTypeIdParam}`,
  ).then<ChangeFilesType>(processResponse)
}
