import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import {BuildTypeId, ChangeId} from '../../../types'

import {requestChangeFiles} from './ChangeFiles.rest'

export const fetchChangesFiles = createFetchAction(
  'fetchChangesFiles',
  ({
    changeId,
    buildTypeId,
    personal,
  }: {
    readonly changeId: ChangeId
    readonly buildTypeId?: BuildTypeId | null | undefined
    readonly personal?: boolean | undefined
  }) => requestChangeFiles(restRoot, changeId, buildTypeId, personal),
)
