import {$Values} from 'utility-types'

import type {BranchType, BuildTypeId, ProjectId, ProjectOrBuildTypeNode} from '../../types'

export const Sizes = {
  S: 14,
  M: 20,
}
export const types = {
  project: 'project',
  buildType: 'buildType',
  template: 'template',
  investigation: 'investigation',
  vcs_root: 'vcs-root',
  pausedOutline: 'paused-outline',
  paused: 'paused',
} as const
export type ProjectOrBuildTypeIconType = $Values<typeof types>
export type OverviewHeaderIconOwnProps = {
  readonly showPausedInStatus?: boolean
  readonly showInvestigationInStatus?: boolean
  readonly ignoreStatus?: boolean
  readonly size?: keyof typeof Sizes
  readonly className?: string | null | undefined
  readonly title?: string
  readonly arrowClassName?: string | null | undefined
  readonly projectOrBuildTypeNode?: ProjectOrBuildTypeNode | null | undefined
  readonly withArrow?: boolean
}
export type OverviewHeaderIconAddProps =
  | {
      readonly type: 'project'
      readonly id?: ProjectId
      readonly branch?: BranchType
    }
  | {
      readonly type: 'buildType'
      readonly id?: BuildTypeId
      readonly branch?: BranchType
      readonly showInfoStatus?: boolean
    }
  | {
      readonly type?: Exclude<ProjectOrBuildTypeIconType, 'project' | 'buildType'> | null
    }
export type OverviewStatusIconOwnProps = OverviewHeaderIconOwnProps & OverviewHeaderIconAddProps
export type OverviewStatusIconStateProps = {
  readonly title?: string | null | undefined
  readonly status?: 'failed' | 'successful' | null
  readonly type: ProjectOrBuildTypeIconType | null | undefined
  readonly composite?: boolean
}
export type DefaultProps = {
  readonly type: ProjectOrBuildTypeIconType
  readonly size: keyof typeof Sizes
}
export type Props = OverviewStatusIconOwnProps & OverviewStatusIconStateProps & DefaultProps
