import {DefaultRootState} from 'react-redux'

import {BuildId} from '../../../types'

import {BuildApprovalType} from './BuildApproval.types'

export const getBuildApprovalDetails = (
  state: DefaultRootState,
  buildId: BuildId,
): BuildApprovalType | null => state.buildApprovals[buildId] ?? null
