import {createFetchAction} from '../../reducers/fetchable'

import {fetchRules, deleteRule, createRule, updateRule} from './Cleanup.requests'
import type {CleanupHolderNodeType, FetchRulesOptons, RuleId, RuleType} from './Cleanup.types'

type FetchCleanupRulesArg = {
  holderNode: CleanupHolderNodeType
  options?: FetchRulesOptons | null | undefined
}
export const fetchCleanupRulesAction = createFetchAction(
  'fetchCleanupRules',
  async ({holderNode, options}: FetchCleanupRulesArg) => {
    const response = await fetchRules(holderNode, options)
    return response.data.holdersWithRules
  },
)
export const fetchCleanupRules = (
  holderNode: CleanupHolderNodeType,
  options?: FetchRulesOptons | null | undefined,
) => fetchCleanupRulesAction({holderNode, options})
type DeleteCleanupRuleArg = {
  holderNode: CleanupHolderNodeType
  ruleId: string
}
export const deleteCleanupRuleAction = createFetchAction(
  'deleteCleanupRule',
  ({holderNode, ruleId}: DeleteCleanupRuleArg) => deleteRule(holderNode, ruleId),
)
export const deleteCleanupRule = (holderNode: CleanupHolderNodeType, ruleId: string) =>
  deleteCleanupRuleAction({holderNode, ruleId})
type UpdateCleanupRuleArg = {
  holderNode: CleanupHolderNodeType
  rule: RuleType
}
export const updateCleanupRuleAction = createFetchAction(
  'updateCleanupRule',
  ({holderNode, rule}: UpdateCleanupRuleArg) => updateRule(holderNode, rule),
)
export const updateCleanupRule = (holderNode: CleanupHolderNodeType, rule: RuleType) =>
  updateCleanupRuleAction({holderNode, rule})
type CreateCleanupRuleArg = {
  holderNode: CleanupHolderNodeType
  rule: RuleType
  ruleId?: RuleId
}
export const createCleanupRuleAction = createFetchAction(
  'createCleanupRule',
  async ({holderNode, rule, ruleId}: CreateCleanupRuleArg) => {
    const response = await createRule(holderNode, rule, ruleId)
    return response.data.ruleId
  },
)
export const createCleanupRule = (
  holderNode: CleanupHolderNodeType,
  rule: RuleType,
  ruleId?: RuleId,
) => createCleanupRuleAction({holderNode, rule, ruleId})
