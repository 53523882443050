import {withHandlers, branch as branchHOC, ComponentEnhancer} from 'recompose'

import compose from 'lodash/flowRight'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import {resetBuildsLocatorState} from '../actions'
import {fetchBuildsData} from '../actions/builds'
import type {FetchBuildDataParams} from '../actions/builds'
import Fetcher from '../components/common/Fetcher/Fetcher'
import withEffect from '../hocs/withEffect'
import {withProjectOrBuildTypeNode} from '../hooks/routes'
import type {State} from '../reducers/types'
import {getLocatorIfReady, getBuildType} from '../selectors'
import {BuildId, getProjectBuildTypeFilter} from '../types'
import type {
  BuildTypeInternalId,
  LocatorOptions,
  ProjectOrBuildTypeNode,
  ActiveEntityProps,
} from '../types'
import {notNull} from '../utils/guards'
import createSimpleStream from '../utils/simpleStream'
import type {SimpleStream} from '../utils/simpleStream'
import {subscribeOnBuildTypeEvents} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'

type OwnProps = LocatorOptions &
  ActiveEntityProps & {
    readonly locator?: string | null | undefined
    readonly withPager?: boolean
    readonly withQueued?: boolean
    readonly withRunning?: boolean
    readonly withBuildTypeDetails?: boolean
    readonly withSnapshotDependencies?: boolean
    readonly withDownloadedArtifactsFrom?: BuildId | null
    readonly withProgress?: boolean
    readonly fetchCount?: number
    readonly projectOrBuildTypeNode?: ProjectOrBuildTypeNode | null | undefined
    readonly update$?: SimpleStream<void>
    readonly updatePeriod?: number | null | undefined
    readonly children?: React.ReactNode
    readonly pause?: boolean
    readonly customEndpoint?: string
  }
type StateProps = {
  locator: string | null | undefined
  buildTypeInternalId: BuildTypeInternalId | null | undefined
}
type ActionProps = {
  fetchBuilds: (arg0: FetchBuildDataParams) => Promise<unknown>
  resetLocatorState?: (locator: string) => unknown
}
type HandlerProps = {
  getUpdate$: () => SimpleStream<void>
}

const mapStateToProps = (
  state: State,
  {
    locator,
    projectOrBuildTypeNode,
    projectId,
    buildTypeId,
    withQueued,
    withRunning,
    withSnapshotDependencies,
    withPager,
    withBuildTypeDetails,
    agentId,
    agentPoolId,
    agentTypeId,
    buildId,
    children,
    isFavorites,
    pause,
    testId,
    update$,
    updatePeriod,
    customEndpoint,
    ...restProps
  }: OwnProps,
): StateProps => ({
  locator:
    locator ??
    getLocatorIfReady(state, {
      projectBuildtype:
        projectOrBuildTypeNode ??
        getProjectBuildTypeFilter({
          projectId,
          buildTypeId,
        }) ??
        undefined,
      withRunningAndQueued: withRunning === true || withQueued,
      ...restProps,
    }),
  buildTypeInternalId: getBuildType(state, buildTypeId)?.internalId,
})

const actionCreators = {
  fetchBuilds: fetchBuildsData,
  resetLocatorState: resetBuildsLocatorState,
}

type Props = OwnProps & StateProps & ActionProps & HandlerProps

class BuildsFetcher extends React.Component<Props> {
  static defaultProps = {
    withQueued: false,
    withRunning: false,
    withBuildTypeDetails: true,
    withSnapshotDependencies: false,
  }

  fetchData = (locator: string, inBackground?: boolean) => {
    const {
      fetchBuilds,
      withPager,
      withRunning,
      withQueued,
      withBuildTypeDetails,
      withSnapshotDependencies,
      withDownloadedArtifactsFrom,
      withProgress,
      fetchCount,
      customEndpoint,
    } = this.props
    return fetchBuilds({
      locator,
      withPager,
      requestOptions: {
        fetchCount,
        withProgress,
        withBuildTypeDetails,
        withSnapshotDependencies,
        withDownloadedArtifactsFrom,
        withQueuedInfo: withQueued,
        withRunningInfo: withRunning,
        customEndpoint,
      },
      inBackground,
    })
  }

  render() {
    const {
      buildTypeInternalId,
      getUpdate$,
      updatePeriod,
      locator,
      resetLocatorState,
      children,
      pause,
    } = this.props
    return (
      <Fetcher
        locator={locator}
        resetLocatorState={resetLocatorState}
        pause={pause}
        fetchData={this.fetchData}
        update$={getUpdate$()}
        updatePeriod={buildTypeInternalId != null ? null : updatePeriod}
        hasSubscription={buildTypeInternalId != null}
      >
        {children}
      </Fetcher>
    )
  }
}

export const getEvents = (
  withQueued: boolean | null | undefined,
  withRunning: boolean | null | undefined,
): ReadonlyArray<string> =>
  [
    withQueued === true ? SubscriptionEvents.BUILD_TYPE_ADDED_TO_QUEUE : null,
    withQueued === true ? SubscriptionEvents.BUILD_TYPE_REMOVED_FROM_QUEUE : null,
    withRunning === true || withQueued === true ? SubscriptionEvents.BUILD_STARTED : null,
    withRunning === true ? SubscriptionEvents.BUILD_CHANGES_LOADED : null,
    SubscriptionEvents.BUILD_FINISHED,
    SubscriptionEvents.BUILD_INTERRUPTED,
    SubscriptionEvents.BUILD_REMOVED,
    SubscriptionEvents.BUILD_TAGS_CHANGED,
  ].filter(notNull)

const BuildsFetcherContainer: React.ComponentType<OwnProps> = compose(
  React.memo,
  branchHOC(
    ({projectId, buildTypeId, projectOrBuildTypeNode}: OwnProps) =>
      !projectOrBuildTypeNode && !projectId && !buildTypeId,
    withProjectOrBuildTypeNode as ComponentEnhancer<OwnProps, OwnProps>,
  ),
  ReactRedux.connect(mapStateToProps, actionCreators),
  React.memo,
  withHandlers(() => {
    const update$ = createSimpleStream()
    return {
      getUpdate$: (props: OwnProps & StateProps & ActionProps) => () => props.update$ ?? update$,
    }
  }),
  withEffect(
    (props: Props) => props.buildTypeInternalId,
    props => props.withQueued,
    props => props.withRunning,
    props => props.getUpdate$,
    props => props.pause,
    props => props.locator,
    (internalId, withQueued, withRunning, getUpdate$, pause) =>
      internalId != null && !pause
        ? subscribeOnBuildTypeEvents(internalId, getEvents(withQueued, withRunning), () =>
            getUpdate$().fire(),
          )
        : undefined,
  ),
)(BuildsFetcher)
export default BuildsFetcherContainer
