import type {DslOptionType} from '../types'

import processResponse from './processResponse'
import request from './request'

const endpoint = 'dslApiVersions.html'
export default (serverUrl: string): Promise<ReadonlyArray<DslOptionType>> =>
  request(serverUrl, `${endpoint}`)
    .then<{kotlin: ReadonlyArray<DslOptionType>}>(processResponse)
    .then(data => data.kotlin)
