import type {AppThunk} from '../../actions/types'
import {createFetchAction} from '../../reducers/fetchable'
import {restRoot} from '../../rest/consts'
import {
  Entities,
  NormalizedTestOccurrences,
  normalizeTestMetadata,
  normalizeTestOccurrences,
  normalizeTestOccurrencesInvocations,
} from '../../rest/schemata'
import {stringifyId, BuildTypeHierarchyType} from '../../types'
import type {
  BuildId,
  BuildTypeId,
  ProjectId,
  TestId,
  TestOccurrenceId,
  UrlExtension,
} from '../../types'
import {base_uri, BS} from '../../types/BS_types'
import {ActionThrottler} from '../../utils/actionThrottler'
import {mergeEntities} from '../../utils/entities'
import type {WritableKeyValue} from '../../utils/object'
import {getPager} from '../common/Pager/Pager.selectors'
import {PagerGroup} from '../common/Pager/Pager.types'

import {
  requestFlakyTests,
  requestTestBuildTypeHierarchy,
  requestTestOccurrenceMetadata,
  requestTestOccurrences,
  requestTestOccurrencesCounts,
  requestTestOccurrencesInvocations,
  requestTestOccurrencesTree,
  requestTestScopes,
} from './Tests.rest'
import {
  getTestOccurrencesCountsFetchable,
  getTestOccurrencesFetchable,
  getTestOccurrencesInvocationsFetchable,
  getTestOccurrencesSubtreeFetchable,
  getTestOccurrencesTreeFetchable,
  getTestScopesFetchable,
} from './Tests.selectors'
import type {
  RequestFlakyTestsType,
  RequestTestOccurrenceOptionsParams,
  TestOccurrencesCountsType,
  TestOccurrencesTreeType,
  TestOccurrenceType,
  TestScopesKeyType,
} from './Tests.types'
import {getTestOccurrencesEntities} from './Tests.utils'

const THROTTLER_TIMEOUT = 5000

const processTestOccurrenceTree = (
  data: TestOccurrencesTreeType,
  options?: RequestTestOccurrenceOptionsParams,
) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const testOccurrences = leaf.testOccurrences?.testOccurrence ?? []
    const normalizedTestOccurrences = getTestOccurrencesEntities(testOccurrences, options)
    entities = mergeEntities(entities, normalizedTestOccurrences.entities)
  })
  return {tree: data, entities}
}

const processTestOccurrencesSubtree = ({
  data,
  options,
}: {
  data: TestOccurrencesTreeType
  options?: RequestTestOccurrenceOptionsParams
}) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const testOccurrences = leaf.testOccurrences?.testOccurrence ?? []
    const normalizedTestOccurrences = getTestOccurrencesEntities(testOccurrences, options)
    entities = mergeEntities(entities, normalizedTestOccurrences.entities)
  })
  return {tree: data, entities}
}

const processTestOccurrencesInvocations = ({
  data: testOccurrencesData,
  options,
}: {
  data: ReadonlyArray<TestOccurrenceType>
  options?: RequestTestOccurrenceOptionsParams
}) => {
  const normalizedTestOccurrences = normalizeTestOccurrences(testOccurrencesData)
  const normalizedTestOccurrencesInvocations =
    normalizeTestOccurrencesInvocations(testOccurrencesData)
  let entities = {...normalizedTestOccurrencesInvocations.entities}
  const testOccurrences = normalizedTestOccurrences.entities.testOccurrences
  normalizedTestOccurrences.result.forEach((testOccurrenceId: TestOccurrenceId) => {
    const invocations = testOccurrences?.[testOccurrenceId]?.invocations?.testOccurrence ?? []
    const testOccurrencesInvocations = getTestOccurrencesEntities(invocations, options)
    entities = mergeEntities(entities, testOccurrencesInvocations.entities)
  })
  return {
    entities,
    result: normalizedTestOccurrencesInvocations.result,
  }
}

type FetchTestOccurencesArg = {
  locator: string
  options?: RequestTestOccurrenceOptionsParams
  isBackground?: boolean
}
type FetchTestOccurencesPayload = {
  data: NormalizedTestOccurrences
  hasMore?: boolean
  loadedLessThanRequested?: boolean
}
export const fetchTestOccurrences = createFetchAction(
  'fetchTestOccurrences',
  async (
    {locator, options}: FetchTestOccurencesArg,
    {getState},
  ): Promise<FetchTestOccurencesPayload> => {
    const state = getState()
    const {pageSize} = getPager(state, PagerGroup.TEST)

    const {data, hasMore} = await requestTestOccurrences(restRoot, locator, options)
    return {
      data: getTestOccurrencesEntities(data, options),
      hasMore,
      loadedLessThanRequested: data.length < pageSize,
    }
  },
  {getPendingMeta: ({arg}) => ({isBackground: arg.isBackground})},
)
export const receiveTestOccurrences = ({
  data,
  locator,
  hasMore,
  loadedLessThanRequested,
  options,
}: {
  data: ReadonlyArray<TestOccurrenceType>
  locator: string
  hasMore?: boolean
  loadedLessThanRequested?: boolean
  options?: RequestTestOccurrenceOptionsParams
}) =>
  fetchTestOccurrences.fulfilled(
    {
      data: getTestOccurrencesEntities(data, options),
      hasMore,
      loadedLessThanRequested,
    },
    '',
    {
      options,
      locator,
    },
  )

export const fetchTestOccurrence =
  (
    testOccurrenceId: TestOccurrenceId,
    options?: RequestTestOccurrenceOptionsParams,
  ): AppThunk<any> =>
  dispatch => {
    dispatch(
      fetchTestOccurrences({
        locator: `${stringifyId(testOccurrenceId)}`,
        isBackground: false,
        options,
      }),
    )
  }

const fetchTestOccurrencesThrottler = new ActionThrottler(
  fetchTestOccurrences,
  THROTTLER_TIMEOUT,
  true,
)

export const fetchTestOccurrencesWithThrottle =
  (
    locator: string,
    options?: RequestTestOccurrenceOptionsParams,
    isBackground?: boolean,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {loading} = getTestOccurrencesFetchable(state, locator)

    if (loading && isBackground === true) {
      return
    }

    dispatch(
      fetchTestOccurrencesThrottler.fetch(
        {
          locator,
          options,
          isBackground,
        },
        !isBackground,
      ),
    )
  }

export type FetchTestScopesArg = {
  scope: TestScopesKeyType
  locator: string
  isBackground?: boolean
}
export const fetchTestScopes = createFetchAction(
  'fetchTestScopes',
  async ({scope, locator}: FetchTestScopesArg, {getState}) => {
    const state = getState()
    const {pageSize} = getPager(state, PagerGroup.TEST_SCOPE)

    const {data, hasMore} = await requestTestScopes(restRoot, scope, locator)
    return {
      data,
      hasMore,
      loadedLessThanRequested: data.length < pageSize,
    }
  },
  {getPendingMeta: ({arg}) => ({isBackground: arg.isBackground})},
)

const fetchTestScopesThrottler = new ActionThrottler(fetchTestScopes, THROTTLER_TIMEOUT, true)
export const fetchTestScopesWithThrottle =
  (scope: TestScopesKeyType, locator: string, isBackground?: boolean): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {loading} = getTestScopesFetchable(state, scope, locator)

    if (loading && isBackground === true) {
      return
    }

    dispatch(
      fetchTestScopesThrottler.fetch(
        {
          scope,
          locator,
          isBackground,
        },
        !isBackground,
      ),
    )
  }

type FetchTestOccurrenceTreeParams = {
  locatorInEndpoint?: boolean
  endpoint: string
  locator: string
  options?: RequestTestOccurrenceOptionsParams
  isBackground?: boolean
}
export const fetchTestOccurrenceTree = createFetchAction(
  'fetchTestOccurrenceTree',
  ({locatorInEndpoint, endpoint, locator, options}: FetchTestOccurrenceTreeParams) =>
    requestTestOccurrencesTree({
      serverUrl: restRoot,
      endpoint,
      options,
      locator: !locatorInEndpoint ? locator : null,
    }).then(data => processTestOccurrenceTree(data, options)),
  {getPendingMeta: ({arg}) => ({isBackground: arg.isBackground})},
)

export const receiveTestOccurrenceTree = ({
  data,
  locator,
  options,
}: {
  data: TestOccurrencesTreeType
  locator: string
  options?: RequestTestOccurrenceOptionsParams
}) =>
  fetchTestOccurrenceTree.fulfilled(processTestOccurrenceTree(data, options), '', {
    endpoint: '',
    options,
    locator,
  })

const fetchTestOccurrenceTreeThrottler = new ActionThrottler(
  fetchTestOccurrenceTree,
  THROTTLER_TIMEOUT,
  true,
)
export const fetchTestOccurrenceTreeWithThrottle =
  (params: FetchTestOccurrenceTreeParams): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {isBackground, locator} = params
    const {loading, ready} = getTestOccurrencesTreeFetchable(state, locator)

    if ((loading && isBackground) || (loading && !ready)) {
      return
    }

    dispatch(fetchTestOccurrenceTreeThrottler.fetch(params, !isBackground))
  }

export type FetchTestOccurrenceSubTreeParamsType = {
  locatorInEndpoint?: boolean
  subTreeRootIdInLocator?: boolean
  endpoint: string
  treeLocator: string
  subTreeRootId: string
  depth: number
  options?: RequestTestOccurrenceOptionsParams
}
export const fetchTestOccurrenceSubtree = createFetchAction(
  'fetchTestOccurrenceSubtree',
  ({
    locatorInEndpoint,
    subTreeRootIdInLocator,
    endpoint,
    treeLocator,
    subTreeRootId,
    options,
  }: FetchTestOccurrenceSubTreeParamsType) => {
    const subtreeLocator = !locatorInEndpoint
      ? [treeLocator, subTreeRootIdInLocator ? `subTreeRootId:${subTreeRootId}` : null]
          .filter(Boolean)
          .join(',')
      : null
    const subTreeRootIdParam = !subTreeRootIdInLocator ? subTreeRootId : null

    return requestTestOccurrencesTree({
      serverUrl: restRoot,
      endpoint,
      locator: subtreeLocator,
      subTreeRootId: subTreeRootIdParam,
      options,
    }).then(data => processTestOccurrencesSubtree({data, options}))
  },
)

const fetchTestOccurrenceSubtreeThrottler = new ActionThrottler(
  fetchTestOccurrenceSubtree,
  THROTTLER_TIMEOUT,
  true,
)
export const fetchTestOccurrenceSubtreeWithThrottle =
  (params: FetchTestOccurrenceSubTreeParamsType & {force?: boolean}): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {force, ...otherParams} = params
    const {treeLocator, subTreeRootId} = params
    const {loading} = getTestOccurrencesSubtreeFetchable(state, treeLocator, subTreeRootId)

    if (!loading) {
      dispatch(fetchTestOccurrenceSubtreeThrottler.fetch(otherParams, force))
    }
  }

export const fetchTestOccurrenceMetadata = createFetchAction(
  'fetchTestOccurrenceMetadata',
  (testOccurrenceId: TestOccurrenceId) =>
    requestTestOccurrenceMetadata(restRoot, testOccurrenceId).then(normalizeTestMetadata),
)

export const receiveTestOccurrenceMetadata = (data: TestOccurrenceType) =>
  fetchTestOccurrenceMetadata.fulfilled(normalizeTestMetadata(data), '', '')

type FetchTestOccurrencesInvocationsArg = {
  locator: string
  invocationsLocator: string
  options?: RequestTestOccurrenceOptionsParams
}
export const fetchTestOccurrencesInvocations = createFetchAction(
  'fetchTestOccurrencesInvocations',
  (params: FetchTestOccurrencesInvocationsArg) => {
    const {locator, invocationsLocator = '', options} = params
    return requestTestOccurrencesInvocations(restRoot, locator, invocationsLocator, options).then(
      data =>
        processTestOccurrencesInvocations({
          data,
          options,
        }),
    )
  },
)

export const receiveTestOccurrencesInvocations = ({
  data,
  locator,
  invocationsLocator,
  options,
}: {
  data: ReadonlyArray<TestOccurrenceType>
  locator: string
  invocationsLocator: string
  options?: RequestTestOccurrenceOptionsParams
}) =>
  fetchTestOccurrencesInvocations.fulfilled(
    processTestOccurrencesInvocations({data, options}),
    '',
    {
      options,
      locator,
      invocationsLocator,
    },
  )

const fetchTestOccurrencesInvocationsThrottler = new ActionThrottler(
  fetchTestOccurrencesInvocations,
  THROTTLER_TIMEOUT,
  true,
)

export const fetchTestOccurrencesInvocationsWithThrottle =
  (
    locator: string,
    invocationsLocator: string = '',
    options?: RequestTestOccurrenceOptionsParams,
    isBackground?: boolean,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {loading} =
      getTestOccurrencesInvocationsFetchable(state, locator, invocationsLocator) ?? {}

    if (loading && isBackground === true) {
      return
    }

    dispatch(
      fetchTestOccurrencesInvocationsThrottler.fetch(
        {
          locator,
          invocationsLocator,
          options,
        },
        !isBackground,
      ),
    )
  }

export const fetchTestOccurrencesCounts = createFetchAction(
  'fetchTestOccurrencesCounts',
  (locator: string) => requestTestOccurrencesCounts(restRoot, locator),
)

export const receiveTestOccurrencesCounts = (data: TestOccurrencesCountsType, locator: string) =>
  fetchTestOccurrencesCounts.fulfilled(data, '', locator)

const fetchTestOccurrencesCountsThrottler = new ActionThrottler(
  fetchTestOccurrencesCounts,
  THROTTLER_TIMEOUT,
  true,
)

export const fetchTestOccurrencesCountsWithThrottle =
  (locator: string, isBackground?: boolean): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {loading} = getTestOccurrencesCountsFetchable(state, locator)

    if (loading && isBackground === true) {
      return
    }

    dispatch(fetchTestOccurrencesCountsThrottler.fetch(locator, !isBackground))
  }

export type FetchFlakyTestsArg = {
  buildId: BuildId
  tests: RequestFlakyTestsType
  extension: UrlExtension<{}>
}
export const fetchFlakyTestsAction = createFetchAction(
  'fetchFlakyTests',
  ({tests, extension}: FetchFlakyTestsArg) =>
    requestFlakyTests(extension.serverUrl ?? base_uri, extension.endpoint, tests),
)
export const fetchFlakyTests =
  (buildId: BuildId, tests: RequestFlakyTestsType, extension?: UrlExtension<any>): AppThunk<any> =>
  dispatch => {
    if (!extension) {
      return
    }

    dispatch(fetchFlakyTestsAction({buildId, tests, extension}))
  }

export const showTestMetadataGraph =
  (
    anchor: EventTarget,
    testNameId: TestId,
    buildId: BuildId,
    escapedName: string,
    buildTypeId: BuildTypeId | null | undefined,
  ): AppThunk<any> =>
  () =>
    buildTypeId != null &&
    BS?.TestMetadata?.showGraph(anchor, testNameId, buildId, escapedName, buildTypeId)

export const assignTestInvestigations =
  (
    projectId: ProjectId,
    tests: ReadonlyArray<TestId>,
    flakyTests: ReadonlyArray<TestId>,
    buildIds: ReadonlyArray<BuildId>,
    fixMode: boolean = false,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () => {
    const testsData: WritableKeyValue<string, string> = {
      projectId: stringifyId(projectId),
    }
    const flakyTestIds: WritableKeyValue<string, boolean> = {}
    tests.forEach(item => {
      const key = stringifyId(item)
      testsData[key] = `${buildIds.join(',')},`
      flakyTestIds[key] = flakyTests.indexOf(item) !== -1
    })
    BS?.BulkInvestigateMuteTestDialog?.show(testsData, fixMode, flakyTestIds, true, submitHandler)
  }

export const fetchTestBuildTypeHierarchy = createFetchAction(
  'fetchTestBuildTypeHierarchy',
  (locator: string) => requestTestBuildTypeHierarchy(base_uri, locator),
)

export const receiveTestBuildTypeHierarchy = (data: BuildTypeHierarchyType, locator: string) =>
  fetchTestBuildTypeHierarchy.fulfilled(data, '', locator)
