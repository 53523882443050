import buildType14pxArrowIcon from '@jetbrains/icons/buildType-14px-arrow.svg'
import buildType14pxFillArrowIcon from '@jetbrains/icons/buildType-14px-fill-arrow.svg'
import buildType14pxFillIcon from '@jetbrains/icons/buildType-14px-fill.svg'
import buildType14pxIcon from '@jetbrains/icons/buildType-14px.svg'
import buildType20pxArrowIcon from '@jetbrains/icons/buildType-20px-arrow.svg'
import buildType20pxFillArrowIcon from '@jetbrains/icons/buildType-20px-fill-arrow.svg'
import buildType20pxFillIcon from '@jetbrains/icons/buildType-20px-fill.svg'
import buildType20pxIcon from '@jetbrains/icons/buildType-20px.svg'
import buildTypeComposite14pxArrowIcon from '@jetbrains/icons/buildType-composite-14px-arrow.svg'
import buildTypeComposite14pxFillArrowIcon from '@jetbrains/icons/buildType-composite-14px-fill-arrow.svg'
import buildTypeComposite14pxFillIcon from '@jetbrains/icons/buildType-composite-14px-fill.svg'
import buildTypeComposite14pxIcon from '@jetbrains/icons/buildType-composite-14px.svg'
import buildTypeComposite20pxArrowIcon from '@jetbrains/icons/buildType-composite-20px-arrow.svg'
import buildTypeComposite20pxFillArrowIcon from '@jetbrains/icons/buildType-composite-20px-fill-arrow.svg'
import buildTypeComposite20pxFillIcon from '@jetbrains/icons/buildType-composite-20px-fill.svg'
import buildTypeComposite20pxIcon from '@jetbrains/icons/buildType-composite-20px.svg'
import pausedOutline14pxIcon from '@jetbrains/icons/paused-outline-14px.svg'
import project14pxArrowIcon from '@jetbrains/icons/project-14px-arrow.svg'
import project14pxFillArrowIcon from '@jetbrains/icons/project-14px-fill-arrow.svg'
import project14pxFillIcon from '@jetbrains/icons/project-14px-fill.svg'
import project14pxIcon from '@jetbrains/icons/project-14px.svg'
import project20pxArrowIcon from '@jetbrains/icons/project-20px-arrow.svg'
import project20pxFillArrowIcon from '@jetbrains/icons/project-20px-fill-arrow.svg'
import project20pxFillIcon from '@jetbrains/icons/project-20px-fill.svg'
import project20pxIcon from '@jetbrains/icons/project-20px.svg'
import template14pxArrowIcon from '@jetbrains/icons/template-14px-arrow.svg'
import template14pxIcon from '@jetbrains/icons/template-14px.svg'
import template20pxArrowIcon from '@jetbrains/icons/template-20px-arrow.svg'
import template20pxIcon from '@jetbrains/icons/template-20px.svg'
import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'
import * as React from 'react'

import {KeyValue} from '../../utils/object'
import SvgIcon from '../common/SvgIcon/SvgIcon'

import {ProjectOrBuildTypeIconType, Sizes, types} from './ProjectOrBuildTypeIcon.types'
import type {DefaultProps, Props} from './ProjectOrBuildTypeIcon.types'

import styles from './ProjectOrBuildTypeIcon.css'

const Icons: KeyValue<string, Icon> = {
  'buildType-14px-arrow': buildType14pxArrowIcon,
  'buildType-14px-fill-arrow': buildType14pxFillArrowIcon,
  'buildType-14px-fill': buildType14pxFillIcon,
  'buildType-14px': buildType14pxIcon,
  'buildType-20px-arrow': buildType20pxArrowIcon,
  'buildType-20px-fill-arrow': buildType20pxFillArrowIcon,
  'buildType-20px-fill': buildType20pxFillIcon,
  'buildType-20px': buildType20pxIcon,
  'buildType-composite-14px-arrow': buildTypeComposite14pxArrowIcon,
  'buildType-composite-14px-fill-arrow': buildTypeComposite14pxFillArrowIcon,
  'buildType-composite-14px-fill': buildTypeComposite14pxFillIcon,
  'buildType-composite-14px': buildTypeComposite14pxIcon,
  'buildType-composite-20px-arrow': buildTypeComposite20pxArrowIcon,
  'buildType-composite-20px-fill-arrow': buildTypeComposite20pxFillArrowIcon,
  'buildType-composite-20px-fill': buildTypeComposite20pxFillIcon,
  'buildType-composite-20px': buildTypeComposite20pxIcon,
  'project-14px-arrow': project14pxArrowIcon,
  'project-14px-fill-arrow': project14pxFillArrowIcon,
  'project-14px-fill': project14pxFillIcon,
  'project-14px': project14pxIcon,
  'project-20px-arrow': project20pxArrowIcon,
  'project-20px-fill-arrow': project20pxFillArrowIcon,
  'project-20px-fill': project20pxFillIcon,
  'project-20px': project20pxIcon,
  'template-14px-arrow': template14pxArrowIcon,
  'template-14px': template14pxIcon,
  'template-20px-arrow': template20pxArrowIcon,
  'template-20px': template20pxIcon,
  'paused-outline-14px': pausedOutline14pxIcon,
}

const getIcon = (name: string) => Icons[name] ?? name

const primaryTypes = new Set<ProjectOrBuildTypeIconType>([types.project, types.buildType])

class ProjectOrBuildTypeIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    type: 'project',
    size: 'S',
  }

  render(): React.ReactNode {
    const {type, composite, status, size, className, arrowClassName, title, withArrow} = this.props

    if (type == null) {
      return null
    }

    const typeIsInvestigation = type === types.investigation
    const typeIsPaused = type === types.paused || type === types.pausedOutline
    const typeIsPrimary = primaryTypes.has(type)
    const classes = classNames(
      styles.icon,
      styles[`size_${size}`],
      className,
      (typeIsPrimary || typeIsInvestigation || typeIsPaused) && status && styles[status],
    )
    const arrowClasses = classNames(classes, styles.arrow, arrowClassName)
    const name = [
      type,
      type === 'buildType' && composite === true && 'composite',
      `${Sizes[size]}px`,
      typeIsPrimary && status === 'failed' && 'fill',
    ]
      .filter(Boolean)
      .join('-')
    return (
      <>
        <SvgIcon className={classes} icon={getIcon(name)} title={title} />
        {(withArrow || arrowClassName != null) && (
          <SvgIcon className={arrowClasses} icon={getIcon(`${name}-arrow`)} />
        )}
      </>
    )
  }
}

export default ProjectOrBuildTypeIcon
