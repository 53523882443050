import memoize from '@jetbrains/ring-ui/components/global/memoize'

import {stringifyId} from '../types'
import type {BranchType, ProjectId} from '../types'
import {BS} from '../types/BS_types'

export const DEFAULT_NAME = '<default>'
export const defaultBranch: BranchType = {
  default: true,
  name: DEFAULT_NAME,
}
export const stringifyBranch = (branch: BranchType | null | undefined): string => {
  if (!branch) {
    return ''
  }

  return BS && BS.Branch
    ? BS.Branch.stringifyBranch(branch)
    : branch.default === true
    ? DEFAULT_NAME
    : branch.name
}
export const defaultStringifiedBranch: string = stringifyBranch(defaultBranch)
const memoizedParse: (arg0: string | null | undefined) => BranchType | null | undefined = memoize(
  (name: string | null | undefined) =>
    BS && BS.Branch
      ? BS.Branch.parseBranch(name)
      : name != null
      ? {
          name,
          default: name === DEFAULT_NAME,
        }
      : null,
)
export const parseBranch = (name: string | null | undefined): BranchType | null | undefined =>
  name != null ? memoizedParse(name) : null
export const getBranchParam = (projectId: ProjectId | null | undefined): string =>
  projectId ? `branch_${stringifyId(projectId)}` : 'branch'
