import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {castDraft} from 'immer'

import isEqual from 'lodash/isEqual'
import pull from 'lodash/pull'
import sortBy from 'lodash/sortBy'
import {shallowEqual} from 'react-redux'

import {BuildType, Project, Property, restApi, Step} from '../../../../services/rest'
import {BuildTypeId} from '../../../../types'

type Draft = Project & {
  isSaving?: boolean
  deleted?: {
    jobs?: BuildTypeId[]
  }
}

type PipelineDraftState = Record<string, Draft | undefined>

function getJob(state: PipelineDraftState, pipelineId: string, jobId: string) {
  return state[pipelineId]?.buildTypes?.buildType?.find(job => job.id === jobId)
}

function getOrCreateJob(state: PipelineDraftState, pipelineId: string, jobId: string) {
  state[pipelineId] ??= {}
  state[pipelineId]!.buildTypes ??= {
    buildType: [],
  }
  let job = getJob(state, pipelineId, jobId)
  if (job == null) {
    job = {id: jobId}
    state[pipelineId]!.buildTypes!.buildType!.push(job)
  }
  return job
}

function deletePipelineIfEmpty(state: PipelineDraftState, pipelineId: string) {
  if (Object.keys(state[pipelineId]!).length === 0) {
    delete state[pipelineId]
  }
}

function deleteJobIfEmpty(state: PipelineDraftState, pipelineId: string, job: BuildType) {
  // "id" is always present
  if (Object.keys(job).length === 1) {
    pull(state[pipelineId]!.buildTypes!.buildType!, job)
    if (state[pipelineId]!.buildTypes!.buildType!.length === 0) {
      delete state[pipelineId]!.buildTypes
      deletePipelineIfEmpty(state, pipelineId)
    }
  }
}

export const pipelineDraft = createSlice({
  name: 'pipelineDraft',
  initialState: {} as PipelineDraftState,
  reducers: {
    reset(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setIsSaving(state, action: PayloadAction<string>) {
      const id = action.payload
      state[id] ??= {}
      state[id]!.isSaving = true
    },
    setPipelineName(state, action: PayloadAction<{value: string; savedValue: string; id: string}>) {
      const {value, savedValue, id} = action.payload
      if (value === savedValue) {
        if (state[id] != null) {
          delete state[id]!.name
          deletePipelineIfEmpty(state, id)
        }
      } else {
        state[id] ??= {}
        state[id]!.name = value
      }
    },
    setJobName(
      state,
      action: PayloadAction<{
        value: string
        savedValue: string
        id: string
        pipelineId: string
      }>,
    ) {
      const {value, savedValue, id, pipelineId} = action.payload
      if (value === savedValue) {
        const job = getJob(state, pipelineId, id)
        if (job != null) {
          delete job.name
          deleteJobIfEmpty(state, pipelineId, job)
        }
      } else {
        getOrCreateJob(state, pipelineId, id).name = value
      }
    },
    addBuildType: (state, action: PayloadAction<{value: BuildType; projectId: string}>) => {
      const {value, projectId} = action.payload
      const job = getOrCreateJob(state, projectId, value.id!)
      Object.assign(job, value)
    },
    setDependencies(
      state,
      action: PayloadAction<{
        jobId: string
        dependencies: string[]
        savedDependencies: readonly string[]
        pipelineId: string
      }>,
    ) {
      const {jobId, dependencies, savedDependencies, pipelineId} = action.payload
      if (shallowEqual([...dependencies].sort(), [...savedDependencies].sort())) {
        const job = getJob(state, pipelineId, jobId)
        if (job != null) {
          delete job['snapshot-dependencies']
          deleteJobIfEmpty(state, pipelineId, job)
        }
      } else {
        getOrCreateJob(state, pipelineId, jobId)['snapshot-dependencies'] = {
          'snapshot-dependency': dependencies.map(id => ({id})),
        }
      }
    },
    setParameters(
      state,
      action: PayloadAction<{
        jobId: string
        parameters: readonly Property[]
        savedParameters: readonly Property[]
        pipelineId: string
      }>,
    ) {
      const {jobId, parameters, savedParameters, pipelineId} = action.payload
      if (
        isEqual(
          sortBy(parameters, item => item.name),
          sortBy(savedParameters, item => item.name),
        )
      ) {
        const job = getJob(state, pipelineId, jobId)
        if (job != null) {
          delete job.parameters
          deleteJobIfEmpty(state, pipelineId, job)
        }
      } else {
        getOrCreateJob(state, pipelineId, jobId).parameters = {property: castDraft(parameters)}
      }
    },
    setSteps(
      state,
      action: PayloadAction<{
        jobId: string
        steps: readonly Step[]
        savedSteps: readonly Step[] | undefined
        pipelineId: string
      }>,
    ) {
      const {jobId, steps, savedSteps, pipelineId} = action.payload
      if (isEqual(steps, savedSteps)) {
        const job = getJob(state, pipelineId, jobId)
        if (job != null) {
          delete job.steps
          deleteJobIfEmpty(state, pipelineId, job)
        }
      } else {
        getOrCreateJob(state, pipelineId, jobId).steps = {step: castDraft(steps)}
      }
    },
    deleteJob(state, action: PayloadAction<{jobId: string; pipelineId: string}>) {
      const {jobId, pipelineId} = action.payload
      state[pipelineId] ??= {}
      state[pipelineId]!.deleted ??= {}
      state[pipelineId]!.deleted!.jobs ??= []
      state[pipelineId]!.deleted!.jobs!.push(jobId)
    },
    restoreJob(state, action: PayloadAction<{jobId: string; pipelineId: string}>) {
      const {jobId, pipelineId} = action.payload
      const deleted = state[pipelineId]?.deleted
      if (deleted?.jobs?.includes(jobId)) {
        deleted.jobs = deleted.jobs.filter(id => id !== jobId)
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(restApi.endpoints.getProject.matchFulfilled, (state, action) => {
      const [_, id] = action.meta.arg.originalArgs.projectLocator.match(/^id:(.*)$/) ?? []
      if (state[id]?.isSaving) {
        delete state[id]
      }
    })
  },
})

export const hoveredJob = createSlice({
  name: 'hoveredJob',
  initialState: null as string | null,
  reducers: {
    set: (_, action: PayloadAction<string | null>) => action.payload,
  },
})
