import {History} from 'history'
import * as React from 'react'
import {startTransition} from 'react'
import {useDispatch} from 'react-redux'
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom'

import {preloadRoute} from '../components/App/App.routes'

import {browserHistory, HistoryContext} from '.'

type HistoryProviderProps = {
  readonly history?: History
  readonly children: React.ReactNode
}
export default function HistoryProvider({
  history = browserHistory,
  children,
}: HistoryProviderProps): React.ReactElement {
  const dispatch = useDispatch()
  const patchedHistory: History = {
    ...history,
    get action() {
      return history.action
    },
    get location() {
      return history.location
    },
    push(to, state) {
      if (!state?.isQuerySync) {
        dispatch(preloadRoute(to, history.location))
      }
      startTransition(() => history.push(to, state))
    },
    replace(to, state) {
      if (!state?.isQuerySync) {
        dispatch(preloadRoute(to, history.location))
      }
      startTransition(() => history.replace(to, state))
    },
  }
  return (
    <HistoryContext.Provider value={patchedHistory}>
      <HistoryRouter history={patchedHistory}>{children}</HistoryRouter>
    </HistoryContext.Provider>
  )
}
