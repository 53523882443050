import Button from '@jetbrains/ring-ui/components/button/button'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {dialog} from '../../slices'
import {DialogType, ProjectId, ROOT_PROJECT_ID} from '../../types'
import IconButton from '../common/IconButton/IconButton'
import {useSequenceLoaderReadyHandler} from '../common/SequenceLoader/SequenceLoader.hooks'
import {HINT_CATEGORY, stringifyHintId, toHintId} from '../Hints/Hints.types'
import type {HintType} from '../Hints/Hints.types'
import useHint from '../Hints/useHint'

import styles from './EditProjectSidebar.css'

const configureSidebarHint: HintType = {
  id: toHintId('configure-project-sidebar'),
  name: 'Configure sidebar',
  text: 'Reorder your favorite projects.',
  category: HINT_CATEGORY.sidebar,
  className: styles.configureSidebarHint,
  directions: [Directions.BOTTOM_RIGHT],
}
type OwnProps = {
  readonly button?: boolean | null | undefined
  readonly projectId?: ProjectId | undefined
  readonly seqKey?: string
}
export const openEditDialog = (projectId: ProjectId = ROOT_PROJECT_ID) =>
  dialog.actions.open(projectId, DialogType.EDIT_SIDEBAR)
export default function EditProjectSidebar({button, projectId}: OwnProps) {
  useSequenceLoaderReadyHandler(true)
  useHint(configureSidebarHint)
  const dispatch = useDispatch()
  const openDialog = useCallback(() => dispatch(openEditDialog(projectId)), [dispatch, projectId])
  return button === true ? (
    <Button
      data-hint-container-id={stringifyHintId(configureSidebarHint.id)}
      className={styles.button}
      onClick={openDialog}
    >
      {`Configure favorites...`}
    </Button>
  ) : (
    <IconButton
      data-hint-container-id={stringifyHintId(configureSidebarHint.id)}
      icon="pencil"
      onClick={openDialog}
      title="Configure favorites"
    />
  )
}
