import {createReducer} from '@reduxjs/toolkit'

import {fetchTestOccurrences} from '../../../Tests/Tests.actions'
import {submitPager} from '../Pager.actions.base'
import type {PagerType} from '../Pager.types'
import {PagerGroup} from '../Pager.types'

const defaultPager = {
  hasMore: true,
  loadedLessThanRequested: null,
  openTotal: false,
  pageSize: 100,
  total: 0,
}

const pagerReducer = createReducer<PagerType>(defaultPager, builder => {
  builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
    if (action.meta.arg.options?.withUpdatePager === true) {
      const actionData = action.payload.data

      if (actionData == null) {
        state.total = 0
        state.openTotal = false
        state.show = false
        return
      }

      const data = actionData.result
      const {hasMore, loadedLessThanRequested} = action.payload || {}
      state.total = data.length
      state.hasMore = hasMore
      state.openTotal = hasMore === true && !loadedLessThanRequested
      state.loadedLessThanRequested = loadedLessThanRequested
    }
  })
  builder.addCase(submitPager, (state, action) => {
    if (action.meta.group === PagerGroup.TEST) {
      Object.assign(state, action.payload)
    }
  })
})

export default pagerReducer
