import Button from '@jetbrains/ring-ui/components/button/button'
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog'
import {ControlsHeight} from '@jetbrains/ring-ui/components/global/controls-height'
import {H2} from '@jetbrains/ring-ui/components/heading/heading'
import {Content} from '@jetbrains/ring-ui/components/island/island'
import Panel from '@jetbrains/ring-ui/components/panel/panel'
import classNames from 'classnames'
import * as React from 'react'

import SakuraReleaseImage from '../../svg/sakura-release-image.svg'

import {aboutSakuraUIHref} from '../HelpDropdown/HelpDropdown.consts'

import styles from './SakuraRelease.popup.css'

const benefits = [
  'Expandable UI elements for getting the required level of detail',
  'Responsive build log page for manipulating large log files',
  'Accessible design that facilitates keyboard navigation',
  'Sidebar for easily navigating through many entities',
  'Dependencies timeline for tracking the pipeline',
]

const PopupContent = React.memo(() => <></>)
PopupContent.displayName = 'PopupContent'

export function SakuraReleasePopup({
  show,
  onCloseAttempt,
  href,
}: {
  show: boolean
  onCloseAttempt: () => void
  href: string
}) {
  return (
    <Dialog
      show={show}
      showCloseButton
      onCloseAttempt={onCloseAttempt}
      contentClassName={styles.wrapper}
      autoFocusFirst={false}
    >
      <Content>
        <div className={styles.content}>
          <div className={styles.description}>
            <H2 className={styles.header}>{'The Sakura UI is Ready!'}</H2>
            {benefits.map(benefit => (
              <p className={styles.text} key={benefit}>
                {benefit}
              </p>
            ))}
            <div className={styles.controls}>
              <Button className={styles.button} primary href={href} height={ControlsHeight.M}>
                {'Switch to the Sakura UI'}
              </Button>
              <Button
                className={classNames(styles.button, styles.link)}
                href={aboutSakuraUIHref}
                target={'_blank'}
                rel="noreferrer"
                height={ControlsHeight.M}
              >
                {'Read the full article'}
              </Button>
            </div>
          </div>
          <SakuraReleaseImage className={styles.image} />
        </div>
      </Content>
      <Panel />
    </Dialog>
  )
}
