import {createFetchAction} from '../../../reducers/fetchable'
import {getExtensionEndpoint} from '../../../selectors'
import type {BuildId} from '../../../types'
import {base_uri} from '../../../types/BS_types'

import {
  requestBuildsForCompare,
  requestCompareBuildsInfo,
  requestCompareBuildsList,
} from './CompareBuildsPage.rest'
import {settings} from './CompareBuildsPage.slices'

export const setShowChangedOnly = () => settings.actions.setShowChangedOnly(true)
export const setShowAll = () => settings.actions.setShowChangedOnly(false)

type FetchCompareBuildsListArg = {
  readonly sourceId: BuildId
  readonly compareWithId: BuildId
}
export const fetchCompareBuildsListAction = createFetchAction(
  'compareBuilds.fetchCompareBuildsList',
  ({sourceId, compareWithId}: FetchCompareBuildsListArg, {getState}) => {
    const extension = getExtensionEndpoint(getState(), 'builds-diff')! // checked in condition

    return requestCompareBuildsList(
      extension.serverUrl ?? base_uri,
      extension.endpoint,
      sourceId,
      compareWithId,
    )
  },
  {
    condition(_, {getState}) {
      const extension = getExtensionEndpoint(getState(), 'builds-diff')
      return extension != null && !window.GLOBAL_FETCH_DISABLED
    },
  },
)
export const fetchCompareBuildsList = (sourceId: BuildId, compareWithId: BuildId) =>
  fetchCompareBuildsListAction({sourceId, compareWithId})

type FetchCompareBuildsInfoArg = {
  readonly compareType: string
  readonly sourceId: BuildId
  readonly compareWithId: BuildId | null | undefined
  readonly showChangedOnly?: boolean
  readonly filterText?: string | null | undefined
}
export const fetchCompareBuildsInfoAction = createFetchAction(
  'compareBuilds.fetchCompareBuildsInfo',
  (
    {compareType, sourceId, compareWithId, showChangedOnly, filterText}: FetchCompareBuildsInfoArg,
    {getState},
  ) => {
    const extension = getExtensionEndpoint(getState(), 'builds-diff')! // checked in condition

    return requestCompareBuildsInfo(
      extension.serverUrl ?? base_uri,
      extension.endpoint,
      compareType,
      sourceId,
      compareWithId!, // checked in condition
      showChangedOnly ? 'changed' : 'all',
      filterText?.trim(),
    )
  },
  {
    condition({compareWithId}, {getState}) {
      const extension = getExtensionEndpoint(getState(), 'builds-diff')

      return extension != null && !window.GLOBAL_FETCH_DISABLED && compareWithId != null
    },
  },
)
export const fetchCompareBuildsInfo = (
  compareType: string,
  sourceId: BuildId,
  compareWithId: BuildId | null | undefined,
  showChangedOnly: boolean,
  filterText?: string | null | undefined,
) =>
  fetchCompareBuildsInfoAction({compareType, sourceId, compareWithId, showChangedOnly, filterText})
export const fetchBuildsForCompare = createFetchAction(
  'compareBuilds.fetchBuildsForCompare',
  (buildId: BuildId, {getState}) => {
    const extension = getExtensionEndpoint(getState(), 'builds-diff')! // checked in condition

    return requestBuildsForCompare(extension.serverUrl ?? base_uri, extension.endpoint, buildId)
  },
  {
    condition(_, {getState}) {
      const extension = getExtensionEndpoint(getState(), 'builds-diff')

      return extension != null && !window.GLOBAL_FETCH_DISABLED
    },
  },
)
