import type {BranchType, BuildId, BuildTypeId, FilterOptionsType} from '../types'
import {stringifyId} from '../types'
import escapeLocatorDimension from '../utils/escapeLocatorDimension'

export const getBranchLocatorPart = (
  branch?: BranchType | null | undefined,
  inPath?: boolean,
): string | null | undefined => {
  if (branch == null || branch.wildcard === true) {
    return 'default:any'
  }

  if (branch.default === true) {
    return 'default:true'
  }

  if (branch.groupFlag === true && branch.internalName != null) {
    return `group:(${branch.internalName})`
  }

  if (branch.name && branch.name.trim().length > 0) {
    return `name:(matchType:equals,value:${escapeLocatorDimension(branch.name, inPath)})`
  }

  return null
}
export const getBranchLocator = (
  branch?: BranchType | null | undefined,
  inPath?: boolean,
): string => {
  const branchLocatorPart = getBranchLocatorPart(branch, inPath)

  if (branchLocatorPart == null) {
    return ''
  }

  if (
    branch == null ||
    branch.wildcard === true ||
    branch.default === true ||
    branch.groupFlag === true
  ) {
    return `branch:(${branchLocatorPart})`
  }

  return `branch:(policy:ALL_BRANCHES,${branchLocatorPart})`
}
export const getBuildTypeAndBranchLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  branch: BranchType | null | undefined,
): string =>
  [`buildType:(id:${stringifyId(buildTypeId)})`, getBranchLocator(branch)].filter(Boolean).join(',')
export const getBuildLocator = (id: BuildId): string => `id:${stringifyId(id)}`
export const getBuildStatsLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  branch: BranchType | null | undefined,
  count: number,
): string => {
  const buildTypeAndBranchLocator = getBuildTypeAndBranchLocator(buildTypeId, branch)
  return `item:(${buildTypeAndBranchLocator},history:false,state:(running:true,finished:true),count:${count})`
}
export const getBuildStatsAfterAndBeforeBuildLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  buildId: BuildId,
  branch: BranchType | null | undefined,
  count: number,
): string => {
  const buildTypeAndBranchLocator = getBuildTypeAndBranchLocator(buildTypeId, branch)
  const stringifyBuildId = stringifyId(buildId)
  return [
    `item:(${buildTypeAndBranchLocator},untilBuild(id:${stringifyBuildId}),defaultFilter:false,state:(running:true,finished:true),history:false,count:${count})`,
    `item:(${stringifyBuildId})`,
    `item:(${buildTypeAndBranchLocator},sinceBuild:(id:${stringifyBuildId}),defaultFilter:false,state:(running:true,finished:true),history:false,count:${count})`,
  ].join(',')
}

export const getChangesLocator = (
  params: FilterOptionsType & {
    readonly baseLocator: string
    readonly unique?: boolean
    readonly currentPage?: number | null
    readonly count?: number
  },
): string => {
  const {
    versionedSettings = true,
    userId,
    username,
    comment = '',
    path = '',
    revision = '',
    projectId,
    unique,
    showDependenciesChanges,
    baseLocator,
    currentPage,
    count,
  } = params ?? {}
  return [
    baseLocator,
    unique != null ? `unique:${unique}` : '',
    versionedSettings ? '' : 'versionedSettings:false',
    projectId != null ? `affectedProject:(id:${projectId})` : '',
    userId != null && userId !== '' ? `user:(id:${stringifyId(userId)})` : '',
    username != null && username !== '' ? `username:${username}` : '',
    showDependenciesChanges != null ? `changesFromDependencies:${showDependenciesChanges}` : '', // TODO fix this when the https://youtrack.jetbrains.com/issue/TW-73600/Make-it-possible-to-get-changes-from-dependencies-without-changes-in-current-build issue is fixed
    comment !== ''
      ? `comment:(value:${escapeLocatorDimension(comment)},matchType:contains,ignoreCase:true)`
      : '',
    path !== ''
      ? `file:path:(value:${escapeLocatorDimension(path)},matchType:contains,ignoreCase:true)`
      : '',
    revision !== ''
      ? `version:(value:${escapeLocatorDimension(revision)},matchType:contains,ignoreCase:true)`
      : '',
    count != null ? `count:${count}` : '',
    count != null && currentPage != null ? `start:${count * (currentPage - 1)}` : '',
  ]
    .filter(Boolean)
    .join(',')
}
