import {fetchBuildsData} from '../../../../../actions/builds'
import type {AppThunk} from '../../../../../actions/types'
import {createFetchAction} from '../../../../../reducers/fetchable'
import {restRoot} from '../../../../../rest/consts'
import type {BuildTypeId} from '../../../../../types'

import {getLastDeployedBuildLocator, requestPossibleDeployments} from './Deployments.rest'
import type {LastDeployedBuildConfig} from './Deployments.types'

export const fetchPossibleDeployments = createFetchAction(
  'fetchPossibleDeployments',
  (buildTypeId: BuildTypeId) => requestPossibleDeployments(restRoot, buildTypeId),
)
export const fetchLastDeployedBuild = (
  config: LastDeployedBuildConfig,
): AppThunk<Promise<unknown>> =>
  fetchBuildsData({
    locator: getLastDeployedBuildLocator(config),
    requestOptions: {
      withQueuedInfo: true,
    },
  })
