import {createFetchAction} from '../../../../reducers/fetchable'
import {saveBuildsComment} from '../../../../rest/builds'
import {restRoot} from '../../../../rest/consts'
import type {BuildId} from '../../../../types'

export type SaveCommentArg = {
  buildIds: readonly BuildId[]
  text: string
}
export const saveComment = createFetchAction('saveComment', ({buildIds, text}: SaveCommentArg) =>
  saveBuildsComment(restRoot, buildIds, text),
)
