import {createFetchAction} from '../../../../reducers/fetchable'
import {restRoot} from '../../../../rest/consts'
import {ProjectId} from '../../../../types'

import {InvestigationsCounter} from './ProjectInvesigations.types'
import {requestCountTestProjectInvestigations} from './ProjectInvestigations.rest'

export const fetchProjectInvestigations = createFetchAction(
  'fetchProjectInvestigations',
  (projectId: ProjectId) => requestCountTestProjectInvestigations(restRoot, projectId),
)

export const receiveTestsInvestigationsOccurrencesCounts = (
  data: InvestigationsCounter,
  projectId: ProjectId,
) => fetchProjectInvestigations.fulfilled(data, '', projectId)
