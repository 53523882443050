import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {castDraft} from 'immer'

import {saveComment} from '../components/common/BuildActionsDropdown/CommentDialog/CommentDialog.actions'
import {pinBuildFromDialog} from '../components/common/BuildActionsDropdown/PinDialog/PinDialog.actions'
import {
  addBuildsTags,
  changeBuildTagsAction,
} from '../components/common/BuildActionsDropdown/TagDialog/TagDialog.actions'
import {BuildId} from '../types'
import {KeyValue} from '../utils/object'

export const buildSelections = createSlice({
  name: 'buildSelections',
  initialState: {} as KeyValue<string, readonly BuildId[]>,
  reducers: {
    select: (state, action: PayloadAction<{selectionId: string; buildId: BuildId}>) => {
      const {selectionId, buildId} = action.payload
      state[selectionId] ??= []
      if (!state[selectionId]!.includes(buildId)) {
        state[selectionId]!.push(buildId)
      }
    },
    deselect: (state, action: PayloadAction<{selectionId: string; buildId: BuildId}>) => {
      const {selectionId, buildId} = action.payload
      state[selectionId] ??= []
      if (state[selectionId]!.includes(buildId)) {
        state[selectionId] = state[selectionId]!.filter(id => id !== buildId)
      }
    },
    setSelection: (
      state,
      action: PayloadAction<{selectionId: string; selection: readonly BuildId[]}>,
    ) => {
      const {selectionId, selection} = action.payload
      state[selectionId] = castDraft(selection)
    },
  },
  extraReducers: builder =>
    builder.addMatcher(
      action =>
        changeBuildTagsAction.fulfilled.match(action) ||
        saveComment.fulfilled.match(action) ||
        pinBuildFromDialog.fulfilled.match(action) ||
        addBuildsTags.fulfilled.match(action),
      () => ({}),
    ),
})
