import * as Redux from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {emptyArray} from '../../../utils/empty'

import {fetchBuildArtifactDependenciesChanges} from './ArtifactChanges.actions'
import {ArtifactChangesStateType} from './ArtifactChanges.types'

const artifactChanges = Redux.combineReducers<ArtifactChangesStateType>({
  buildArtifactDependencyChangesByLocator: keyValueFetchable(
    arg => arg,
    fetchBuildArtifactDependenciesChanges,
    emptyArray,
    (_, action) => action.payload.result,
  ),
})

export default artifactChanges
