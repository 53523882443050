import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getBuild, getBuildType, getProjectLinks} from '../../../../selectors'
import type {BuildId} from '../../../../types'
import {resolveWebEntityEditLink} from '../../../../utils/entityLinks'
import Link from '../../Link/Link'

import {fetchProjectLinksIfNeeded} from './BuildArtifactStorageInfo.actions'

import {useArtifactStorage} from './BuildArtifactStorageInfo.hooks'

import styles from './BuildArtifactStorageInfo.css'

type Props = {
  readonly buildId: BuildId
}
export default function BuildArtifactStorageInfo({buildId}: Props) {
  const projectId = useSelector(state => {
    const buildTypeId = getBuild(state, buildId)?.buildType
    return getBuildType(state, buildTypeId)?.projectId
  })
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (projectId != null) {
      dispatch(fetchProjectLinksIfNeeded(projectId))
    }
  }, [dispatch, projectId])
  const editHref = useSelector(state => {
    const links = getProjectLinks(state, projectId)
    return resolveWebEntityEditLink(links)
  })
  const storage = useArtifactStorage(buildId)
  const {name, type} = storage

  if (name.length === 0 && type.length === 0) {
    return null
  }

  const formattedName = name.length > 0 ? `${name} (${type})` : type
  return (
    <div className={styles.info}>
      {'Some artifacts of this build were published using '}
      {editHref != null ? (
        <Link href={`${editHref}&tab=artifactsStorage`}>{formattedName}</Link>
      ) : (
        formattedName
      )}
    </div>
  )
}
