import {safeLocalStorage} from '../../utils/safeStorages'

import type {AgentsTreeExpandState} from './AgentsScreen.types'
import {mergeExpandState} from './AgentsScreen.utils'
import {
  DETACHED_AGENTS_SELECTION_ID,
  UNAUTHORIZED_AGENTS_SELECTION_ID,
} from './AgentsSidebar/AgentsSidebar.types'

export type AgentsTreeExpandStateKeyType = 'agentsSidebarExpandState' | 'agentsOverviewExpandState'
export const EXPAND_SIDEBAR_STATE_KEY: AgentsTreeExpandStateKeyType = 'agentsSidebarExpandState'
export const EXPAND_AGENTS_OVERVIEW_STATE_KEY: AgentsTreeExpandStateKeyType =
  'agentsOverviewExpandState'
const EMPTY_EXPAND_STATE: AgentsTreeExpandState = {
  ['agent-selection']: {
    [UNAUTHORIZED_AGENTS_SELECTION_ID]: false,
    [DETACHED_AGENTS_SELECTION_ID]: false,
  },
  ['agent-pool']: {},
  ['agent-type']: {},
}
export const getStoredAgentsTreeExpandState: (
  arg0: AgentsTreeExpandStateKeyType,
) => AgentsTreeExpandState = key => safeLocalStorage.getItemInJSON(key) || EMPTY_EXPAND_STATE
export const setStoredExpandState: (
  arg0: AgentsTreeExpandStateKeyType,
  arg1: AgentsTreeExpandState,
) => void = (key, expandState) =>
  safeLocalStorage.setItem(
    key,
    JSON.stringify(mergeExpandState(getStoredAgentsTreeExpandState(key), expandState)),
  )
