import {useDispatch, useSelector} from 'react-redux'

import {useLazyLoading} from '../../../hocs/withLazyLoading'
import {
  getBuild,
  getHasArtifacts,
  getHasArtifactsState,
  getHiddenArtifactsLoading,
} from '../../../selectors'
import {BuildId} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'
import {Namespaces} from '../../../utils/namespaces'
import {getArtifactsHref} from '../BuildArtifactsTree/BuildArtifactsTree.container'

import BuildArtifacts from './BuildArtifacts'
import {fetchBuildArtifactsCountQueue} from './BuildArtifacts.actions'
import type {
  OwnProps,
  UseBuildArtifactsOutput,
  UseBuildArtifactsParams,
} from './BuildArtifacts.types'

const useBuildArtifacts = ({
  buildId,
  childUsesVisibility,
}: UseBuildArtifactsParams): UseBuildArtifactsOutput => {
  const hasArtifacts = useSelector(state => getHasArtifacts(state, buildId))
  const needsDetails = useSelector(state => {
    const build = getBuild(state, buildId)
    return (
      build != null &&
      (hasArtifacts == null ||
        (!hasArtifacts && getHasArtifactsState(state, buildId) !== build.state))
    )
  })
  const dispatch = useDispatch()
  const lazyLoadingProps = useLazyLoading<BuildId, HTMLSpanElement>({
    namespace: Namespaces.ARTIFACTS,
    childUsesVisibility,
    ignoreFetcher: true,
    id: buildId,
    needsDetails,
    fetchDetails: id => dispatch(fetchBuildArtifactsCountQueue(id)),
    defaultVisible: false,
  })
  return {...lazyLoadingProps, hasArtifacts}
}
export default function BuildArtifactsContainer(props: OwnProps) {
  const {buildId} = props
  const hasArtifacts = useSelector(state => getHasArtifacts(state, buildId))
  const href = useSelector(state =>
    getArtifactsHref(resolveWebEntityLink(getBuild(state, buildId)), !hasArtifacts),
  )
  const isLoading = href == null || hasArtifacts == null
  const addProps = useBuildArtifacts({
    buildId,
    childUsesVisibility: isLoading,
  })
  const isLoadingHiddenArtifacts = useSelector(state =>
    getHiddenArtifactsLoading(state, buildId, ''),
  )
  return (
    <BuildArtifacts
      {...props}
      {...addProps}
      href={href}
      isLoading={isLoading}
      isLoadingHiddenArtifacts={isLoadingHiddenArtifacts}
    />
  )
}
