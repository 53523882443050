import Button from '@jetbrains/ring-ui/components/button/button'
import classnames from 'classnames'
import plur from 'plur'
import * as React from 'react'

import {DialogType, stringifyId} from '../../../types'
import SvgIcon from '../SvgIcon/SvgIcon'

import TagFilter from './TagFilter/TagFilter'
import type {ChildProps, DefaultProps, Props, State} from './TagsList.types'

import styles from './TagsList.css'

const MIN_SHOWN = 5
const MAX_SHOWN_DIRECTLY = 7

function noop() {}

class TagsList extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    editable: false,
    withIcon: false,
    children: ({tag, selected, tagClasses, buildTypeId, onSelect}: ChildProps) => (
      <TagFilter
        key={tag}
        label={tag}
        buildTypeId={buildTypeId}
        selected={tag === selected}
        onSelect={onSelect}
        className={tagClasses}
      />
    ),
    openDialog: noop,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    const {tags, selected} = nextProps
    return {
      showAll: prevState.showAll || (selected != null && tags.indexOf(selected) >= MIN_SHOWN),
    }
  }

  state: State = {
    showAll: false,
  }

  showAll: () => void = () =>
    this.setState({
      showAll: true,
    })

  render(): React.ReactNode {
    const {
      className,
      tags,
      children,
      tagClassName,
      suppressLabel,
      isExperimentalUI,
      withIcon,
      editable,
      buildId,
      openDialog,
    } = this.props
    const tagClasses = classnames(styles.tag, tagClassName)
    const showMoreClasses = classnames(styles.showMore, {
      [styles.showMoreOld]: !isExperimentalUI,
    })
    const count = tags.length

    if (count === 0) {
      return null
    }

    const showAll = this.state.showAll || count <= MAX_SHOWN_DIRECTLY
    const shownTags = showAll ? tags : tags.slice(0, MIN_SHOWN)
    return (
      <div className={classnames(className, styles.wrapper)}>
        {withIcon && <SvgIcon icon="tag" title="Tags" className={styles.icon} />}
        {suppressLabel !== true && <span className={styles.label}>{'Filter by tag:'}</span>}
        {shownTags.map(tag => children({...this.props, tag, tagClasses}))}
        <Button
          hidden={showAll}
          text
          className={showMoreClasses}
          onClick={this.showAll}
        >{`Show all ${count} ${plur('tag', count)}`}</Button>
        {editable && (
          <Button
            className={styles.add}
            text
            onClick={() => openDialog(stringifyId(buildId), DialogType.TAGS)}
          >
            {'Add tags...'}
          </Button>
        )}
      </div>
    )
  }
}

export default TagsList
