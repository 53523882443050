import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import * as Redux from 'redux'

import {DslOptions} from '../../types'

export type DslActionPayload = {
  controlId: string
}

export const toggleDsl = createSlice({
  name: 'toggleDsl',
  initialState: false,
  reducers: {
    show: (_, __: PayloadAction<DslActionPayload>) => true,
    hide: (_, __: PayloadAction<DslActionPayload>) => false,
  },
})

export const dslVersion = createSlice({
  name: 'dslVersion',
  initialState: '',
  reducers: {
    set: (_, action: PayloadAction<DslActionPayload & {version: string}>) => action.payload.version,
  },
})

export const dslPortable = createSlice({
  name: 'dslPortable',
  initialState: true,
  reducers: {
    set: (_, action: PayloadAction<DslActionPayload & {portable: boolean}>) =>
      action.payload.portable,
  },
})

const dslReducer = Redux.combineReducers({
  show: toggleDsl.reducer,
  version: dslVersion.reducer,
  portable: dslPortable.reducer,
})

export const dslOptions = createSlice({
  name: 'dslOptions',
  initialState: {} as DslOptions,
  reducers: {
    init: (_, action: PayloadAction<DslActionPayload & {options: DslOptions}>) =>
      action.payload.options,
  },
  extraReducers: builder => builder.addDefaultCase(dslReducer),
})

export const dslFragment = createSlice({
  name: 'dslFragment',
  initialState: null as string | null,
  reducers: {
    store: (_, action: PayloadAction<string>) => action.payload,
  },
  extraReducers: builder => {
    builder.addCase(toggleDsl.actions.hide, () => null)
    builder.addCase(dslVersion.actions.set, () => null)
    builder.addCase(dslPortable.actions.set, () => null)
  },
})
