import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import {memo, useCallback} from 'react'

import TransformText from '../../containers/TransformText'
import {displayDate} from '../../utils/dateTimeUtils'
import Avatar, {Size} from '../common/Avatar/Avatar.container'

import type {InvestigationHistoryType, Props} from './InvestigationHistory.types'
import {
  getInvestigationHistoryActionTextByType,
  getInvestigationHistoryResolveMethod,
} from './InvestigationHistory.utils'

import styles from './InvestigationHistory.css'

function InvestigationHistory({
  className,
  investigations,
  isAutodetectTimeZone,
  isLoading,
  isBackgroundLoading,
  isReady,
}: Props) {
  const renderInvestigation = useCallback(
    (investigation: InvestigationHistoryType) => {
      const resolveMethod = getInvestigationHistoryResolveMethod(investigation)
      const actorName = investigation.actor?.descriptiveName ?? investigation.actor?.userName
      return (
        <div key={investigation.timestamp} className={styles.investigation}>
          <div className={styles.investigationHeader}>
            {investigation.actor != null && (
              <Avatar
                user={{
                  id: investigation.actor.userId,
                  username: investigation.actor.userName,
                  name: investigation.actor.descriptiveName,
                  avatars: {
                    urlToSize20: investigation.actor.avatar20Url,
                    urlToSize40: investigation.actor.avatar40Url,
                  },
                }}
                size={Size.Size20}
              />
            )}
            <span className={styles.user}>{actorName ?? 'TeamCity'}</span>
            <span className={styles.timestamp}>
              {` ${displayDate(investigation.timestamp, isAutodetectTimeZone, true, true)}`}
            </span>
          </div>
          <div className={styles.investigationContent}>
            <TransformText
              text={getInvestigationHistoryActionTextByType(investigation)}
              className={styles.comment}
            />
            <span className={styles.resolveMethod}>
              {resolveMethod != null && `resolve method: ${resolveMethod}`}
            </span>
          </div>
        </div>
      )
    },
    [isAutodetectTimeZone],
  )
  return (
    <div className={classNames(className, styles.content)}>
      {isReady && (!isLoading || isBackgroundLoading) ? (
        investigations.length > 0 ? (
          [...investigations].reverse().map(investigation => renderInvestigation(investigation))
        ) : (
          <div className={styles.emptyHistory}>{'History is empty'}</div>
        )
      ) : (
        <LoaderInline>{'Loading...'}</LoaderInline>
      )}
    </div>
  )
}

export default memo<Props>(InvestigationHistory)
