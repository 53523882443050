import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'

import {ChangeBuildTypeType, ChangeDeploymentType} from './ChangeDetailsTabs.types'

type BuildTypeIdsData = {
  buildType: readonly ChangeBuildTypeType[]
}
export const requestChangeBuildTypes = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeBuildTypeType>> =>
  request(serverUrl, `changes/${encodeURIComponent(locator)}/buildTypes?fields=buildType(id,type)`)
    .then<BuildTypeIdsData>(processResponse)
    .then(data => {
      const buildTypes = data.buildType
      return buildTypes.filter(
        (buildType, index) => !buildTypes.slice(0, index).some(other => other.id === buildType.id),
      )
    })

type DeploymentsIdsData = {
  buildType: readonly ChangeDeploymentType[]
}
export const requestChangeDeployments = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeDeploymentType>> =>
  request(
    serverUrl,
    `changes/${encodeURIComponent(locator)}/deploymentConfigurations?fields=buildType(id)`,
  )
    .then<DeploymentsIdsData>(processResponse)
    .then(data => data?.buildType)
