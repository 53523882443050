import processResponse from '../../../../../rest/processResponse'
import request from '../../../../../rest/request'
import {BuildChangesRevisionsType} from '../../../../../types'

import {RequestBuildChangesRevisionsParams} from './ChangesRevisions.types'

const revisionParams = `version,vcsBranchName,vcs-root-instance(id,vcs-root(id,name,vcsName,project(id)))`

type BuildChangesRevisionsData = {
  build: BuildChangesRevisionsType[]
}
export const requestBuildChangesRevisions = (
  serverUrl: string,
  locator: string,
  options?: RequestBuildChangesRevisionsParams,
): Promise<ReadonlyArray<BuildChangesRevisionsType>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(
      locator,
    )}&fields=build(id,vcsLabels(vcs-root-instance(id,vcs-root(id)),failureReason,status,text),${[
      options?.withSettingsRevisions === true && `versionedSettingsRevision(${revisionParams})`,
      `revisions(revision(${revisionParams}))`,
    ]
      .filter(Boolean)
      .join(',')})`,
  )
    .then<BuildChangesRevisionsData>(processResponse)
    .then(data => data.build)
