import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getEmptyHash} from '../../utils/empty'

export const defaultAgentsScreenState = {
  agentsTreeExpandState: getEmptyHash(),
  agentsTreeSearchExpandState: getEmptyHash(),
  agentsTreeSearchState: {
    searchQuery: '',
  },
}

export const agentsTreeSearchState = createSlice({
  name: 'agentsPage.agentsTreeSearchState',
  initialState: defaultAgentsScreenState.agentsTreeSearchState,
  reducers: {
    search(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload
    },
  },
})
