import {defaultProps} from 'recompose'

import compose from 'lodash/flowRight'
import {memo, ComponentType} from 'react'
import * as ReactRedux from 'react-redux'

import {withBranchProps} from '../../hooks/routes'
import type {State} from '../../reducers/types'
import {getBuildType, getStatus} from '../../selectors'
import {getBuildTypeStatusRequest, getProjectStatusRequest, InvestigationStates} from '../../types'
import type {ProjectOrBuildTypeStatus, StatusRequest} from '../../types'

import ProjectOrBuildTypeIcon from './ProjectOrBuildTypeIcon'
import {types as ProjectOrBuildTypeIconTypes} from './ProjectOrBuildTypeIcon.types'
import type {
  OverviewStatusIconOwnProps,
  OverviewStatusIconStateProps,
} from './ProjectOrBuildTypeIcon.types'

const statusMap = {
  SUCCESS: 'successful',
  FAILURE: 'failed',
  UNKNOWN: null,
} as const

function getStatusRequest(props: OverviewStatusIconOwnProps): StatusRequest | null {
  switch (props.type) {
    case 'project':
      return props.id != null ? getProjectStatusRequest(props.id, props.branch) : null

    case 'buildType':
      return props.id != null ? getBuildTypeStatusRequest(props.id, props.branch) : null

    default:
      return null
  }
}

const mapStateToProps = (
  state: State,
  props: OverviewStatusIconOwnProps,
): OverviewStatusIconStateProps => {
  const buildType = props.type === 'buildType' ? getBuildType(state, props.id) : null
  const statusObj: ProjectOrBuildTypeStatus | null | undefined = getStatus(
    state,
    getStatusRequest(props),
  )
  const statusStr = statusObj?.status
  const status = statusStr ? statusMap[statusStr] : null
  let title
  let iconItemType = props.type
  const investigation = statusObj?.investigation

  if (props.showInvestigationInStatus === true) {
    if (investigation === InvestigationStates.TAKEN) {
      title = 'Investigation is assigned'
      iconItemType = ProjectOrBuildTypeIconTypes.investigation
    }
  }

  if (props.showPausedInStatus === true) {
    const paused = buildType?.paused

    if (paused === true) {
      title = 'Build configuration was paused'
      iconItemType =
        status === 'failed'
          ? ProjectOrBuildTypeIconTypes.paused
          : ProjectOrBuildTypeIconTypes.pausedOutline
    }
  }

  return {
    title,
    status: props.ignoreStatus === true ? null : status,
    type: iconItemType,
    composite: buildType?.type === 'composite',
  }
}

const OverviewStatusIcon: ComponentType<OverviewStatusIconOwnProps> = compose(
  memo,
  withBranchProps,
  defaultProps({
    showPausedInStatus: false,
    showInvestigationInStatus: false,
  }),
  ReactRedux.connect(mapStateToProps, {}),
)(ProjectOrBuildTypeIcon)
export default OverviewStatusIcon
