import {$Values} from 'utility-types'

import {Mute} from '../../services/rest'
import type {
  BuildAgentType,
  BuildId,
  BuildTypeId,
  BuildTypeInternalId,
  BuildTypeWithDetailsType,
  Fetchable,
  Inexact,
  InvestigationType,
  BuildTypeHierarchyType,
  TestId,
  TestOccurrenceId,
  TestsTreeNodeId,
} from '../../types'
import type {KeyValue} from '../../utils/object'

export const PAGER_QUERY_PARAM_NAME = 'pager.currentPage'
export const testStatuses = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  UNKNOWN: 'UNKNOWN',
} as const
export const OrderByFields = {
  status: 'status',
  name: 'name',
  duration: 'duration',
  runOrder: 'runOrder',
  newFailure: 'newFailure',
  count: 'count',
  success: 'success', // Sorting by these fields is not supported yet.
  failure: 'failure', // ...
  muted: 'muted', // ...
  ignored: 'ignored', // ...
  buildStartDate: 'buildStartDate',
} as const
export const OrderDirections = {
  asc: 'asc',
  desc: 'desc',
} as const
export const TestScopesKey = {
  suite: 'suite',
  package: 'package',
  class: 'class',
} as const
export const TreeEntityType = {
  PROJECT: 'PROJECT',
  BUILD_TYPE: 'BUILD_TYPE',
  BUILD: 'BUILD',
  SUITE: 'SUITE',
  PACKAGE: 'PACKAGE',
  CLASS: 'CLASS',
}
export type TestStatusesType = $Values<typeof testStatuses>
export type OrderByFieldsType = $Values<typeof OrderByFields>
export type OrderDirectionsType = $Values<typeof OrderDirections>
export type TestScopesKeyType = $Values<typeof TestScopesKey>
export type TreeEntityTypeType = $Values<typeof TreeEntityType>

export type RequestTestOccurrenceOptionsParams = {
  withNewFailure?: boolean
  withMetadataCount?: boolean
  withUpdatePager?: boolean
  withNextFixed?: boolean
  withFirstFailed?: boolean
  withRunOrder?: boolean
  withMuteInfo?: boolean
  withInvocationsCounters?: boolean
  withInvestigationInfo?: boolean
  withBuildInfo?: boolean
}
type TestType = {
  readonly id?: TestId
  readonly name?: string
  readonly mutes?: {
    readonly mute: ReadonlyArray<Mute>
  }
  readonly investigations?: {
    readonly investigation: ReadonlyArray<InvestigationType>
  }
  readonly parsedTestName?: {
    readonly testPackage: string
    readonly testSuite: string
    readonly testClass: string
    readonly testShortName: string
    readonly testNameWithoutPrefix: string
    readonly testMethodName: string
    readonly testNameWithParameters: string
  }
}
export type TestOccurrenceMetadataValuesType = {
  readonly name: string
  readonly type?: string
  readonly value?: string
}
export type TestOccurrenceMetadataType = {
  readonly count?: number
  readonly typedValues?: ReadonlyArray<TestOccurrenceMetadataValuesType>
}
export type TestOccurrencesCountsType = {
  readonly all?: number
  readonly failed?: number
  readonly success?: number
  readonly muted?: number
  readonly ignored?: number
  readonly newFailed?: number
  readonly duration?: number
}
export type TestOccurrenceType = {
  readonly id: TestOccurrenceId
  readonly firstFailed?: TestOccurrenceType
  readonly nextFixed?: TestOccurrenceType | null
  readonly test?: TestType
  readonly newFailure?: boolean
  readonly currentlyInvestigated?: boolean
  readonly currentlyMuted?: boolean
  readonly muted?: boolean
  readonly mute?: Mute
  readonly duration?: number
  readonly runOrder?: string
  readonly invocations?: {
    testCounters?: TestOccurrencesCountsType
    testOccurrence?: ReadonlyArray<TestOccurrenceType>
  }
  readonly build?:
    | Inexact<{
        readonly id?: BuildId | null | undefined
        readonly buildType?: Partial<BuildTypeWithDetailsType> | null | undefined
        readonly buildTypeId?: BuildTypeId | null | undefined
        readonly startDate?: string | null | undefined
        readonly agent?: Partial<BuildAgentType>
        readonly number?: string | null | undefined
        readonly personal?: boolean
      }>
    | null
    | undefined
  readonly logAnchor?: string
  readonly name?: string
  readonly status?: TestStatusesType
  readonly metadata?: Inexact<TestOccurrenceMetadataType>
}
type BaseTestOccurrencesType<T> = {
  readonly testCounters?: TestOccurrencesCountsType
  readonly testOccurrence?: ReadonlyArray<T>
}
type TestOccurrencesType = BaseTestOccurrencesType<TestOccurrenceType>
type NormalizedTestOccurrencesType = BaseTestOccurrencesType<TestOccurrenceId>
export type TestOccurrenceInvocationsType = {
  readonly id: TestOccurrenceId
  readonly invocations?: NormalizedTestOccurrencesType
}
type BaseTestOccurrencesTreeLeaf<T> = {
  readonly nodeId: TestsTreeNodeId
  readonly testOccurrences?: T
}
export type TestOccurrencesTreeLeaf = BaseTestOccurrencesTreeLeaf<TestOccurrencesType>
export type TestOccurrencesTreeNode = {
  readonly id: TestsTreeNodeId
  readonly parentId?: TestsTreeNodeId
  readonly name: string
  readonly childrenCount?: number
  readonly testCounters?: TestOccurrencesCountsType
  readonly type: TreeEntityTypeType
}
type BaseTestOccurrencesTreeType<T> = {
  readonly leaf?: ReadonlyArray<BaseTestOccurrencesTreeLeaf<T>>
  readonly node?: ReadonlyArray<TestOccurrencesTreeNode>
}
export type TestOccurrencesTreeType = BaseTestOccurrencesTreeType<TestOccurrencesType>
export type TestScopeType = {
  readonly suite?: string
  readonly package?: string
  readonly class?: string
  readonly testOccurrences?: TestOccurrencesType
}
export type TestScope = {
  readonly suite: string | null | undefined
  readonly package: string | null | undefined
  readonly class: string | null | undefined
}
export type TestFlakyType = {
  readonly id: TestId
  readonly isFlaky: boolean
  readonly title?: string
  readonly descriptions?: ReadonlyArray<string>
}
export type RequestFlakyTestsType = ReadonlyArray<{
  testName: TestId | null | undefined
  buildTypeId: BuildTypeInternalId | null | undefined
}>
export type TestsStateType = {
  readonly testOccurrencesInvocations: KeyValue<string, Fetchable<ReadonlyArray<TestOccurrenceId>>>
  readonly testOccurrencesCounts: KeyValue<string, Fetchable<TestOccurrencesCountsType | null>>
  readonly testBuildTypeHierarchy: KeyValue<string, Fetchable<BuildTypeHierarchyType | null>>
  readonly testScopes: KeyValue<
    TestScopesKeyType,
    KeyValue<string, Fetchable<ReadonlyArray<TestScopeType>>>
  >
  readonly testOccurrencesTree: KeyValue<string, Fetchable<TestOccurrencesTreeType | null>>
  readonly testOccurrencesSubtree: KeyValue<
    string,
    KeyValue<string, Fetchable<TestOccurrencesTreeType | null>>
  >
}
