import Button from '@jetbrains/ring-ui/components/button/button'
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {memo, ReactElement} from 'react'
import {useSelector} from 'react-redux'

import {getBuildType, getProject} from '../../../selectors'
import {BuildTypeId, getProjectBuildTypeFilter, ProjectId} from '../../../types'
import {resolveRelative} from '../../../utils/url'
import Popup from '../Popup/Popup.lazy'
import ProjectsPopup from '../ProjectsPopup/ProjectsPopup.lazy'

import styles from './BreadcrumbsDropdown.css'

type Props = {
  icon: ReactElement
  buildTypeId?: BuildTypeId
  projectId?: ProjectId
}

export default memo(function BreadcrumbsDropdown({buildTypeId, projectId, icon}: Props) {
  const parentId = useSelector(state =>
    buildTypeId != null
      ? getBuildType(state, buildTypeId)?.projectId
      : getProject(state, projectId)?.parentProjectId,
  )
  const activeItem = getProjectBuildTypeFilter({buildTypeId, projectId})

  return (
    <Dropdown
      anchor={<Button className={styles.popupIcon} title="View siblings" icon={() => icon} />}
    >
      <Popup>
        <ProjectsPopup
          parentProjectId={parentId}
          activeItem={
            activeItem && {
              ...activeItem,
              group: 'search',
            }
          }
          buildTypeUrlFormat={resolveRelative('/buildConfiguration/{id}')}
          projectUrlFormat={resolveRelative('/project/{id}')}
        />
      </Popup>
    </Dropdown>
  )
})
