import * as React from 'react'
import {useSelector} from 'react-redux'

import {getBuild, getBuildType} from '../selectors'
import type {ActiveEntityProps} from '../types'

export const EntityContext: React.Context<ActiveEntityProps> = React.createContext(
  Object.freeze({}),
)
type EntityProviderProps = ActiveEntityProps & {
  readonly seqKey?: string
  readonly children: React.ReactNode
}
export const EntityProvider: React.ComponentType<EntityProviderProps> = React.memo(
  function EntityProvider({
    projectId,
    buildTypeId,
    buildId,
    agentId,
    agentPoolId,
    agentTypeId,
    testId,
    children,
  }) {
    const resolvedBuildTypeId = useSelector(
      state => buildTypeId ?? getBuild(state, buildId)?.buildType,
    )
    const resolvedProjectId = useSelector(
      state => projectId ?? getBuildType(state, buildTypeId)?.projectId,
    )
    const contextValue = React.useMemo(
      () => ({
        projectId: resolvedProjectId,
        buildTypeId: resolvedBuildTypeId,
        buildId,
        agentId,
        agentPoolId,
        agentTypeId,
        testId,
      }),
      [agentId, agentPoolId, agentTypeId, buildId, resolvedBuildTypeId, resolvedProjectId, testId],
    )
    return <EntityContext.Provider value={contextValue}>{children}</EntityContext.Provider>
  },
)
