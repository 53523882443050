import {mapProps} from 'recompose'

import compose from 'lodash/flowRight'
import * as React from 'react'

import {ComponentProps} from 'react'

import Redirect from '../components/App/Redirect/Redirect'
import RouterLink from '../components/common/RouterLink/RouterLink'
import type {RouterLinkProps} from '../components/common/RouterLink/RouterLink'
import {getOverviewHref, getHrefWithQueryParams, withLocation} from '../routes'
import {BuildTypePageTabNamesEnum, stringifyId} from '../types'
import type {ActiveEntityProps, BuildId, BuildTypeId, Id, ProjectId} from '../types'
import type {QueryParams} from '../utils/queryParams'

type EntityRedirectProps = ActiveEntityProps & {
  readonly redirectedFromId?: Id
}
export const EntityRedirect: React.ComponentType<EntityRedirectProps> = compose(
  withLocation,
  mapProps(
    ({
      projectId,
      buildTypeId,
      buildId,
      location,
      redirectedFromId,
    }): ComponentProps<typeof Redirect> => ({
      to: getHrefWithQueryParams(
        location,
        getOverviewHref({
          projectId,
          buildTypeId,
          buildId,
        }),
        params => ({...params, redirectedFromId}),
      ),
    }),
  ),
)(Redirect)
type EntityLinkProps = RouterLinkProps & {
  readonly projectId?: ProjectId | null | undefined
  readonly buildTypeId?: BuildTypeId | null | undefined
  readonly buildId?: BuildId | null | undefined
  readonly isAllProjects?: boolean
}

export const getParams = ({branch}: QueryParams): QueryParams => ({
  branch,
  buildTypeTab:
    branch != null && branch.length > 0 ? stringifyId(BuildTypePageTabNamesEnum.OVERVIEW) : null,
})

export default mapProps(
  ({
    projectId,
    buildTypeId,
    buildId,
    isAllProjects,
    ...restProps
  }: EntityLinkProps): RouterLinkProps => ({
    to: getOverviewHref({
      projectId,
      buildTypeId,
      buildId,
      isAllProjects,
    }),
    params: getParams,
    ...restProps,
  }),
)(RouterLink)
