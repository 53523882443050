import {createReducer, Draft, PayloadAction} from '@reduxjs/toolkit'
import deepEqual from 'fast-deep-equal'
import {castDraft, original} from 'immer'
import merge from 'lodash/merge'
import * as Redux from 'redux'

import {
  compatibleAgents,
  fetchBuildsAction,
  receiveBuildAction,
  receiveBuildChanges,
  receiveBuildWSDataAction,
} from '../actions/builds'
import {
  assignAgentWithPool,
  changeAgentAuthState,
  changeAgentState,
  fetchBranchesWithBuilds,
  fetchSingleInvestigation,
  removeAgent,
} from '../actions/fetch'
import {fetchOverviewAction} from '../actions/overview'
import {fetchProjectsDataAction, fetchSingleProjectDataAction} from '../actions/projects'
import {fetchStatuses} from '../actions/statuses'
import {
  fetchAgentPoolsData,
  fetchCloudImagesData,
  fetchSingleAgentData,
  markAgentDeleted,
} from '../components/AgentsScreen/AgentsScreen.actions'
import {overviewExpandState} from '../components/App/ProjectsSidebar/ProjectsSidebar.actions'
import {
  allProjectsExpandState,
  searchProjectsExpandState,
} from '../components/App/ProjectsSidebar/ProjectsSidebar.slices'
import {
  fetchProblemOccurrenceSubtree,
  fetchProblemOccurrenceTree,
} from '../components/BuildProblems/BuildProblems.actions'
import {ProblemOccurrenceType} from '../components/BuildProblems/BuildProblems.types'
import {receiveAgents} from '../components/common/Agents/Agents.actions'
import {fetchBuildArtifactDependenciesChanges} from '../components/common/ArtifactChanges/ArtifactChanges.actions'
import {saveComment} from '../components/common/BuildActionsDropdown/CommentDialog/CommentDialog.actions'
import {pinBuildFromDialog} from '../components/common/BuildActionsDropdown/PinDialog/PinDialog.actions'
import {
  addBuildsTags,
  addDependenciesTags,
  changeBuildTagsAction,
} from '../components/common/BuildActionsDropdown/TagDialog/TagDialog.actions'
import {buildArtifacts} from '../components/common/BuildArtifacts/BuildArtifacts.slice'
import {
  commentBuild,
  tagBuild,
} from '../components/common/Builds/Build/BuildActions/BuildActions.actions'
import {pinBuild} from '../components/common/Builds/Build/PinBuild/PinBuild.actions'
import {
  fetchChangesBranches,
  fetchChangeStatus,
  fetchChangeStatusBuilds,
} from '../components/common/Changes/Changes.actions'
import {fetchBuildChangesRevisions} from '../components/pages/BuildPage/BuildChangesTab/ChangesRevisions/ChangesRevisions.actions'
import {
  fetchArtifactDependenciesExist,
  fetchBuildTypesWithSnapshotDependencies,
} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.actions'
import {
  fetchTestOccurrenceMetadata,
  fetchTestOccurrences,
  fetchTestOccurrencesInvocations,
  fetchTestOccurrenceSubtree,
  fetchTestOccurrenceTree,
} from '../components/Tests/Tests.actions'
import type {TestOccurrenceType} from '../components/Tests/Tests.types'
import type {Entities, Normalized} from '../rest/schemata'
import {normalizePartialBuild} from '../rest/schemata'
import {getIdFromLocator, restApi} from '../services/rest'
import type {
  AgentId,
  BuildId,
  BuildTypeId,
  BuildTypeType,
  ChangeId,
  NormalizedAgentPreviewType,
  NormalizedAgentType,
  NormalizedBuildType,
  NormalizedChangeType,
  NormalizedProjectType,
  ProjectId,
  SnapshotDependenciesIDListType,
  TestOccurrenceId,
  WebLinks,
} from '../types'
import {InvestigationType, ProblemOccurrenceId, toBuildId, toBuildTypeId} from '../types'
import {getEmptyHash} from '../utils/empty'
import type {KeyValue} from '../utils/object'
import {objectEntries} from '../utils/object'

import {assignIfDeepDifferent} from './utils'

const buildArtifactDependencies = createReducer<
  KeyValue<
    BuildId,
    {
      delivered: boolean
      downloaded: boolean
    }
  >
>({}, builder =>
  builder.addCase(fetchArtifactDependenciesExist.fulfilled, (state, action) => {
    state[action.meta.arg] = action.payload
  }),
)

const agentReducer = createReducer<KeyValue<AgentId, NormalizedAgentType | null | undefined>>(
  {},
  builder => {
    builder.addCase(changeAgentState.fulfilled, (state, action) => {
      const agent = state[action.meta.arg.agentId]
      if (agent != null) {
        agent.enabled = action.payload.status
        agent.enabledInfo = action.payload
      }
    })
    builder.addCase(changeAgentAuthState.fulfilled, (state, action) => {
      const agent = state[action.meta.arg.agentId]
      if (agent != null) {
        agent.authorized = action.payload.status
        agent.authorizedInfo = action.payload
      }
    })
    builder.addCase(assignAgentWithPool.fulfilled, (state, action) => {
      const agent = state[action.meta.arg.agentId]
      if (agent != null) {
        agent.pool = castDraft(action.payload)
      }
    })
    builder.addCase(removeAgent.fulfilled, (state, action) => {
      delete state[action.meta.arg]
    })
  },
)

export default Redux.combineReducers<Entities>({
  agents: createReducer({}, builder =>
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action)
      ) {
        Object.assign(state, action.payload.entities.agents)
        return undefined
      }
      return agentReducer(state, action)
    }),
  ),

  agentPreviews: createReducer<KeyValue<AgentId, NormalizedAgentPreviewType | null | undefined>>(
    {},
    builder => {
      builder.addCase(changeAgentState.fulfilled, (state, action) => {
        const agent = state[action.meta.arg.agentId]
        if (agent != null) {
          agent.enabled = action.payload.status
        }
      })
      builder.addCase(changeAgentAuthState.fulfilled, (state, action) => {
        const agent = state[action.meta.arg.agentId]
        if (agent != null) {
          agent.authorized = action.payload.status
        }
      })
      builder.addCase(assignAgentWithPool.fulfilled, (state, action) => {
        const agent = state[action.meta.arg.agentId]
        if (agent != null) {
          agent.pool = castDraft(action.payload)
        }
      })
      builder.addCase(removeAgent.fulfilled, (state, action) => {
        delete state[action.meta.arg]
      })
      builder.addDefaultCase((state, action) => {
        if (
          restApi.endpoints.getAllAgentPreviewsNormalized.matchFulfilled(action) ||
          restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
          receiveAgents.match(action)
        ) {
          return action.payload.entities.agentPreviews || getEmptyHash()
        }
        return undefined
      })
    },
  ),

  agent: createReducer<KeyValue<AgentId, NormalizedAgentType | null>>({}, builder => {
    builder.addCase(fetchSingleAgentData.fulfilled, (state, action) => {
      Object.assign(state, action.payload.agent.entities.agents)
    })
    builder.addCase(markAgentDeleted, (state, action) => {
      const agent = state[action.payload]
      if (agent != null) {
        agent.deleted = true
      }
    })
    builder.addDefaultCase(agentReducer)
  }),

  agentPools: createReducer({}, builder =>
    builder.addCase(fetchAgentPoolsData.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.agentPools)
    }),
  ),

  cloudImage: createReducer({}, builder => {
    builder.addCase(fetchSingleAgentData.fulfilled, (state, action) => {
      Object.assign(state, action.payload.agent.entities.cloudImage)
    })
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action)
      ) {
        Object.assign(state, action.payload.entities.cloudImage)
      }
    })
  }),

  cloudImages: createReducer({}, builder =>
    builder.addCase(fetchCloudImagesData.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.cloudImages)
    }),
  ),

  branches: createReducer({}, builder =>
    builder.addCase(fetchBranchesWithBuilds.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.branches)
    }),
  ),
  buildTypes: createReducer<KeyValue<BuildTypeId, BuildTypeType>>({}, builder => {
    const shallowMergeBuildTypes = (
      state: Draft<KeyValue<BuildTypeId, BuildTypeType>>,
      action: PayloadAction<Normalized<unknown>>,
    ) => {
      Object.assign(state, action.payload.entities.buildTypes)
    }
    builder.addCase(fetchBuildTypesWithSnapshotDependencies.fulfilled, shallowMergeBuildTypes)
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withBuildTypes) {
        assignIfDeepDifferent(state, action.payload.entities.buildTypes)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withBuildTypes) {
        Object.assign(state, action.payload.entities.buildTypes)
      }
    })
    function mergeBuildTypes(
      state: Draft<KeyValue<BuildTypeId, BuildTypeType>>,
      buildTypes: KeyValue<BuildTypeId, BuildTypeType>,
    ) {
      objectEntries(buildTypes).forEach(([id, buildType]) => {
        const buildTypeId = toBuildTypeId(id)
        const existingBuildType = original(state)?.[buildTypeId] as BuildTypeType
        if (existingBuildType != null && buildType != null) {
          objectEntries(buildType).forEach(([key, value]) => {
            if (!deepEqual(existingBuildType[key], value)) {
              state[buildTypeId]![key] = value as never
            }
          })
        } else {
          state[buildTypeId] = castDraft(buildType)
        }
      })
    }
    builder.addCase(fetchBuildsAction.fulfilled, (state, action) => {
      if (
        action.meta.arg.requestOptions.withBuildTypeDetails &&
        action.payload.entities.buildTypes
      ) {
        mergeBuildTypes(state, action.payload.entities.buildTypes)
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) =>
      assignIfDeepDifferent(state, action.payload.entities.buildTypes),
    )
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action)
      ) {
        shallowMergeBuildTypes(state, action)
      } else if (
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action)
      ) {
        if (action.meta.arg.originalArgs.withBuildTypes && action.payload.entities.buildTypes) {
          mergeBuildTypes(state, action.payload.entities.buildTypes)
        }
      }
    })
  }),

  buildTypeParameters: createReducer({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withParameters) {
        Object.assign(state, action.payload.entities.buildTypeParameters)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withParameters) {
        Object.assign(state, action.payload.entities.buildTypeParameters)
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypeParameters)
    })
  }),

  buildTypeLinks: createReducer({}, builder => {
    const mergeBuildTypeLinks = (
      state: Draft<KeyValue<BuildTypeId, WebLinks>>,
      action: PayloadAction<Normalized<unknown>>,
    ) => {
      Object.assign(state, action.payload.entities.buildTypeLinks)
    }
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withLinks) {
        mergeBuildTypeLinks(state, action)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withLinks) {
        mergeBuildTypeLinks(state, action)
      }
    })
    builder.addCase(fetchBuildsAction.fulfilled, (state, action) => {
      if (action.meta.arg.requestOptions.withBuildTypeDetails) {
        mergeBuildTypeLinks(state, action)
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, mergeBuildTypeLinks)
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action)
      ) {
        mergeBuildTypeLinks(state, action)
      } else if (
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action)
      ) {
        if (action.meta.arg.originalArgs.withBuildTypes) {
          mergeBuildTypeLinks(state, action)
        }
      }
    })
  }),

  buildTypeDescription: createReducer({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withDescription) {
        Object.assign(state, action.payload.entities.buildTypeDescription)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withDescription) {
        Object.assign(state, action.payload.entities.buildTypeDescription)
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypeDescription)
    })
  }),
  buildTypePauseComment: createReducer({}, builder =>
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypePauseComment)
    }),
  ),
  buildTypesWithSnapshotDependencies: createReducer(getEmptyHash(), builder =>
    builder.addCase(fetchBuildTypesWithSnapshotDependencies.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypes)
    }),
  ),

  investigations: createReducer<KeyValue<BuildTypeId, InvestigationType>>({}, builder =>
    builder.addCase(fetchSingleInvestigation.fulfilled, (state, action) => {
      const {investigations} = action.payload.entities
      if (investigations != null) {
        Object.assign(state, investigations)
      } else {
        delete state[action.meta.arg]
      }
    }),
  ),

  projects: createReducer<KeyValue<ProjectId, NormalizedProjectType>>({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      const {projectId} = action.meta.arg
      const prevProject = state[projectId]
      const project = action.payload.entities.projects?.[projectId]
      if (prevProject != null) {
        assignIfDeepDifferent(prevProject, project)
      } else {
        state[projectId] = castDraft(project)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.projects)
    })
  }),

  projectDescription: createReducer({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withDescription) {
        Object.assign(state, action.payload.entities.projectDescription)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withDescription) {
        Object.assign(state, action.payload.entities.projectDescription)
      }
    })
  }),

  projectArchivedSubprojectsIds: createReducer({}, builder =>
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withArchivedSubprojectsIds) {
        Object.assign(state, action.payload.entities.projectArchivedSubprojectsIds)
      }
    }),
  ),

  projectParameters: createReducer({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withParameters) {
        Object.assign(state, action.payload.entities.projectParameters)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withParameters) {
        Object.assign(state, action.payload.entities.projectParameters)
      }
    })
  }),

  projectLinks: createReducer({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withLinks) {
        Object.assign(state, action.payload.entities.projectLinks)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withLinks) {
        Object.assign(state, action.payload.entities.projectLinks)
      }
    })
  }),

  projectTemplates: createReducer({}, builder => {
    builder.addCase(fetchSingleProjectDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withTemplates) {
        Object.assign(state, action.payload.entities.projectTemplates)
      }
    })
    builder.addCase(fetchProjectsDataAction.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withTemplates) {
        Object.assign(state, action.payload.entities.projectTemplates)
      }
    })
  }),

  overviewBuildTypes: createReducer<KeyValue<BuildTypeId, BuildTypeType>>({}, builder => {
    builder.addCase(fetchOverviewAction.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.overviewBuildTypes)
    })
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      const buildTypeId = getIdFromLocator(action.meta.arg.originalArgs.btLocator)
      const buildType = action.payload.entities.buildTypes?.[buildTypeId]

      const oldBuildType = original(state)![buildTypeId]
      if (oldBuildType != null && !deepEqual(oldBuildType, buildType)) {
        state[buildTypeId] = castDraft(buildType)
      }
    })
  }),

  overviewProjects: createReducer({}, builder =>
    builder.addCase(fetchOverviewAction.fulfilled, (state, action) => {
      assignIfDeepDifferent(state, action.payload.entities.overviewProjects)
    }),
  ),

  testOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrenceType>>({}, builder =>
    builder.addDefaultCase((state, action) => {
      const testOccurrences = (() => {
        if (fetchTestOccurrences.fulfilled.match(action)) {
          return action.payload.data.entities.testOccurrences
        }
        if (
          fetchTestOccurrenceTree.fulfilled.match(action) ||
          fetchTestOccurrenceSubtree.fulfilled.match(action) ||
          fetchTestOccurrencesInvocations.fulfilled.match(action)
        ) {
          return action.payload.entities.testOccurrences
        }
        return undefined
      })()
      if (testOccurrences != null) {
        for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
          const count = testOccurrence?.invocations?.testCounters?.all

          if (count == null || count === 0) {
            state[testOccurrenceId] = castDraft(testOccurrence)
          }
        }
      }
    }),
  ),

  multirunTestOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrenceType>>(
    {},
    builder =>
      builder.addDefaultCase((state, action) => {
        const testOccurrences = (() => {
          if (fetchTestOccurrences.fulfilled.match(action)) {
            return action.payload.data.entities.testOccurrences
          }
          if (
            fetchTestOccurrenceTree.fulfilled.match(action) ||
            fetchTestOccurrenceSubtree.fulfilled.match(action) ||
            fetchTestOccurrencesInvocations.fulfilled.match(action)
          ) {
            return action.payload.entities.testOccurrences
          }
          return undefined
        })()
        if (testOccurrences != null) {
          for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
            const count = testOccurrence?.invocations?.testCounters?.all

            if (count != null && count > 0) {
              state[testOccurrenceId] = castDraft(testOccurrence)
            }
          }
        }
      }),
  ),

  testOccurrencesFirstFailed: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withFirstFailed) {
        Object.assign(state, action.payload.data.entities.testOccurrencesFirstFailed)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withFirstFailed) {
          Object.assign(state, action.payload.entities.testOccurrencesFirstFailed)
        }
      }
    })
  }),

  testOccurrencesNextFixed: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withNextFixed) {
        Object.assign(state, action.payload.data.entities.testOccurrencesNextFixed)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withNextFixed) {
          Object.assign(state, action.payload.entities.testOccurrencesNextFixed)
        }
      }
    })
  }),

  testOccurrencesRunOrder: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withRunOrder) {
        Object.assign(state, action.payload.data.entities.testOccurrencesRunOrder)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withRunOrder) {
          Object.assign(state, action.payload.entities.testOccurrencesRunOrder)
        }
      }
    })
  }),

  testOccurrencesNewFailure: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withNewFailure) {
        Object.assign(state, action.payload.data.entities.testOccurrencesNewFailure)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withNewFailure) {
          Object.assign(state, action.payload.entities.testOccurrencesNewFailure)
        }
      }
    })
  }),

  testOccurrencesMetadataCount: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withMetadataCount) {
        Object.assign(state, action.payload.data.entities.testOccurrencesMetadataCount)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withMetadataCount) {
          Object.assign(state, action.payload.entities.testOccurrencesMetadataCount)
        }
      }
    })
  }),

  testOccurrencesInvestigations: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withInvestigationInfo) {
        Object.assign(state, action.payload.data.entities.testOccurrencesInvestigations)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withInvestigationInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesInvestigations)
        }
      }
    })
  }),

  testOccurrencesCurrentlyMutes: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withMuteInfo) {
        Object.assign(state, action.payload.data.entities.testOccurrencesCurrentlyMutes)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withMuteInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesCurrentlyMutes)
        }
      }
    })
  }),

  testOccurrencesMute: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withMuteInfo) {
        Object.assign(state, action.payload.data.entities.testOccurrencesMute)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withMuteInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesMute)
        }
      }
    })
  }),

  testOccurrencesInvocationsCounters: createReducer({}, builder => {
    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withInvocationsCounters) {
        Object.assign(state, action.payload.data.entities.testOccurrencesInvocationsCounters)
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        (fetchTestOccurrenceTree.fulfilled.match(action) ||
          fetchTestOccurrenceSubtree.fulfilled.match(action)) &&
        action.meta.arg.options?.withInvocationsCounters
      ) {
        Object.assign(state, action.payload.entities.testOccurrencesInvocationsCounters)
      }
    })
  }),

  testOccurrencesInvocations: createReducer({}, builder =>
    builder.addCase(fetchTestOccurrencesInvocations.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.testOccurrencesInvocations)
    }),
  ),

  testOccurrenceMetadata: createReducer({}, builder =>
    builder.addCase(fetchTestOccurrenceMetadata.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.testOccurrenceMetadata)
    }),
  ),

  problemOccurrences: createReducer<KeyValue<ProblemOccurrenceId, ProblemOccurrenceType>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getBuildProblemOccurrence.matchFulfilled,
        (state, action) => {
          state[action.payload.id!] = action.payload
        },
      )
      builder.addDefaultCase((state, action) => {
        if (
          fetchProblemOccurrenceTree.fulfilled.match(action) ||
          fetchProblemOccurrenceSubtree.fulfilled.match(action)
        ) {
          Object.assign(state, action.payload.entities.problemOccurrences)
        }
      })
    },
  ),

  buildArtifactDependencies,

  buildArtifacts: buildArtifacts.reducer,

  buildTypeStatuses: createReducer({}, builder =>
    builder.addCase(fetchStatuses.fulfilled, (state, action) => {
      Object.assign(state, action.payload?.entities.buildTypeStatuses)
    }),
  ),

  overviewExpandState: overviewExpandState.reducer,

  allProjectsExpandState: allProjectsExpandState.reducer,

  searchProjectsExpandState: searchProjectsExpandState.reducer,

  compatibleAgents: compatibleAgents.reducer,

  builds: createReducer<KeyValue<BuildId, NormalizedBuildType | null>>({}, builder => {
    function mergeBuilds(
      state: Draft<KeyValue<BuildId, NormalizedBuildType | null>>,
      action: PayloadAction<Normalized<unknown>>,
    ) {
      merge(state, action.payload.entities.builds)
    }
    builder.addCase(fetchBranchesWithBuilds.fulfilled, mergeBuilds)
    function mergeDelayedByBuilds(
      state: Draft<KeyValue<BuildId, NormalizedBuildType | null>>,
      action: PayloadAction<Normalized<unknown>>,
    ) {
      const {delayedByBuilds} = action.payload.entities
      if (delayedByBuilds != null) {
        for (const [key, build] of Object.entries(delayedByBuilds)) {
          const buildId = toBuildId(key)
          const oldBuild = state[buildId]
          if (oldBuild != null) {
            Object.assign(oldBuild, build)
          } else {
            state[buildId] = castDraft(build)
          }
        }
      }
    }
    builder.addCase(fetchBuildsAction.fulfilled, (state, action) => {
      mergeBuilds(state, action)
      mergeDelayedByBuilds(state, action)
    })

    builder.addCase(fetchTestOccurrences.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withBuildInfo) {
        Object.assign(state, action.payload.data.entities.builds)
      }
    })

    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      for (const [id, newBuild] of Object.entries(action.payload)) {
        const buildId = toBuildId(id)
        const oldBuild = state[buildId]
        if (oldBuild != null && newBuild != null) {
          const {agent, ['running-info']: runningInfo, ...restBuild} = newBuild
          Object.assign(oldBuild, restBuild)
          if (oldBuild.agent != null) {
            Object.assign(oldBuild.agent, agent)
          } else {
            oldBuild.agent = castDraft(agent)
          }
          if (oldBuild['running-info'] != null && runningInfo != null) {
            const {outdatedReasonBuild, ...restRunningInfo} = runningInfo
            Object.assign(oldBuild['running-info'], restRunningInfo)
            if (oldBuild['running-info'].outdatedReasonBuild != null) {
              Object.assign(oldBuild['running-info'].outdatedReasonBuild, outdatedReasonBuild)
            } else {
              oldBuild['running-info'].outdatedReasonBuild = castDraft(outdatedReasonBuild)
            }
          } else {
            oldBuild['running-info'] = castDraft(runningInfo)
          }
        } else {
          state[buildId] = castDraft(newBuild)
        }
      }
    })

    builder.addCase(fetchBuildArtifactDependenciesChanges.fulfilled, mergeBuilds)
    builder.addCase(pinBuild.fulfilled, (state, action) => {
      const build = state[action.meta.arg.buildId]
      if (build != null) {
        build.pinned = action.payload
      }
    })

    builder.addCase(saveComment.fulfilled, (state, action) => {
      action.payload.forEach(operationResult => {
        const {build} = operationResult.related ?? {}

        if (build != null) {
          const prevState = state[build.id!]

          if (prevState != null) {
            prevState.comment = build.comment
          }
        }
      })
    })

    builder.addCase(pinBuildFromDialog.fulfilled, (state, action) => {
      action.payload.forEach(operationResult => {
        const {build} = operationResult.related ?? {}

        if (build != null) {
          const normalizedBuild = normalizePartialBuild(build).entities.builds[build.id!]
          const prevState = state[build.id!]

          if (prevState != null) {
            Object.assign(prevState, normalizedBuild)
          }
        }
      })
    })

    builder.addCase(commentBuild.fulfilled, (state, action) => {
      const build = state[action.meta.arg.buildId]
      if (build != null) {
        build.comment ??= {}
        build.comment.text = action.payload
      }
    })

    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchFulfilled, mergeBuilds)

    function deepMergeBuild(
      state: Draft<KeyValue<BuildId, NormalizedBuildType | null>>,
      newBuild: NormalizedBuildType | null | undefined,
    ) {
      const buildId = newBuild?.id
      if (buildId == null) {
        return
      }
      const oldBuild = state[buildId]
      if (oldBuild != null) {
        assignIfDeepDifferent(oldBuild, newBuild)
        if (newBuild?.state === 'finished') {
          oldBuild['running-info'] = undefined
        }
      } else {
        state[buildId] = castDraft(newBuild)
      }
    }

    builder.addMatcher(restApi.endpoints.getBuildNormalized.matchFulfilled, (state, action) => {
      const buildId = action.payload.result
      const newBuild = action.payload.entities.builds?.[buildId]
      deepMergeBuild(state, newBuild)
      mergeDelayedByBuilds(state, action)
    })

    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action)
      ) {
        const builds = action.payload.entities.builds
        if (builds == null) {
          return
        }
        // Don't override already fetched builds
        objectEntries(builds).forEach(([key, value]) => {
          if (!(key in state)) {
            state[key] = castDraft(value)
          }
        })
      } else if (
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action)
      ) {
        action.payload.result.forEach(buildId => {
          const newBuild = action.payload.entities.builds?.[buildId]
          deepMergeBuild(state, newBuild)
        })
        mergeDelayedByBuilds(state, action)
      } else if (
        fetchTestOccurrenceTree.fulfilled.match(action) ||
        fetchTestOccurrenceSubtree.fulfilled.match(action) ||
        fetchTestOccurrencesInvocations.fulfilled.match(action)
      ) {
        if (action.meta.arg.options?.withBuildInfo) {
          Object.assign(state, action.payload.entities.builds)
        }
      } else if (
        changeBuildTagsAction.fulfilled.match(action) ||
        tagBuild.fulfilled.match(action)
      ) {
        const build = state[action.meta.arg.buildId]
        if (build != null) {
          build.tags = castDraft(action.payload)
        }
      } else if (
        addBuildsTags.fulfilled.match(action) ||
        addDependenciesTags.fulfilled.match(action)
      ) {
        action.payload.forEach(operationResult => {
          const {build} = operationResult.related ?? {}

          if (build != null) {
            const prevState = state[build.id!]

            if (prevState != null) {
              prevState.tags = build.tags
            }
          }
        })
      }
    })
  }),

  buildsTestOccurrencesCount: createReducer<KeyValue<BuildId, number | undefined>>({}, builder => {
    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      for (const [id, build] of Object.entries(action.payload)) {
        const buildId = toBuildId(id)
        const count = build?.testOccurrences?.count
        if (count != null) {
          state[buildId] = count
        } else {
          delete state[buildId]
        }
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildNormalized.matchFulfilled, (state, action) => {
      const buildId = action.payload.result
      const count = action.payload.entities.buildsTestOccurrencesCount?.[buildId]
      if (count != null) {
        state[buildId] = count
      } else {
        delete state[buildId]
      }
    })
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action)
      ) {
        action.payload.result.forEach(buildId => {
          const count = action.payload.entities.buildsTestOccurrencesCount?.[buildId]
          if (count != null) {
            state[buildId] = count
          } else {
            delete state[buildId]
          }
        })
      }
    })
  }),

  buildsFirstBuildWithSameChanges: createReducer<KeyValue<BuildId, NormalizedBuildType | null>>(
    {},
    builder => {
      builder.addMatcher(restApi.endpoints.getBuildNormalized.matchFulfilled, (state, action) => {
        Object.assign(state, action.payload.entities.buildsFirstBuildWithSameChanges)
      })
    },
  ),

  changes: createReducer<KeyValue<ChangeId, NormalizedChangeType>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getModificationsOfChange.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.entities.changes)
      },
    )
    builder.addMatcher(restApi.endpoints.getAllChanges.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.changes?.entities.changes)
    })
    builder.addDefaultCase((state, action) => {
      if (
        receiveBuildChanges.match(action) ||
        restApi.endpoints.getBuildNormalized.matchFulfilled(action)
      ) {
        const {changes} = action.payload.entities
        if (changes != null) {
          objectEntries(changes).forEach(([key, value]) => {
            if (!(key in state)) {
              state[key] = castDraft(value)
            }
          })
        }
      }
    })
  }),

  changesStatus: createReducer(getEmptyHash(), builder =>
    builder.addCase(fetchChangeStatus.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.changesStatus)
    }),
  ),

  changesStatusBuilds: createReducer(getEmptyHash(), builder =>
    builder.addCase(fetchChangeStatusBuilds.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.changesStatus)
    }),
  ),

  changesBranches: createReducer(getEmptyHash(), builder =>
    builder.addCase(fetchChangesBranches.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.changesBranches)
    }),
  ),

  modificationsOfChanges: createReducer(getEmptyHash(), builder =>
    builder.addMatcher(
      restApi.endpoints.getModificationsOfChange.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.entities.modificationsOfChanges)
      },
    ),
  ),

  revisions: createReducer({}, builder =>
    builder.addCase(fetchBuildChangesRevisions.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.revisions)
    }),
  ),

  vcsLabels: createReducer({}, builder =>
    builder.addDefaultCase((state, action) => {
      if (
        receiveBuildChanges.match(action) ||
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getModificationsOfChange.matchFulfilled(action) ||
        fetchBuildChangesRevisions.fulfilled.match(action)
      ) {
        Object.assign(state, action.payload.entities.vcsLabels)
      }
    }),
  ),

  vcsRootInstances: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getAllChanges.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.changes?.entities.vcsRootInstances)
    })
    builder.addDefaultCase((state, action) => {
      if (
        receiveBuildChanges.match(action) ||
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getModificationsOfChange.matchFulfilled(action) ||
        fetchBuildChangesRevisions.fulfilled.match(action)
      ) {
        Object.assign(state, action.payload.entities.vcsRootInstances)
      }
    })
  }),

  vcsRoots: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getAllChanges.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.changes?.entities.vcsRoots)
    })
    builder.addDefaultCase((state, action) => {
      if (
        receiveBuildChanges.match(action) ||
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getModificationsOfChange.matchFulfilled(action) ||
        fetchBuildChangesRevisions.fulfilled.match(action)
      ) {
        Object.assign(state, action.payload.entities.vcsRoots)
      }
    })
  }),

  snapshotDependencies: createReducer<SnapshotDependenciesIDListType>({}, builder =>
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action) ||
        fetchBranchesWithBuilds.fulfilled.match(action) ||
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action) ||
        fetchBuildsAction.fulfilled.match(action)
      ) {
        const builds = action.payload.entities.builds

        if (!builds) {
          return
        }

        for (const [buildId, build] of objectEntries(builds)) {
          const dependencies = build?.['snapshot-dependencies']?.build

          if (dependencies != null) {
            state[toBuildId(buildId)] = dependencies.map(dependency => dependency.id)
          }
        }
      }
    }),
  ),
})
