import batchVisible from '../../../actions/batchVisible'
import {buildDetailsQueue} from '../../../actions/buildDetailsQueue'
import type {AppThunk} from '../../../actions/types'
import {normalizeBuildArtifacts} from '../../../rest/schemata'
import {Build, restApi} from '../../../services/rest'
import {toBuildId} from '../../../types'
import type {BuildId} from '../../../types'
import {Namespaces} from '../../../utils/namespaces'

import {getBuildArtifactsArg} from './BuildArtifacts.rest'
import {buildArtifacts} from './BuildArtifacts.slice'

export const receiveBuildArtifacts = (data: ReadonlyArray<Build>) =>
  buildArtifacts.actions.receive(normalizeBuildArtifacts(data))
export const fetchBuildArtifactsCountQueue: (id: BuildId) => AppThunk<void> = batchVisible({
  queue: buildDetailsQueue,
  namespace: Namespaces.ARTIFACTS,
  request: (id, dispatch) =>
    dispatch(restApi.endpoints.getBuild.initiate(getBuildArtifactsArg(toBuildId(id)))).then(
      ({data}) => {
        if (data == null) {
          return []
        }
        if (data.artifacts?.count == null) {
          return [
            {
              ...data,
              artifacts: {
                count: 0,
              },
            },
          ]
        }

        return [data]
      },
    ),
  action: receiveBuildArtifacts,
})
