import {createFetchAction} from '../reducers/fetchable'
import {restRoot} from '../rest/consts'
import {projectFetchGenerator, requestSingleProject} from '../rest/projects'
import type {RestRequestOptions} from '../rest/request'
import {NormalizedProjects, normalizeProjectsData} from '../rest/schemata'
import {
  getProject,
  getProjectRequest,
  getProjectsFetchable,
  isSidebarArchivedProjectsLoaded,
} from '../selectors'
import {ROOT_PROJECT_ID} from '../types'
import type {
  ProjectId,
  ProjectReceiveMeta,
  ProjectWithDetailsType,
  RequestOptionsParams,
} from '../types'
import memoryCache from '../utils/memoryCache'

import type {AppThunk} from './types'
import {processServiceWorkerResponse} from './utils'

const processProject = (
  data: ProjectWithDetailsType,
  requestOptions: RequestOptionsParams = {
    withBuildTypes: true,
    withLinks: true,
    withParameters: true,
    withDescription: true,
    withArchivedSubprojectsIds: true,
  },
) => {
  const projects = normalizeProjectsData([data], requestOptions)
  return {
    entities: projects.entities,
    result: projects.result[0],
  }
}
type FetchSingleProjectDataArg = {
  projectId: ProjectId
  options?: RequestOptionsParams
  restOptions?: RestRequestOptions
}
export const fetchSingleProjectDataAction = createFetchAction(
  'fetchSingleProjectData',
  ({projectId, options, restOptions}: FetchSingleProjectDataArg) =>
    requestSingleProject(restRoot, projectId, options, restOptions).then(data =>
      processProject(data, options),
    ),
)
export const fetchSingleProjectData = (
  projectId: ProjectId,
  options: RequestOptionsParams = {
    withBuildTypes: true,
    withLinks: true,
    withParameters: true,
    withArchivedSubprojectsIds: true,
    withDescription: true,
    withPauseComment: true,
  },
  restOptions?: RestRequestOptions,
) => fetchSingleProjectDataAction({projectId, options, restOptions})
export const receiveProject = (
  data: ProjectWithDetailsType,
  id?: ProjectId,
  requestOptions: RequestOptionsParams = {
    withBuildTypes: true,
    withLinks: true,
    withParameters: true,
    withDescription: true,
    withArchivedSubprojectsIds: true,
  },
) =>
  fetchSingleProjectDataAction.fulfilled(processProject(data, requestOptions), '', {
    projectId: id ?? data.id,
    options: requestOptions,
  })
export const fetchProjectWithAllParentsData =
  (projectId: ProjectId, restOptions?: RestRequestOptions): AppThunk<any> =>
  async (dispatch, getState) => {
    let parentProjectId: ProjectId | null | undefined = projectId

    while (
      parentProjectId &&
      (parentProjectId !== ROOT_PROJECT_ID || parentProjectId === projectId)
    ) {
      const state = getState()
      let parent = getProject(state, parentProjectId)

      if (parent == null) {
        const request =
          getProjectRequest(state, parentProjectId) ||
          dispatch(fetchSingleProjectData(parentProjectId, undefined, restOptions))
        await request
        parent = getProject(getState(), parentProjectId)
      }

      parentProjectId = parent?.parentProjectId
    }
  }
type FetchProjectsDataArg = {
  options: RequestOptionsParams
  rootProjectId: ProjectId
  force?: boolean
  meta?: ProjectReceiveMeta
}
export const fetchProjectsDataAction = createFetchAction<NormalizedProjects, FetchProjectsDataArg>(
  'fetchProjectsData',
  () => new Promise(() => {}),
)
export const receiveProjects = (
  projectsData: ReadonlyArray<ProjectWithDetailsType>,
  requestOptions: RequestOptionsParams,
  receiveMeta: ProjectReceiveMeta = {},
  rootProjectId: ProjectId = ROOT_PROJECT_ID,
) => {
  const data = normalizeProjectsData(projectsData, requestOptions)
  return fetchProjectsDataAction.fulfilled(data, '', {
    rootProjectId,
    options: requestOptions,
    meta: receiveMeta,
  })
}
export const fetchProjectsDataWithOptions =
  (
    options: RequestOptionsParams,
    rootProjectId: ProjectId,
    force?: boolean,
    meta?: ProjectReceiveMeta,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const arg = {options, rootProjectId, force, meta}
    const state = getState()

    if (
      force !== true &&
      (getProjectsFetchable(state, rootProjectId).inited ||
        getProjectsFetchable(state, ROOT_PROJECT_ID).inited)
    ) {
      return Promise.resolve()
    }

    processServiceWorkerResponse({
      iterator: projectFetchGenerator(restRoot, rootProjectId, options),
      onStart: () => dispatch(fetchProjectsDataAction.pending('', arg)),
      onSuccess: payload => {
        memoryCache.reset('fullPath')
        dispatch(receiveProjects(payload, options, meta, rootProjectId))
      },
      onError: error => dispatch(fetchProjectsDataAction.rejected(error, '', arg)),
    })
    return Promise.resolve()
  }
export const fetchProjectsData =
  (force?: boolean): AppThunk<any> =>
  (dispatch, getState) =>
    fetchProjectsDataWithOptions(
      {
        withBuildTypes: false,
        withLinks: true,
      },
      ROOT_PROJECT_ID,
      force,
    )(dispatch, getState)
export const fetchProjectsWithArchivedData =
  (force?: boolean): AppThunk<any> =>
  (dispatch, getState) =>
    fetchProjectsDataWithOptions(
      {
        withBuildTypes: false,
        withLinks: true,
        archived: 'any',
      },
      ROOT_PROJECT_ID,
      force,
    )(dispatch, getState)
export const fetchProjectsWithBuildTypesData =
  (
    force?: boolean,
    rootProjectId: ProjectId = ROOT_PROJECT_ID,
    options?: RequestOptionsParams,
  ): AppThunk<any> =>
  (dispatch, getState) =>
    fetchProjectsDataWithOptions(
      {
        withBuildTypes: true,
        withDescription: true,
        withAncestorProjects: true,
        ...options,
      },
      rootProjectId,
      force,
      {
        sidebarProjectsLoaded: true,
      },
    )(dispatch, getState)
export const fetchArchivedProjects =
  (rootProjectId: ProjectId = ROOT_PROJECT_ID): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState()
    const archivedProjectsLoaded = isSidebarArchivedProjectsLoaded(state, rootProjectId)

    if (!archivedProjectsLoaded) {
      dispatch(
        fetchProjectsDataWithOptions(
          {
            withBuildTypes: true,
            withDescription: true,
            archived: 'any',
          },
          rootProjectId,
          true,
          {
            sidebarArchivedProjectsLoaded: true,
          },
        ),
      )
    }
  }
