import Button from '@jetbrains/ring-ui/components/button/button'
import type {ButtonAttrs} from '@jetbrains/ring-ui/components/button/button'
import buttonStyles from '@jetbrains/ring-ui/components/button/button.css'
import type {IconType} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'
import * as React from 'react'

import {getDisplayName} from '../../../utils/getDisplayName'
import SvgIcon from '../SvgIcon/SvgIcon'

type DefaultProps = {
  readonly loader: boolean
}
type Props = ButtonAttrs &
  DefaultProps & {
    readonly className?: string
    readonly icon: IconType | string
    readonly iconClassName?: string
    readonly iconWrapperClassName?: string
    readonly special?: boolean
    readonly title?: string
    readonly children?: React.ReactNode
    readonly childrenLeft?: boolean
  }
export default class IconButton extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    loader: false,
  }

  render(): React.ReactNode {
    const {
      className,
      icon,
      iconClassName,
      iconWrapperClassName,
      loader,
      special,
      title,
      children,
      childrenLeft,
      ...restProps
    } = this.props
    const classes = classNames(buttonStyles.withIcon, className, {
      [buttonStyles.withNormalIcon]: special !== true && !restProps.primary && !restProps.danger,
      [buttonStyles.withDangerIcon]: restProps.danger,
    })
    const iconWrapperClasses = classNames(buttonStyles.icon, iconWrapperClassName)
    return (
      <Button
        {...restProps}
        className={classes}
        title={title ?? (typeof children === 'string' ? children : null) ?? getDisplayName(icon)}
      >
        {Boolean(children) && Boolean(childrenLeft) && <span>{children}</span>}
        <span className={iconWrapperClasses}>
          <SvgIcon icon={icon} className={iconClassName} loading={loader} />
        </span>
        {Boolean(children) && (childrenLeft == null || children === false) && (
          <span>{children}</span>
        )}
      </Button>
    )
  }
}
