import {useSelector} from 'react-redux'

import {BuildTypeId} from '../../types'
import {getBuildTypeDsl, getDslRequestEndpoint, getFragmentDsl} from '../Dsl/Dsl.selectors'

export const useIsDslLoaded = (
  controlId: string,
  buildTypeOrTemplateId?: BuildTypeId,
  isFragment?: boolean,
) => {
  const dslEndpoint = useSelector(state =>
    !isFragment && buildTypeOrTemplateId != null
      ? getDslRequestEndpoint(state, buildTypeOrTemplateId, controlId)
      : null,
  )
  const data = useSelector(
    state =>
      (dslEndpoint != null ? getBuildTypeDsl(state, dslEndpoint) : getFragmentDsl(state)) ?? null,
  )

  return data != null
}
