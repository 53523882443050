import * as React from 'react'
import * as ReactRedux from 'react-redux'

import type {State} from '../../../../reducers/types'
import {getBuild, getIsAutodetectTimeZone} from '../../../../selectors'
import type {BuildId} from '../../../../types'

import BuildDurationAnchor from './BuildDurationAnchor'

type Props = {
  buildId: BuildId
  children?: React.ReactNode
}

const mapStateToProps = (state: State, props: Props) => {
  const build = getBuild(state, props.buildId)
  const {
    percentageComplete,
    elapsedSeconds = 0,
    estimatedTotalSeconds = 0,
    leftSeconds,
    overtime,
  } = build?.['running-info'] || {}
  return {
    progress: percentageComplete,
    status: build?.status,
    elapsed: elapsedSeconds,
    left: leftSeconds,
    overtime: overtime ?? (estimatedTotalSeconds > 0 ? elapsedSeconds - estimatedTotalSeconds : 0),
    started: build?.startDate,
    finished: build?.finishDate,
    isAutodetectTimeZone: getIsAutodetectTimeZone(state),
  }
}

export default ReactRedux.connect(mapStateToProps)(BuildDurationAnchor)
