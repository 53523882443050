import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import * as React from 'react'

import {BS} from '../../types/BS_types'

type DefaultProps = {
  readonly children: React.ReactNode
  readonly Component: React.ComponentType<any> | string
}
type Props = DefaultProps & {
  readonly url: string
  readonly width?: number
  readonly height?: number
  readonly icon?: Icon | string
  readonly className?: string
  readonly preservePlus?: boolean
}

class HelpLink extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    Component: 'a',
    children: null,
  }

  handleClick: (arg0: React.SyntheticEvent) => void = e => {
    const {url, width, height, preservePlus} = this.props
    BS?.Util?.showHelp(e.nativeEvent, url, {
      width,
      height,
      preservePlus,
    })
    e.preventDefault()
  }

  render(): React.ReactNode {
    const {children, url, width, height, Component, preservePlus, ...restProps} = this.props
    return (
      <Component {...restProps} href={url} onClick={this.handleClick} title="View help">
        {children}
      </Component>
    )
  }
}

export default HelpLink
