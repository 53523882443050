import type {History, Location} from 'history'
import {createBrowserHistory, parsePath} from 'history'
import * as React from 'react'
import {matchPath, useLocation, useNavigate} from 'react-router-dom'
import type {NavigateFunction, PathMatch} from 'react-router-dom'

import {ChangePageTabNamesEnum} from '../components/common/ChangeDetailsTabs/ChangeDetailsTabs.types'
import withHook from '../hocs/withHook'
import type {
  ActiveEntityURLProps,
  AgentId,
  AgentPoolId,
  AgentTypeId,
  BuildId,
  BuildTypeId,
  ChangeId,
  Enhancer,
  FederationServerId,
  ProjectId,
  TestId,
} from '../types'
import {stringifyId} from '../types'
import {base_uri} from '../types/BS_types'
import type {KeyValue} from '../utils/object'
import type {QueryParams} from '../utils/queryParams'
import {objectToQuery, queryToObject} from '../utils/queryParams'
import {parseURL, resolveRelativeCustomBase} from '../utils/url'

import SharedRoutes from './shared-routes.json'

export const browserHistory = createBrowserHistory()

// Remove trailing slash, ensure leading slash
const basePath = parseURL(base_uri)
  .pathname.replace(/\/$/g, '')
  .replace(/^[^\/]/, '/$&')
export const Routes = {...SharedRoutes, BASE: `${basePath}/*`}
const AGENTS_SCREEN_ROUTES = [
  Routes.AGENTS_OVERVIEW,
  Routes.DISCONNECTED_AGENTS_OVERVIEW,
  Routes.AGENT,
  Routes.AGENT_POOL,
  Routes.CLOUD_IMAGE,
  Routes.AGENTS_UNAUTHORIZED,
  Routes.AGENTS,
]
export default Routes
export const getBaseRoute = (route: string): string => Routes.BASE.replace('*', route)
export const matchRoute = (
  route: string | null | undefined,
  {pathname}: Location | Window['location'],
): PathMatch | null => (route == null ? null : matchPath(getBaseRoute(route), pathname))
export const HistoryContext: React.Context<History> = React.createContext<History>(browserHistory)
export const getHref = (
  route: string,
  params: KeyValue<string, string> = {},
  hash?: string | null,
): string =>
  getBaseRoute(route)
    .replace(/:([^\/]*)/g, (_, param) => params[param] ?? '')
    .concat(hash != null ? `#${hash}` : '')
export const ALL_PROJECTS_HASH = 'all-projects'
export const getProjectHref = (projectId: ProjectId, isAllProjects?: boolean): string =>
  getHref(
    Routes.PROJECT,
    {
      projectId: stringifyId(projectId),
    },
    isAllProjects === true ? ALL_PROJECTS_HASH : null,
  )
export const getFavoriteProjectsHref = (): string => getBaseRoute(Routes.FAVORITE_PROJECTS)
export const getFavoriteBuildsHref = (): string => getBaseRoute(Routes.FAVORITE_BUILDS)
export const getBuildTypeHref = (buildTypeId: BuildTypeId, isAllProjects?: boolean): string =>
  getHref(
    Routes.BUILD_TYPE,
    {
      buildTypeId: stringifyId(buildTypeId),
    },
    isAllProjects === true ? ALL_PROJECTS_HASH : null,
  )
export const getBuildHref = (
  buildId: BuildId | string,
  buildTypeId?: BuildTypeId | null | undefined,
  isAllProjects?: boolean | null | undefined,
): string =>
  getHref(
    buildTypeId != null ? Routes.BUILD : Routes.BUILD_UNKNOWN_BUILDTYPE,
    {
      buildId: stringifyId(buildId),
      buildTypeId: stringifyId(buildTypeId),
    },
    isAllProjects === true ? ALL_PROJECTS_HASH : null,
  )
export const getCompareBuildHref = (sourceId: BuildId): string =>
  getHref(Routes.COMPARE_BUILDS, {
    buildIdSource: stringifyId(sourceId),
  })
export const getOverviewHref = ({
  projectId,
  buildTypeId,
  buildId,
  isAllProjects,
}: ActiveEntityURLProps): string => {
  if (buildId != null) {
    return getBuildHref(buildId, buildTypeId, isAllProjects)
  }

  if (buildTypeId != null) {
    return getBuildTypeHref(buildTypeId, isAllProjects)
  }

  if (projectId != null) {
    return getProjectHref(projectId, isAllProjects)
  }

  return getFavoriteProjectsHref()
}
export const getAgentsHref = (): string => getHref(Routes.AGENTS, {})
export const getAgentsOverviewHref = (): string => getHref(Routes.AGENTS_OVERVIEW, {})
export const getDisconnectedAgentsOverviewHref = (): string =>
  getHref(Routes.DISCONNECTED_AGENTS_OVERVIEW, {})
export const getUnauthorizedAgentsHref = (): string => getHref(Routes.AGENTS_UNAUTHORIZED, {})
export const getAgentHref = (agentId: AgentId): string =>
  getHref(Routes.AGENT, {
    agentId: stringifyId(agentId),
  })
export const getAgentPoolHref = (agentPoolId: AgentPoolId): string =>
  getHref(Routes.AGENT_POOL, {
    agentPoolId: stringifyId(agentPoolId),
  })
export const getAgentTypeHref = (agentTypeId: AgentTypeId | null | undefined): string =>
  getHref(Routes.CLOUD_IMAGE, {
    agentTypeId: stringifyId(agentTypeId),
  })
export const getTestHistoryHref = (
  testId: TestId | null | undefined,
  params: QueryParams,
): string =>
  `${getHref(Routes.TEST, {
    testId: stringifyId(testId),
  })}?${objectToQuery(params)}`

export const getChangeHref = ({
  changeId,
  buildTypeId,
  personal,
  tab,
}: {
  changeId: ChangeId
  buildTypeId?: BuildTypeId | null | undefined
  personal?: boolean | null | undefined
  tab?: string | null | undefined
}): string =>
  `${getHref(Routes.CHANGE, {changeId: stringifyId(changeId)})}?${objectToQuery({
    personal: personal != null ? String(personal) : null,
    buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
    tab: tab ?? ChangePageTabNamesEnum.FILES,
  })}`

export const getChangesHref = (): string => getHref(Routes.CHANGES)

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const getPipelinesHref = (): string => getHref(Routes.PIPELINES).slice(0, -2)

export const getOldOverviewHrefWithCustomBase = ({
  projectId,
  buildTypeId,
  serverId,
  isAdmin = false,
  isTemplate = false,
}: {
  projectId?: ProjectId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  serverId: FederationServerId
  isAdmin?: boolean
  isTemplate?: boolean
}): string => {
  if (projectId) {
    return resolveRelativeCustomBase(
      stringifyId(serverId),
      isAdmin ? '/admin/editProject.html' : '/project.html',
      {
        projectId: stringifyId(projectId),
      },
    )
  }

  if (buildTypeId) {
    return resolveRelativeCustomBase(
      stringifyId(serverId),
      isAdmin ? '/admin/editBuild.html' : '/viewType.html',
      isAdmin
        ? {
            id: `${isTemplate ? 'template' : 'buildType'}:${stringifyId(buildTypeId)}`,
          }
        : {
            buildTypeId: stringifyId(buildTypeId),
          },
    )
  }

  return stringifyId(serverId)
}
export const getQueueHref = (): string => getHref(Routes.QUEUE, {})
export type LocationProps = {
  readonly location: Location
  readonly navigate: NavigateFunction
}
export const withLocation: Enhancer<LocationProps, any> = withHook(() => ({
  location: useLocation(),
  navigate: useNavigate(),
}))
export const getHrefWithQueryParams = (
  location: Location,
  href?: string | null,
  params: QueryParams | ((prevParams: QueryParams) => QueryParams) = p => p,
  hash?: string,
): string =>
  browserHistory.createHref({
    ...(href != null ? parsePath(href) : location),
    search: objectToQuery(
      typeof params === 'function' ? params(queryToObject(location.search ?? {})) : params,
    ),
    ...(hash != null
      ? {
          hash: `#${hash}`,
        }
      : null),
  })
//
// export const useHrefWithQueryParams = (
//   href: ?string,
//   params: ?QueryParams | (QueryParams => QueryParams),
//   hash: ?string,
// ) => useLocationSelector(location => getHrefWithQueryParams(location, href, params, hash))
export const isQueueScreen = (location: Location | Window['location']): boolean =>
  matchRoute(Routes.QUEUE, location) != null

// It's not possible to solve this issue with the `react-router-dom`
export function isAgentsScreen(location: Location) {
  return Boolean(AGENTS_SCREEN_ROUTES.find(route => matchRoute(route, location)))
}

export const isChangesScreen = (location: Location): boolean =>
  matchRoute(Routes.CHANGES, location) != null

export const isPipelinesScreen = (location: Location): boolean =>
  matchRoute(Routes.PIPELINES, location) != null

const EXPERIMENTAL_UI_ONLY_ROUTES = [Routes.COMPARE_BUILDS, Routes.GUIDES, Routes.PIPELINES]
export const isExperimentalUIOnlyScreen = (location: Location): boolean =>
  EXPERIMENTAL_UI_ONLY_ROUTES.some(route => matchRoute(route, location) != null)
