import {createReducer, PayloadAction} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {
  defaultAgentsScreenState,
  agentsTreeSearchState as agentsTreeSearchStateSlice,
} from './AgentsScreen.slices'
import type {AgentsScreenState} from './AgentsScreen.types'
import {AgentsTreeExpandState} from './AgentsScreen.types'
import {setAgentsTreeItemExpandStateAction} from './AgentsSidebar/AgentsSidebar.actions'

const agentsTreeExpandStateReducer = createReducer<AgentsTreeExpandState>({}, builder =>
  builder.addCase(setAgentsTreeItemExpandStateAction, (state, action) => {
    const {agentItemType, agentItemId, expanded} = action.payload
    state[agentItemType] ??= {}
    state[agentItemType]![agentItemId] = expanded
  }),
)

export const agentsScreenReducers: Redux.Reducer<AgentsScreenState, PayloadAction<unknown>> = (
  {
    agentsTreeExpandState,
    agentsTreeSearchExpandState,
    agentsTreeSearchState,
  }: AgentsScreenState = defaultAgentsScreenState,
  action,
): AgentsScreenState => {
  const {searchQuery} = agentsTreeSearchState
  return {
    agentsTreeExpandState:
      searchQuery === ''
        ? agentsTreeExpandStateReducer(agentsTreeExpandState, action)
        : agentsTreeExpandState,
    agentsTreeSearchExpandState:
      searchQuery !== ''
        ? agentsTreeExpandStateReducer(agentsTreeSearchExpandState, action)
        : defaultAgentsScreenState.agentsTreeSearchExpandState,
    agentsTreeSearchState: agentsTreeSearchStateSlice.reducer(agentsTreeSearchState, action),
  }
}
