import {createFetchAction} from '../../../../../reducers/fetchable'
import {restRoot} from '../../../../../rest/consts'
import {normalizeBuildChangesRevisions} from '../../../../../rest/schemata'
import {BuildChangesRevisionsType} from '../../../../../types'

import {requestBuildChangesRevisions} from './ChangesRevisions.rest'
import {getBuildChangesRevisionsFetchable} from './ChangesRevisions.selectors'
import {RequestBuildChangesRevisionsParams} from './ChangesRevisions.types'

type FetchBuildChangesRevisionsArg = {
  locator: string
  options?: RequestBuildChangesRevisionsParams
}
export const fetchBuildChangesRevisions = createFetchAction(
  'fetchBuildChangesRevisions',
  ({locator, options}: FetchBuildChangesRevisionsArg) =>
    requestBuildChangesRevisions(restRoot, locator, options).then(normalizeBuildChangesRevisions),
  {
    condition({locator}, {getState}) {
      const state = getState()
      return !getBuildChangesRevisionsFetchable(state, locator).loading
    },
  },
)

export const receiveBuildChangesRevisions = (
  data: ReadonlyArray<BuildChangesRevisionsType>,
  locator: string,
) => fetchBuildChangesRevisions.fulfilled(normalizeBuildChangesRevisions(data), '', {locator})
