import * as Redux from 'redux'

import {FetchAction} from '../../actions/types'
import {keyValueFetchable} from '../../reducers/fetchable'
import {keyValueReducer} from '../../reducers/utils'

import {
  fetchProblemOccurrenceSubtree,
  FetchProblemOccurrenceSubTreeParamsType,
  fetchProblemOccurrenceTree,
} from './BuildProblems.actions'
import {ProblemOccurrencesTreeType, BuildProblemsStateType} from './BuildProblems.types'

const problemOccurrencesTree = keyValueFetchable(
  arg => arg.locator,
  fetchProblemOccurrenceTree,
  null as ProblemOccurrencesTreeType | null,
  (_, action) => action.payload.tree,
)

const problemOccurrencesSubtree = keyValueFetchable(
  arg => arg.subTreeRootId,
  fetchProblemOccurrenceSubtree,
  null as ProblemOccurrencesTreeType | null,
  (_, action) => action.payload.tree,
)

const problemsReducer = Redux.combineReducers<BuildProblemsStateType>({
  problemOccurrencesTree,
  problemOccurrencesSubtree: keyValueReducer(
    (action: FetchAction<FetchProblemOccurrenceSubTreeParamsType>) => action.meta.arg.treeLocator,
    problemOccurrencesSubtree,
    [
      fetchProblemOccurrenceSubtree.pending,
      fetchProblemOccurrenceSubtree.fulfilled,
      fetchProblemOccurrenceSubtree.rejected,
    ],
  ),
})
export default problemsReducer
