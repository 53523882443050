import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import processResponse from '../rest/processResponse'
import request, {getHeaders} from '../rest/request'

const baseQuery = (isText: boolean) =>
  fetchBaseQuery({
    baseUrl: window.base_uri,
    credentials: 'include',
    prepareHeaders: headers => {
      if (isText) {
        headers.set('Accept', 'text/plain')
      }
      return new Headers(getHeaders({headers}))
    },
    fetchFn(input, init) {
      const options = typeof input === 'string' ? init : input
      const url = typeof input === 'string' ? input : input.url
      return request(url, null, options)
    },
  })

export const restBaseApi = createApi({
  reducerPath: 'restApi',
  baseQuery: (arg, ...args) =>
    baseQuery(arg.responseHandler === 'text')(
      {
        responseHandler: response => processResponse(response).catch(e => e),
        validateStatus: response => response.ok,
        ...arg,
      },
      ...args,
    ),
  tagTypes: ['User', 'Build'],
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 5,
})
