import moment from 'moment'

import type {NormalizedBuildType, BuildStats} from '../types'

export default ({
  id,
  links,
  status = 'UNKNOWN',
  state,
  queuedDate,
  startDate,
  finishDate,
  ['running-info']: runningInfo,
}: NormalizedBuildType): BuildStats => ({
  id,
  links,
  status,
  state,
  startDate,
  queuedDate,
  finishDate,
  timeInQueue: moment(startDate).diff(moment(queuedDate), 'seconds'),
  duration: runningInfo?.elapsedSeconds ?? moment(finishDate).diff(moment(startDate), 'seconds'),
})
