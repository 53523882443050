import Alert from '@jetbrains/ring-ui/components/alert/alert'
import type {PlaceId} from '@jetbrains/teamcity-api'
import * as React from 'react'

import {base_uri} from '../../../../types/BS_types'
import {getTopNavHelperData} from '../../../App/Header/Header.utils'
import Link from '../../Link/Link'
import ServiceMessage from '../../ServiceMessage/ServiceMessage'

type OwnProps = {
  readonly name: string
  readonly placeId: PlaceId
  readonly children: React.ReactNode
}
type StateProps = {
  error: Error | null | undefined
  showError: boolean
  isClosing: boolean
}

class PluginErrorBoundary extends React.PureComponent<OwnProps, StateProps> {
  state: StateProps = {
    error: null,
    showError: false,
    isClosing: false,
  }

  close: () => void = () => {
    this.setState(state => ({...state, showError: false}))
  }

  componentDidCatch(error: Error) {
    // eslint-disable-next-line no-console
    console.error(`Plugin ${this.props.name} failed.`, error)
    this.setState({
      error,
      showError: true,
    })
  }

  render(): React.ReactNode {
    const {adminSpaceAvailable} = getTopNavHelperData()

    if (this.state.error != null) {
      return this.state.showError ? (
        <ServiceMessage
          title={`Error in Plugin "${this.props.name}"`}
          type={Alert.Type.ERROR}
          onCloseRequest={this.close}
        >
          <p>{`Plugin "${this.props.name}" in ${this.props.placeId} has been broken`}</p>
          {adminSpaceAvailable ? (
            <p>
              {'You could disable plugin '}
              <Link href={`${base_uri}/admin/admin.html?item=plugins`}>{'here'}</Link>
            </p>
          ) : null}
        </ServiceMessage>
      ) : null
    }

    return this.props.children
  }
}

export default PluginErrorBoundary
