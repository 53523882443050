import type {PlaceId} from '@jetbrains/teamcity-api'
import * as React from 'react'
import {useSelector} from 'react-redux'

import {emptyArray} from '../../../utils/empty'

import {usePluginsLoader} from './Plugin.loader'
import {fetchPlaceIdAvailablePlugins} from './Plugin.requests'
import {getCachedPlugins} from './Plugin.selectors'
import type {PluginLocationContext, Props} from './Plugin.types'
import {DummyPluginType} from './Plugin.types'
import {getActiveEntitiesFromUrl} from './Plugin.utils'

const useCachedPlugins = (placeId: PlaceId): ReadonlyArray<string> =>
  useSelector(state => getCachedPlugins(state, placeId))

const useActiveEntities = ({
  projectId,
  buildId,
  buildTypeId,
  agentId,
  agentPoolId,
  agentTypeId,
  testRunId,
}: PluginLocationContext): PluginLocationContext =>
  React.useMemo(
    () =>
      window.ReactUI.isExperimentalUI
        ? {
            projectId,
            buildId,
            buildTypeId,
            agentId,
            agentPoolId,
            agentTypeId,
            testRunId,
          }
        : getActiveEntitiesFromUrl(),
    [agentId, agentPoolId, agentTypeId, buildId, buildTypeId, projectId, testRunId],
  )

const useAvailablePlugins = (
  placeId: PlaceId,
  activeEntities: PluginLocationContext,
  disableFetch?: boolean,
): [boolean, readonly DummyPluginType[]] => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [availablePlugins, setAvailablePlugins] = React.useState(emptyArray)
  React.useEffect(() => {
    const requestPlugins = async () => {
      if (disableFetch === true) {
        return
      }

      setIsLoading(true)

      try {
        const list = await fetchPlaceIdAvailablePlugins(placeId, activeEntities)
        setAvailablePlugins(list.length > 0 ? list : emptyArray)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }

    requestPlugins()
  }, [activeEntities, disableFetch, placeId])
  return [isLoading, availablePlugins]
}

export const usePlugins = ({
  placeId,
  pluginDevelopmentMode,
  disableFetch,
  ...restEntities
}: Props): [PluginLocationContext, ReadonlyArray<string>] => {
  const activeEntities = useActiveEntities(restEntities)
  const [availablePluginsLoading, availablePlugins] = useAvailablePlugins(
    placeId,
    activeEntities,
    disableFetch,
  )
  usePluginsLoader({
    availablePlugins,
    availablePluginsLoading,
    activeEntities,
    placeId,
    pluginDevelopmentMode,
  })
  const pluginsToRender = useCachedPlugins(placeId)
  return [activeEntities, pluginsToRender]
}
