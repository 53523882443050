import {WrapTextProps} from '@jetbrains/ring-ui/components/link/link'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import type {ListDataItem} from '@jetbrains/ring-ui/components/list/consts'
import {ComponentType, Fragment, useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {setUserProperty} from '../../actions'
import {getIsGuestOrRoot} from '../../selectors'
import {BS} from '../../types/BS_types'
import {truthy} from '../../utils/guards'
import {resolveHelpURL} from '../../utils/url'
import {UserProperties} from '../../utils/userProperties'
import SvgIcon from '../common/SvgIcon/SvgIcon'
import {getTipsModeEnabled} from '../Hints/Hints.selectors'

import styles from './HelpDropdown.css'

export const UNIT = 8
const links = [
  {
    label: 'Help',
    href: resolveHelpURL('?TeamCity Documentation'),
    rgItemType: ListItemType.LINK,
  },
  {
    label: 'Feedback',
    href: BS?.feedbackUrl,
    rgItemType: ListItemType.LINK,
  },
  {
    label: 'Getting started',
    href: resolveHelpURL('?Configure and Run Your First Build'),
    rgItemType: ListItemType.LINK,
  },
]
export const whatsNewHref: string = resolveHelpURL("?What's+New+in+TeamCity")
export const aboutSakuraUIHref: string = resolveHelpURL('teamcity-sakura-ui.html')
export function useHelpDropdownContent(): ReadonlyArray<ListDataItem> {
  const dispatch = useDispatch()
  const isGuestUser = useSelector(state => getIsGuestOrRoot(state))
  const enabled = useSelector(getTipsModeEnabled)
  const toggleTipsMode = useCallback(() => {
    dispatch(setUserProperty(UserProperties.TIPS_MODE, enabled ? 'false' : 'true'))
  }, [dispatch, enabled])

  return [
    ...links,
    !isGuestUser && {
      key: 'enable-tips',
      rgItemType: ListItemType.LINK,
      label: `${enabled ? 'Hide' : 'Show'} hints`,
      onClick: toggleTipsMode,
    },
    {
      key: 'about-sakura-ui',
      className: styles.customLink,
      href: aboutSakuraUIHref,
      children: (WrapText: ComponentType<WrapTextProps>) => (
        <Fragment>
          <WrapText>{'About Sakura UI'}</WrapText>
        </Fragment>
      ),
    },
    {
      key: 'whats-new',
      className: styles.customLink,
      href: whatsNewHref,
      children: (WrapText: ComponentType<WrapTextProps>) => (
        <Fragment>
          <SvgIcon icon="gift" className={styles.customLinkIcon} />
          <WrapText>{"What's new"}</WrapText>
        </Fragment>
      ),
    },
  ].filter(truthy)
}
