import MoveToTopIcon from '@jetbrains/icons/move-to-top.svg'
import classNames from 'classnames'
import * as React from 'react'

import type {BuildId} from '../../types'
import IconButton from '../common/IconButton/IconButton'

import styles from './MoveToTop.css'

type DefaultProps = {
  readonly clickHandler: (arg0: BuildId) => Promise<void>
}
type Props = DefaultProps & {
  readonly className?: string
  readonly buildId: BuildId
  readonly isDraggable: boolean
  readonly updateBuilds?: () => unknown
}
type State = {
  readonly isMoving: boolean
}

const getTitle = () => 'Move to top'

export default class MoveToTop extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    clickHandler: () => Promise.resolve(),
  }

  state: State = {
    isMoving: false,
  }

  clickHandler: () => Promise<void> = async () => {
    this.setState({
      isMoving: true,
    })
    const {buildId, updateBuilds} = this.props
    await this.props.clickHandler(buildId)
    this.setState({
      isMoving: false,
    })

    if (updateBuilds) {
      updateBuilds()
    }
  }

  render(): React.ReactNode {
    const {className, isDraggable} = this.props
    const classes = classNames(styles.moveToTop, className)
    return isDraggable ? (
      <IconButton
        className={classes}
        title={getTitle()}
        icon={MoveToTopIcon}
        onClick={this.clickHandler}
        loader={this.state.isMoving}
      />
    ) : null
  }
}
export type MoveToTopProps = JSX.LibraryManagedAttributes<typeof MoveToTop, Props>
