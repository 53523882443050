import {requestPostponedCallback} from './postponedTask'

type QueueEntry = {
  readonly id: string
  refCount: number
  readonly request: () => Promise<any>
}
const successRequestsInterval = 20 //ms
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const minFailedRequestsInterval = successRequestsInterval * 10

export default class RequestQueue {
  entries: Array<QueueEntry> = []
  isBusy: boolean = false
  failedRequestsInterval: number = minFailedRequestsInterval
  flush: () => Promise<void> = async () => {
    if (this.entries.length === 0) {
      this.isBusy = false
      return
    }

    const nextEntry = this.entries[0]
    this.isBusy = true

    try {
      await nextEntry.request()

      this.remove(nextEntry)
      requestPostponedCallback(this.flush, {timeout: successRequestsInterval})
      this.failedRequestsInterval = minFailedRequestsInterval
    } catch (e) {
      setTimeout(this.flush, this.failedRequestsInterval)
      this.failedRequestsInterval *= 2
    }
  }

  get(id: string): QueueEntry | null | undefined {
    return this.entries.find(entry => entry.id === id)
  }

  add(id: string, request: () => Promise<any>) {
    if (process.env.NODE_ENV === 'test') {
      request()
      return
    }

    const entry = this.get(id)

    if (entry != null) {
      /* $FlowFixMe This comment suppresses an error found when upgrading Flow
       * to v0.114.0. To view the error, delete this comment and run Flow. */
      entry.refCount += 1
    } else {
      this.entries.push({
        id,
        request,
        refCount: 1,
      })

      if (!this.isBusy) {
        this.flush()
      }
    }
  }

  cancel(id: string) {
    const entry = this.get(id)

    if (entry != null) {
      /* $FlowFixMe This comment suppresses an error found when upgrading Flow
       * to v0.114.0. To view the error, delete this comment and run Flow. */
      entry.refCount -= 1

      if (entry.refCount === 0) {
        this.remove(entry)
      }
    }
  }

  remove(entryToRemove: QueueEntry) {
    this.entries = this.entries.filter(entry => entry !== entryToRemove)
  }
}
