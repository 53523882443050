import EmptyStarIcon from '@jetbrains/icons/star-empty.svg'
import StarIcon from '@jetbrains/icons/star-filled.svg'
import classnames from 'classnames'
import * as React from 'react'

import type {BuildId} from '../../../types'
import IconButton from '../IconButton/IconButton'

import styles from './StarBuild.css'

function noop() {}

type DefaultProps = {
  readonly showEmpty: boolean
  readonly onStar: (starred: boolean) => unknown
}
type Props = DefaultProps & {
  readonly buildId?: BuildId
  readonly className?: string
  readonly isStarred: boolean
  readonly isStarrable: boolean
}

class StarBuild extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    showEmpty: false,
    onStar: noop,
  }

  getTitle: () => string = () =>
    this.props.isStarred ? 'Remove from favorites' : 'Add to favorites'

  handleStar: () => unknown = () => this.props.onStar(!this.props.isStarred)

  render(): React.ReactNode {
    const {className, isStarred, isStarrable, showEmpty} = this.props
    const invisibleClasses = classnames({
      [styles.invisible]: !showEmpty && !isStarred,
      [styles.hidden]: !isStarrable && !isStarred,
    })
    const classes = classnames(styles.button, className, invisibleClasses)
    const iconWrapperClasses = classnames({
      [styles.starred]: isStarred,
    })

    return (
      <IconButton
        title={this.getTitle()}
        disabled={!isStarrable}
        icon={isStarred ? StarIcon : EmptyStarIcon}
        onClick={this.handleStar}
        className={classes}
        iconClassName={invisibleClasses}
        iconWrapperClassName={iconWrapperClasses}
      />
    )
  }
}

export default StarBuild
