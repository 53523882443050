import {getBuildFields} from '../../rest/builds'
import {investigation} from '../../rest/investigations'
import processResponse from '../../rest/processResponse'
import request from '../../rest/request'
import {stringifyId, BuildTypeHierarchyType} from '../../types'
import type {TestOccurrenceId} from '../../types'

import {objectToQuery} from '../../utils/queryParams'

import type {
  RequestTestOccurrenceOptionsParams,
  TestFlakyType,
  TestOccurrenceType,
  TestOccurrencesCountsType,
  RequestFlakyTestsType,
  TestScopeType,
  TestScopesKeyType,
  TestOccurrencesTreeType,
} from './Tests.types'

export const muteFields =
  'mute(id,state,resolution(type,time),scope(project(id,name),buildTypes(buildType(id,name))),assignee(id,name,username),assignment(user(id,name,username),timestamp,text))'

const getTestCountersFields = (withDuration?: boolean): string =>
  `testCounters(failed,success,muted,all,ignored,newFailed${
    withDuration === true ? ',duration' : ''
  })`

const getTestOccurrenceFields = (options?: RequestTestOccurrenceOptionsParams): string => {
  const {
    withNewFailure,
    withMetadataCount,
    withNextFixed,
    withFirstFailed,
    withRunOrder,
    withMuteInfo,
    withInvocationsCounters,
    withInvestigationInfo,
    withBuildInfo,
  } = options ?? {}
  let fields =
    `id,name,status,duration,logAnchor,currentlyInvestigated,currentlyMuted,muted,` +
    `test(id,parsedTestName${
      withInvestigationInfo === true ? `,investigations(${investigation})` : ''
    }${withMuteInfo === true ? `,mutes(${muteFields})` : ''})`

  if (withNewFailure === true) {
    fields += ',newFailure'
  }

  if (withMetadataCount === true) {
    fields += ',metadata(count)'
  }

  if (withNextFixed === true) {
    fields += ',nextFixed(id)'
  }

  if (withFirstFailed === true) {
    fields += ',firstFailed(id)'
  }

  if (withRunOrder === true) {
    fields += ',runOrder'
  }

  if (withMuteInfo === true) {
    fields += `,${muteFields}`
  }

  if (withInvocationsCounters === true) {
    fields += `,invocations($locator(count:-1),${getTestCountersFields()})`
  }

  if (withBuildInfo === true) {
    fields += `,build(buildTypeId,${getBuildFields({
      withShortProjectDetails: true,
      withBuildTypeDetails: true,
      withQueuedInfo: true,
      withRunningInfo: true,
    })})`
  } else {
    fields += `,build(id,buildTypeId,personal,startDate,buildType(internalId,project(id,virtual,parentProjectId)),agent(id,name))`
  }

  return `testOccurrence(${fields})`
}

type TestOccurrencesData = {
  testOccurrence: readonly TestOccurrenceType[]
  nextHref?: string
}
export const requestTestOccurrences = (
  serverUrl: string,
  locator: string,
  options?: RequestTestOccurrenceOptionsParams,
): Promise<{
  data: ReadonlyArray<TestOccurrenceType>
  hasMore: boolean
}> =>
  request(
    serverUrl,
    `testOccurrences?locator=${encodeURIComponent(
      locator,
    )}&fields=nextHref,${getTestOccurrenceFields(options)}`,
  )
    .then<TestOccurrencesData>(processResponse)
    .then(data => ({
      data: data.testOccurrence,
      hasMore: data.nextHref != null,
    }))

type TestScopesData = {
  testScope: readonly TestScopeType[]
  nextHref?: string
}
export const requestTestScopes = (
  serverUrl: string,
  scope: TestScopesKeyType,
  locator: string,
): Promise<{
  data: ReadonlyArray<TestScopeType>
  hasMore: boolean
}> =>
  request(
    serverUrl,
    `testScopes/${scope}?locator=${encodeURIComponent(
      locator,
    )}&fields=nextHref,testScope(suite,package,class,testOccurrences(${getTestCountersFields(
      true,
    )}))`,
  )
    .then<TestScopesData>(processResponse)
    .then(data => ({
      data: data.testScope,
      hasMore: data.nextHref != null,
    }))

const getTestOccurrencesTreeFields = (options?: RequestTestOccurrenceOptionsParams) => {
  const countersFields = getTestCountersFields(true)
  const testOccurrenceFields = getTestOccurrenceFields(options)
  return `node(name,parentId,type,id,${countersFields},childrenCount),leaf(nodeId,testOccurrences(${testOccurrenceFields}))`
}
export const requestTestOccurrencesTree = ({
  serverUrl,
  endpoint,
  locator,
  subTreeRootId,
  options,
}: {
  serverUrl: string
  endpoint: string
  locator?: string | undefined | null
  subTreeRootId?: string | undefined | null
  options?: RequestTestOccurrenceOptionsParams
}): Promise<TestOccurrencesTreeType> =>
  request(
    serverUrl,
    `${endpoint}?${objectToQuery({
      locator,
      subTreeRootId,
      fields: getTestOccurrencesTreeFields(options),
    })}`,
  ).then<TestOccurrencesTreeType>(processResponse)

export const requestTestOccurrenceMetadata = (
  serverUrl: string,
  testOccurrenceId: TestOccurrenceId,
): Promise<TestOccurrenceType> =>
  request(
    serverUrl,
    `testOccurrences/${stringifyId(testOccurrenceId)}?fields=id,metadata`,
  ).then<TestOccurrenceType>(processResponse)

type TestOccurrencesInvocationsData = {
  testOccurrence: TestOccurrenceType[]
}
export const requestTestOccurrencesInvocations = (
  serverUrl: string,
  locator: string,
  invocationsLocator: string,
  options?: RequestTestOccurrenceOptionsParams,
): Promise<ReadonlyArray<TestOccurrenceType>> =>
  request(
    serverUrl,
    `testOccurrences?locator=${encodeURIComponent(
      locator,
    )}&fields=testOccurrence(id,invocations($locator(${encodeURIComponent(
      invocationsLocator,
    )}),${getTestOccurrenceFields(options)}))`,
  )
    .then<TestOccurrencesInvocationsData>(processResponse)
    .then(data => data.testOccurrence)

type TestOccurrencesCountsData = {
  testCounters: TestOccurrencesCountsType
}
export const requestTestOccurrencesCounts = (
  serverUrl: string,
  locator: string,
): Promise<TestOccurrencesCountsType> =>
  request(
    serverUrl,
    `testOccurrences?locator=${encodeURIComponent(locator)}&fields=${getTestCountersFields()}`,
  )
    .then<TestOccurrencesCountsData>(processResponse)
    .then(data => data.testCounters)

export const requestFlakyTests = (
  serverUrl: string,
  endpoint: string,
  tests: RequestFlakyTestsType,
): Promise<ReadonlyArray<TestFlakyType>> =>
  request(serverUrl, `${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(tests),
  }).then<readonly TestFlakyType[]>(processResponse)

export const requestTestBuildTypeHierarchy = (
  serverUrl: string,
  locator: string,
): Promise<BuildTypeHierarchyType> =>
  request(serverUrl, `app/testBuildTypeHierarchy?${locator}`).then<BuildTypeHierarchyType>(
    processResponse,
  )
