import {CombinedState} from 'redux'
import {$Values} from 'utility-types'

import type {BuildId, Fetchable, ProblemOccurrenceId, TestOccurrenceId} from '../../types'
import type {KeyValue} from '../../utils/object'

export type LogFilter = 'debug' | 'important' | 'err'
export type BuildLogOptionType = {
  readonly count?: (number | null | undefined) | ReadonlyArray<number>
  readonly logAnchor?: number
  readonly expandState?: ReadonlyArray<number> | null | undefined
  readonly expandAll?: boolean
  readonly stageKey?: string
  readonly target?: string
  readonly testOccurrenceId?: TestOccurrenceId
  readonly problemOccurrenceId?: ProblemOccurrenceId
  readonly filter?: LogFilter | null | undefined
  readonly searchQuery?: string
  readonly logView?: LogView
}

export enum Status {
  ERROR = 4,
  TRACE_ERROR = 3,
  WARNING = 2,
}

export type BuildLogMessage = {
  readonly id: number
  readonly parentId?: number | null | undefined
  readonly blockType?: string | null | undefined
  readonly containsMessages?: boolean | null | undefined
  text: string
  readonly status: Status
  readonly level: number
  readonly timestamp?: string
  readonly serverTimestamp?: string
  readonly duration?: number | null | undefined
  readonly flowId?: number | null | undefined
  readonly linkedMessageId?: number | null | undefined
  readonly verbose?: boolean | null | undefined
  // appended on client properties
  processed?: boolean
  childrenEmpty?: boolean
  nextIsHole?: boolean
  containAnsi?: boolean | null | undefined
  containUrl?: boolean | null | undefined
  inaccurateSize?: boolean | null | undefined
  isBroken?: boolean | null | undefined
  isLast?: boolean | null | undefined
  linesLengths?: ReadonlyArray<number>
  maxLineLength?: number
}
export type BuildLogMessagesResponseAnchor = {
  position: number
  type: string
}
export const testTargetAnchorTypes = {
  TEST_START: 'TEST_START' as 'TEST_START',
  TEST_EXCEPTION: 'TEST_EXCEPTION' as 'TEST_EXCEPTION',
  TEST_ACTUAL: 'TEST_ACTUAL' as 'TEST_ACTUAL',
  TEST_EXPECTED: 'TEST_EXPECTED' as 'TEST_EXPECTED',
}
type TestTargetAnchorTypesType = $Values<typeof testTargetAnchorTypes>
export type BuildLogTestTargetAnchor = {
  position: number
  type: TestTargetAnchorTypesType
}
type BuildLogTestTarget = {
  anchors: ReadonlyArray<BuildLogTestTargetAnchor>
  id: number
  type: 'TEST'
}
export type BuildLogMessagesResponse = {
  messages: ReadonlyArray<BuildLogMessage>
  expandedMessagesIndices?: ReadonlyArray<number>
  testTarget?: BuildLogTestTarget
  stageTarget?: {
    key: string
    anchors: ReadonlyArray<BuildLogMessagesResponseAnchor>
  }
  lastMessageIncluded?: boolean
  nextVisible?: number
  prevVisible?: number
  view?: LogView
  focusIndex?: number
}
type TimelinePoint = {
  id: number
  x: number
  name?: string | null | undefined
  buildAnchor: number
  type: string | null | undefined
}
export type TimelineStageStatus = 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'UNKNOWN'
export type TimelineStage = {
  key: string
  name: string
  start: number
  end: number
  // Milliseconds
  duration: number
  point?: TimelinePoint
  statuses: ReadonlyArray<TimelineStageStatus> | null | undefined
  now?: {
    x: number
  }
}
export type BuildLogTimeline = {
  stages: ReadonlyArray<TimelineStage>
  nowPoint?: number | null | undefined
}
export type BuildLogKey = string
export type FullLogScrollBarInfo = {
  readonly horizontal: boolean
  readonly size: number | null | undefined
  readonly vertical: boolean
}
export type FullLogLoadingDirection = 'up' | 'down' | null
export type LogView = 'flowAware' | 'linear' | null | undefined
export type FullLogState = {
  buildId: BuildId | null | undefined
  focusLine: number | null | undefined
  expandState: ReadonlyArray<number> | null | undefined
  expandAll: boolean
  showedFirstDate: string | null | undefined
  showedFirstId: number | null | undefined
  showedLastId: number | null | undefined
  showedLastDate: string | null | undefined
  scrollBarInfo: FullLogScrollBarInfo | null | undefined
  messagesExists: boolean
  filter: LogFilter | null | undefined
  lastLoadingDirection: FullLogLoadingDirection
  logView: LogView
}
export type FullLogTarget = 'page' | 'popup'
export type FetchMessagesParams = {
  buildId: BuildId
  buildLogKey: BuildLogKey
  options?: BuildLogOptionType
  invalidate?: boolean
  target?: FullLogTarget
  mergeData?: boolean
}
export enum SearchDirection {
  Next = 'Next',
  Previous = 'Previous',
}
export type MessagesState = KeyValue<BuildLogKey, Fetchable<ReadonlyArray<BuildLogMessage>>>
type TimelinesState = KeyValue<BuildId, Fetchable<BuildLogTimeline | null>>
export type TestAnchorsState = KeyValue<BuildLogKey, BuildLogTestTarget | null>
export type LastMessageIncludedState = KeyValue<BuildLogKey, boolean | null>
export type MessagesLoadState = KeyValue<
  string,
  {
    loading: boolean
    lastLoadedTime: number | null | undefined
  }
>
type FullLogStates = {
  readonly page: FullLogState
  readonly popup: FullLogState
}
type LogSettingsState = {
  readonly softWrapLines: boolean
  readonly darkTheme: boolean
}
export type BuildLogSearchState = {
  readonly query: string | null | undefined
  readonly nextStartId: number | null | undefined
  readonly foundId: number | null | undefined
  readonly matchPosition: number | null | undefined
  readonly matchesCount: number
  readonly startId: number | null | undefined
  readonly limitReachedTimes: number
  readonly notFound: boolean
  readonly loading: boolean
  readonly searchDirection: SearchDirection
}
type BuildLogSearchStates = {
  readonly page: BuildLogSearchState
  readonly popup: BuildLogSearchState
}
export type BuildLogState = CombinedState<{
  readonly messages: MessagesState
  readonly timelines: TimelinesState
  readonly testAnchors: TestAnchorsState
  readonly lastMessageIncluded: LastMessageIncludedState
  readonly fullLogStates: FullLogStates
  readonly messagesLoadStates: KeyValue<BuildLogKey, MessagesLoadState>
  readonly settings: LogSettingsState
  readonly searchStates: BuildLogSearchStates
}>
export type KeyBuildLogParams =
  | {
      readonly type: 'preview'
      readonly id: BuildId | null | undefined
    }
  | {
      readonly type: 'test'
      readonly id: TestOccurrenceId | null | undefined
    }
  | {
      readonly type: 'problem'
      readonly id: ProblemOccurrenceId | null | undefined
    }
  | {
      readonly type: 'full'
      readonly target: FullLogTarget
    }

export type BuildLogMessageSelectionStart = {
  readonly direction: 'down' | 'up'
  readonly index: number
  readonly messageId: number
  readonly timeSelected: boolean
}
