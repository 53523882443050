import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import {BuildId} from '../../../types'

import {fetchBuildApprovalData, requestApproveBuild} from './BuildApproval.rest'

export const fetchApproval = createFetchAction(
  'buildApproval/fetchData',
  ({buildId}: {buildId: BuildId}) => fetchBuildApprovalData(restRoot, buildId).then(data => data),
)

export const approveBuild = createFetchAction(
  'buildApproval/approve',
  async ({buildId}: {buildId: BuildId}, {dispatch}) => {
    await requestApproveBuild(restRoot, buildId)
    dispatch(fetchApproval({buildId}))
  },
)
