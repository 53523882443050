import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {fetchApproval} from './BuildApproval.actions'
import {BuildApprovalViewContext} from './BuildApproval.context'
import {getBuildApprovalDetails} from './BuildApproval.selectors'
import {BuildApprovalViewMode, Props} from './BuildApproval.types'
import ApprovalClassic from './Classic/BuildApproval.classic'
import ApprovalInline from './Inline/BuildApproval'
import ApprovalPopup from './Popup/DetailsApproval'

export default function BuildApprovalLayout(props: Props) {
  const {buildId, view} = props
  const approvalInfo = useSelector(state => getBuildApprovalDetails(state, buildId))
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchApproval({buildId}))
  }, [buildId, dispatch])

  let Tag
  let contextValue

  switch (view) {
    case BuildApprovalViewMode.CLASSIC_UI:
      Tag = ApprovalClassic
      contextValue = BuildApprovalViewMode.INLINE
      break
    case BuildApprovalViewMode.POPUP:
      Tag = ApprovalPopup
      contextValue = BuildApprovalViewMode.POPUP
      break
    default:
      contextValue = BuildApprovalViewMode.INLINE
      Tag = ApprovalInline
  }

  return (
    <BuildApprovalViewContext.Provider value={contextValue}>
      <Tag approvalInfo={approvalInfo} buildId={buildId} />
    </BuildApprovalViewContext.Provider>
  )
}
