import {stringifyId} from '../types'
import type {BuildId, FileType} from '../types'
import {escapeFilePathForURL} from '../utils/url'

import processResponse from './processResponse'
import request from './request'

export default (
  serverUrl: string,
  buildId: BuildId,
  path: string,
  withHidden?: boolean,
): Promise<ReadonlyArray<FileType>> =>
  request(
    serverUrl,
    `builds/id:${stringifyId(buildId)}/artifacts/children${escapeFilePathForURL(
      path,
    )}?fields=file(name,size)&locator=hidden:${withHidden === true ? 'any' : 'false'}`,
  )
    .then<{file: ReadonlyArray<FileType>}>(processResponse)
    .then(data => data.file)
