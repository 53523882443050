import {branch, mapProps, renderNothing} from 'recompose'

import compose from 'lodash/flowRight'
import type {ComponentType} from 'react'
import * as ReactRedux from 'react-redux'

import withDialogContainer, {DialogProps} from '../../../hocs/withDialogContainer'
import type {State} from '../../../reducers/types'
import {getBuildType, getIsExperimentalUI, getProject} from '../../../selectors'
import {DialogType, stringifyId} from '../../../types'
import type {BuildTypeId, Id, ProjectInternalId} from '../../../types'

import InvestigationHistoryPopup from './InvestigationHistoryPopup'

type OwnProps = {
  readonly id: Id | null | undefined
  readonly buildTypeId: BuildTypeId | null | undefined
}
type StateProps = {
  readonly projectInternalId: ProjectInternalId | null | undefined
  readonly isExperimentalUI?: boolean
}
type OwnPropsDummy = OwnProps & StateProps

const mapStateToProps: (arg0: State, arg1: OwnProps) => StateProps = (state, {buildTypeId}) => {
  const buildType = getBuildType(state, buildTypeId)
  return {
    projectInternalId: getProject(state, buildType?.projectId)?.internalId,
    isExperimentalUI: getIsExperimentalUI(state),
  }
}

export const BuildTypeInvestigationHistoryPopupDummy: ComponentType<OwnPropsDummy> = compose(
  withDialogContainer(DialogType.INVESTIGATION_HISTORY, (state, props: OwnPropsDummy) =>
    stringifyId(props.id),
  ),
  mapProps(({id, ...restProps}: DialogProps<OwnPropsDummy>) => ({
    ...restProps,
    locator: `buildTypeId=${stringifyId(restProps.buildTypeId)}`,
  })),
  branch((props: DialogProps<OwnPropsDummy>) => !props.isOpened, renderNothing),
)(InvestigationHistoryPopup)
export default ReactRedux.connect(mapStateToProps, {})(BuildTypeInvestigationHistoryPopupDummy)
