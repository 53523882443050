import {createReducer} from '@reduxjs/toolkit'
import {castDraft} from 'immer'
import * as Redux from 'redux'

import {restApi} from '../../../services/rest'
import type {ChangeFileType, ChangeId} from '../../../types'
import {toChangeId} from '../../../types'
import type {KeyValue} from '../../../utils/object'

import {fetchChangesFiles} from './ChangeFiles.actions'
import {ChangeFilesStateType} from './ChangeFiles.types'

const changeFiles = Redux.combineReducers<ChangeFilesStateType>({
  changesFiles: createReducer<KeyValue<ChangeId, ReadonlyArray<ChangeFileType>>>({}, builder => {
    builder.addCase(fetchChangesFiles.fulfilled, (state, action) => {
      const files = action.payload?.file

      if (files == null) {
        return
      }

      state[action.meta.arg.changeId] = castDraft(files)
    })
    builder.addMatcher(restApi.endpoints.getAllChanges.matchFulfilled, (state, action) => {
      const {changes} = action.payload.changes?.entities ?? {}

      if (changes == null) {
        return
      }

      for (const [changeId, change] of Object.entries(changes)) {
        if (change?.files?.file != null) {
          state[toChangeId(changeId)] = change.files.file
        }
      }
    })
  }),
})

export default changeFiles
