import {branch, renderNothing, mapProps} from 'recompose'

import compose from 'lodash/flowRight'
import type {ComponentType} from 'react'
import * as ReactRedux from 'react-redux'

import withDialogContainer, {DialogProps} from '../../../hocs/withDialogContainer'
import type {State} from '../../../reducers/types'
import {getBuildType, getIsExperimentalUI, getProject} from '../../../selectors'
import {DialogType, stringifyId} from '../../../types'
import type {TestId, ProjectId, TestOccurrenceId, ProjectInternalId} from '../../../types'
import {getTestOccurrence} from '../../Tests/Tests.selectors'

import InvestigationHistoryPopup from './InvestigationHistoryPopup'

type OwnProps = {
  readonly testOccurrenceId: TestOccurrenceId
}
type StateProps = {
  readonly projectId: ProjectId | null | undefined
  readonly projectInternalId: ProjectInternalId | null | undefined
  readonly testId: TestId | null | undefined
  readonly testName: string | null | undefined
  readonly isExperimentalUI: boolean
}
type OwnPropsDummy = {
  readonly projectId: ProjectId | null | undefined
  readonly projectInternalId: ProjectInternalId | null | undefined
  readonly testId: TestId | null | undefined
  readonly testName: string | null | undefined
}

const mapStateToProps: (arg0: State, arg1: OwnProps) => StateProps = (
  state,
  {testOccurrenceId},
) => {
  const testOccurrence = getTestOccurrence(state, testOccurrenceId)
  const buildTypeId = testOccurrence?.build?.buildTypeId
  const buildType = getBuildType(state, buildTypeId)
  const project = getProject(state, buildType?.projectId)
  return {
    projectId: buildType?.projectId,
    testId: testOccurrence?.test?.id,
    testName: testOccurrence?.name,
    projectInternalId: project?.internalId,
    isExperimentalUI: getIsExperimentalUI(state),
  }
}

export const TestInvestigationHistoryPopupDummy: ComponentType<OwnPropsDummy> = compose(
  withDialogContainer(DialogType.INVESTIGATION_HISTORY, (state, props: OwnPropsDummy) =>
    stringifyId(props.testId),
  ),
  mapProps((props: DialogProps<OwnPropsDummy>) => ({
    ...props,
    subTitle: `Test name – ${props.testName ?? 'Unknown'}`,
    locator: `projectId=${stringifyId(props.projectId)}&testNameId=${stringifyId(props.testId)}`,
  })),
  branch((props: DialogProps<OwnPropsDummy>) => !props.isOpened, renderNothing),
)(InvestigationHistoryPopup)
export default ReactRedux.connect(mapStateToProps, {})(TestInvestigationHistoryPopupDummy)
