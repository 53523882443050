import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import {normalizeBuilds} from '../../../rest/schemata'
import {BuildType} from '../../../types'

import {requestBuildArtifactDependenciesChanges} from './ArtifactChanges.rest'

export const fetchBuildArtifactDependenciesChanges = createFetchAction(
  'fetchBuildArtifactDependenciesChanges',
  (locator: string) =>
    requestBuildArtifactDependenciesChanges(restRoot, locator).then(normalizeBuilds),
)

export const receiveBuildArtifactDependenciesChanges = (
  data: ReadonlyArray<BuildType>,
  locator: string,
) => fetchBuildArtifactDependenciesChanges.fulfilled(normalizeBuilds(data), '', locator)
