import type {AgentId, AgentTypeId, AgentPoolId, OSType, BuildId} from '../../../types'

type AgentNodeTypeType = 'agent'
export type AgentsTreeAgentNodeType = {
  readonly id: AgentId
  readonly poolId: AgentPoolId
  readonly type: AgentNodeTypeType
  readonly title: string
  readonly name: string
  readonly authorized: boolean
  readonly osType: OSType | null | undefined
  readonly busy: boolean
  readonly connected: boolean
  readonly enabled: boolean
  readonly buildId?: BuildId | null | undefined
  readonly ip: string
  readonly cloud: boolean
  readonly matching?: boolean
  readonly containsMatching?: never
  readonly removable?: boolean
}
type CloudImageNodeTypeType = 'cloud-image'
export type AgentsTreeCloudImageNodeType = {
  readonly type: CloudImageNodeTypeType
  readonly agentTypeId: AgentTypeId
  readonly agentTypeRawId: string
  readonly title: string
  readonly children: Array<AgentsTreeAgentNodeType>
  count: number
  busyCount: number
  disconnectedCount: number
  readonly matching?: boolean
  readonly containsMatching?: boolean
  readonly osType: OSType | null | undefined
}
type AgentTypeNodeTypeType = 'agent-type'
export type AgentsTreeAgentTypeNodeType = {
  readonly type: AgentTypeNodeTypeType
  readonly id: AgentTypeId
  readonly rawId: string
  readonly title: string
  readonly children: Array<AgentsTreeAgentNodeType>
  count: number
  busyCount: number
  disconnectedCount: number
  readonly matching?: boolean
  readonly containsMatching?: boolean
  readonly osType: OSType | null | undefined
}
export const UNAUTHORIZED_AGENTS_SELECTION_ID = 'unauthorized-agents'
export const DETACHED_AGENTS_SELECTION_ID = 'detached-agents'
export type AgentSelectionId = 'unauthorized-agents' | 'detached-agents'
export type AgentSelectionNodeTypeType = 'agent-selection'
export type AgentsTreeRootNodeChildType = AgentsTreeAgentTypeNodeType | AgentsTreeAgentNodeType
export const isAgentTypeNode = (
  node: AgentsTreeRootNodeChildType,
): node is AgentsTreeAgentTypeNodeType => node.type === 'agent-type'
export const isAgentNode = (node: AgentsTreeRootNodeChildType): node is AgentsTreeAgentNodeType =>
  node.type === 'agent'
export type AgentsTreeAgentSelectionNodeType = {
  readonly id: AgentSelectionId
  readonly type: AgentSelectionNodeTypeType
  readonly title: string
  readonly children: Array<AgentsTreeRootNodeChildType>
  count: number
  readonly containsMatching?: boolean
  disconnectedCount: number
}
export type AgentPoolNodeTypeType = 'agent-pool'
export type AgentsTreeAgentPoolNodeType = {
  readonly id: AgentPoolId
  readonly type: AgentPoolNodeTypeType
  readonly title: string
  readonly children: Array<AgentsTreeRootNodeChildType>
  count: number
  busyCount: number
  disconnectedCount: number
  readonly matching?: boolean
  readonly containsMatching?: boolean
}
export type AgentsTreeRootNodeType = AgentsTreeAgentSelectionNodeType | AgentsTreeAgentPoolNodeType
export type AgentsTreeType = ReadonlyArray<AgentsTreeRootNodeType>
export type AgentBranchId = AgentPoolId | AgentSelectionId | AgentTypeId
export type AgentBranchType =
  | AgentPoolNodeTypeType
  | AgentSelectionNodeTypeType
  | AgentTypeNodeTypeType
export type AgentsTreeNodeType = AgentsTreeRootNodeType | AgentsTreeRootNodeChildType
type AgentsTreeItem = {
  readonly title: string
  readonly level: number
}
type AgentsTreeSelectableItem = AgentsTreeItem & {
  readonly active: boolean
}
export type AgentsTreeItemTitleType = AgentsTreeItem & {
  readonly id: string
  readonly type: 'agents-tree-title'
}
export type AgentsTreeItemLinkType = AgentsTreeSelectableItem & {
  readonly id: string
  readonly type: 'agents-tree-link'
  readonly to: string
  disconnectedCount?: number
  readonly focusable: boolean
}
export type AgentsTreeItemPoolType = AgentsTreeSelectableItem & {
  readonly id: AgentPoolId
  readonly type: AgentPoolNodeTypeType
  readonly containsActive: boolean
  readonly count: number
  readonly busyCount: number
  readonly disconnectedCount: number
  readonly expanded?: boolean
  readonly matching?: boolean
  readonly focusable: boolean
}
export type AgentsTreeItemSelectionType = AgentsTreeSelectableItem & {
  readonly id: AgentSelectionId
  readonly type: AgentSelectionNodeTypeType
  readonly count: number
  readonly disconnectedCount: number
  readonly containsActive: boolean
  readonly expanded?: boolean
  readonly focusable: boolean
}
export type AgentsTreeItemAgentTypeType = AgentsTreeSelectableItem & {
  readonly type: AgentTypeNodeTypeType
  readonly id: AgentTypeId
  readonly count: number
  readonly busyCount: number
  readonly disconnectedCount: number
  readonly containsActive: boolean
  readonly expanded?: boolean
  readonly osType: OSType | null | undefined
  readonly matching?: boolean
  readonly focusable: boolean
}
export type AgentsTreeItemAgentType = AgentsTreeSelectableItem & {
  readonly name: string
  readonly id: AgentId
  readonly type: AgentNodeTypeType
  readonly authorized: boolean
  readonly osType: OSType | null | undefined
  readonly busy?: boolean
  readonly connected?: boolean
  readonly enabled?: boolean
  readonly cloud: boolean
  readonly matching?: boolean
  readonly focusable: boolean
}
export type AgentsTreeItemType =
  | AgentsTreeItemTitleType
  | AgentsTreeItemLinkType
  | AgentsTreeItemAgentType
  | AgentsTreeItemAgentTypeType
  | AgentsTreeItemPoolType
  | AgentsTreeItemSelectionType
export type FlatAgentsTreeType = ReadonlyArray<AgentsTreeItemType>
export type ActiveItemFilter =
  | {
      readonly id: AgentId
      readonly type: AgentNodeTypeType
    }
  | {
      readonly id: AgentPoolId
      readonly type: AgentPoolNodeTypeType
    }
  | {
      readonly id: AgentTypeId
      readonly type: AgentTypeNodeTypeType
    }
  | {
      readonly id: AgentSelectionId
      readonly type: AgentSelectionNodeTypeType
    }
  | {
      readonly id: string
      readonly type: 'agents-tree-link'
    }
export type AgentsRouteProps = {
  readonly agentId?: AgentId | null | undefined
  readonly agentTypeId?: AgentTypeId | null | undefined
  readonly agentPoolId?: AgentPoolId | null | undefined
}
