import compose from 'lodash/flowRight'
import * as ReactRedux from 'react-redux'

import withUserPermissions from '../../../../../hocs/withUserPermissions'
import {withBuildId} from '../../../../../hooks/routes'
import type {State} from '../../../../../reducers/types'
import {
  getBuild,
  getBuildType,
  getJoinedTags,
  getIsStarred,
  getHasDependencies,
  hasPermission,
} from '../../../../../selectors'
import {BuildTypeType, NormalizedBuildType, Permission, ROOT_PROJECT_ID} from '../../../../../types'
import {starBuild} from '../../../StarBuild/StarBuild.actions'
import {pinBuild} from '../PinBuild/PinBuild.actions'

import BuildActions from './BuildActions'
import {commentBuild, tagBuild} from './BuildActions.actions'
import type {ReduxOwnProps, StateProps, ActionProps} from './BuildActions.types'
import {BuildActionsProps} from './BuildActions.types'

const mapStateToProps = (state: State, props: ReduxOwnProps): StateProps => {
  const build: Partial<NormalizedBuildType> = getBuild(state, props.buildId) || {}
  const {pinned, pinInfo} = build
  const buildType: Partial<BuildTypeType> = getBuildType(state, build.buildType) || {}
  const {projectId} = buildType
  const joinedTags = getJoinedTags(state, props.buildId)
  const isCustomizedParams = !!build.customization?.parameters
  const isCustomizedChanges = !!build.customization?.changes
  const isCustomizedArtifacts = !!build.customization?.artifactDependencies
  const isPinnable: boolean =
    props.isPinnable !== false &&
    build.state === 'finished' &&
    hasPermission(state, Permission.PIN_UNPIN_BUILD, projectId)
  const isTaggable: boolean =
    props.isTaggable !== false && hasPermission(state, Permission.TAG_BUILD, projectId)
  const isStarrable: boolean =
    hasPermission(state, Permission.TAG_BUILD, projectId) &&
    hasPermission(state, Permission.CHANGE_OWN_PROFILE)
  return {
    projectId,
    buildTypeId: buildType.id,
    isCommentable: hasPermission(state, Permission.COMMENT_BUILD, projectId),
    comment: build.comment && build.comment.text,
    isPinnable,
    isTaggable,
    isStarrable,
    isCustomizedParams,
    isCustomizedChanges,
    isCustomizedArtifacts,
    isPinned: pinned,
    pinComment: pinInfo && pinInfo.text,
    hasDependencies: getHasDependencies(state, props.buildId),
    isStarred: getIsStarred(state, props.buildId),
    joinedTags,
  }
}

const actionCreators: ActionProps = {
  onPin: pinBuild,
  onTags: tagBuild,
  onStar: starBuild,
  onComment: commentBuild,
}
export default compose(
  withBuildId,
  ReactRedux.connect(mapStateToProps, actionCreators),
  withUserPermissions(
    [Permission.PIN_UNPIN_BUILD, Permission.TAG_BUILD, Permission.COMMENT_BUILD],
    (props: BuildActionsProps) => props.projectId,
  ),
  withUserPermissions([Permission.CHANGE_OWN_PROFILE], () => ROOT_PROJECT_ID),
)(BuildActions)
