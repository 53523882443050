import {Source} from 'callbag'

import create from 'callbag-create'
import map from 'callbag-map'
import pipe from 'callbag-pipe'
import share from 'callbag-share'
import callbagSubscribe from 'callbag-subscribe'
import tapUp from 'callbag-tap-up'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import {stringifyId} from '../types'
import type {
  AgentId,
  BuildId,
  BuildType,
  BuildTypeInternalId,
  ProjectInternalId,
  UserId,
} from '../types'
import {BS, internalProps} from '../types/BS_types'

import {WritableKeyValue} from './object'
import {safeLocalStorage} from './safeStorages'
import {generateUID} from './subscriptionId'
import type {SubscriptionID} from './subscriptionId'

export const defaultThrottleTime = 500
const NETWORK_TIMEOUT = 10000
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const TTL = 10 * 60000 // 10 min
const STORAGE_KEY = 'useFirstHandlerTime'
type HandlerType<T> = (data?: T | null | undefined) => unknown
const topicsBySubscriptionId = new Map()
export type Unsubscribe = () => void

export enum POSTPONE_METHOD {
  DEBOUNCE,
  THROTTLE,
}

export const DEFAULT_DEBOUNCE_INTERVAL =
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  internalProps['teamcity.ui.subscriptions.debounceInterval'] ?? 3000

function noop() {}

function shouldUseFirstHandler() {
  const time = safeLocalStorage.getItemInJSON<number>(STORAGE_KEY)
  return time != null && time > Date.now()
}

const subscribe = <T>(
  topic: string,
  handler: HandlerType<T>,
  subscriptionId: SubscriptionID,
): Unsubscribe => {
  let prevData: string | null = null
  if (BS?.SubscriptionManager) {
    return BS.SubscriptionManager.subscribe(
      topic,
      data => {
        if (data === prevData) {
          return
        }

        handler(JSON.parse(data))
        prevData = data
      },
      subscriptionId,
    )
  } else {
    // in tests, call the handler once
    handler()
    return () => {}
  }
}

export const getProjectSuffix = (id: ProjectInternalId) => `p:${stringifyId(id)}`

const getAgentSuffix: (arg0: AgentId) => string = id => `a:${stringifyId(id)}`

export const getBuildTypeSuffix = (id: BuildTypeInternalId) => `b:${stringifyId(id)}`

export const getUserSuffix = (id: UserId) => `u:${stringifyId(id)}`

export const getTopic = (eventType: string, entitySuffix: string = ''): string =>
  `events/${eventType};${entitySuffix}`
export const getTopics = (
  entitySuffix: string,
  eventTypes: ReadonlyArray<string>,
): ReadonlyArray<string> => eventTypes.map(eventType => getTopic(eventType, entitySuffix))

export const subscribeController = (
  topics: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number,
  postponeMethod: POSTPONE_METHOD = POSTPONE_METHOD.THROTTLE,
): Unsubscribe => {
  const subscriptionId = generateUID()
  topicsBySubscriptionId.set(subscriptionId, topics)

  const postponeHandler =
    postponeMethod === POSTPONE_METHOD.THROTTLE
      ? throttle(handler, timeout, {
          leading: true,
          trailing: true,
        })
      : debounce(handler, timeout, {
          leading: true,
          trailing: true,
        })

  let topicsToWait = new Set()
  let networkTimeout: number
  if (shouldUseFirstHandler()) {
    postponeHandler()
  } else {
    topicsToWait = new Set(topics)
    networkTimeout = window.setTimeout(() => {
      safeLocalStorage.setItemInJSON(STORAGE_KEY, Date.now() + TTL)
      postponeHandler()
    }, NETWORK_TIMEOUT)
  }
  const unsubscribeFns = topics
    .map(topic =>
      subscribe(
        topic,
        data => {
          topicsToWait.delete(topic)
          if (topicsToWait.size === 0) {
            clearTimeout(networkTimeout)
            postponeHandler(data)
          }
        },
        subscriptionId,
      ),
    )
    .concat(() => clearTimeout(networkTimeout))
  return () => unsubscribeFns.forEach(fn => fn())
}

const streams: WritableKeyValue<string, Source<unknown>> = {}

function subscribeControllerMulticast(
  key: string,
  topics: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number,
): Unsubscribe {
  let stream = streams[key]
  if (stream == null) {
    stream = streams[key] = pipe(
      create(next => subscribeController(topics, next, timeout)),
      map(handler),
      tapUp(noop, noop, () => {
        delete streams[key]
      }),
      share,
    )
  }

  return pipe(stream, callbagSubscribe(noop))
}
export const subscribeOnAgentEvents = (
  agentId: AgentId,
  eventTypes: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
): Unsubscribe => {
  const topics = getTopics(getAgentSuffix(agentId), eventTypes)
  return subscribeController(topics, handler, timeout)
}
export const subscribeOnProjectEvents = (
  projectInternalId: ProjectInternalId,
  eventTypes: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
): Unsubscribe => {
  const topics = getTopics(getProjectSuffix(projectInternalId), eventTypes)
  return subscribeController(topics, handler, timeout)
}
export const subscribeOnBuildTypeEvents = (
  buildTypeInternalId: BuildTypeInternalId,
  eventTypes: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
): Unsubscribe => {
  const topics = getTopics(getBuildTypeSuffix(buildTypeInternalId), eventTypes)
  return subscribeController(topics, handler, timeout)
}
export const subscribeOnBuildTypesEvents = (
  buildTypeInternalIds: ReadonlyArray<BuildTypeInternalId | undefined>,
  eventTypes: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
): Unsubscribe => {
  const topics = buildTypeInternalIds.reduce(
    (result: Array<string>, buildTypeInternalId) => [
      ...result,
      ...(buildTypeInternalId != null
        ? getTopics(getBuildTypeSuffix(buildTypeInternalId), eventTypes)
        : []),
    ],
    [],
  )

  return subscribeController(topics, handler, timeout)
}
export const subscribeOnUserEvents = (
  key: string,
  userId: UserId,
  eventTypes: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
): Unsubscribe => {
  const topics = getTopics(getUserSuffix(userId), eventTypes)
  return subscribeControllerMulticast(`${key}:${topics.join(',')}`, topics, handler, timeout)
}
export const subscribeOnOverallEvents = (
  topics: ReadonlyArray<string>,
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
  postponeMethod: POSTPONE_METHOD = POSTPONE_METHOD.THROTTLE,
): Unsubscribe => subscribeController(topics, handler, timeout, postponeMethod)
export const subscribeonStatisticsUpdates = (
  handler: HandlerType<any>,
  timeout: number = defaultThrottleTime,
): Unsubscribe => subscribeController(['statistics'], handler, timeout)
export const subscribeOnRunningBuild = (
  buildId: BuildId,
  handler: HandlerType<BuildType>,
): Unsubscribe => subscribeOnOverallEvents([`rb/${stringifyId(buildId)}`], handler)
