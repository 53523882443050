import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import {normalizeChangesStatus, normalizeChangesBranches} from '../../../rest/schemata'

import {requestChangeBranches, requestChangeStatus, requestChangeStatusBuilds} from './Changes.rest'

export const fetchChangeStatus = createFetchAction('fetchChangeStatus', (locator: string) =>
  requestChangeStatus(restRoot, locator).then(normalizeChangesStatus),
)

export const fetchChangeStatusBuilds = createFetchAction(
  'fetchChangeStatusBuilds',
  (locator: string) => requestChangeStatusBuilds(restRoot, locator).then(normalizeChangesStatus),
)

export const fetchChangesBranches = createFetchAction('fetchChangeBranches', (locator: string) =>
  requestChangeBranches(restRoot, locator).then(normalizeChangesBranches),
)
