import {createSelector} from 'reselect'

import {State} from '../../../../../reducers/types'
import {
  BuildId,
  Fetchable,
  NormalizedRevisionType,
  RevisionId,
  UniqueRevisionId,
  VcsLabelType,
  VcsRootId,
  VcsRootInstanceId,
} from '../../../../../types'
import {emptyArray, emptyArrayFetchable, getEmptyHash} from '../../../../../utils/empty'
import {KeyValue, WritableKeyValue} from '../../../../../utils/object'

export const getBuildChangesRevisionsFetchable: (
  state: State,
  locator: string,
) => Fetchable<ReadonlyArray<BuildId>> = (state, locator) =>
  state.changesRevisions.buildChangesRevisionsByLocator[locator] || emptyArrayFetchable

export const getBuildSettingsRevisionId = (
  state: State,
  buildId: BuildId,
): UniqueRevisionId | null | undefined => state.changesRevisions.buildSettingsRevisions[buildId]

const getBuildRevisionsIds: (
  state: State,
  buildId: BuildId | null | undefined,
) => ReadonlyArray<UniqueRevisionId> = (state, buildId) =>
  buildId != null ? state.changesRevisions.buildRevisions[buildId] ?? emptyArray : emptyArray

export const getRevision: (
  state: State,
  revisionId: UniqueRevisionId | null | undefined,
) => NormalizedRevisionType | null | undefined = (state, revisionId) =>
  revisionId != null ? state.entities.revisions[revisionId] : null

export const getBuildRevisionIdsByVcsRootInstanceId: (
  state: State,
  buildId: BuildId | null | undefined,
) => KeyValue<VcsRootInstanceId, ReadonlyArray<UniqueRevisionId>> = createSelector(
  getBuildRevisionsIds,
  (state: State) => state.entities.revisions,
  (
    revisionsIds: ReadonlyArray<UniqueRevisionId>,
    revisions: KeyValue<UniqueRevisionId, NormalizedRevisionType>,
  ): KeyValue<VcsRootInstanceId, ReadonlyArray<RevisionId>> => {
    if (revisionsIds.length === 0) {
      return getEmptyHash()
    }

    const mainRevisionIdsByVcsRootInstanceId: WritableKeyValue<
      VcsRootInstanceId,
      ReadonlyArray<RevisionId>
    > = {}
    revisionsIds.forEach(id => {
      const vcsRootInstanceId = revisions[id]?.['vcs-root-instance']
      if (vcsRootInstanceId != null) {
        const revisionIds = mainRevisionIdsByVcsRootInstanceId[vcsRootInstanceId] ?? []

        if (!revisionIds.includes(id)) {
          mainRevisionIdsByVcsRootInstanceId[vcsRootInstanceId] = revisionIds.concat(id)
        }
      }
    })
    return mainRevisionIdsByVcsRootInstanceId
  },
)

export const getBuildsRevisionIdsByVcsRootInstanceId: (
  state: State,
  locator: string,
) => KeyValue<VcsRootInstanceId, ReadonlyArray<UniqueRevisionId>> = createSelector(
  (state: State, locator: string) => getBuildChangesRevisionsFetchable(state, locator).data,
  (state: State) => state.entities.revisions,
  (state: State) => state.entities.vcsRootInstances,
  (state: State) => state.changesRevisions.buildRevisions,
  (
    buildsData: ReadonlyArray<BuildId>,
    revisions,
    vcsRootInstances,
    buildRevisions: KeyValue<BuildId, ReadonlyArray<UniqueRevisionId>>,
  ): KeyValue<VcsRootInstanceId, ReadonlyArray<UniqueRevisionId>> => {
    if (buildsData.length === 0) {
      return getEmptyHash()
    }

    const dependencyRevisionsByVcsRootInstanceId: WritableKeyValue<
      VcsRootInstanceId,
      ReadonlyArray<UniqueRevisionId>
    > = {}
    buildsData.forEach(buildId => {
      const buildRevisionsIds = buildRevisions[buildId] ?? []
      buildRevisionsIds.forEach(revisionId => {
        const vcsRootInstanceId = revisions[revisionId]?.['vcs-root-instance']

        if (vcsRootInstanceId != null) {
          const revisionsArray = dependencyRevisionsByVcsRootInstanceId[vcsRootInstanceId] ?? []

          if (!revisionsArray.includes(revisionId)) {
            dependencyRevisionsByVcsRootInstanceId[vcsRootInstanceId] =
              revisionsArray.concat(revisionId)
          }
        }
      })
    })
    return dependencyRevisionsByVcsRootInstanceId
  },
)

export const makeGetVcsLabels: () => (
  state: State,
  buildId?: BuildId | null,
  vcsRootId?: VcsRootId | null,
) => ReadonlyArray<VcsLabelType> = () =>
  createSelector(
    [
      (_: State, buildId?: BuildId | null) => buildId,
      (_: State, __?: BuildId | null, vcsRootId?: VcsRootId | null) => vcsRootId,
      (state: State) => state.entities.vcsLabels,
    ],
    (
      buildId: BuildId | null | undefined,
      vcsRootId: VcsRootId | null | undefined,
      vcsLabelsHash: KeyValue<BuildId, ReadonlyArray<VcsLabelType>>,
    ): readonly VcsLabelType[] => {
      const vcsLabels = buildId != null ? vcsLabelsHash[buildId] ?? emptyArray : emptyArray
      return vcsLabels.filter(
        vcsRoot => vcsRoot?.['vcs-root-instance']?.['vcs-root']?.id === vcsRootId,
      )
    },
  )
