import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import classNames from 'classnames'
import * as React from 'react'

import TransformText from '../../../containers/TransformText'
import {useDistanceToNow} from '../../../hooks/useTimeSelector'
import type {BuildTypeId} from '../../../types'
import Popup from '../Popup/Popup.lazy'

import styles from './CommentPopup.css'

type Props = {
  readonly anchor: React.ReactElement
  readonly actionTitle?: string
  readonly timestamp?: string | null | undefined
  readonly user?: string | null | undefined
  readonly text: string | null | undefined
  readonly className?: string
  readonly buildTypeId?: BuildTypeId | null | undefined
  readonly top?: number
  readonly left?: number
  readonly directions?: ReadonlyArray<Directions>
  readonly lines?: number
}
const DEFAULT_POPUP_DIRECTIONS = [Directions.RIGHT_BOTTOM, Directions.LEFT_BOTTOM]
const DEFAULT_POPUP_LEFT = 3

function CommentPopup({
  anchor,
  actionTitle,
  text,
  timestamp,
  user,
  className,
  buildTypeId,
  top,
  left,
  directions,
  lines,
  ...restProps
}: Props) {
  const classes = classNames(styles.commentPopup, className)
  const timestampFromNow = useDistanceToNow(timestamp)
  return (
    <Dropdown anchor={anchor} hoverHideTimeOut={300} clickMode={false} hoverMode>
      <Popup
        directions={directions || DEFAULT_POPUP_DIRECTIONS}
        left={left ?? DEFAULT_POPUP_LEFT}
        top={top}
      >
        <div {...restProps} className={classes}>
          {text != null && (
            <TransformText
              text={text}
              lines={lines}
              className={styles.text}
              buildTypeId={buildTypeId}
              showPlaceholder={false}
            />
          )}
          {(user != null || timestamp != null) && (
            <div className={styles.info}>
              {actionTitle != null && `${actionTitle}`}
              {user != null && ` by ${user}`}
              {timestampFromNow != null && ` ${timestampFromNow}.`}
            </div>
          )}
        </div>
      </Popup>
    </Dropdown>
  )
}

export default CommentPopup
