import type {PlaceId} from '@jetbrains/teamcity-api'

import processResponse, {processTextResponse} from '../../../rest/processResponse'
import request from '../../../rest/request'
import {objectToQuery, queryToObject} from '../../../utils/queryParams'
import {parseURL} from '../../../utils/url'

import type {DummyPluginType, PluginLocationContext} from './Plugin.types'
import {getPluginUIContext} from './Plugin.utils'

export const fetchPlaceIdAvailablePlugins = async (
  placeId: PlaceId,
  activeEntities: PluginLocationContext,
): Promise<ReadonlyArray<DummyPluginType>> => {
  const response = await request(
    window.base_uri,
    `app/placeId/${placeId}?${objectToQuery({
      pluginUIContext: getPluginUIContext(activeEntities),
    })}`,
  )
  return await processResponse(response)
}
export const getPluginContent = async (
  pluginUrl: string,
  activeEntities: PluginLocationContext,
): Promise<any> => {
  const url = parseURL(`${window.base_uri}${pluginUrl}`)
  url.search = objectToQuery({
    ...queryToObject(url.search),
    pluginUIContext: getPluginUIContext(activeEntities),
  })
  const response = await request(url.href)
  return await processTextResponse(response)
}
