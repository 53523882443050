import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import type {BuildId} from '../../../types'

import {requestWaitReasons} from './BuildQueueInfo.rest'
import {getWaitReasonsFetchable} from './BuildQueueInfo.selectors'
import type {WaitReasons} from './BuildQueueInfo.types'

export const fetchWaitReasons = createFetchAction(
  'fetchWaitReasons',
  (buildId: BuildId) => requestWaitReasons(restRoot, buildId),
  {
    condition: (buildId, {getState}) =>
      !getWaitReasonsFetchable(getState().queueInfo, buildId).loading,
  },
)
export const receiveWaitReasons = (buildId: BuildId, data: WaitReasons) =>
  fetchWaitReasons.fulfilled(data, '', buildId)
