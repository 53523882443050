import {stringifyId, TabParams, TabParamsKey} from '../types'

import {stringifyBranch} from './branchNames'
import {objectToQuery} from './queryParams'

type ParsedTabTitle = {
  readonly name: string
  readonly counter?: string
  readonly marker?: boolean
}
export function parseTabTitle(title: string): ParsedTabTitle {
  const re = /^(.*)\s\(([\d\+\!]+)\)$/ // tab title format can be: (<num>), (<num>+), (+), (<num>!), (<num>+!), (!), (+!)

  const match = title.match(re)

  if (!match) {
    return {
      name: title,
    }
  }

  const [_, name, counter] = match

  if (counter === '+' || counter === '+!') {
    return {
      name,
      marker: true,
    }
  }

  return {
    name,
    counter,
  }
}

export const getTabParamsKey = ({
  buildId,
  buildTypeId,
  projectId,
  changeId,
  isEdit = false,
  agentId,
  agentTypeId,
  branch,
  personal,
}: TabParams): TabParamsKey => {
  if (buildId != null) {
    return objectToQuery({
      buildId: stringifyId(buildId),
    })
  }

  if (buildTypeId != null) {
    return objectToQuery(
      isEdit
        ? {editBuildTypeId: stringifyId(buildTypeId)}
        : {
            buildTypeId: stringifyId(buildTypeId),
            ...(!isEdit && branch != null ? {branch: stringifyBranch(branch)} : {}),
          },
    )
  }

  if (projectId != null) {
    return objectToQuery(
      isEdit
        ? {editProjectId: stringifyId(projectId)}
        : {
            projectId: stringifyId(projectId),
            ...(!isEdit && branch != null ? {branch: stringifyBranch(branch)} : {}),
          },
    )
  }

  if (agentId != null) {
    return objectToQuery({
      agentId: stringifyId(agentId),
    })
  }

  if (agentTypeId != null) {
    return objectToQuery({
      agentTypeId: stringifyId(agentTypeId),
    })
  }

  if (changeId != null) {
    return objectToQuery({
      modId: stringifyId(changeId),
      personal: (personal ?? false).toString(),
    })
  }

  return ''
}
