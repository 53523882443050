import {fetchHasBuilds} from '../../../actions/builds'
import {createFetchAction} from '../../../reducers/fetchable'
import {changeTags, getSingleBuildArg} from '../../../rest/builds'
import {restRoot} from '../../../rest/consts'
import {getBuildLocator} from '../../../rest/locators'
import {getIsExperimentalUI, getTags} from '../../../selectors'
import {restApi} from '../../../services/rest'
import {STAR_TAG, STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER} from '../../../types'
import type {BuildId} from '../../../types'

type StarBuildArg = {
  buildId: BuildId
  starred: boolean
}
export const starBuildAction = createFetchAction(
  'starBuild',
  async ({buildId, starred}: StarBuildArg, {dispatch, getState}) => {
    const state = getState()
    const tags = getTags(state, buildId)
    await changeTags(
      restRoot,
      buildId,
      starred
        ? tags.concat({
            name: STAR_TAG,
            private: true,
          })
        : tags,
    )
    if (getIsExperimentalUI(state)) {
      dispatch(fetchHasBuilds(STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER))
    }

    dispatch(
      restApi.endpoints.getBuildNormalizedAsList.initiate(
        getSingleBuildArg(getBuildLocator(buildId)),
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    )
  },
)
export const starBuild = (buildId: BuildId, starred: boolean) => starBuildAction({buildId, starred})
