import {memo, cloneElement, Children, useCallback, ReactElement} from 'react'
import {useSelector} from 'react-redux'
import {NavigateOptions, useNavigate} from 'react-router-dom'

import {getIsExperimentalUI} from '../../../selectors'

import {shouldNavigate} from './RouterLinkWrapper.utils'

type Props = {
  readonly children: ReactElement
  readonly options?: NavigateOptions
  readonly scrollToTop?: boolean
  readonly insideApp?: boolean
}

function RouterLinkWrapper({children, options, scrollToTop = true, insideApp = true}: Props) {
  const navigate = useNavigate()
  const isExperimentalUI = useSelector(getIsExperimentalUI)

  const onClick = useCallback(
    (event: Event & React.KeyboardEvent) => {
      const child = Children.only(children)

      if (child.props.onClick != null) {
        child.props.onClick(event)
      }

      if (isExperimentalUI && insideApp && child.props.href != null && shouldNavigate(event)) {
        event.preventDefault()
        if (child.props.href.startsWith(window.location.origin)) {
          const {pathname, search, hash} = new URL(child.props.href)
          navigate({pathname, search, hash}, options)
        } else {
          navigate(child.props.href, options)
        }

        if (scrollToTop) {
          window.scrollTo(0, 0)
        }
      }
    },
    [children, insideApp, navigate, options, scrollToTop, isExperimentalUI],
  )

  return cloneElement(Children.only(children), {onClick})
}

export default memo<Props>(RouterLinkWrapper)
