import {BuildId, GroupType, InexactCountable, UserType} from '../../../types'

export enum ApprovalStatuses {
  WAITING_FOR_APPROVAL = 'waitingForApproval',
  APPROVED = 'approved',
  TIMED_OUT = 'timedOut',
  CANCELED = 'canceled',
}
type ApprovalStatusType = ApprovalStatuses

export type BuildApprovalType = {
  readonly status: ApprovalStatusType
  readonly canBeApprovedByCurrentUser: boolean
  readonly configurationValid: boolean
  readonly timeoutTimestamp: string
  readonly groupApprovals?: InexactCountable & {
    readonly groupApproval?: ReadonlyArray<GroupApprovalRuleStatus>
  }
  readonly userApprovals?: InexactCountable & {
    readonly userApproval?: ReadonlyArray<UserApprovalRuleStatus>
  }
}

export type UserApprovalRuleStatus = {
  readonly user: UserType
  readonly approved: boolean
}

export type GroupApprovalRuleStatus = {
  readonly group: GroupType
  readonly requiredApprovalsCount: number
  readonly currentlyApprovedBy: InexactCountable & {
    readonly user: ReadonlyArray<UserType>
  }
}

export enum BuildApprovalViewMode {
  INLINE = 'inline',
  CLASSIC_UI = 'classic_ui',
  POPUP = 'popup',
}

export type OwnProps = {
  readonly buildId: BuildId
  readonly className?: string
  readonly view?: BuildApprovalViewMode
}

export type StateProps = {
  readonly shouldRender: boolean
}

export type Props = OwnProps & StateProps

export type ViewProps = {
  readonly buildId: BuildId
  readonly approvalInfo: BuildApprovalType | null | undefined
}
