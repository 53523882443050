import {QueryActionCreatorResult} from '@reduxjs/toolkit/src/query/core/buildInitiate'
import {QueryDefinition} from '@reduxjs/toolkit/src/query/endpointDefinitions'

export function wrapEndpointCache(
  fetch: (subscribe: boolean) => QueryActionCreatorResult<QueryDefinition<any, any, any, any>>,
) {
  let unsubscribe: (() => void) | undefined
  return [
    () => {
      const result = fetch(unsubscribe == null)
      unsubscribe ??= result.unsubscribe
    },
    unsubscribe ?? (() => {}),
  ]
}
