import {stringifyId} from '../types'
import type {BuildTypeId, TagType} from '../types'

import processResponse from './processResponse'
import request from './request'

export default (serverUrl: string, buildTypeId: BuildTypeId): Promise<ReadonlyArray<string>> =>
  request(serverUrl, `buildTypes/id:${stringifyId(buildTypeId)}/buildTags`)
    .then<{tag: ReadonlyArray<TagType>}>(processResponse)
    .then(data => (data.tag || []).map(tag => tag.name))
