import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import type {UserType} from '../../../types'

import {ChangeCommiter} from './UserSelect.types'

export const requestAllUsers = (serverUrl: string): Promise<ReadonlyArray<UserType>> =>
  request(serverUrl, 'users?fields=user(id,name,username,avatars)')
    .then<{user: ReadonlyArray<UserType>}>(processResponse)
    .then(data => data.user)

export const requestChangesCommiters = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeCommiter>> =>
  request(
    serverUrl,
    `changes/${encodeURIComponent(
      locator,
    )}/commiters?fields=count,commiter(vcsUsername,users(count,user(id,name,username,avatars)))`,
  )
    .then<{commiter: ChangeCommiter[]}>(processResponse)
    .then(data => data.commiter)
