import type {State} from '../../reducers/types'
import type {Fetchable} from '../../types'
import {emptyArrayFetchable} from '../../utils/empty'

import type {InvestigationHistoryType} from './InvestigationHistory.types'

export const getInvestigationHistoryFetchable: (
  arg0: State,
  arg1: string,
) => Fetchable<ReadonlyArray<InvestigationHistoryType>> = (state, locator) =>
  state.investigationHistory.investigations[locator] ?? emptyArrayFetchable
