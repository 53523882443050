import {stringifyId} from '../types'
import type {ProjectOrBuildTypeNode, BranchWithBuilds, BuildTypeId, BranchType} from '../types'

import {getBuildFields} from './builds'
import {getBranchLocatorPart} from './locators'
import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

export enum Policy {
  ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES = 'ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES',
  ALL_BRANCHES = 'ALL_BRANCHES',
  HISTORY_BRANCHES = 'HISTORY_BRANCHES',
}

export type FetchBranchesParams = {
  node?: ProjectOrBuildTypeNode | null | undefined
  policy?: Policy | null | undefined
  includeSnapshots?: boolean | null | undefined
  includeSubprojects?: boolean | null | undefined
  excludeGroups?: boolean | null | undefined
  excludeDefault?: boolean
  branch?: BranchType
  inPath?: boolean
}
export const getEndpointForBranches = ({
  node,
  includeSubprojects,
  includeSnapshots,
  policy,
  excludeGroups,
  excludeDefault = false,
  branch,
}: FetchBranchesParams) => {
  const {nodeType, id} = node ?? {}

  if (!id) {
    return {data: '', presence: ''}
  }

  const isProject = nodeType === 'project'
  const defaultPolicy = isProject ? 'ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES' : 'ALL_BRANCHES'
  const isGroup = branch != null && branch.groupFlag === true && branch.internalName != null
  const getLocator = (inPath: boolean) =>
    encodeURIComponent(
      [
        isProject &&
          includeSubprojects !== false &&
          `buildType:(affectedProject:(id:${stringifyId(id)}))`,
        !isGroup && `policy:${policy ?? defaultPolicy}`,
        !isGroup &&
          includeSnapshots != null &&
          `changesFromDependencies:${String(includeSnapshots)}`,
        excludeGroups !== true && 'includeGroups:true',
        excludeDefault && 'default:false',
        branch != null && getBranchLocatorPart(branch, inPath),
      ]
        .filter(Boolean)
        .join(','),
    )
  const base = `${isProject ? 'projects' : 'buildTypes'}/id:${stringifyId(id)}/branches`
  return {
    data: `${base}?locator=${getLocator(false)}`,
    presence: `${base}/${getLocator(true)}/exists`,
  }
}
export default (serverUrl: string, endpoint: string): Promise<ReadonlyArray<BranchType>> => {
  if (!endpoint) {
    return Promise.resolve([])
  }

  return request(serverUrl, `${endpoint}&fields=branch(internalName,name,default,active,groupFlag)`)
    .then<{branch: ReadonlyArray<BranchType>}>(processResponse)
    .then(data => data.branch)
}
// TODO: migrate to RTK Query when TW-76918 is fixed
export async function requestIsBranchPresent(
  serverUrl: string,
  endpoint: string,
  restOptions?: RestRequestOptions | null | undefined,
): Promise<boolean> {
  if (!endpoint) {
    return false
  }

  const response = await request(serverUrl, endpoint, {
    ...restOptions,
    headers: {Accept: 'text/plain'},
  })
  return processResponse(response)
}

const getBranchGroupLocator = (branch: BranchType): string => {
  if (!branch) {
    return ''
  }

  if (branch.groupFlag === true && branch.internalName != null) {
    return `group:(${branch.internalName})`
  }

  return ''
}

type BranchesWithBuildsData = {
  branch: BranchWithBuilds[]
}
export const getBranchesWithBuilds = async (
  serverUrl: string,
  {
    buildTypeId,
    branchFilter,
    personalBuildsLocator,
  }: {
    buildTypeId: BuildTypeId
    branchFilter: BranchType | null | undefined
    personalBuildsLocator: string
  },
): Promise<ReadonlyArray<BranchWithBuilds>> => {
  const requestBranches = (buildsLocator: string, branchLocator?: string) =>
    request(
      serverUrl,
      `buildTypes/id:${stringifyId(buildTypeId)}/branches?locator=${
        branchLocator != null ? `${encodeURIComponent(branchLocator)},` : ''
      }${
        branchFilter != null && branchFilter.groupFlag === true
          ? `${encodeURIComponent(getBranchGroupLocator(branchFilter))},`
          : 'policy:ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES'
      }&fields=branch(internalName,name,default,active,groupFlag,builds(build(${getBuildFields({
        withQueuedInfo: true,
        withRunningInfo: true,
      })}),href,count,$locator(${encodeURIComponent(
        buildsLocator,
      )},defaultFilter:false${encodeURIComponent(personalBuildsLocator)})))`,
    )
      .then<BranchesWithBuildsData>(processResponse)
      .then(data => data.branch.map(branch => ({...branch, buildTypeId})))

  const branchLists = await Promise.all([
    requestBranches('state:(queued:true,running:true),count:100'),
    requestBranches('state:finished,count:5', 'default:true'),
    requestBranches('state:finished,count:1', 'default:false'),
  ])
  return branchLists.flat()
}
