import type {PagerType} from './Pager.types'

const itemsOnPage = (page: number, pager: PagerType): number =>
  Math.min(pager.pageSize, pager.total - (page - 1) * pager.pageSize)

export const hasItemsOnPage = (page: number, pager: PagerType): boolean =>
  itemsOnPage(page, pager) > 0
export const getOffset = (pager: PagerType, page?: number): number =>
  Math.max(0, (page ?? pager.currentPage ?? 1) - 1) * pager.pageSize
export const getLastPageToCount = (pager: PagerType): number =>
  (pager.currentPage ?? 1) - 1 + (pager.precountedPages ?? 1)
export const needsLoadMore = (page: number, pager: PagerType): boolean => {
  const {hasMore, total, pageSize} = pager
  const lastPage = Math.ceil(total / pageSize)
  return Boolean(hasMore) && page > lastPage
}
