import type {FederationServerId, FederationServerType} from '../types'

import processResponse from './processResponse'
import request from './request'

export default (serverUrl: string): Promise<ReadonlyArray<FederationServerType>> =>
  request(serverUrl, `federation/servers?fields=server`)
    .then<{server: ReadonlyArray<FederationServerType>}>(processResponse)
    .then(data => data.server)
type EditServersResponse = {
  readonly server: ReadonlyArray<FederationServerType>
}
export const editServers = (
  serverUrl: string,
  servers: ReadonlyArray<FederationServerId>,
): Promise<EditServersResponse> =>
  request(serverUrl, `federation/servers`, {
    method: 'PUT',
    body: JSON.stringify({
      server: servers.map(url => ({
        url,
      })),
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then<EditServersResponse>(processResponse)
