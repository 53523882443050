import {createFetchAction} from '../reducers/fetchable'
import {restRoot} from '../rest/consts'
import requestFederationServers from '../rest/federationServers'
import {normalizeFederationServer} from '../rest/schemata'
import type {FederationServerType} from '../types'

export const fetchFederationServers = createFetchAction(
  'fetchFederationServers',
  () => requestFederationServers(restRoot).then(normalizeFederationServer),
  {
    condition(force: boolean | void, {getState}) {
      const {federationServers} = getState()

      return force === true || (!federationServers.inited && !federationServers.loading)
    },
  },
)
export const receiveFederationServers = (servers: ReadonlyArray<FederationServerType>) => {
  const normalizedData = normalizeFederationServer(servers)
  return fetchFederationServers.fulfilled(normalizedData, '')
}
