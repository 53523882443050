import * as ReactRedux from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuildAgent, getIsExperimentalUI} from '../../../selectors'

import AgentLink from './AgentLink'
import {mapAgentToProps} from './AgentLink.container'
import type {BuildAgentLinkOwnProps, BuildAgentLinkStateProps} from './AgentLink.types'

const mapStateToProps = (state: State, props: BuildAgentLinkOwnProps): BuildAgentLinkStateProps => {
  const isExperimentalUI = getIsExperimentalUI(state)
  const agent = getBuildAgent(state, props.buildId)
  return {
    ...mapAgentToProps(agent),
    isExperimentalUI,
    secondary: props.secondary ?? true,
  }
}

export default ReactRedux.connect(mapStateToProps)(AgentLink)
