import * as React from 'react'

import type {BuildTypeId} from '../../../../types'
import BuildTag from '../../BuildTag/BuildTag'

type Props = {
  readonly label: string
  readonly buildTypeId?: BuildTypeId | undefined
  readonly selected?: boolean
  readonly className?: string
  readonly onSelect?: (arg0: string) => unknown
}

class TagFilter extends React.PureComponent<Props> {
  handleSelect: () => void = () => {
    const {label, onSelect} = this.props

    if (onSelect != null) {
      onSelect(label)
    }
  }

  render(): React.ReactNode {
    const {onSelect, ...restProps} = this.props
    return <BuildTag {...restProps} onSelect={onSelect ? this.handleSelect : undefined} />
  }
}

export default TagFilter
