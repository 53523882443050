import {createSelector} from 'reselect'

import {State} from '../../../../reducers/types'
import {Project} from '../../../../services/rest'
import {hasPipelineHeadParameter} from '../EditPipelinePage/EditPipelinePage.utils'

export const getPipelineHead = (data: Project | undefined) =>
  data?.buildTypes?.buildType?.find(
    job => job?.type === 'composite' && hasPipelineHeadParameter(job),
  )

export const getPipelineHeadId = (data: Project | undefined) => getPipelineHead(data)?.id

export const getPipelinesStateToPersist = createSelector(
  (state: State) => state.pipelines.pipelineDraft,
  pipelineDraft => ({
    pipelineDraft,
  }),
)
