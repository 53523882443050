import type {PlaceId} from '@jetbrains/teamcity-api'

import {EqualityFn} from 'react-redux'
import {CombinedState} from 'redux'

import type {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import {AppDispatch} from '../actions/types'
import type {AgentsScreenState} from '../components/AgentsScreen/AgentsScreen.types'
import type {QueueSidebarState} from '../components/App/QueueSidebar/QueueSidebar.types'
import type {BuildLogState} from '../components/BuildLog/BuildLog.types'
import {BuildProblemsStateType} from '../components/BuildProblems/BuildProblems.types'
import type {CleanupStateType} from '../components/CleanupBuildType/Cleanup.types'
import type {CleanupPoliciesStateType} from '../components/CleanupProject/CleanupPolicies.types'
import type {AgentAuthStateType} from '../components/common/AgentAuth/AgentAuth.types'
import {ArtifactChangesStateType} from '../components/common/ArtifactChanges/ArtifactChanges.types'
import type {BuildApprovalType} from '../components/common/BuildApproval/BuildApproval.types'
import type {BuildQueueInfoStateType} from '../components/common/BuildQueueInfo/BuildQueueInfo.types'
import {BuildStatusTooltipStateType} from '../components/common/BuildStatus/BuildStatusLink/BuildStatusTooltip/BuildStatusTooltip.types'
import {ChangeDetailsTabsStateType as DepricatedChangesStateType} from '../components/common/ChangeDetailsTabs/ChangeDetailsTabs.types'
import {ChangeProjectsSelectStateType} from '../components/common/ChangeDetailsTabs/FilesTab/ChangeBuildTypeSelect/ChangeBuildTypeSelect.types'
import {ChangeFilesStateType} from '../components/common/ChangeFiles/ChangeFiles.types'
import {ChangesStateType} from '../components/common/Changes/Changes.types'
import {ChangesDropdownStateType} from '../components/common/ChangesDropdown/ChangesDropdown.types'
import type {ErrorAlertsStateType} from '../components/common/ErrorAlerts/ErrorAlerts.types'
import type {PagerStateType} from '../components/common/Pager/Pager.types'
import {UserSelectStateType} from '../components/common/UserSelect/UserSelect.types'
import type {HintsStateType} from '../components/Hints/Hints.types'
import type {InvestigationHistoryStateType} from '../components/InvestigationHistory/InvestigationHistory.types'
import {ChangesRevisionsStateType} from '../components/pages/BuildPage/BuildChangesTab/ChangesRevisions/ChangesRevisions.types'
import type {BuildSnippetStateType} from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.types'
import type {DeploymentsStateType} from '../components/pages/BuildPage/BuildOverviewTab/Deployments/Deployments.state.types'
import type {CompareBuildsState} from '../components/pages/CompareBuildsPage/CompareBuildsPage.types'
import {HttpsStateType} from '../components/pages/HttpsConfigurationPage/HttpsConfigurationPage.types'
import {PipelinesState} from '../components/pages/PipelinesPages/PipelinesPages.reducers'
import {ProjectInvestigations} from '../components/pages/ProjectPage/ProjectInvestigationsTab/ProjectInvesigations.types'
import type {ProjectPageStateType} from '../components/pages/ProjectPage/ProjectPage.types'
import type {TestFlakyType, TestsStateType} from '../components/Tests/Tests.types'
import type {Entities, StatusKey} from '../rest/schemata'
import {restApi} from '../services/rest'
import type {
  AgentDetails,
  AgentId,
  AgentPoolId,
  AgentTypeId,
  ArtifactExtensions,
  BranchType,
  BuildArtifactsSizeType,
  BuildId,
  BuildStats,
  BuildTriggeredBuildType,
  BuildTypeId,
  CurrentUserType,
  Dialog,
  DslOptions,
  DslOptionType,
  FederationServerData,
  FederationServerId,
  Fetchable,
  FileType,
  Id,
  LicensingDataType,
  Permission,
  PoolPermissions,
  ProjectId,
  ReduxLocatorOptions,
  RouteAvailabilityResponse,
  ServerInfo,
  Sorting,
  Tab,
  TabId,
  TabParamsKey,
  TestId,
  TestOccurrenceId,
  UrlExtension,
} from '../types'
import {stringifyId} from '../types'
import type {KeyValue} from '../utils/object'
import {generateUID} from '../utils/uid'

type SidebarState = {
  searchQuery: string
  searchActive: boolean
  allProjectsCollapsed: boolean
  showArchivedProjects: boolean
}
export type CollapsibleBlockItems = KeyValue<CollapsibleBlock, KeyValue<string | Id, boolean>>
export type TogglingOverview = {
  project: KeyValue<ProjectId, boolean | null | undefined>
  bt: KeyValue<BuildTypeId, boolean | null | undefined>
}
export type OverviewDraft = {
  readonly projects: ReadonlyArray<ProjectId> | null | undefined
  readonly buildTypes: KeyValue<ProjectId, Array<BuildTypeId> | null | undefined>
}
type PoolPermissionsState = {
  readonly canChangeStatus: PoolPermissions
  readonly canAuthorize: PoolPermissions
}

export type State = CombinedState<{
  readonly clientId: ClientId
  readonly isExperimentalUI: boolean
  readonly urlExtensions: ReadonlyArray<UrlExtension<any>>
  readonly routeAvailabilityResponse: KeyValue<string, RouteAvailabilityResponse>
  readonly entities: Entities
  readonly buildsDetails: KeyValue<string, Fetchable<unknown>>
  readonly builds: KeyValue<string, Fetchable<ReadonlyArray<BuildId>>>
  readonly buildTriggerBuilds: KeyValue<BuildId, BuildTriggeredBuildType>
  readonly artifacts: KeyValue<ArtifactsKey, Fetchable<ReadonlyArray<FileType>>>
  readonly agent: KeyValue<AgentId, Fetchable<AgentId | null>>
  readonly agentPools: Fetchable<ReadonlyArray<AgentPoolId>>
  readonly cloudImages: Fetchable<ReadonlyArray<AgentTypeId>>
  readonly branches: KeyValue<string, Fetchable<ReadonlyArray<BranchType>>>
  readonly isBranchPresent: KeyValue<string, Fetchable<boolean | null>>
  readonly projects: KeyValue<ProjectId, Fetchable<ReadonlyArray<ProjectId>>>
  readonly projectsWithArchived: KeyValue<ProjectId, Fetchable<ReadonlyArray<ProjectId>>>
  readonly project: KeyValue<ProjectId, Fetchable<ProjectId | null>>
  readonly buildTypes: KeyValue<string, Fetchable<ReadonlyArray<BuildTypeId>>>
  readonly hasFavoriteProjects: boolean | null
  readonly overview: Fetchable<ReadonlyArray<ProjectId>>
  readonly projectInvestigations: ProjectInvestigations
  readonly overviewDraft: OverviewDraft
  readonly currentUser: CombinedState<Fetchable<CurrentUserType | null>>
  readonly userProperties: KeyValue<string, string>
  readonly permissions: KeyValue<Permission, KeyValue<ProjectId, boolean>>
  readonly poolPermissions: PoolPermissionsState
  readonly html: KeyValue<string, string | null>
  readonly startingBuilds: KeyValue<StatusKey, boolean>
  readonly stoppingBuilds: KeyValue<BuildId, boolean>
  readonly starringBuilds: KeyValue<BuildId, boolean | null>
  readonly togglingOverview: TogglingOverview
  readonly buildsFilters: ReduxLocatorOptions
  readonly sorting: Sorting
  readonly agentsInCloud: KeyValue<AgentId, boolean>
  readonly pager: PagerStateType
  readonly blocks: CollapsibleBlockItems
  readonly buildTypeTags: KeyValue<BuildTypeId, Fetchable<ReadonlyArray<string>>>
  readonly buildsStats: KeyValue<string, Fetchable<ReadonlyArray<BuildStats>>>
  readonly haveDependants: KeyValue<BuildId, boolean>
  readonly dialog: Dialog
  readonly buildTypeTab: TabId | null
  readonly buildTab: TabId | null
  readonly projectPage: ProjectPageStateType
  readonly sidebar: SidebarState
  readonly dslOptions: KeyValue<string, DslOptions>
  readonly dslFragment: string | null
  readonly artifactExtensions: KeyValue<BuildId, ArtifactExtensions>
  readonly availableDslOptions: Fetchable<ReadonlyArray<DslOptionType>>
  readonly showQueuedBuildsPerBranch: KeyValue<StatusKey, boolean>
  readonly showQueuedBuildsInProject: KeyValue<BuildTypeId, boolean>
  readonly showQueuedBuildsCount: KeyValue<StatusKey, number>
  readonly showQueuedBuildsInBuildsList: boolean
  readonly branchesWithBuilds: KeyValue<BuildTypeId, Fetchable<ReadonlyArray<StatusKey>>>
  readonly federationServers: Fetchable<ReadonlyArray<FederationServerId>>
  readonly federationServersData: KeyValue<FederationServerId, FederationServerData>
  readonly federationServersEntities: KeyValue<FederationServerId, Partial<Entities>>
  readonly hasBuilds: KeyValue<string, Fetchable<boolean>>
  readonly tabs: KeyValue<TabParamsKey, Fetchable<ReadonlyArray<Tab>>>
  readonly serverInfo: ServerInfo | null
  readonly dummyCalls: number
  readonly overviewExpandState: Fetchable<ReadonlyArray<ProjectId>>
  readonly cachedPlugins: KeyValue<PlaceId, readonly string[]>
  readonly testOccurrencesByLocator: KeyValue<string, Fetchable<ReadonlyArray<TestOccurrenceId>>>
  readonly cleanup: CleanupStateType
  readonly cleanupPolicies: CleanupPoliciesStateType
  readonly buildSnippets: BuildSnippetStateType
  readonly flakyTests: KeyValue<string, Fetchable<KeyValue<TestId, TestFlakyType>>>
  readonly compareBuilds: CompareBuildsState
  readonly buildLog: BuildLogState
  readonly agentsPage: AgentsScreenState
  readonly queueInfo: BuildQueueInfoStateType
  readonly deployments: DeploymentsStateType
  readonly changeDetailsTabs: DepricatedChangesStateType
  readonly changesRevisions: ChangesRevisionsStateType
  readonly changesDropdown: ChangesDropdownStateType
  readonly changeFiles: ChangeFilesStateType
  readonly artifactChanges: ArtifactChangesStateType
  readonly changeProjectsSelect: ChangeProjectsSelectStateType
  readonly agentAuth: AgentAuthStateType
  readonly userSelect: UserSelectStateType
  readonly changes: ChangesStateType
  readonly buildStatusTooltip: BuildStatusTooltipStateType
  readonly errorAlerts: ErrorAlertsStateType
  readonly investigationHistory: InvestigationHistoryStateType
  readonly tests: TestsStateType
  readonly buildProblems: BuildProblemsStateType
  readonly queueSidebar: QueueSidebarState
  readonly syncStorageValues: KeyValue<string, string | null>
  readonly hints: HintsStateType
  readonly https: HttpsStateType
  readonly queuedToggler: {
    readonly autoExpand: KeyValue<StatusKey, boolean | null>
    readonly hasTriggeredByMeBuilds: KeyValue<BuildTypeId, boolean | null>
  }
  readonly licensingData: Fetchable<LicensingDataType | null>
  readonly buildTypesLimit: number
  readonly artifactSizes: KeyValue<BuildId, BuildArtifactsSizeType>
  readonly agentDetails: KeyValue<AgentId, AgentDetails | null>
  readonly buildTypeInvestigations: KeyValue<BuildTypeId, Fetchable<boolean>>
  readonly deliveredArtifacts: KeyValue<string, readonly string[]>
  readonly buildApprovals: KeyValue<BuildId, BuildApprovalType>
  readonly buildSelections: KeyValue<string, readonly BuildId[]>
  readonly pipelines: PipelinesState
  readonly restApi: ReturnType<typeof restApi.reducer>
}>

declare module 'react-redux' {
  function useSelector<Selected = unknown>(
    selector: (state: State) => Selected,
    equalityFn?: EqualityFn<Selected> | undefined,
  ): Selected
  function useDispatch<D = AppDispatch>(): D
  type DefaultRootState = State
}
type ArtifactsKey = string
export const getArtifactsKey = (
  buildId: BuildId | null | undefined,
  path: string | null | undefined,
  hidden: boolean | null | undefined,
): ArtifactsKey => `${stringifyId(buildId)}${path ?? ''}${hidden === true ? ':hidden' : ''}`
export type ClientId = string
export const clientId: ClientId = generateUID('uid')
