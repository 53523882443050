import Dropdown, {Anchor} from '@jetbrains/ring-ui/components/dropdown/dropdown'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import * as React from 'react'

import {ApprovalStatuses, BuildApprovalType, ViewProps} from '../BuildApproval.types'

import {checkIfUserCanViewApprovalInfo} from '../BuildApproval.utils'

import DetailsApprovalPopup from './DetailsApproval.popup'

import styles from './DetailsApproval.css'

function BuildApprovalAnchor({approvalInfo}: {approvalInfo: BuildApprovalType}) {
  return (
    <Anchor
      className={classNames(styles.caption, {
        [styles.awaits]: approvalInfo.status === ApprovalStatuses.WAITING_FOR_APPROVAL,
        [styles.refused]: approvalInfo.status === ApprovalStatuses.TIMED_OUT,
      })}
    >
      <span>{'Requires approval'}</span>
    </Anchor>
  )
}

export default function BuildApprovalDetails({buildId, approvalInfo}: ViewProps) {
  if (!approvalInfo) {
    return (
      <span>
        {'Loading '}
        <LoaderInline />
      </span>
    )
  }

  if (approvalInfo.status === ApprovalStatuses.APPROVED) {
    return <div>{'Build has been approved'}</div>
  }

  if (!checkIfUserCanViewApprovalInfo(approvalInfo)) {
    return (
      <span className={classNames(styles.caption, styles.readonly)}>{'Requires approval'}</span>
    )
  }

  return (
    <div>
      <Dropdown hoverMode anchor={<BuildApprovalAnchor approvalInfo={approvalInfo} />}>
        <DetailsApprovalPopup buildId={buildId} approvalInfo={approvalInfo} />
      </Dropdown>
    </div>
  )
}
