import {stringifyId} from '../types'
import type {BuildTypeId, InvestigationType} from '../types'

import processResponse from './processResponse'
import request from './request'

export const investigation =
  'investigation(id,state,resolution(type),scope(project(id,name),buildTypes(buildType(id,name))),assignee(id,name,username),assignment(user(id,name,username),timestamp,text))'
export default (
  serverUrl: string,
  buildTypeId: BuildTypeId,
): Promise<ReadonlyArray<InvestigationType>> =>
  request(
    serverUrl,
    `investigations?locator=buildType:(id:${encodeURIComponent(
      stringifyId(buildTypeId),
    )})&fields=${investigation}`,
  )
    .then<{investigation: readonly InvestigationType[]}>(processResponse)
    .then(data => data.investigation)
