import type {BuildNumberProps as APIProps} from '@jetbrains/teamcity-api'
import {skipToken} from '@reduxjs/toolkit/query'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import type {State} from '../../../reducers/types'
import {getSingleBuildArg} from '../../../rest/builds'
import {getBuildLocator} from '../../../rest/locators'
import {getOverviewHref} from '../../../routes'
import {getBuild, getIsExperimentalUI, getIsMine} from '../../../selectors'
import {restApi} from '../../../services/rest'
import type {BuildId} from '../../../types'
import {toBuildId} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'

import BuildNumberComponent from './BuildNumber'

type OwnProps = {
  buildId: BuildId | null | undefined
  number?: string
  withLink?: boolean
  hideStar?: boolean
  className?: string
  showEmptyStar?: boolean
  withWarnings?: boolean
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const build = getBuild(state, props.buildId)
  const isExperimentalUI = getIsExperimentalUI(state)
  const {number, personal, canceledInfo, queuePosition} = build || {}
  const href = isExperimentalUI
    ? getOverviewHref({
        buildId: props.buildId,
        buildTypeId: build?.buildType,
      })
    : resolveWebEntityLink(build)
  return {
    href,
    queuePosition,
    loaded: number != null || queuePosition != null || props.number != null,
    number: number ?? props.number,
    isPersonal: personal === true,
    isMine: getIsMine(state, props.buildId),
    isQueued: build?.state === 'queued',
    isCanceled: canceledInfo != null,
  }
}

const connector = ReactRedux.connect(mapStateToProps)

type Props = OwnProps & ReactRedux.ConnectedProps<typeof connector>

const BuildNumberContainer = connector(function BuildNumberContainer({
  buildId,
  loaded,
  ...restProps
}: Props) {
  restApi.endpoints.getBuildNormalized.useQuerySubscription(
    buildId != null && !loaded ? getSingleBuildArg(getBuildLocator(buildId)) : skipToken,
  )
  return loaded && buildId != null ? (
    <BuildNumberComponent {...restProps} buildId={buildId} />
  ) : null
})
export default BuildNumberContainer
export const BuildNumberAPI: React.ComponentType<APIProps> = ({
  buildId,
  number,
  withLink,
  hideStar,
}) => (
  <BuildNumberContainer
    buildId={buildId != null ? toBuildId(buildId) : null}
    number={number}
    withLink={withLink}
    hideStar={hideStar}
  />
)
