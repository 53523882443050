import {stringifyId, toProjectId} from '../types'
import type {ProjectId, ProjectWithDetailsType, RequestOptionsParams} from '../types'
import type {SWGenerator} from '../types/generators'
import {REVALIDATION_HEADER_NAME} from '../workers/sw.constants'

import {buildTypeFields, linksField, parametersField} from './buildTypes'
import {subscribeOnServiceWorkerMessageOnce} from './caching/events'
import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

const basicProjectFields = ['id', 'internalId', 'name', 'parentProjectId', 'archived', 'readOnlyUI']
const descriptionField = 'description'
const archivedProjectsIdField = 'projects(project(id),count,$locator(archived:true))'
export const projectFields: (
  arg0: RequestOptionsParams | null | undefined,
) => ReadonlyArray<string> = options =>
  basicProjectFields
    .concat(
      options?.withBuildTypes === true
        ? `buildTypes(buildType(${buildTypeFields(options).join(',')}))`
        : [],
    )
    .concat(options?.withTemplates === true ? 'templates(buildType(id,name))' : [])
    .concat(options?.withLinks === true ? linksField : [])
    .concat(options?.withParameters === true ? parametersField : [])
    .concat(options?.withDescription === true ? descriptionField : [])
    .concat(options?.withArchivedSubprojectsIds === true ? archivedProjectsIdField : [])
    .concat(
      options?.withAncestorProjects === true
        ? `ancestorProjects(project(${projectFields({}).join(',')}))`
        : [],
    )

const getEndpoint = (projectId: ProjectId, options?: RequestOptionsParams) => {
  const childrenLocator = `archived:${options && options.archived ? options.archived : 'false'},${
    options?.withLowNesting === true ? 'project' : 'affectedProject'
  }:${stringifyId(projectId)}`
  return `projects?fields=project(${projectFields(options).join(',')})&locator=${
    options?.includeRoot === true
      ? `item(id:${stringifyId(projectId)}),item(${childrenLocator})`
      : childrenLocator
  }`
}

type ResponseType = {
  project: ReadonlyArray<ProjectWithDetailsType>
}
type ResultType = ReadonlyArray<ProjectWithDetailsType>

const resolver = (payload: ResponseType): ResultType => payload.project ?? null

export default (
  serverUrl: string,
  projectId: ProjectId,
  options?: RequestOptionsParams,
  withAuth?: boolean,
): Promise<ResultType> => {
  const endpoint = getEndpoint(projectId, options)
  return request(serverUrl, endpoint, {}, withAuth)
    .then<ResponseType>(processResponse)
    .then(resolver)
}
export async function* projectFetchGenerator(
  serverUrl: string,
  projectId: ProjectId,
  options?: RequestOptionsParams,
  withAuth?: boolean,
): SWGenerator<ResultType, void> {
  const endpoint = getEndpoint(projectId, options)
  const requestOptions: RestRequestOptions = {}

  if (options?.cache != null) {
    requestOptions.headers = {
      [REVALIDATION_HEADER_NAME]: options.cache,
    }
  }

  const subscription = subscribeOnServiceWorkerMessageOnce({
    serverUrl,
    endpoint,
    resolver,
  })
  const cachedResponse = await request(serverUrl, endpoint, requestOptions, withAuth)
  const payload: ResponseType = await processResponse(cachedResponse)
  yield {payload: resolver(payload)}
  return await subscription
}
export const requestSingleProject = (
  serverUrl: string,
  projectId: ProjectId,
  options?: RequestOptionsParams,
  restOptions?: RestRequestOptions | null | undefined,
): Promise<ProjectWithDetailsType> =>
  request(
    serverUrl,
    `projects/id:${stringifyId(projectId)}?fields=${projectFields(options).join(',')}`,
    restOptions,
  ).then<ProjectWithDetailsType>(processResponse)
export type RequestProjectIdsByLocatorResponseType = {
  project: ReadonlyArray<{
    id: string
  }>
}
export const requestProjectIdsByLocatorEndpoint = (locator: string): string =>
  `projects?fields=project(id)&locator=${encodeURIComponent(locator)}`
export const requestProjectIdsByLocatorResolver = (
  payload: RequestProjectIdsByLocatorResponseType,
): ReadonlyArray<ProjectId> => payload.project.map(project => toProjectId(project.id))
export const requestProjectIdsByLocator = (
  serverUrl: string,
  locator: string,
  options?: RestRequestOptions | null | undefined,
): Promise<ReadonlyArray<ProjectId>> =>
  request(serverUrl, requestProjectIdsByLocatorEndpoint(locator), options)
    .then<RequestProjectIdsByLocatorResponseType>(processResponse)
    .then(requestProjectIdsByLocatorResolver)
