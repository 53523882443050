import {keyValueFetchable} from '../../../../../reducers/fetchable'
import {BuildTypeHierarchyType} from '../../../../../types'

import {fetchChangeBuildTypeHierarchy} from './ChangeBuildTypeSelect.actions'

const changeProjectsSelectReducer = keyValueFetchable(
  arg => arg,
  fetchChangeBuildTypeHierarchy,
  null as BuildTypeHierarchyType | null,
  (_, action) => action.payload,
)

export default changeProjectsSelectReducer
