import 'whatwg-fetch'
import type {PlaceId} from '@jetbrains/teamcity-api'
import {createAction} from '@reduxjs/toolkit'

import {createFetchAction} from '../reducers/fetchable'
import {State} from '../reducers/types'
import requestArtifacts from '../rest/artifacts'
import requestBranches, {requestIsBranchPresent} from '../rest/branches'
import {moveToTop, requestBuildsStats, requestBuildsStatsAroundBuild} from '../rest/builds'
import {restRoot} from '../rest/consts'
import requestCurrentUser, {setProperty} from '../rest/currentUser'
import requestLicensingData from '../rest/licensingData'
import projectPermissionGenerator, {requestPoolPermissions} from '../rest/permission'
import processResponse, {processTextResponse} from '../rest/processResponse'
import type {RestRequestOptions} from '../rest/request'
import makeRequest from '../rest/request'
import {getStatusKey} from '../rest/schemata'
import requestTabs from '../rest/tabs'
import type {BranchesToShow} from '../selectors'
import {
  buildTypeBranchesSectionCollapsedKey,
  getAutoExpandQueuedBuilds,
  getExtensionEndpointsByKind,
  getIsAllTabsLoading,
  getIsBranchPresent,
  getIsBranchPresentInited,
  getIsGuestOrRoot,
  getShowQueuedBuildsInBuildsList,
} from '../selectors'
import {
  agentsInCloud,
  blocks,
  builds,
  buildTab,
  buildTypeTab,
  dialog,
  isExperimentalUI,
  permissions,
  routeAvailabilityResponse,
  showQueuedBuildsInBuildsList,
  sorting,
  urlExtensions,
  showQueuedBuildsPerBranch,
  showQueuedBuildsCount,
  showQueuedBuildsInProject,
  serverInfo,
  dummyCalls,
  cachedPlugins,
  syncStorageValues,
  queuedTogglerAutoExpand,
  buildTypesLimit,
} from '../slices'
import buildsFilters from '../slices/buildsFilters'
import type {
  AgentId,
  AgentPoolId,
  ArtifactExtension,
  BranchType,
  BuildId,
  BuildTypeId,
  CurrentUserType,
  LicensingDataType,
  Permission,
  PoolPermissions,
  ProblemId,
  ProjectId,
  Tab,
  TabParamsKey,
  UrlExtension,
  UserId,
} from '../types'
import {ALL_PROJECTS, getBuildTypeStatusRequest, stringifyId} from '../types'
import {base_uri, BS} from '../types/BS_types'
import {getChildPath, isOnlyFolder} from '../utils/fileTree'
import type {Unsubscribe} from '../utils/subscriber'
import {getTopics, subscribeOnOverallEvents, subscribeOnUserEvents} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'
import {resolveRelative} from '../utils/url'
import type {UserProperty} from '../utils/userProperties'

import CollapsibleBlocks from './collapsibleBlockTypes'
import type {ActionCreator, AppThunk} from './types'
import {processServiceWorkerResponse} from './utils'

export const resetState = createAction<State>('resetState')

export const fetchCurrentUserData = createFetchAction(
  'fetchCurrentUserData',
  (): Promise<CurrentUserType | null> => requestCurrentUser(restRoot),
  {
    condition(force: boolean | void, {getState}) {
      const {currentUser} = getState()
      return force || !currentUser.inited
    },
  },
)

type FetchArtifactExtensionArg = {
  extension: UrlExtension<{}>
  id: BuildId
}
export const fetchArtifactExtension = createFetchAction(
  'fetchArtifactExtension',
  async ({extension: {serverUrl = base_uri, endpoint}, id}: FetchArtifactExtensionArg) => {
    const response = await makeRequest(serverUrl, `${endpoint}?buildId=${stringifyId(id)}`)
    const data: ArtifactExtension = await processResponse(response)
    return data
  },
)

export const fetchArtifactExtensions =
  (id: BuildId): AppThunk<any> =>
  (dispatch, getState) =>
    getExtensionEndpointsByKind(getState(), 'artifacts').forEach(extension =>
      dispatch(fetchArtifactExtension({extension, id})),
    )

type FetchArtifactsListArg = {
  id: BuildId
  path: string
  withHidden?: boolean
}
export const fetchArtifactsListAction = createFetchAction(
  'fetchArtifactsList',
  async ({id, path, withHidden}: FetchArtifactsListArg, {dispatch}) => {
    const data = await requestArtifacts(restRoot, id, path, withHidden)
    if (isOnlyFolder(data)) {
      await dispatch(fetchArtifactsListAction({id, path: getChildPath(path, data[0].name)}))
    }
    return data
  },
)
export const fetchArtifactsList = (id: BuildId, path: string, withHidden?: boolean) =>
  fetchArtifactsListAction({id, path, withHidden})
export const receiveCurrentUserData = (data: CurrentUserType | null) =>
  fetchCurrentUserData.fulfilled(data, '')
export const receiveAgentsInCloud = agentsInCloud.actions.receive
export const subscribeOnPermission =
  (permission: Permission, projectId: ProjectId, userId: UserId): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnUserEvents(
      `${permission}:${projectId === ALL_PROJECTS ? '' : stringifyId(projectId)}`,
      userId,
      [SubscriptionEvents.USER_PERMISSIONS_CHANGED],
      () => {
        processServiceWorkerResponse({
          iterator: projectPermissionGenerator(restRoot, permission, projectId),
          onSuccess: data => dispatch(permissions.actions.receive({data, permission, projectId})),
        })
      },
    )

type PoolPermissionsPayload = {
  canChangeStatus?: PoolPermissions
  canAuthorize?: PoolPermissions
}
export const fetchPoolPermissions = createFetchAction(
  'fetchPoolPermissions',
  (essential?: boolean): Promise<PoolPermissionsPayload> =>
    requestPoolPermissions(resolveRelative('/overview'), {
      essential,
    }),
)
export const receiveCanChangeStatusPoolPermissions = (canChangeStatus: PoolPermissions) =>
  fetchPoolPermissions.fulfilled({canChangeStatus}, '', undefined)
export const receiveCanAuthorizePoolPermissions = (canAuthorize: PoolPermissions) =>
  fetchPoolPermissions.fulfilled({canAuthorize}, '', undefined)

type FetchHtmlArg = {
  path: string
  method?: string | null
  absolute?: boolean
}
export const fetchHtmlAction = createFetchAction(
  'fetchHtml',
  async ({path, method, absolute}: FetchHtmlArg): Promise<string | null> => {
    const res = await makeRequest(absolute === true ? null : base_uri, path, {
      method: method ?? 'GET',
      headers: {
        Accept: '*/*',
      },
    })
    return processTextResponse(res)
  },
)
export const fetchHtml = (path: string, method?: string | null, absolute?: boolean) =>
  fetchHtmlAction({path, method, absolute})
type FetchBuildStatsArg = {
  locator: string
  buildId?: BuildId
  statCount?: number
}
export const fetchBuildStatsAction = createFetchAction(
  'fetchBuildStats',
  ({locator, buildId, statCount}: FetchBuildStatsArg) =>
    buildId != null && statCount != null
      ? requestBuildsStatsAroundBuild(restRoot, locator, buildId, statCount)
      : requestBuildsStats(restRoot, locator),
)
export const fetchBuildStats = (locator: string) => fetchBuildStatsAction({locator})
export const fetchBuildStatsAroundBuild = (locator: string, buildId: BuildId, statCount: number) =>
  fetchBuildStatsAction({locator, buildId, statCount})

export const moveBuildToTop = createFetchAction('moveBuildToTop', (buildId: BuildId) =>
  moveToTop(restRoot, buildId),
)
export const openDialog = dialog.actions.open
export const closeDialog = dialog.actions.close
type SetUserPropertyArg = {
  name: UserProperty
  value: string
}
export const setUserPropertyAction = createFetchAction(
  'setUserProperty',
  ({name, value}: SetUserPropertyArg) => setProperty(restRoot, name, value),
  {
    condition(_, {getState}) {
      const state = getState()
      return !getIsGuestOrRoot(state)
    },
  },
)
export const setUserProperty = (name: UserProperty, value: string) =>
  setUserPropertyAction({name, value})
export const updateResults =
  (update: () => unknown): ActionCreator =>
  () =>
  dispatch => {
    dispatch(buildsFilters.actions.updateResults())
    update()
  }
export const {
  changeStateFilter,
  changeProjectBuildtypeFilter,
  setTagFilter,
  toggleAdvandedMode: setAdvancedMode,
  changeLocator,
  setLocatorReady,
  setAgentIdFilter,
  setAgentTypeIdFilter,
  setAgentPatternFilter,
} = buildsFilters.actions
export const changeBuildTypeTab = buildTypeTab.actions.change
export const changeBuildTab = buildTab.actions.change
export const fetchBranches = createFetchAction('fetchBranches', (endpoint: string) =>
  requestBranches(restRoot, endpoint),
)

type FetchIsBranchPresentArg = {
  endpoint: string
  restOptions?: RestRequestOptions
}
export const fetchIsBranchPresentAction = createFetchAction(
  'fetchIsBranchPresent',
  ({endpoint, restOptions}: FetchIsBranchPresentArg) =>
    requestIsBranchPresent(restRoot, endpoint, restOptions),
  {condition: ({endpoint}, {getState}) => !getIsBranchPresentInited(getState(), endpoint)},
)
export const fetchIsBranchPresent = (endpoint: string, restOptions?: RestRequestOptions) =>
  fetchIsBranchPresentAction({endpoint, restOptions})
export const fetchOrGetIsBranchPresent =
  (
    endpoint: string,
    restOptions?: RestRequestOptions,
  ): AppThunk<Promise<boolean | null | undefined>> =>
  async (dispatch, getState) => {
    await dispatch(fetchIsBranchPresent(endpoint, restOptions))
    return getIsBranchPresent(getState(), endpoint)
  }
export const setProjectFilter = (id: ProjectId | null | undefined) =>
  changeProjectBuildtypeFilter(
    id
      ? {
          nodeType: 'project',
          id,
        }
      : {
          nodeType: 'all',
        },
  )
export const setBuildtypeFilter = (id: BuildTypeId | null | undefined) =>
  changeProjectBuildtypeFilter(
    id
      ? {
          nodeType: 'bt',
          id,
        }
      : {
          nodeType: 'all',
        },
  )
export const toggleAdvancedMode = () => setAdvancedMode()
export const setIsExperimentalUI = isExperimentalUI.actions.set
export const changeSortingDimension = sorting.actions.changeDimension
export const changeSortingDirection = sorting.actions.changeDirection
export const changeSorting =
  (dimension: string, descending: boolean): AppThunk<any> =>
  dispatch => {
    dispatch(changeSortingDimension(dimension))
    dispatch(changeSortingDirection(descending))
  }
export const showAuthorizeAgentDialog =
  (agentId: AgentId, poolId: AgentPoolId, isCloud: boolean): AppThunk<any> =>
  () => {
    BS?.Agent?.showChangeStatusDialog(
      true,
      agentId,
      false,
      'changeAuthorizeStatus',
      {
        cloud: isCloud,
        poolId,
      },
      () => {
        BS?.AgentsReact?.refreshFetcherData()

        BS?.AgentsReact?.refreshTabsCounters()
      },
    )
  }

const addBlocks = blocks.actions.add

const removeBlocks = blocks.actions.remove

export const collapseBuildTypeBranchesSection = (id: BuildTypeId, branchesToShow: BranchesToShow) =>
  addBlocks(CollapsibleBlocks.COLLAPSED_BRANCHES_SECTION, [
    buildTypeBranchesSectionCollapsedKey(id, branchesToShow),
  ])
export const expandBuildTypeBranchesSection = (id: BuildTypeId, branchesToShow: BranchesToShow) =>
  removeBlocks(CollapsibleBlocks.COLLAPSED_BRANCHES_SECTION, [
    buildTypeBranchesSectionCollapsedKey(id, branchesToShow),
  ])
export const collapseBuildTypeBuildsSection = (id: BuildTypeId | ReadonlyArray<BuildTypeId>) =>
  addBlocks(CollapsibleBlocks.COLLAPSED_PROJECT_BUILDTYPELINE, Array.isArray(id) ? id : [id])
export const expandBuildTypeBuildsSection = (id: BuildTypeId | ReadonlyArray<BuildTypeId>) =>
  removeBlocks(CollapsibleBlocks.COLLAPSED_PROJECT_BUILDTYPELINE, Array.isArray(id) ? id : [id])
export const expandSubproject = (id: ProjectId | ReadonlyArray<ProjectId>) =>
  removeBlocks(CollapsibleBlocks.COLLAPSED_SUBPROJECT, Array.isArray(id) ? id : [id])
export const collapseSubproject = (id: ProjectId | ReadonlyArray<ProjectId>) =>
  addBlocks(CollapsibleBlocks.COLLAPSED_SUBPROJECT, Array.isArray(id) ? id : [id])
export const toggleSubproject = (id: ProjectId, expand: boolean) =>
  expand ? expandSubproject(id) : collapseSubproject(id)
export const resetBuildsLocatorState = builds.actions.reset
export const storeUrlExtensions = urlExtensions.actions.set
export const setRouteAvailabilityResponse = routeAvailabilityResponse.actions.set
export const changeInvestigation =
  (
    buildTypeId: BuildTypeId,
    buildTypeName: string,
    presetFix: boolean,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () =>
    BS?.ResponsibilityDialog?.showDialog(buildTypeId, buildTypeName, presetFix, true, submitHandler)
export const showQueuedBuildsForBranch = (buildTypeId: BuildTypeId, branch: BranchType) =>
  showQueuedBuildsPerBranch.actions.show({
    buildTypeId,
    branch,
  })
export const hideQueuedBuildsForBranch = (buildTypeId: BuildTypeId, branch: BranchType) =>
  showQueuedBuildsPerBranch.actions.hide({
    buildTypeId,
    branch,
  })
export const setShowQueuedBuildsCount = (
  count: number,
  buildTypeId: BuildTypeId,
  branch?: BranchType,
) =>
  showQueuedBuildsCount.actions.set({
    buildTypeId,
    branch,
    count,
  })
export const showQueuedBuildsForProject = showQueuedBuildsInProject.actions.show
export const hideQueuedBuildsForProject =
  (buildTypeId: BuildTypeId, branch?: BranchType | null): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))

    if (getAutoExpandQueuedBuilds(state, statusKey)) {
      return dispatch(queuedTogglerAutoExpand.actions.collapse(statusKey))
    }

    return dispatch(showQueuedBuildsInProject.actions.hide(buildTypeId))
  }
export const toggleQueuedVisibility =
  (
    buildTypeId: BuildTypeId | null | undefined,
    branch: BranchType | null | undefined,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()

    if (buildTypeId != null && !getShowQueuedBuildsInBuildsList(state)) {
      const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))

      if (getAutoExpandQueuedBuilds(state, statusKey)) {
        return dispatch(queuedTogglerAutoExpand.actions.collapse(statusKey))
      }
    }

    return dispatch(showQueuedBuildsInBuildsList.actions.toggle())
  }
export const fetchTabsAction = createFetchAction(
  'fetchTabs',
  (_: TabParamsKey): readonly Tab[] => [],
)
export const fetchTabs =
  (
    tabParamsKey: TabParamsKey,
    restOptions?: RestRequestOptions,
    cacheTabs: boolean = true,
    force: boolean = true,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    if (
      (window.GLOBAL_FETCH_DISABLED === true && restOptions?.essential !== true) ||
      (!force && getIsAllTabsLoading(getState(), tabParamsKey))
    ) {
      return
    }

    processServiceWorkerResponse<ReadonlyArray<Tab>, any>({
      iterator: requestTabs(tabParamsKey, restOptions, cacheTabs),
      onStart: () => dispatch(fetchTabsAction.pending('', tabParamsKey)),
      onSuccess: data => {
        dispatch(fetchTabsAction.fulfilled(data, '', tabParamsKey))
      },
      onError: error => dispatch(fetchTabsAction.rejected(error, '', tabParamsKey)),
    })
  }

type SizeData = {
  size: string
}
export const fetchArtifactsSize = createFetchAction(
  'fetchArtifactsSize',
  async (buildId: BuildId) => {
    const path = `buildArtifactsDetails.html?buildId=${stringifyId(buildId)}`
    const [visible, total]: [SizeData, SizeData] = await Promise.all([
      makeRequest(base_uri, `${path}&showAll=false`).then(processResponse<SizeData>),
      makeRequest(base_uri, `${path}&showAll=true`).then(processResponse<SizeData>),
    ])

    return {
      visible: visible.size,
      total: total.size,
    }
  },
)
export const receiveServerInfo = serverInfo.actions.receive
export const dummyAction = dummyCalls.actions.increment

export const addPlugin = (placeId: PlaceId, name: string) =>
  cachedPlugins.actions.add({placeId, name})
export const removePlugin = (placeId: PlaceId, name: string) =>
  cachedPlugins.actions.remove({placeId, name})
export const assignProblemInvestigations =
  (
    problemId: ProblemId,
    buildId: BuildId,
    presetFix: boolean,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () =>
    BS?.BulkInvestigateMuteTestDialog?.showForBuildProblem(
      problemId,
      buildId,
      presetFix,
      true,
      () => submitHandler != null && submitHandler(),
    )
export const setSyncStorageValue = (key: string, value: string | null) =>
  syncStorageValues.actions.set({key, value})
export const fetchLicensingData = createFetchAction('fetchLicensingData', () =>
  requestLicensingData(restRoot),
)
export const receiveLicensingData = (licensingData: LicensingDataType) =>
  fetchLicensingData.fulfilled(licensingData, '')
const REMAINING_AGENTS_TIMEOUT = 1000
const REMAINING_AGENT_EVENT_TYPE_TOPICS = getTopics('', [
  SubscriptionEvents.AGENT_REGISTERED,
  SubscriptionEvents.AGENT_UNREGISTERED,
  SubscriptionEvents.AGENT_STATUS_CHANGED,
  SubscriptionEvents.AGENT_REMOVED,
])
export const subscribeOnRemainingAgents = (): AppThunk<() => void> => dispatch =>
  subscribeOnOverallEvents(
    REMAINING_AGENT_EVENT_TYPE_TOPICS,
    () => {
      dispatch(fetchLicensingData())
    },
    REMAINING_AGENTS_TIMEOUT,
  )
export const setBuildTypesLimit = buildTypesLimit.actions.set
