import type {IEnvironment} from 'relay-runtime'
import {createMockEnvironment, MockPayloadGenerator} from 'relay-test-utils'

const LENGTH = 4

const connectionFromLength = (length: number) => () => ({
  edges: Array(length).fill(undefined),
})

export default function getTestEnvironment(): IEnvironment {
  const testEnvironment = createMockEnvironment()

  const queueResolver = () =>
    testEnvironment.mock.queueOperationResolver(operation => {
      queueResolver()
      return MockPayloadGenerator.generate(operation, {
        AgentBuildTypeConnection: connectionFromLength(LENGTH),
        BuildType: (_, generateId) => ({
          name: `Build Configuration ${generateId()}`,
        }),
      })
    })

  queueResolver()
  return testEnvironment
}
