import type {StatusRequest, ProjectOrBuildTypeStatus} from '../types'

import processResponse from './processResponse'
import request from './request'

export default (
  serverUrl: string,
  endpoint: string,
  requestIds: ReadonlyArray<StatusRequest>,
): Promise<ReadonlyArray<ProjectOrBuildTypeStatus>> =>
  request(serverUrl, `${endpoint}?statuses=true`, {
    method: 'POST',
    body: JSON.stringify(requestIds),
  }).then<readonly ProjectOrBuildTypeStatus[]>(processResponse)
