import type {BranchType} from '../../../../types'

export const Modes = {
  BRANCHES: 'branches',
  BUILDS: 'builds',
}
export const isForbiddenCombo = (
  branch: BranchType | null | undefined,
  mode: string | null | undefined,
): boolean => mode === Modes.BRANCHES && branch != null && !branch.groupFlag
