import type {AppThunk} from '../../actions/types'
import {createFetchAction} from '../../reducers/fetchable'
import {restRoot} from '../../rest/consts'
import {Entities, normalizeProblemOccurrences} from '../../rest/schemata'
import {restApi} from '../../services/rest'
import {ProjectId, stringifyId} from '../../types'
import {BS} from '../../types/BS_types'
import {ActionThrottler} from '../../utils/actionThrottler'

import {mergeEntities} from '../../utils/entities'
import {WritableKeyValue} from '../../utils/object'

import {problemOccurrenceFields, requestProblemOccurrencesTree} from './BuildProblems.rest'
import {
  getProblemOccurrencesSubtreeFetchable,
  getProblemOccurrencesTreeFetchable,
} from './BuildProblems.selectors'
import type {ProblemOccurrencesTreeType, ProblemOccurrenceType} from './BuildProblems.types'

const THROTTLER_TIMEOUT = 10000

const processProblemOccurrencesTree = (data: ProblemOccurrencesTreeType) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const problemOccurrences = leaf.problemOccurrences?.problemOccurrence ?? []
    const normalizedProblemOccurrences = normalizeProblemOccurrences(problemOccurrences)
    entities = mergeEntities(entities, normalizedProblemOccurrences.entities)
  })
  return {tree: data, entities}
}

const processProblemOccurrencesSubtree = (data: ProblemOccurrencesTreeType) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const problemOccurrences = leaf.problemOccurrences?.problemOccurrence ?? []
    const normalizedProblemOccurrences = normalizeProblemOccurrences(problemOccurrences)
    entities = mergeEntities(entities, normalizedProblemOccurrences.entities)
  })
  return {tree: data, entities}
}

const getFetchProblemOccurenceArg = (problemLocator: string) => ({
  problemLocator,
  fields: problemOccurrenceFields,
})

export const fetchProblemOccurrence = (problemLocator: string) =>
  restApi.endpoints.getBuildProblemOccurrence.initiate(
    getFetchProblemOccurenceArg(problemLocator),
    {subscribe: false, forceRefetch: true},
  )

type FetchProblemOccurrenceTreeParamsType = {
  locatorInEndpoint?: boolean
  endpoint: string
  locator: string
}
export const fetchProblemOccurrenceTree = createFetchAction(
  'fetchProblemOccurrenceTree',
  ({locatorInEndpoint, endpoint, locator}: FetchProblemOccurrenceTreeParamsType) => {
    const treeLocator = !locatorInEndpoint ? locator : null
    return requestProblemOccurrencesTree(restRoot, endpoint, treeLocator).then(
      processProblemOccurrencesTree,
    )
  },
)

export const receiveProblemOccurrencesTree = ({
  data,
  locator,
}: {
  data: ProblemOccurrencesTreeType
  locator: string
}) =>
  fetchProblemOccurrenceTree.fulfilled(processProblemOccurrencesTree(data), '', {
    endpoint: '',
    locator,
  })

const fetchProblemOccurrenceTreeThrottler = new ActionThrottler(
  fetchProblemOccurrenceTree,
  THROTTLER_TIMEOUT,
  true,
)
export const fetchProblemOccurrenceTreeWithThrottle =
  (params: FetchProblemOccurrenceTreeParamsType & {force?: boolean}): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {force, ...otherParams} = params
    const {loading} = getProblemOccurrencesTreeFetchable(state, params.locator)
    if (!loading) {
      dispatch(fetchProblemOccurrenceTreeThrottler.fetch(otherParams, force))
    }
  }

const fetchRootCausesTreeThrottler = new ActionThrottler(
  fetchProblemOccurrenceTree,
  THROTTLER_TIMEOUT,
  true,
)
export const fetchRootCausesTreeWithThrottle =
  (params: FetchProblemOccurrenceTreeParamsType & {force?: boolean}): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {force, ...otherParams} = params
    const {loading} = getProblemOccurrencesTreeFetchable(state, params.locator)

    if (!loading) {
      dispatch(fetchRootCausesTreeThrottler.fetch(otherParams, force))
    }
  }

export type FetchProblemOccurrenceSubTreeParamsType = {
  locatorInEndpoint?: boolean
  subTreeRootIdInLocator?: boolean
  endpoint: string
  treeLocator: string
  subTreeRootId: string
  depth: number
}
export const fetchProblemOccurrenceSubtree = createFetchAction(
  'fetchProblemOccurrenceSubtree',
  ({
    locatorInEndpoint,
    subTreeRootIdInLocator,
    endpoint,
    treeLocator,
    subTreeRootId,
  }: FetchProblemOccurrenceSubTreeParamsType) => {
    const subtreeLocator = !locatorInEndpoint
      ? [treeLocator, subTreeRootIdInLocator ? `subTreeRootId:${subTreeRootId}` : null]
          .filter(Boolean)
          .join(',')
      : null
    const subTreeRootIdParam = !subTreeRootIdInLocator ? subTreeRootId : null

    return requestProblemOccurrencesTree(
      restRoot,
      endpoint,
      subtreeLocator,
      subTreeRootIdParam,
    ).then(processProblemOccurrencesSubtree)
  },
)

export const receiveProblemOccurrencesSubtree = ({
  data,
  treeLocator,
  subTreeRootId,
  depth,
}: {
  data: ProblemOccurrencesTreeType
  treeLocator: string
  subTreeRootId: string
  depth: number
}) =>
  fetchProblemOccurrenceSubtree.fulfilled(processProblemOccurrencesSubtree(data), '', {
    treeLocator,
    subTreeRootId,
    depth,
    endpoint: '',
  })

const fetchProblemOccurrenceSubtreeThrottler = new ActionThrottler(
  fetchProblemOccurrenceSubtree,
  THROTTLER_TIMEOUT,
  true,
)
export const fetchProblemOccurrenceSubtreeWithThrottle =
  (params: FetchProblemOccurrenceSubTreeParamsType & {force?: boolean}): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const {force, ...otherParams} = params
    const {treeLocator, subTreeRootId} = params
    const {loading} = getProblemOccurrencesSubtreeFetchable(state, treeLocator, subTreeRootId)

    if (!loading) {
      dispatch(fetchProblemOccurrenceSubtreeThrottler.fetch(otherParams, force))
    }
  }

export const assignProblemsInvestigations =
  (
    projectId: ProjectId,
    problemOccurrences: ReadonlyArray<ProblemOccurrenceType>,
    fixMode: boolean = false,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () => {
    const problemsData: WritableKeyValue<string, string> = {}
    problemOccurrences.forEach(problemOccurrence => {
      const problemId = problemOccurrence?.problem?.id
      const buildId = problemOccurrence?.build?.id
      if (problemId != null && buildId != null) {
        problemsData[`BuiPro${stringifyId(problemId)}`] = `${stringifyId(buildId)}`
      }
    })
    BS?.BulkInvestigateMuteTestDialog?.showForBuildProblems(
      problemsData,
      fixMode,
      true,
      submitHandler,
    )
  }
