import {createReducer} from '@reduxjs/toolkit'
import {castDraft} from 'immer'
import * as Redux from 'redux'

import {keyValueFetchable} from '../../reducers/fetchable'
import type {KeyValue} from '../../utils/object'

import {
  createCleanupRuleAction,
  deleteCleanupRuleAction,
  fetchCleanupRulesAction,
  updateCleanupRuleAction,
} from './Cleanup.actions'
import {getCleanupProjectNode, getCleanupBuildTypeNode} from './Cleanup.types'
import type {ErrorAnswerType, RuleType, CleanupHolderNodeType} from './Cleanup.types'
import {getKey} from './Cleanup.utils'
import formReducer from './CleanupForm/CleanupForm.reducers'

const cleanupGetRulesReducer = keyValueFetchable(
  arg => getKey(arg.holderNode),
  fetchCleanupRulesAction,
  null,
  () => fetchCleanupRulesAction.typePrefix,
)
const cleanupDeleteRulesReducer = keyValueFetchable(
  arg => getKey(arg.holderNode),
  deleteCleanupRuleAction,
  null,
  () => deleteCleanupRuleAction.typePrefix,
)
const cleanupUpdateRulesReducer = keyValueFetchable(
  arg => getKey(arg.holderNode),
  updateCleanupRuleAction,
  null,
  () => updateCleanupRuleAction.typePrefix,
)
const cleanupCreateRulesReducer = keyValueFetchable(
  arg => getKey(arg.holderNode),
  createCleanupRuleAction,
  null,
  () => createCleanupRuleAction.typePrefix,
)

const cleanupEntitiesReducer = createReducer<KeyValue<string, ReadonlyArray<RuleType>>>(
  {},
  builder => {
    builder.addCase(fetchCleanupRulesAction.fulfilled, (state, action) => {
      for (const item of action.payload) {
        const node: CleanupHolderNodeType =
          item.holder === 'project'
            ? getCleanupProjectNode({
                id: item.holderExternalId,
              })
            : getCleanupBuildTypeNode({
                id: item.holderExternalId,
              })
        const key = getKey(node)

        const currentState = state[key]
        if (item.rules.length > 0 || (currentState != null && currentState.length > 0)) {
          state[key] = castDraft(item.rules)
        }
      }
    })

    builder.addCase(createCleanupRuleAction.fulfilled, (state, action) => {
      const key = getKey(action.meta.arg.holderNode)
      state[key] ??= []
      state[key]!.push({
        ruleId: action.payload,
        ...action.meta.arg.rule,
      })
    })

    builder.addCase(updateCleanupRuleAction.fulfilled, (state, action) => {
      const key = getKey(action.meta.arg.holderNode)
      if (state[key] != null) {
        Object.assign(state[key]!, action.meta.arg.rule)
      }
    })

    builder.addCase(deleteCleanupRuleAction.fulfilled, (state, action) => {
      const key = getKey(action.meta.arg.holderNode)
      if (state[key] != null) {
        state[key] = state[key]!.filter(rule => rule.ruleId !== action.meta.arg.ruleId)
      }
    })
  },
)

const errorReducer = createReducer<ErrorAnswerType | null | undefined>(null, builder => {
  builder.addCase(fetchCleanupRulesAction.pending, () => null)
  builder.addCase(fetchCleanupRulesAction.fulfilled, () => null)
  builder.addCase(createCleanupRuleAction.pending, () => null)
  builder.addCase(createCleanupRuleAction.fulfilled, () => null)
  builder.addCase(updateCleanupRuleAction.pending, () => null)
  builder.addCase(updateCleanupRuleAction.fulfilled, () => null)
  builder.addCase(deleteCleanupRuleAction.pending, () => null)
  builder.addCase(deleteCleanupRuleAction.fulfilled, () => null)

  builder.addDefaultCase((state, action) => {
    if (
      fetchCleanupRulesAction.rejected.match(action) ||
      createCleanupRuleAction.rejected.match(action) ||
      updateCleanupRuleAction.rejected.match(action) ||
      deleteCleanupRuleAction.rejected.match(action)
    ) {
      return JSON.parse(action.error?.message ?? 'null')
    }
    return state
  })
})

const requestStatusReducer = createReducer({}, builder => {
  builder.addMatcher(
    action => action.type.startsWith(fetchCleanupRulesAction.typePrefix),
    cleanupGetRulesReducer,
  )
  builder.addMatcher(
    action => action.type.startsWith(createCleanupRuleAction.typePrefix),
    cleanupCreateRulesReducer,
  )
  builder.addMatcher(
    action => action.type.startsWith(updateCleanupRuleAction.typePrefix),
    cleanupUpdateRulesReducer,
  )
  builder.addMatcher(
    action => action.type.startsWith(deleteCleanupRuleAction.typePrefix),
    cleanupDeleteRulesReducer,
  )
})
const cleanupReducer = Redux.combineReducers({
  errors: errorReducer,
  form: formReducer,
  entities: cleanupEntitiesReducer,
  requestStatus: requestStatusReducer,
})
export default cleanupReducer
