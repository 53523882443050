import {NodeType} from './Tree.types'

export const getTreeNodes = (roots: ReadonlyArray<NodeType>): ReadonlyArray<NodeType> => {
  const stack = []
  const result = []
  stack.push(...roots)

  let nextNode
  while ((nextNode = stack.pop()) != null) {
    const node: NodeType = {...nextNode}

    if (node.children != null) {
      stack.push(...[...node.children].reverse())
    }

    result.push(node)
  }

  return result.filter(node => !node.detailsComponent)
}

export const hasSelection = () => {
  const selection = window.getSelection()
  return selection != null && selection.toString().length > 0
}
