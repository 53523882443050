import type {
  GraphQLResponseWithData,
  IEnvironment,
  RequestParameters,
  Variables,
} from 'relay-runtime'
import {Environment, Network, RecordSource, Store} from 'relay-runtime'

import processResponse, {processGraphQLErrors} from '../rest/processResponse'
import request from '../rest/request'
import {base_uri} from '../types/BS_types'

import getTestEnvironment from './getTestEnvironment'

function fetchQuery(operation: RequestParameters, variables: Variables) {
  return request(base_uri, 'app/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  })
    .then<GraphQLResponseWithData | ReadonlyArray<GraphQLResponseWithData>>(processResponse)
    .then(response => {
      if (operation.operationKind === 'query') {
        processGraphQLErrors(response instanceof Array ? response[0] : response)
      }

      return response
    })
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})
const isTest = process.env.NODE_ENV === 'test' || window.STORYBOOK_ENV != null
export default (): IEnvironment => (isTest ? getTestEnvironment() : environment)
