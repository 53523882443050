import type {To} from 'history'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  to: To
}
export default function Redirect({to}: Props) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}
