interface RequestIdleCallbackOptions {
  timeout?: number
}

enum CallbackIDType {
  RAF,
  ICB,
  TIMEOUT,
}

export type PostponedCallbackReturn = {
  id: number
  type: CallbackIDType
}

type PostponedTaskOptions = RequestIdleCallbackOptions & {
  fallbackToRAF?: boolean
}

const DEFAULT_TIMEOUT = 30
const IDLE_MULTIPLIER = 10

export const requestPostponedCallback = (
  callback: () => void,
  options: PostponedTaskOptions = {},
): PostponedCallbackReturn => {
  if (window.requestIdleCallback) {
    return {
      type: CallbackIDType.ICB,
      id: window.requestIdleCallback(callback, {
        timeout: (options?.timeout || DEFAULT_TIMEOUT) * IDLE_MULTIPLIER,
      }),
    }
  } else if (options.fallbackToRAF) {
    return {
      type: CallbackIDType.RAF,
      id: window.requestAnimationFrame(callback),
    }
  } else {
    return {
      type: CallbackIDType.TIMEOUT,
      id: window.setTimeout(callback, options.timeout),
    }
  }
}

export const cancelPostponedCallback = (data: PostponedCallbackReturn) => {
  if (data.type === CallbackIDType.ICB) {
    window.cancelIdleCallback(data.id)
    return
  }

  if (data.type === CallbackIDType.RAF) {
    window.cancelAnimationFrame(data.id)
    return
  }

  window.clearTimeout(data.id)
}
