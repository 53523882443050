import {
  AsyncThunk,
  createAsyncThunk,
  createReducer,
  Draft,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
} from '@reduxjs/toolkit'
import type {AsyncThunkFulfilledActionCreator} from '@reduxjs/toolkit/dist/createAsyncThunk'
import deepEqual from 'fast-deep-equal'
import {original} from 'immer'

import {AppDispatch, FetchAction} from '../actions/types'
import type {Fetchable, SuccessReceiveMeta} from '../types'

import {BS} from '../types/BS_types'

import {State} from './types'
import {keyValueReducer} from './utils'

type PendingMeta = {
  invalidate?: boolean
  isBackground?: boolean
  request?: Promise<unknown>
}

export type AsyncThunkConfig = {
  state: State
  dispatch: AppDispatch
  pendingMeta: PendingMeta
  serializedErrorType: Error | null
}

export const createFetchAction = <D, P = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<D, P, AsyncThunkConfig>,
  options?: Partial<AsyncThunkOptions<P, AsyncThunkConfig>>,
) => {
  let resolveRequest: (value: unknown) => unknown
  const request = new Promise(resolve => {
    resolveRequest = resolve
  })
  const thunk = createAsyncThunk<D, P, AsyncThunkConfig>(typePrefix, payloadCreator, {
    serializeError: e => (e instanceof Error ? e : null),
    getPendingMeta: (...args) => ({request, ...options?.getPendingMeta?.(...args)}),
    ...options,
  })
  return Object.assign(
    (arg: P) => (dispatch: AppDispatch) => {
      const result = dispatch(thunk(arg))
      resolveRequest(
        result.unwrap().catch(e => {
          BS?.Log?.error('Something went wrong: ', e)
        }),
      )
      return result
    },
    thunk,
  )
}

export const fetchable = <T, D, P>(
  thunk: AsyncThunk<D, P, AsyncThunkConfig>,
  defaultState: T,
  dataReducer: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<D, P, AsyncThunkConfig>>,
  ) => T,
  getReceiveMeta: (arg: P) => SuccessReceiveMeta | undefined = () => undefined,
) =>
  createReducer(
    (): Fetchable<T> => ({
      data: defaultState,
      loading: false,
      backgroundLoading: false,
      error: null,
      ready: false,
      inited: false,
      receiveMeta: {},
      request: null,
    }),
    builder => {
      builder.addCase(thunk.pending, (state, action) => {
        const {invalidate, isBackground, request} = action.meta
        if (invalidate) {
          state.data = defaultState as Draft<T>
          state.ready = false
        }
        state.loading = true
        state.backgroundLoading = isBackground ?? false
        state.inited = true
        if (request != null) {
          state.request = request
        }
      })
      builder.addCase(thunk.fulfilled, (state, action) => {
        const originalData = original(state)!.data as T
        const data = dataReducer(originalData, action)
        if (!deepEqual(data, originalData)) {
          state.data = data as Draft<T>
        }
        state.loading = false
        state.backgroundLoading = false
        state.error = null
        state.ready = true
        state.inited = true
        const receiveMeta = getReceiveMeta(action.meta.arg)
        if (state.receiveMeta != null) {
          Object.assign(state.receiveMeta, receiveMeta)
        } else {
          state.receiveMeta = receiveMeta
        }
      })
      builder.addCase(thunk.rejected, (state, action) => {
        state.loading = false
        state.backgroundLoading = false
        state.error = action.error
        state.ready = true
        state.inited = true
      })
    },
  )

export const keyValueFetchable = <T, D, P>(
  getKey: (arg: P) => string,
  thunk: AsyncThunk<D, P, AsyncThunkConfig>,
  defaultState: T,
  dataReducer: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<D, P, AsyncThunkConfig>>,
  ) => T,
  getReceiveMeta?: (arg: P) => SuccessReceiveMeta | undefined,
) =>
  keyValueReducer(
    (action: FetchAction<P>) => getKey(action.meta.arg),
    fetchable(thunk, defaultState, dataReducer, getReceiveMeta),
    [thunk.pending, thunk.fulfilled, thunk.rejected],
  )
