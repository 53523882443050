import type {WritableKeyValue} from './object'

export default function asyncBatch<I, O>(
  asyncFn: (data: I) => Promise<O>,
): (data: I) => Promise<O> {
  let isNextScheduled = false
  let scheduledData = null
  let currentJob: Promise<O> | null = null
  return (data: I) =>
    new Promise(async (resolve, reject) => {
      try {
        scheduledData = data

        if (isNextScheduled) {
          return
        }

        if (currentJob != null) {
          isNextScheduled = true
          await currentJob
          isNextScheduled = false
        }

        const job = asyncFn(scheduledData)
        currentJob = job
        const result: O = await job

        if (!isNextScheduled && job === currentJob) {
          resolve(result)
        }
      } catch (e) {
        currentJob = null
        isNextScheduled = false
        if (e instanceof Error) {
          reject(e)
        }
      }
    })
}
type AsyncBatchByIdParams<I, O, ID> = {
  readonly asyncFn: (data: I) => Promise<O>
  readonly getId: (arg0: I) => ID
}
export function asyncBatchById<I, O, ID extends PropertyKey>({
  getId,
  asyncFn,
}: AsyncBatchByIdParams<I, O, ID>): (data: I) => Promise<O> {
  const batched: WritableKeyValue<ID, (data: I) => Promise<O>> = {}
  return (data: I) => {
    const id = getId(data)
    let callback = batched[id]

    if (callback == null) {
      callback = batched[id] = asyncBatch(asyncFn)
    }

    return callback(data)
  }
}
