import {InvestigationActionTypes} from './InvestigationHistory.types'
import type {InvestigationHistoryType} from './InvestigationHistory.types'

export const getInvestigationHistoryActionTextByType = (
  investigationHistoryItem: InvestigationHistoryType,
): string => {
  const assigneeName =
    investigationHistoryItem.assignee?.descriptiveName ??
    investigationHistoryItem.assignee?.userName ??
    'Unknown'
  const comment = investigationHistoryItem.commentText ?? ''
  const commentWithWrapper = comment !== '' ? `: ${comment}` : ''

  switch (investigationHistoryItem.actionType) {
    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN:
    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN_STICKY:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY:
      return `Failure investigation was assigned to ${assigneeName}${commentWithWrapper}`

    case InvestigationActionTypes.TEST_INVESTIGATION_REMOVE:
    case InvestigationActionTypes.TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_REMOVE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_REMOVE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE:
      return `Failure investigation was removed${commentWithWrapper}`

    case InvestigationActionTypes.TEST_MARK_AS_FIXED:
    case InvestigationActionTypes.BUILD_TYPE_MARK_AS_FIXED:
    case InvestigationActionTypes.BUILD_PROBLEM_MARK_AS_FIXED:
      return `Marked as fixed by ${assigneeName}${commentWithWrapper}`

    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
      return `Failure investigation was assigned${commentWithWrapper}`

    case InvestigationActionTypes.TEST_MARK_AS_FIXED_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE:
      return `Marked as fixed${commentWithWrapper}`

    case InvestigationActionTypes.TEST_INVESTIGATION_CHANGE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_CHANGE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_CHANGE:
      return `Investigation was modified${comment != null ? `. ${comment}` : ''}`

    case InvestigationActionTypes.TEST_INVESTIGATION_CHANGE_WITH_REASSIGN:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN:
      return `Investigation was modified and reassigned to ${assigneeName}${
        comment != null ? `. ${comment}` : ''
      }`

    default:
      return `Unknown action type – ${investigationHistoryItem.actionType}`
  }
}
export const getInvestigationHistoryResolveMethod = (
  investigationHistoryItem: InvestigationHistoryType,
): string | null | undefined => {
  switch (investigationHistoryItem.actionType) {
    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN:
    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
      return 'on failure fix'

    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN_STICKY:
    case InvestigationActionTypes.TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY:
    case InvestigationActionTypes.BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY:
    case InvestigationActionTypes.BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
      return 'manually'

    default:
      return null
  }
}
