import {branch, ComponentEnhancer, withProps} from 'recompose'

import compose from 'lodash/flowRight'
import {
  createContext,
  ElementType,
  memo,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import type {Enhancer} from '../../../types'
import {withContextAsProp} from '../../../utils/contexts'
import fromRenderProps from '../../../utils/fromRenderProps'

import {SeqProps} from '../SequenceLoader/SequenceLoader'

import {observeVisibility} from './Visible.utils'

type Props = SeqProps & {
  readonly id?: string
  readonly children: (isVisible: boolean) => ReactNode
  readonly TagName?: ElementType
  readonly defaultVisible?: boolean
  readonly className?: string
}
const VisibilityContext = createContext<boolean | null | undefined>(null)
const withVisibilityContext = withContextAsProp(VisibilityContext, 'isVisible')
export const useParentVisibility = (): boolean | null | undefined => useContext(VisibilityContext)
export function useVisibility(
  ref: {
    current: HTMLElement | null | undefined
  },
  id?: string | null | undefined,
  defaultVisible: boolean = false,
  disable: boolean = false,
): boolean {
  const [isVisible, setIsVisible] = useState(defaultVisible)
  useEffect(() => {
    if (!disable && ref.current != null) {
      return observeVisibility(ref.current, setIsVisible, id)
    }

    return undefined
  }, [id, ref, disable])
  return isVisible
}

export function useVisibilityRef(
  id?: string | null | undefined,
  defaultVisible: boolean = false,
  disable: boolean = false,
): [Ref<HTMLDivElement>, boolean] {
  const [isVisible, setIsVisible] = useState(defaultVisible)
  const [storedNode, setStoredNode] = useState<HTMLDivElement>()
  // Both refs are needed.
  // First is to always access real current value, second is to ensure rendering on the value update.
  const visibilityRevObject = useRef<HTMLDivElement | null>(null)
  const visibilityRefCallback = useCallback((node: HTMLDivElement) => {
    if (node != null) {
      visibilityRevObject.current = node
      setStoredNode(node)
    }
  }, [])

  const setVisibility = useCallback(
    (visible: boolean) => {
      // We should switch the visibility based only on the real current node
      if (storedNode === visibilityRevObject.current) {
        setIsVisible(visible)
      }
    },
    [storedNode],
  )

  useEffect(() => {
    if (!disable && storedNode != null) {
      return observeVisibility(storedNode, setVisibility, id)
    }

    return undefined
  }, [id, storedNode, disable, setVisibility])

  return [visibilityRefCallback, isVisible]
}

function VisibleContainer({id, children, TagName = 'div', className, defaultVisible}: Props) {
  const ref = useRef()
  const isVisible = useVisibility(ref, id, defaultVisible)
  return (
    <TagName key={id} ref={ref} className={className}>
      <VisibilityContext.Provider value={isVisible}>
        {children(isVisible)}
      </VisibilityContext.Provider>
    </TagName>
  )
}

export type VisibleProps = {
  readonly isVisible?: boolean | null
}

export const withVisibility = (
  detect?: boolean | null,
  TagName: ElementType = 'div',
  ignoreProp: boolean = false,
  className?: string,
): Enhancer<VisibleProps, any> => {
  const addProps: Partial<Props> = {
    TagName,
    className,
  }
  const withVisibilityDetect = fromRenderProps(withProps(addProps)(VisibleContainer), isVisible =>
    ignoreProp
      ? Object.freeze({})
      : {
          isVisible,
        },
  )

  switch (detect) {
    case true:
      return withVisibilityDetect

    case false:
      return withVisibilityContext

    default:
      // try context, fall back to detect
      return compose(
        withVisibilityContext,
        branch(
          props => props.isVisible == null,
          withVisibilityDetect as ComponentEnhancer<VisibleProps, VisibleProps>,
        ),
      )
  }
}
export default memo(VisibleContainer)
