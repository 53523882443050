import type {GraphQLTaggedNode} from 'react-relay'
import {graphql} from 'react-relay'

export const poolsAuthorizePermissionsQuery: GraphQLTaggedNode = graphql`
  query AgentsScreenPoolsAuthorizePermissionsQuery {
    agentPools {
      edges {
        node {
          id
          rawId
          name
          permissions {
            authorizeAgents
            manageAgents
          }
        }
      }
    }
  }
`

export const poolAuthorizePermissionsQuery: GraphQLTaggedNode = graphql`
  query AgentsScreenPoolAuthorizePermissionsQuery($agentPoolId: ID!) {
    agentPool(id: $agentPoolId) {
      id
      permissions {
        authorizeAgents
        manageAgents
      }
    }
  }
`

export const agentTypesQuery: GraphQLTaggedNode = graphql`
  query AgentsScreenAgentTypesQuery {
    agentTypes {
      edges {
        node {
          rawId
          name
          isCloud
          agentPool {
            rawId
            name
          }
          cloudImage {
            name
          }
          environment {
            os {
              type
            }
          }
        }
      }
    }
  }
`
