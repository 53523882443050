import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getPager} from '../components/common/Pager/Pager.selectors'
import type {PagerType} from '../components/common/Pager/Pager.types'
import {PagerGroup} from '../components/common/Pager/Pager.types'
import {getLastPageToCount} from '../components/common/Pager/Pager.utils'
import {createFetchAction} from '../reducers/fetchable'
import requestBuilds, {
  requestBuildsCount,
  requestBuildsDetails,
  requestHasBuilds,
} from '../rest/builds'
import {restRoot} from '../rest/consts'
import {getBuildLocator} from '../rest/locators'
import type {RestRequestOptions} from '../rest/request'
import {
  normalizeBuild,
  normalizeBuildChanges,
  normalizeBuilds,
  normalizeBuildTypeLinksFromBuilds,
  normalizeCompatibleAgents,
  NormalizedBuilds,
} from '../rest/schemata'
import {getBuildsLoading, getBuildsLocatorWithPage} from '../selectors'
import {Build} from '../services/rest'
import type {BuildId, BuildType, NormalizedBuildType, RequestOptionsParams} from '../types'
import {
  ActionThrottlerWithArrayCollect,
  ActionThrottlerWithObjectCollect,
} from '../utils/actionThrottler'
import type {KeyValue} from '../utils/object'
import {keyValue} from '../utils/object'
import type {Unsubscribe} from '../utils/subscriber'
import {subscribeOnRunningBuild} from '../utils/subscriber'

import {buildDetailsQueue} from './buildDetailsQueue'
import type {AppThunk} from './types'

const processBuildsData = (data: readonly BuildType[], requestOptions?: RequestOptionsParams) => {
  const builds = normalizeBuilds(data)
  const withBuildTypes = requestOptions?.withBuildTypeDetails
  let entities = {...builds.entities}

  if (withBuildTypes === true) {
    const buildTypeLinks = normalizeBuildTypeLinksFromBuilds(data)
    entities = {...entities, buildTypeLinks: buildTypeLinks.entities.buildTypeLinks}
  }

  return {
    result: builds.result,
    entities,
  }
}

type ReceiveBuildThrottledParams = {
  readonly withBuildTypes?: boolean
  readonly request?: Promise<unknown>
}

export const receiveBuildAction = createAction(
  'receiveBuild',
  (data: readonly BuildType[], locators: string[], params?: ReceiveBuildThrottledParams) => ({
    payload: normalizeBuilds(data),
    meta: {arg: {originalArgs: {locators, ...params}}},
  }),
)

export const receiveBuild = (data: BuildType, locator: string = getBuildLocator(data.id)) =>
  receiveBuildAction([data], [locator])

const receiveBuildThrottled =
  (
    receiveBuildData: ReadonlyArray<BuildType>,
    params?: ReceiveBuildThrottledParams,
  ): AppThunk<any> =>
  dispatch => {
    if (receiveBuildData.length) {
      dispatch(
        receiveBuildAction(
          receiveBuildData,
          receiveBuildData.map(item => getBuildLocator(item.id)),
          params,
        ),
      )
    }
  }

const receiveBuildWithThrottle = new ActionThrottlerWithArrayCollect(
  builds =>
    receiveBuildThrottled(builds, {
      withBuildTypes: true,
    }),
  [],
)
export const multiplyReceiveBuild = (data: BuildType): AppThunk<any> =>
  receiveBuildWithThrottle.fetch([data])

export const receiveBuildWSDataAction =
  createAction<KeyValue<BuildId, NormalizedBuildType | null>>('receiveBuildWSData')

const receiveBuildWSDataWithThrottle = new ActionThrottlerWithObjectCollect(
  receiveBuildWSDataAction,
  {},
)
export const receiveBuildWSData = (data: NormalizedBuildType): AppThunk<any> =>
  receiveBuildWSDataWithThrottle.fetch(keyValue(data.id, data))
export const subscribeOnBuild =
  (buildId: BuildId): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnRunningBuild(buildId, data => {
      if (data == null) {
        return
      }

      const buildsWSData: KeyValue<BuildId, NormalizedBuildType | null> | null | undefined =
        normalizeBuild(data).entities.builds

      if (buildsWSData != null) {
        dispatch(receiveBuildWSDataWithThrottle.fetch(buildsWSData))
      }
    })
export const compatibleAgents = createSlice({
  name: 'compatibleAgents',
  initialState: {},
  reducers: {
    receive(state, action: PayloadAction<NormalizedBuilds>) {
      Object.assign(state, action.payload.entities.compatibleAgents)
    },
  },
  extraReducers: builder =>
    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      Object.assign(state, action.payload)
    }),
})
export const receiveCompatibleAgents = (data: ReadonlyArray<Build>) =>
  compatibleAgents.actions.receive(normalizeCompatibleAgents(data))
export const receiveBuildChanges = createAction(
  'receiveBuildChanges',
  (data: ReadonlyArray<Build>) => ({payload: normalizeBuildChanges(data)}),
)
export const receiveBuildsDetails =
  (data: ReadonlyArray<BuildType>): AppThunk<any> =>
  dispatch => {
    dispatch(receiveBuildChanges(data))
    dispatch(receiveCompatibleAgents(data))
  }
export const stopFetching = (queueEntryId: string): void => buildDetailsQueue.cancel(queueEntryId)

type FetchBuildsDetailsArg = {
  locatorToStore: string
  locatorToFetch: string
  options: RequestOptionsParams | null | undefined
}
export const fetchBuildsDetailsAction = createFetchAction(
  'fetchBuildsDetails',
  ({locatorToFetch, options}: FetchBuildsDetailsArg, {dispatch}) => {
    const throttler = new ActionThrottlerWithArrayCollect(receiveBuildsDetails, [])
    return requestBuildsDetails(restRoot, locatorToFetch, options, build =>
      dispatch(throttler.fetch([build])),
    )
  },
  {
    condition: ({locatorToStore}, {getState}) =>
      getState().buildsDetails[locatorToStore]?.inited !== true,
  },
)
const fetchBuildsDetails = (
  locatorToStore: string,
  locatorToFetch: string,
  options: RequestOptionsParams | null | undefined,
) => fetchBuildsDetailsAction({locatorToStore, locatorToFetch, options})

type FetchBuildsPageDataParams = {
  readonly locator: string
  readonly pager: PagerType
  readonly requestOptions?: RequestOptionsParams
  readonly inBackground?: boolean
}

type FetchBuildsCountParams = FetchBuildsPageDataParams & {
  readonly count: number
  readonly locatorIncludesCount?: boolean
}
export const DEFAULT_LOOKUP_LIMIT = 10000

export const fetchBuildsCount = createFetchAction(
  'fetchBuildsCount',
  ({
    locator,
    pager: {lookupLimit = DEFAULT_LOOKUP_LIMIT},
    requestOptions,
    count,
    locatorIncludesCount,
  }: FetchBuildsCountParams) =>
    requestBuildsCount(
      restRoot,
      locator,
      locatorIncludesCount ? null : count,
      lookupLimit,
      requestOptions,
    ),
)

export type FetchBuildDataParams = {
  readonly locator: string
  readonly withPager?: boolean
  readonly requestOptions: RequestOptionsParams
  readonly inBackground?: boolean
}
export const fetchBuildsAction = createFetchAction(
  'fetchBuilds',
  ({locator, withPager, requestOptions, inBackground}: FetchBuildDataParams, {dispatch}) => {
    if (withPager) {
      return requestBuilds(restRoot, locator, requestOptions).then(data =>
        processBuildsData(data, requestOptions),
      )
    } else {
      const throttler = new ActionThrottlerWithArrayCollect(
        builds =>
          receiveBuildThrottled(builds, {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            request,
          }),
        [],
      )
      const request = requestBuilds(restRoot, locator, requestOptions, build => {
        if (!inBackground && requestOptions.withProgress !== false) {
          dispatch(throttler.fetch([build]))
        }
      }).then(data => processBuildsData(data, requestOptions))
      return request
    }
  },
)

export const receiveBuildsData = ({
  locator,
  data,
  requestOptions = {},
}: {
  locator: string
  data: ReadonlyArray<BuildType>
  requestOptions?: RequestOptionsParams
}) =>
  fetchBuildsAction.fulfilled(processBuildsData(data, requestOptions), '', {
    locator,
    requestOptions,
  })

const fetchBuildsAllData =
  (
    locator: string,
    requestOptions: RequestOptionsParams = {},
    inBackground: boolean = false,
  ): AppThunk<Promise<unknown>> =>
  (dispatch, getState) => {
    const {fetchCount} = requestOptions
    const state = getState()
    if (fetchCount != null) {
      const pager = getPager(state, PagerGroup.BUILD)
      dispatch(
        fetchBuildsCount({
          locator,
          pager,
          requestOptions,
          inBackground,
          count: fetchCount,
          locatorIncludesCount: true,
        }),
      )
    }
    if (getBuildsLoading(state, locator)) {
      return Promise.resolve()
    }
    dispatch(fetchBuildsDetails(locator, locator, requestOptions))
    return dispatch(fetchBuildsAction({locator, requestOptions, inBackground}))
  }

const fetchBuildsPageData =
  (params: FetchBuildsPageDataParams): AppThunk<Promise<unknown>> =>
  (dispatch, getState) => {
    const {locator, pager, requestOptions, inBackground} = params
    const {pageSize} = pager
    const locatorToStore = locator
    const state = getState()
    const lastPageToCount = getLastPageToCount(pager)
    const upperLimit = lastPageToCount * pageSize + 1
    dispatch(fetchBuildsCount({...params, count: upperLimit}))
    const locatorToQuery = getBuildsLocatorWithPage(state, locator)
    const requestInBackground = inBackground || false

    dispatch(fetchBuildsDetails(locatorToStore, locatorToQuery, requestOptions))

    if (getBuildsLoading(state, locatorToQuery)) {
      return Promise.resolve()
    }

    return dispatch(
      fetchBuildsAction({
        locator: locatorToQuery,
        withPager: true,
        requestOptions: {
          ...requestOptions,
          essential: requestOptions?.essential === true && !requestInBackground,
        },
        inBackground,
      }),
    )
  }

export const fetchBuildsData =
  ({
    locator,
    withPager = false,
    requestOptions,
    inBackground = false,
  }: FetchBuildDataParams): AppThunk<Promise<unknown>> =>
  (dispatch, getState) => {
    const state = getState()
    const pager = getPager(state, PagerGroup.BUILD)
    return withPager
      ? dispatch(
          fetchBuildsPageData({
            locator,
            pager,
            requestOptions,
            inBackground,
          }),
        )
      : dispatch(fetchBuildsAllData(locator, requestOptions, inBackground))
  }
type FetchHasBuildsArg = {
  locator: string
  restOptions?: RestRequestOptions
}
export const fetchHasBuildsAction = createFetchAction(
  'fetchHasBuilds',
  ({locator, restOptions}: FetchHasBuildsArg) => requestHasBuilds(restRoot, locator, restOptions),
)
export const fetchHasBuilds = (locator: string, restOptions?: RestRequestOptions) =>
  fetchHasBuildsAction({locator, restOptions})
