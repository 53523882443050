import * as ReactRedux from 'react-redux'

import type {State} from '../../../../reducers/types'
import {getBuild, getIsAutodetectTimeZone} from '../../../../selectors'
import type {BuildId} from '../../../../types'

import BuildDurationDetails from './BuildDurationDetails'

type Props = {
  buildId: BuildId
}

const mapStateToProps = (state: State, props: Props) => {
  const build = getBuild(state, props.buildId)
  const isAutodetectTimeZone = getIsAutodetectTimeZone(state)
  const {
    elapsedSeconds = 0,
    estimatedTotalSeconds = 0,
    leftSeconds,
    overtime,
  } = build?.['running-info'] || {}
  return {
    elapsed: elapsedSeconds,
    estimated: estimatedTotalSeconds,
    left: leftSeconds,
    overtime: overtime ?? (estimatedTotalSeconds > 0 ? elapsedSeconds - estimatedTotalSeconds : 0),
    started: build?.startDate,
    finished: build?.finishDate,
    isAutodetectTimeZone,
  }
}

export default ReactRedux.connect(mapStateToProps)(BuildDurationDetails)
