import {createReducer, PayloadAction} from '@reduxjs/toolkit'
import produce, {castDraft, original} from 'immer'
import {DefaultRootState} from 'react-redux'
import * as Redux from 'redux'

import {
  fetchArtifactExtension,
  fetchArtifactsListAction,
  fetchArtifactsSize,
  fetchBranches,
  fetchBuildStatsAction,
  fetchCurrentUserData,
  fetchHtmlAction,
  fetchIsBranchPresentAction,
  fetchLicensingData,
  fetchPoolPermissions,
  fetchTabsAction,
  resetState,
  setUserPropertyAction,
} from '../actions'
import {
  fetchBuildsAction,
  fetchBuildsDetailsAction,
  fetchHasBuildsAction,
  receiveBuildAction,
} from '../actions/builds'
import {fetchFederationServers} from '../actions/federation'
import {
  fetchBranchesWithBuilds,
  fetchBuildTypeTags,
  fetchDslOptionsAction,
  fetchSingleInvestigation,
  removeAgent,
} from '../actions/fetch'
import {fetchOverviewAction, toggleOverviewAction} from '../actions/overview'
import {fetchProjectsDataAction, fetchSingleProjectDataAction} from '../actions/projects'
import type {FetchAction} from '../actions/types'
import {
  fetchAgentPoolsData,
  fetchCloudImagesData,
  fetchSingleAgentData,
} from '../components/AgentsScreen/AgentsScreen.actions'
import {agentsScreenReducers} from '../components/AgentsScreen/AgentsScreen.reducers'
import {fetchExpandState} from '../components/App/ProjectsSidebar/ProjectsSidebar.actions'
import {sidebar} from '../components/App/ProjectsSidebar/ProjectsSidebar.slices'
import {queueSidebar} from '../components/App/QueueSidebar/QueueSidebar.reducers'
import {buildLogReducers} from '../components/BuildLog/BuildLog.reducers'
import buildProblems from '../components/BuildProblems/BuildProblems.reducers'
import cleanup from '../components/CleanupBuildType/Cleanup.reducers'
import cleanupPolicies from '../components/CleanupProject/CleanupPolicies.reducers'
import agentAuth from '../components/common/AgentAuth/AgentAuth.reducers'
import artifactChanges from '../components/common/ArtifactChanges/ArtifactChanges.reducers'
import buildApprovalsSlice from '../components/common/BuildApproval/BuildApproval.slices'
import queueInfo from '../components/common/BuildQueueInfo/BuildQueueInfo.reducers'
import buildStatusTooltip from '../components/common/BuildStatus/BuildStatusLink/BuildStatusTooltip/BuildStatusTooltip.reducers'
import {fetchBuildTriggerBuild} from '../components/common/BuildTriggeredBy/BuildTriggeredBy.actions'
import changeDetailsTabs from '../components/common/ChangeDetailsTabs/ChangeDetailsTabs.reducers'
import changeProjectsSelect from '../components/common/ChangeDetailsTabs/FilesTab/ChangeBuildTypeSelect/ChangeBuildTypeSelect.reducers'
import changeFiles from '../components/common/ChangeFiles/ChangeFiles.reducers'
import changes from '../components/common/Changes/Changes.reducers'
import changesDropdown from '../components/common/ChangesDropdown/ChangesDropdown.reducers'
import errorAlerts from '../components/common/ErrorAlerts/ErrorAlerts.reducers'
import pagerReducer from '../components/common/Pager/Pager.reducer'
import {
  getTriggerBuildKey,
  triggerBuildAction,
  TriggerBuildArg,
} from '../components/common/RunBuild/RunBuild.actions'
import {fetchFederationServerProjectsDataWithOptions} from '../components/common/SidebarFooter/SidebarFooter.actions'
import {starBuildAction} from '../components/common/StarBuild/StarBuild.actions'
import userSelect from '../components/common/UserSelect/UserSelect.reducers'
import {
  DslActionPayload,
  dslFragment,
  dslOptions,
  dslPortable,
  dslVersion,
  toggleDsl,
} from '../components/Dsl/Dsl.slices'
import {
  requestBuildTypesReorderAction,
  requestProjectsReorderAction,
} from '../components/EditProjectSidebar/EditProjectSidebar.actions'
import {hints} from '../components/Hints/Hints.slices'
import investigationHistory from '../components/InvestigationHistory/InvestigationHistory.reducers'
import changesRevisions from '../components/pages/BuildPage/BuildChangesTab/ChangesRevisions/ChangesRevisions.reducers'
import buildSnippets from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.reducer'
import deployments from '../components/pages/BuildPage/BuildOverviewTab/Deployments/Deployments.reducers'
import {fetchBuildTypesWithSnapshotDependencies} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.actions'
import {getDeliveredArtifactsKey} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {compareBuildsPageReducers} from '../components/pages/CompareBuildsPage/CompareBuildsPage.reducers'
import httpsConfigurationPageReducer from '../components/pages/HttpsConfigurationPage/HttpsConfigurationPage.reducers'
import {pipelines} from '../components/pages/PipelinesPages/PipelinesPages.reducers'
import projectInvestigationsReducers from '../components/pages/ProjectPage/ProjectInvestigationsTab/ProjectInvestigations.reducers'
import projectPage from '../components/pages/ProjectPage/ProjectPage.reducers'
import {fetchFlakyTestsAction, fetchTestOccurrences} from '../components/Tests/Tests.actions'
import tests from '../components/Tests/Tests.reducers'
import {TestFlakyType} from '../components/Tests/Tests.types'
import type {Entities} from '../rest/schemata'
import {restApi, Property} from '../services/rest'
import {
  agentsInCloud,
  blocks,
  builds as buildsSlice,
  buildTab,
  buildTypesLimit,
  buildTypeTab,
  cachedPlugins,
  dialog,
  dummyCalls,
  haveDependants,
  isExperimentalUI,
  permissions,
  queuedTogglerAutoExpand,
  routeAvailabilityResponse,
  serverInfo,
  showQueuedBuildsCount,
  showQueuedBuildsInBuildsList,
  showQueuedBuildsInProject,
  showQueuedBuildsPerBranch,
  sorting,
  stoppingBuilds,
  syncStorageValues,
  urlExtensions,
} from '../slices'
import {buildSelections} from '../slices/buildSelections'
import buildsFilters from '../slices/buildsFilters'
import type {
  AgentId,
  ArchivedOptionType,
  ArtifactExtensions,
  BuildId,
  BuildTriggeredBuildType,
  BuildTypeId,
  FederationServerData,
  FederationServerId,
  Fetchable,
  ProjectId,
  TestId,
} from '../types'
import {
  AgentDetails,
  ArtifactExtension,
  BuildArtifactsSizeType,
  CurrentUserType,
  LicensingDataType,
} from '../types'
import {emptyArray, getEmptyHash} from '../utils/empty'
import type {KeyValue} from '../utils/object'

import entities from './entities'
import federationServersDataReducer from './federationServersData'
import {keyValueFetchable, fetchable} from './fetchable'
import {clientId, getArtifactsKey, OverviewDraft, TogglingOverview} from './types'
import {keyValueReducer} from './utils'

const artifactsExtensionsReducer = fetchable(
  fetchArtifactExtension,
  null as ArtifactExtension | null,
  (_, action) => action.payload,
)
const currentUserReducer = fetchable(
  fetchCurrentUserData,
  null as CurrentUserType | null,
  (_, action) => action.payload,
)

const licensingDataReducer = fetchable(
  fetchLicensingData,
  null as LicensingDataType | null,
  (_, action) => action.payload,
)

const mainReducer = Redux.combineReducers({
  clientId: () => clientId,

  entities,

  buildTriggerBuilds: createReducer<KeyValue<BuildId, BuildTriggeredBuildType>>({}, builder =>
    builder.addCase(fetchBuildTriggerBuild.fulfilled, (state, action) => {
      state[action.meta.arg] = castDraft(action.payload)
    }),
  ),

  buildsDetails: keyValueFetchable(
    ({locatorToStore}) => locatorToStore,
    fetchBuildsDetailsAction,
    null as unknown,
    (_, action) => action.payload,
  ),

  federationServersData: createReducer<KeyValue<FederationServerId, FederationServerData>>(
    {},
    builder =>
      builder.addDefaultCase((state, action) => {
        if (
          fetchFederationServerProjectsDataWithOptions.pending.match(action) ||
          fetchFederationServerProjectsDataWithOptions.fulfilled.match(action) ||
          fetchFederationServerProjectsDataWithOptions.rejected.match(action)
        ) {
          const federationServerId = action.meta.arg.requestOptions.federationServerId
          if (federationServerId != null) {
            state[federationServerId] = castDraft(
              federationServersDataReducer(original(state)![federationServerId], action),
            )
          }
        }
      }),
  ),

  federationServersEntities: createReducer<KeyValue<FederationServerId, Partial<Entities>>>(
    {},
    builder =>
      builder.addCase(fetchFederationServerProjectsDataWithOptions.fulfilled, (state, action) => {
        const federationServerId = action.meta.arg.requestOptions.federationServerId
        if (federationServerId != null) {
          state[federationServerId] = castDraft(action.payload.entities)
        }
      }),
  ),

  isExperimentalUI: isExperimentalUI.reducer,

  urlExtensions: urlExtensions.reducer,

  routeAvailabilityResponse: routeAvailabilityResponse.reducer,
  pager: pagerReducer,
  blocks: blocks.reducer,

  dialog: dialog.reducer,

  builds: buildsSlice.reducer,
  artifacts: keyValueFetchable(
    arg => getArtifactsKey(arg.id, arg.path, arg.withHidden),
    fetchArtifactsListAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  artifactExtensions: createReducer<KeyValue<BuildId, ArtifactExtensions>>({}, builder =>
    builder.addDefaultCase((state, action) => {
      if (
        fetchArtifactExtension.pending.match(action) ||
        fetchArtifactExtension.fulfilled.match(action) ||
        fetchArtifactExtension.rejected.match(action)
      ) {
        const {
          id,
          extension: {name},
        } = action.meta.arg

        if (!id || name == null) {
          return
        }

        let prevState = state[id]
        if (prevState == null) {
          prevState = state[id] = {}
        }
        prevState[name] = artifactsExtensionsReducer(original(prevState)![name], action)
      }
    }),
  ),

  agent: createReducer<KeyValue<AgentId, Fetchable<AgentId | null>>>({}, builder => {
    builder.addCase(removeAgent.fulfilled, (state, action) => {
      state[action.meta.arg] = {
        data: null,
        loading: false,
        backgroundLoading: false,
        inited: false,
        ready: false,
      }
    })
    builder.addDefaultCase(
      keyValueFetchable(
        arg => String(arg.agentId),
        fetchSingleAgentData,
        null as AgentId | null,
        (_, action) => action.payload.agent.result,
      ),
    )
  }),
  agentPools: fetchable(fetchAgentPoolsData, emptyArray, (_, action) => action.payload.result),
  cloudImages: fetchable(fetchCloudImagesData, emptyArray, (state, action) => [
    ...new Set([...state, ...action.payload.result]),
  ]),
  branches: keyValueFetchable(
    arg => arg,
    fetchBranches,
    emptyArray,
    (_, action) => action.payload,
  ),
  isBranchPresent: keyValueFetchable(
    arg => arg.endpoint,
    fetchIsBranchPresentAction,
    null as boolean | null,
    (_, action) => action.payload,
  ),
  flakyTests: keyValueFetchable(
    arg => String(arg.buildId),
    fetchFlakyTestsAction,
    getEmptyHash<TestId, TestFlakyType>(),
    (state, action) =>
      produce(state, draft =>
        action.payload.forEach(flakyTest => {
          draft[flakyTest.id] = castDraft(flakyTest)
        }),
      ),
  ),
  projects: keyValueFetchable(
    arg => arg.rootProjectId,
    fetchProjectsDataAction,
    emptyArray,
    (state, action) => {
      const archived = action.meta.arg.options?.archived
      return archived == null || archived === 'false' ? action.payload.result : state
    },
    arg => arg.meta,
  ),
  projectsWithArchived: keyValueFetchable(
    arg => arg.rootProjectId,
    fetchProjectsDataAction,
    emptyArray,
    (state, action) => {
      const archived: ArchivedOptionType | undefined = action.meta.arg.options?.archived
      return archived === 'any' ? action.payload.result : state
    },
    arg => arg.meta,
  ),
  buildTypes: keyValueFetchable(
    arg => arg,
    fetchBuildTypesWithSnapshotDependencies,
    emptyArray,
    (_, action) => action.payload.result,
  ),

  hasFavoriteProjects: createReducer<boolean | null>(null, builder =>
    builder.addCase(
      fetchOverviewAction.fulfilled,
      (state, action) => action.payload.result.length > 0,
    ),
  ),

  overview: fetchable(fetchOverviewAction, emptyArray, (_, action) => action.payload.result),
  overviewDraft: Redux.combineReducers<OverviewDraft>({
    projects: createReducer<ReadonlyArray<ProjectId> | null>(null, builder => {
      builder.addCase(requestProjectsReorderAction.pending, (_, action) =>
        action.meta.arg.map(item => item.id),
      )
      builder.addCase(requestProjectsReorderAction.fulfilled, () => null)
      builder.addCase(fetchOverviewAction.fulfilled, () => null)
    }),

    buildTypes: createReducer<KeyValue<ProjectId, Array<BuildTypeId> | null | undefined>>(
      {},
      builder => {
        builder.addCase(requestBuildTypesReorderAction.pending, (state, action) => {
          const {projectId, order} = action.meta.arg
          state[projectId] = order?.map(item => item.id)
        })
        builder.addCase(requestBuildTypesReorderAction.fulfilled, (state, action) => {
          state[action.meta.arg.projectId] = null
        })
        builder.addCase(fetchOverviewAction.fulfilled, () => ({}))
      },
    ),
  }),

  project: keyValueFetchable(
    arg => arg.projectId,
    fetchSingleProjectDataAction,
    null as string | null,
    (_, action) => action.payload.result,
  ),

  permissions: permissions.reducer,

  poolPermissions: createReducer(
    {
      canChangeStatus: {},
      canAuthorize: {},
    },
    builder =>
      builder.addCase(fetchPoolPermissions.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      }),
  ),

  html: createReducer<KeyValue<string, string | null>>({}, builder =>
    builder.addCase(fetchHtmlAction.fulfilled, (state, action) => {
      state[action.meta.arg.path] = action.payload
    }),
  ),

  stoppingBuilds: stoppingBuilds.reducer,

  startingBuilds: keyValueReducer(
    (action: FetchAction<TriggerBuildArg>) => getTriggerBuildKey(action.meta.arg),
    createReducer(false, builder => {
      builder.addCase(triggerBuildAction.pending, () => true)
      builder.addCase(triggerBuildAction.fulfilled, () => false)
      builder.addCase(triggerBuildAction.rejected, () => false)
    }),
    [triggerBuildAction.pending, triggerBuildAction.fulfilled, triggerBuildAction.rejected],
  ),

  starringBuilds: createReducer<KeyValue<BuildId, boolean | null | undefined>>({}, builder => {
    builder.addCase(starBuildAction.pending, (state, action) => {
      const {buildId, starred} = action.meta.arg
      state[buildId] = starred
    })
    builder.addCase(starBuildAction.rejected, (state, action) => {
      state[action.meta.arg.buildId] = null
    })
    builder.addMatcher(restApi.endpoints.getBuildNormalized.matchFulfilled, (state, action) => {
      const buildId = action.payload.result
      delete state[buildId]
    })
    builder.addDefaultCase((state, action) => {
      if (
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action)
      ) {
        action.payload.result.forEach(id => {
          delete state[id]
        })
      }
    })
  }),

  togglingOverview: createReducer<TogglingOverview>(
    {
      bt: {},
      project: {},
    },
    builder => {
      builder.addCase(toggleOverviewAction.pending, (state, action) => {
        const {node, on} = action.meta.arg
        if (node.nodeType === 'bt' || node.nodeType === 'project') {
          state[node.nodeType][node.id] = on
        }
      })
      builder.addDefaultCase((state, action) => {
        if (
          toggleOverviewAction.fulfilled.match(action) ||
          toggleOverviewAction.rejected.match(action)
        ) {
          const {node} = action.meta.arg
          if (node.nodeType === 'bt' || node.nodeType === 'project') {
            state[node.nodeType][node.id] = null
          }
        }
      })
    },
  ),

  buildsFilters: buildsFilters.reducer,
  currentUser: currentUserReducer,

  userProperties: createReducer<KeyValue<string, string>>({}, builder => {
    builder.addCase(fetchCurrentUserData.fulfilled, (state, action) => {
      const properties = action.payload?.properties?.property

      if (properties == null) {
        return state
      }

      return Object.fromEntries(properties.map(({name, value}: Property) => [name, value]))
    })
    builder.addCase(setUserPropertyAction.pending, (state, action) => {
      const {name, value} = action.meta.arg
      state[name] = value
    })
  }),

  sorting: sorting.reducer,

  agentsInCloud: agentsInCloud.reducer,

  buildTypeTags: keyValueFetchable(
    arg => arg,
    fetchBuildTypeTags,
    emptyArray,
    (_, action) => action.payload,
  ),

  buildsStats: keyValueFetchable(
    arg => arg.locator,
    fetchBuildStatsAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  haveDependants: haveDependants.reducer,

  buildTypeTab: buildTypeTab.reducer,

  buildTab: buildTab.reducer,

  projectPage,

  sidebar: sidebar.reducer,

  dslOptions: createReducer({}, builder =>
    builder.addDefaultCase(
      keyValueReducer(
        (action: PayloadAction<DslActionPayload>) => action.payload.controlId,
        dslOptions.reducer,
        [
          toggleDsl.actions.show,
          toggleDsl.actions.hide,
          dslVersion.actions.set,
          dslPortable.actions.set,
          dslOptions.actions.init,
        ],
      ),
    ),
  ),

  dslFragment: dslFragment.reducer,

  availableDslOptions: fetchable(fetchDslOptionsAction, emptyArray, (_, action) => action.payload),
  showQueuedBuildsPerBranch: showQueuedBuildsPerBranch.reducer,
  showQueuedBuildsCount: showQueuedBuildsCount.reducer,
  showQueuedBuildsInProject: showQueuedBuildsInProject.reducer,

  showQueuedBuildsInBuildsList: showQueuedBuildsInBuildsList.reducer,

  branchesWithBuilds: keyValueFetchable(
    arg => arg.buildTypeId,
    fetchBranchesWithBuilds,
    emptyArray,
    // deduplicate by converting to Set and back
    (_, action) => Array.from(new Set(action.payload.result)),
  ),

  federationServers: fetchable(
    fetchFederationServers,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  hasBuilds: keyValueFetchable(
    arg => arg.locator,
    fetchHasBuildsAction,
    false,
    (_, action) => action.payload,
  ),
  tabs: keyValueFetchable(
    arg => arg,
    fetchTabsAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  serverInfo: serverInfo.reducer,

  dummyCalls: dummyCalls.reducer,

  overviewExpandState: fetchable(
    fetchExpandState,
    [] as readonly string[],
    (_, action) => action.payload.result,
  ),

  testOccurrencesByLocator: keyValueFetchable(
    arg => arg.locator,
    fetchTestOccurrences,
    emptyArray,
    (_, action) => action.payload.data.result,
  ),
  cachedPlugins: cachedPlugins.reducer,
  syncStorageValues: syncStorageValues.reducer,
  queuedToggler: Redux.combineReducers({
    autoExpand: queuedTogglerAutoExpand.reducer,
    hasTriggeredByMeBuilds: createReducer<KeyValue<BuildTypeId, boolean | null>>({}, builder =>
      builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
        state[action.meta.arg.buildTypeId] = true
      }),
    ),
  }),
  compareBuilds: compareBuildsPageReducers,
  cleanup,
  cleanupPolicies,
  buildLog: buildLogReducers,
  projectInvestigations: projectInvestigationsReducers,
  buildSnippets,
  agentsPage: agentsScreenReducers,
  queueInfo,
  deployments,
  changeDetailsTabs,
  changesRevisions,
  changesDropdown,
  artifactChanges,
  changeFiles,
  buildApprovals: buildApprovalsSlice.reducer,
  changes,
  buildStatusTooltip,
  changeProjectsSelect,
  agentAuth,
  userSelect,
  errorAlerts,
  investigationHistory,
  tests,
  buildProblems,
  queueSidebar,
  hints: hints.reducer,
  https: httpsConfigurationPageReducer,
  licensingData: licensingDataReducer,

  buildTypesLimit: buildTypesLimit.reducer,

  artifactSizes: createReducer<KeyValue<BuildId, BuildArtifactsSizeType>>({}, builder =>
    builder.addCase(fetchArtifactsSize.fulfilled, (state, action) => {
      state[action.meta.arg] = action.payload
    }),
  ),
  agentDetails: createReducer({}, builder =>
    builder.addCase(
      fetchSingleAgentData.fulfilled,
      (state: Record<AgentId, AgentDetails>, action) => {
        const {agentId} = action.meta.arg
        state[agentId] = action.payload.details
      },
    ),
  ),
  buildTypeInvestigations: keyValueFetchable(
    arg => arg,
    fetchSingleInvestigation,
    false,
    () => true,
  ),
  deliveredArtifacts: createReducer<KeyValue<string, readonly string[]>>({}, builder =>
    builder.addCase(fetchBuildsAction.fulfilled, (state, action) => {
      const from = action.meta.arg.requestOptions.withDownloadedArtifactsFrom
      if (from != null) {
        for (const to of action.payload.result) {
          const download =
            action.payload.entities.builds?.[to]?.downloadedArtifacts?.downloadInfo[0]
          if (download != null) {
            state[getDeliveredArtifactsKey(from, to)] = download.artifactInfo?.map(
              item => item.path ?? '',
            )
          }
        }
      }
    }),
  ),
  buildSelections: buildSelections.reducer,
  pipelines,
  [restApi.reducerPath]: restApi.reducer,
})
export default (state: DefaultRootState | undefined, action: PayloadAction<unknown>) =>
  resetState.match(action) ? action.payload : mainReducer(state, action)
