import {combineReducers} from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {emptyArray} from '../../../utils/empty'

import {fetchChangesBranches, fetchChangeStatus, fetchChangeStatusBuilds} from './Changes.actions'
import {ChangesStateType} from './Changes.types'

const changes = combineReducers<ChangesStateType>({
  statuses: keyValueFetchable(
    locator => locator,
    fetchChangeStatus,
    emptyArray,
    (state, action) => action.payload.result,
  ),
  statusBuilds: keyValueFetchable(
    locator => locator,
    fetchChangeStatusBuilds,
    emptyArray,
    (state, action) => action.payload.result,
  ),
  branches: keyValueFetchable(
    locator => locator,
    fetchChangesBranches,
    emptyArray,
    (state, action) => action.payload.result,
  ),
})
export default changes
