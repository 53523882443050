import {PlaceId} from '@jetbrains/teamcity-api'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {original} from 'immer'

import {fetchBuildsAction, receiveBuildAction, receiveBuildWSDataAction} from '../actions/builds'
import {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import {receiveAgents} from '../components/common/Agents/Agents.actions'
import {saveComment} from '../components/common/BuildActionsDropdown/CommentDialog/CommentDialog.actions'
import {pinBuildFromDialog} from '../components/common/BuildActionsDropdown/PinDialog/PinDialog.actions'
import {
  addBuildsTags,
  changeBuildTagsAction,
} from '../components/common/BuildActionsDropdown/TagDialog/TagDialog.actions'
import {
  getTriggerBuildKey,
  triggerBuildAction,
} from '../components/common/RunBuild/RunBuild.actions'
import {keyValueFetchable} from '../reducers/fetchable'
import {CollapsibleBlockItems} from '../reducers/types'
import {getStatusKey, StatusKey} from '../rest/schemata'
import {restApi} from '../services/rest'
import {
  AgentId,
  ALL_PROJECTS,
  BranchType,
  BuildId,
  BuildTypeId,
  Dialog,
  DialogType,
  Fetchable,
  getBuildTypeStatusRequest,
  Id,
  Permission,
  ProjectId,
  RouteAvailabilityResponse,
  ServerInfo,
  TabId,
  UrlExtension,
} from '../types'
import {internalProps} from '../types/BS_types'
import {emptyArray} from '../utils/empty'
import {KeyValue, objectEntries} from '../utils/object'

export const isExperimentalUI = createSlice({
  name: 'isExperimentalUI',
  initialState: false,
  reducers: {
    set: () => true,
  },
})

export const urlExtensions = createSlice({
  name: 'urlExtensions',
  initialState: [] as ReadonlyArray<UrlExtension<any>>,
  reducers: {
    set: (state, action: PayloadAction<readonly UrlExtension<{}>[]>) =>
      state.concat(action.payload),
  },
})

type RouteAvailabilityResponseMeta = {
  readonly path: string
}
export const routeAvailabilityResponse = createSlice({
  name: 'routeAvailabilityResponse',
  initialState: {} as KeyValue<string, RouteAvailabilityResponse>,
  reducers: {
    set: {
      reducer(
        state,
        action: PayloadAction<RouteAvailabilityResponse, string, RouteAvailabilityResponseMeta>,
      ) {
        state[action.meta.path] = action.payload
      },
      prepare: (payload: RouteAvailabilityResponse, path: string) => ({
        payload,
        meta: {path},
      }),
    },
  },
})

export const showQueuedBuildsInBuildsList = createSlice({
  name: 'showQueuedBuildsInBuildsList',
  initialState: false,
  reducers: {
    toggle: state => !state,
  },
})

export const blocks = createSlice({
  name: 'blocks',
  initialState: {} as CollapsibleBlockItems,
  reducers: {
    add: {
      reducer(
        state,
        action: PayloadAction<ReadonlyArray<string | Id>, string, {block: CollapsibleBlock}>,
      ) {
        let prevState = state[action.meta.block]
        if (prevState == null) {
          prevState = state[action.meta.block] = {}
        }
        for (const id of action.payload) {
          prevState[id] = true
        }
      },
      prepare: (block: CollapsibleBlock, ids: ReadonlyArray<string | Id>) =>
        ({
          payload: ids,
          meta: {block},
        } as const),
    },
    remove: {
      reducer(
        state,
        action: PayloadAction<ReadonlyArray<string | Id>, string, {block: CollapsibleBlock}>,
      ) {
        let prevState = state[action.meta.block]
        if (prevState == null) {
          prevState = state[action.meta.block] = {}
        }
        for (const id of action.payload) {
          prevState[id] = false
        }
      },
      prepare: (block: CollapsibleBlock, ids: ReadonlyArray<string | Id>) =>
        ({
          payload: ids,
          meta: {block},
        } as const),
    },
  },
})

type OpenDialogPayload = {
  dialogId: string
  dialogType: DialogType
}

export const dialog = createSlice({
  name: 'dialog',
  initialState: Object.freeze({}) as Dialog,
  reducers: {
    open: {
      reducer(state, action: PayloadAction<OpenDialogPayload>) {
        state.opened = true
        state.error = false
        state.id = action.payload.dialogId
        state.type = action.payload.dialogType
      },
      prepare: (dialogId: string, dialogType: DialogType) => ({payload: {dialogId, dialogType}}),
    },
    close(state) {
      if (!state.processing) {
        state.opened = false
      }
    },
  },
  extraReducers: builder =>
    builder.addDefaultCase((state, action) => {
      if (
        changeBuildTagsAction.pending.match(action) ||
        saveComment.pending.match(action) ||
        pinBuildFromDialog.pending.match(action) ||
        addBuildsTags.pending.match(action)
      ) {
        state.processing = true
      } else if (
        changeBuildTagsAction.fulfilled.match(action) ||
        saveComment.fulfilled.match(action) ||
        pinBuildFromDialog.fulfilled.match(action) ||
        addBuildsTags.fulfilled.match(action)
      ) {
        state.processing = false
        state.opened = false
        state.error = false
      } else if (
        changeBuildTagsAction.rejected.match(action) ||
        saveComment.rejected.match(action) ||
        pinBuildFromDialog.rejected.match(action) ||
        addBuildsTags.rejected.match(action)
      ) {
        state.processing = false
        state.opened = true
        state.error = true
      }
    }),
})

const buildsReducer = keyValueFetchable(
  arg => arg.locator,
  fetchBuildsAction,
  emptyArray,
  (_, action) => action.payload.result,
)

export const builds = createSlice({
  name: 'builds',
  initialState: {} as KeyValue<string, Fetchable<ReadonlyArray<BuildId>>>,
  reducers: {
    reset(state, action: PayloadAction<string>) {
      const prev = state[action.payload]
      if (prev != null) {
        prev.loading = false
        prev.ready = false
      }
    },
  },
  extraReducers: builder => builder.addDefaultCase(buildsReducer),
})

type ReceivePermissionPayload = {
  data: KeyValue<ProjectId, boolean>
  permission: Permission
  projectId?: ProjectId
}

export const permissions = createSlice({
  name: 'permissions',
  initialState: {} as KeyValue<Permission, KeyValue<ProjectId, boolean>>,
  reducers: {
    receive(state, action: PayloadAction<ReceivePermissionPayload>) {
      const {projectId, permission, data} = action.payload
      if (projectId === ALL_PROJECTS) {
        state[permission] = data
      } else {
        state[permission] ??= {}
        Object.assign(state[permission]!, data)
      }
    },
  },
})

export const stoppingBuilds = createSlice({
  name: 'stoppingBuilds',
  initialState: {} as KeyValue<BuildId, boolean>,
  reducers: {
    add(state, action: PayloadAction<BuildId>) {
      state[action.payload] = true
    },
  },
  extraReducers: builder =>
    builder.addDefaultCase((state, action) => {
      const receivedBuilds =
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled(action) ||
        receiveAgents.match(action) ||
        restApi.endpoints.getBuildNormalized.matchFulfilled(action) ||
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled(action) ||
        receiveBuildAction.match(action) ||
        fetchBuildsAction.fulfilled.match(action)
          ? action.payload.entities.builds
          : receiveBuildWSDataAction.match(action)
          ? action.payload
          : undefined
      if (receivedBuilds != null) {
        objectEntries(receivedBuilds).forEach(([id, build]) => {
          if (build) {
            state[id] = false
          }
        })
      }
    }),
})

export const sorting = createSlice({
  name: 'sorting',
  initialState: {dimension: '', descending: false},
  reducers: {
    changeDimension(state, action: PayloadAction<string>) {
      state.dimension = action.payload
    },
    changeDirection(state, action: PayloadAction<boolean>) {
      state.descending = action.payload
    },
  },
})

export const agentsInCloud = createSlice({
  name: 'agentsInCloud',
  initialState: {},
  reducers: {
    receive: (_, action: PayloadAction<readonly AgentId[]>) =>
      Object.fromEntries(action.payload.map(id => [id, true])),
  },
})

export const haveDependants = createSlice({
  name: 'haveDependants',
  initialState: {} as KeyValue<BuildId, boolean>,
  reducers: {
    receive(state, action: PayloadAction<readonly [BuildId, boolean][]>) {
      action.payload.forEach(([buildId, hasDependants]) => {
        state[buildId] = hasDependants
      })
    },
  },
})

export const buildTypeTab = createSlice({
  name: 'buildTypeTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})

export const buildTab = createSlice({
  name: 'buildTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})

type ShowQueuedBuildsPerBranchActionPayload = {
  buildTypeId: BuildTypeId
  branch: BranchType
}
export const showQueuedBuildsPerBranch = createSlice({
  name: 'showQueuedBuildsPerBranch',
  initialState: {} as KeyValue<StatusKey, boolean>,
  reducers: {
    hide(state, action: PayloadAction<ShowQueuedBuildsPerBranchActionPayload>) {
      const {buildTypeId, branch} = action.payload
      state[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))] = false
    },
    show(state, action: PayloadAction<ShowQueuedBuildsPerBranchActionPayload>) {
      const {buildTypeId, branch} = action.payload
      state[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))] = true
    },
  },
})

type SetShowQueuedBuildsCountPayload = {
  buildTypeId: BuildTypeId
  branch: BranchType | null | undefined
  count: number
}
export const showQueuedBuildsCount = createSlice({
  name: 'showQueuedBuildsCount',
  initialState: {} as KeyValue<StatusKey, number>,
  reducers: {
    set(state, action: PayloadAction<SetShowQueuedBuildsCountPayload>) {
      const {buildTypeId, branch, count} = action.payload
      state[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))] = count
    },
  },
})

export const showQueuedBuildsInProject = createSlice({
  name: 'showQueuedBuildsInProject',
  initialState: {} as KeyValue<BuildTypeId, boolean>,
  reducers: {
    hide(state, action: PayloadAction<BuildTypeId>) {
      state[action.payload] = false
    },
    show(state, action: PayloadAction<BuildTypeId>) {
      state[action.payload] = true
    },
  },
})

export const serverInfo = createSlice({
  name: 'serverInfo',
  initialState: null as ServerInfo | null,
  reducers: {
    receive: (_, action: PayloadAction<ServerInfo>) => action.payload,
  },
})

export const dummyCalls = createSlice({
  name: 'dummyCalls',
  initialState: 0,
  reducers: {
    increment: state => state + 1,
  },
})

type CachedPluginsActionPayload = {
  readonly placeId: PlaceId
  readonly name: string
}
export const cachedPlugins = createSlice({
  name: 'cachedPlugins',
  initialState: {} as KeyValue<PlaceId, readonly string[]>,
  reducers: {
    remove(state, action: PayloadAction<CachedPluginsActionPayload>) {
      const {name, placeId} = action.payload
      const currentState = original(state)![placeId]
      if (currentState != null) {
        state[placeId] = currentState.filter(pluginName => pluginName !== name)
      }
    },
    add(state, action: PayloadAction<CachedPluginsActionPayload>) {
      const {name, placeId} = action.payload
      const currentState = original(state)![placeId]
      if (currentState == null) {
        state[placeId] = [name]
      } else if (currentState.includes(name)) {
        // eslint-disable-next-line no-console
        console.warn(`Plugin ${name} has already been registered in Place ${placeId}`)
      } else {
        state[placeId]!.push(name)
      }
    },
  },
})

type SetSyncStorageValuePayload = {
  readonly key: string
  readonly value: string | null
}
export const syncStorageValues = createSlice({
  name: 'syncStorageValues',
  initialState: {} as KeyValue<string, string | null>,
  reducers: {
    set(state, action: PayloadAction<SetSyncStorageValuePayload>) {
      const {key, value} = action.payload
      state[key] = value
    },
  },
})

export const queuedTogglerAutoExpand = createSlice({
  name: 'queuedTogglerAutoExpand',
  initialState: {} as KeyValue<StatusKey, boolean | null>,
  reducers: {
    collapse(state, action: PayloadAction<StatusKey>) {
      state[action.payload] = false
    },
  },
  extraReducers: builder =>
    builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
      if (action.payload.autoExpandQueued) {
        state[getTriggerBuildKey(action.meta.arg)] = true
      }
    }),
})

export const buildTypesLimit = createSlice({
  name: 'buildTypesLimit',
  initialState: internalProps['teamcity.overviewPage.buildTypes.limit'] as number,
  reducers: {
    set: (_, action: PayloadAction<number>) => action.payload,
  },
})
