import * as Redux from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {emptyArray} from '../../../utils/empty'

import {fetchWaitReasons} from './BuildQueueInfo.actions'
import {BuildQueueInfoStateType} from './BuildQueueInfo.types'

const waitReasonsReducer = keyValueFetchable(
  String,
  fetchWaitReasons,
  emptyArray,
  (_, action) => action.payload,
)
const queueInfoReducer = Redux.combineReducers<BuildQueueInfoStateType>({
  waitReasons: waitReasonsReducer,
})
export default queueInfoReducer
