import type {State} from '../../reducers/types'
import {getBooleanUserProperty} from '../../selectors'
import {emptyArray} from '../../utils/empty'
import {UserProperties} from '../../utils/userProperties'

import {HintCategory} from './Hints.types'
import type {HintId, HintType} from './Hints.types'

export const getAvailableHints = (state: State): ReadonlyArray<HintType> =>
  state.hints.available ?? emptyArray
export const getSelectedHints = (state: State): ReadonlyArray<HintId> =>
  state.hints.selected ?? emptyArray
export const getSelectedCategory = (state: State): HintCategory | null =>
  state.hints.selectedCategory
export const getHasSeenTipsReminder = (state: State): boolean =>
  getBooleanUserProperty(state, UserProperties.HAS_SEEN_TIPS_REMINDER)
const searchParams = new URLSearchParams(location.search)
export const embedded = searchParams.get('embedded') === 'true'
export const getTipsModeEnabled = (state: State): boolean =>
  !embedded && getBooleanUserProperty(state, UserProperties.TIPS_MODE, false)
