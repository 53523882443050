import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {Build} from '../../../services/rest'
import {stringifyId} from '../../../types'
import type {BuildId} from '../../../types'

import {emptyArray} from '../../../utils/empty'

import type {WaitReasons} from './BuildQueueInfo.types'

const MILLISECONDS_IN_SECOND = 1000

export async function requestWaitReasons(
  serverUrl: string,
  buildId: BuildId,
): Promise<WaitReasons> {
  const response = await request(
    serverUrl,
    `builds/id:${stringifyId(buildId)}?fields=queuedWaitReasons(property(name,value))`,
  )
  const {queuedWaitReasons} = await processResponse<Build>(response)
  return (
    queuedWaitReasons?.property?.map(({name = '', value}) => ({
      reason: name,
      durationInSeconds: Number(value) / MILLISECONDS_IN_SECOND,
    })) ?? emptyArray
  )
}
