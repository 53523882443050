import {getBuildFields} from '../../../rest/builds'
import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {BuildType} from '../../../types'

const artifactDependencyChangesFields = `artifactDependencyChanges(buildChange($locator(count:100),nextBuild(${getBuildFields()})))`

type BuildArtifactDependenciesChangesItem = {
  nextBuild: BuildType
}
type BuildArtifactDependenciesChangesData = {
  artifactDependencyChanges: {
    buildChange?: BuildArtifactDependenciesChangesItem[]
  }
}
export const requestBuildArtifactDependenciesChanges = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<BuildType>> =>
  request(
    serverUrl,
    `builds/${encodeURIComponent(locator)}?fields=${artifactDependencyChangesFields}`,
  )
    .then<BuildArtifactDependenciesChangesData>(processResponse)
    .then(data => data?.artifactDependencyChanges?.buildChange)
    .then(data => (data != null ? data.map(item => item?.nextBuild) : []))
