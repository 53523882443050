import {GetAllChangesApiArg, GetBuildApiArg} from '../services/rest'
import type {BuildId, ChangesRequestOptions} from '../types'

export const getChangesFields = (options?: ChangesRequestOptions): string =>
  'change(' +
  'id,' +
  'type,' +
  'version,' +
  'parentRevisions,' +
  'personal,' +
  'username,' +
  'name,' +
  'date,' +
  'commiter(users(user(id,name,username,avatars))),' +
  `vcsRootInstance(id,vcs-root(id,name,vcsName,project(id))),` +
  `${
    options?.withFiles === true ? 'files(count,file(file,directory,changeType)),' : 'files(count),'
  }` +
  'comment,' +
  'webUrl,' +
  'storesProjectSettings,' +
  'snapshotDependencyLink(build(id,buildType(id,projectId,name),number),buildType(id,projectId,name),buildTypeBranch)' +
  ')'

const CHANGES_COUNT = 100
export const getBuildChangesArg = ({
  buildId,
  history,
  count = CHANGES_COUNT,
}: {
  buildId: BuildId
  history?: boolean
  count?: number | undefined
}): GetBuildApiArg => ({
  buildLocator: `id:${buildId}`,
  fields: `id${
    history ? ',firstBuildWithSameChanges(id)' : ''
  },changes($locator(count:${count}),${getChangesFields()}),artifactDependencyChanges(count)`,
})

export const getChangesArg = (
  locator: string,
  options?: ChangesRequestOptions,
): GetAllChangesApiArg => ({
  locator,
  fields: `nextHref,${getChangesFields(options)}`,
  withUpdatePager: options?.withUpdatePager,
})
