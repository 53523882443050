import {mapProps} from 'recompose'

import buttonStyles from '@jetbrains/ring-ui/components/button/button.css'
import {ClickableLinkProps} from '@jetbrains/ring-ui/components/link/clickableLink'
import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import {linkHOC} from '@jetbrains/ring-ui/components/link/link'
import type {
  RouterLinkProps as RouterLinkAPIProps,
  RouterButtonProps as RouterButtonAPIProps,
} from '@jetbrains/teamcity-api'
import classNames from 'classnames'
import type {ComponentType, ComponentProps} from 'react'
import {Link, useLocation} from 'react-router-dom'

import {getHrefWithQueryParams} from '../../../routes'
import type {QueryParams} from '../../../utils/queryParams'

import styles from './RouterLink.css'

type RouterLinkInnerProps = ClickableLinkProps &
  Omit<ComponentProps<typeof Link>, 'to'> & {
    readonly to?: string
    readonly params?: QueryParams | ((prevParams: QueryParams) => QueryParams)
    readonly hash?: string
    readonly activeClassName?: string | null
    readonly withMergeParams?: boolean
  }
export type RouterLinkProps = LinkProps<RouterLinkInnerProps>

const RouterLink = ({
  to,
  params,
  hash,
  activeClassName,
  className,
  withMergeParams,
  onPlainLeftClick,
  ...restProps
}: RouterLinkInnerProps) => {
  const location = useLocation()
  const paramsToPass =
    withMergeParams === true ? (prevParams: QueryParams) => ({...prevParams, ...params}) : params
  return (
    <Link
      className={classNames(className, styles.link)}
      to={
        paramsToPass || hash != null
          ? getHrefWithQueryParams(location, to, paramsToPass, hash)
          : to ?? location
      }
      {...restProps}
    />
  )
}
const RouterLinkContainer: ComponentType<RouterLinkProps> = linkHOC(RouterLink)
export default RouterLinkContainer
export const RouterLinkAPI = linkHOC(Link) as ComponentType<RouterLinkAPIProps>
type RouterButtonProps = RouterLinkInnerProps & {
  readonly isActive?: boolean
}
const buttonClassName = classNames(styles.button, buttonStyles.button, buttonStyles.heightS)
export const RouterButton: ComponentType<RouterButtonProps> = mapProps(
  ({isActive, ...restProps}: RouterButtonProps) => ({
    ...restProps,
    className: classNames(buttonClassName, {
      [buttonStyles.active]: isActive,
    }),
  }),
)(RouterLink)
export const RouterButtonAPI: ComponentType<RouterButtonAPIProps> = ({
  className,
  children,
  ...restProps
}) => (
  <Link className={classNames(className, buttonClassName)} {...restProps}>
    {children}
  </Link>
)
