export enum HTTPCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
}

export interface HTTPError {
  statusCode: number | undefined
  url: string | null | undefined
  response: Response | undefined
}

export function instanceOf<T>(object: any, member: string): object is T {
  return member in object
}

export type ProjectId = string
export const ROOT_PROJECT_ID: ProjectId = '_Root'

export enum ParameterNames {
  PipelineProject = 'teamcity.pipelineProject',
  PipelineHead = 'teamcity.pipelineHead',
}

export enum StepTypes {
  CLI = 'simpleRunner',
  Maven = 'Maven2',
}
