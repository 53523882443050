import {restBaseApi} from '../../../services/restBase'

import {Certificate, PendingAcmeOrder, RedirectStatus} from './HttpsConfigurationPage.types'

const settingsPrefix = `app/https/settings`

const transformTextResponse = (responseBody: string) =>
  JSON.parse(responseBody.length > 0 ? responseBody : 'null')

const enhancedRestBaseApi = restBaseApi.enhanceEndpoints({
  addTagTypes: ['HTTPS/Certificate'],
})

const httpsConfigurationApi = enhancedRestBaseApi.injectEndpoints({
  endpoints: build => ({
    getCertificate: build.query<Certificate | null, void>({
      query: _ => ({
        url: `${settingsPrefix}/certificateInfo`,
        responseHandler: 'text',
      }),
      transformResponse: transformTextResponse,
      providesTags: ['HTTPS/Certificate'],
    }),
    getPendingAcmeOrder: build.query<PendingAcmeOrder | null, void>({
      query: _ => ({
        url: `${settingsPrefix}/getAcmeOrder`,
        responseHandler: 'text',
      }),
      transformResponse: transformTextResponse,
    }),
    getTermsOfService: build.query<string, void>({
      query: _ => ({
        url: `${settingsPrefix}/termsOfService`,
        responseHandler: 'text',
      }),
    }),
    fetchCertificateFromAcme: build.mutation<PendingAcmeOrder | null, any>({
      query: _ => ({
        url: `${settingsPrefix}/fetchFromAcme`,
        method: 'POST',
        responseHandler: 'text',
      }),
      transformResponse: transformTextResponse,
      invalidatesTags: (result, error) =>
        error == null && result == null ? ['HTTPS/Certificate'] : [],
    }),
    triggerAcmeChallenge: build.mutation({
      query: _ => ({
        url: `${settingsPrefix}/triggerAcmeChallenge`,
        method: 'POST',
        responseHandler: 'text',
      }),
      transformResponse: transformTextResponse,
      invalidatesTags: (_, error) => (error == null ? ['HTTPS/Certificate'] : []),
    }),
    cancelAcmeChallenge: build.mutation({
      query: _ => ({
        url: `${settingsPrefix}/cancelAcmeChallenge`,
        method: 'POST',
        responseHandler: 'text',
      }),
    }),
    uploadCertificate: build.mutation<
      undefined,
      {certificateFile: File; keyFile: File; port?: string}
    >({
      query: ({certificateFile, keyFile, port}) => {
        const formData = new FormData()

        formData.append('certificate', certificateFile)
        formData.append('key', keyFile)
        formData.append('port', port ?? '443')

        return {
          url: `${settingsPrefix}/uploadCertificate`,
          method: 'POST',
          body: formData,
          responseHandler: 'text',
        }
      },
      invalidatesTags: (_, error) => (error == null ? ['HTTPS/Certificate'] : []),
    }),
    setRedirectStrategy: build.mutation<undefined, {redirectStatus: RedirectStatus}>({
      query: ({redirectStatus}) => {
        const formData = new FormData()

        formData.append('strategy', redirectStatus)

        return {
          url: `${settingsPrefix}/setRedirectStrategy`,
          method: 'POST',
          body: formData,
          responseHandler: 'text',
        }
      },
    }),
    setPort: build.mutation<undefined, {port: string}>({
      query: ({port}) => {
        const formData = new FormData()

        formData.append('port', port ?? '443')
        return {
          url: `${settingsPrefix}/setPort`,
          method: 'POST',
          body: formData,
          responseHandler: 'text',
        }
      },
    }),
    removeCertificate: build.mutation<undefined, void>({
      query: _ => ({
        url: `${settingsPrefix}/removeCertificate`,
        method: 'DELETE',
        responseHandler: 'text',
      }),
    }),
  }),
  overrideExisting: false,
})

export default httpsConfigurationApi
