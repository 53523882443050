import type {AgentPoolType} from '../types'

import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

export default (
  serverUrl: string = '',
  options?: RestRequestOptions,
): Promise<ReadonlyArray<AgentPoolType>> =>
  request(serverUrl, 'agentPools?fields=agentPool(id,name)', options)
    .then<{agentPool: ReadonlyArray<AgentPoolType>}>(processResponse)
    .then(data => data.agentPool)
