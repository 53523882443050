import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import * as React from 'react'

import BuildApprovalReviewers from '../BuildApproval.reviewers'
import {ViewProps} from '../BuildApproval.types'
import ApprovalControls from '../Inline/BuildApproval.controls'

export default function BuildApprovalClassic({buildId, approvalInfo}: ViewProps) {
  if (!approvalInfo) {
    return (
      <span>
        {'Loading'} <LoaderInline />
      </span>
    )
  }

  return (
    <>
      <div>
        <BuildApprovalReviewers approvalInfo={approvalInfo} />
      </div>
      <div>
        <ApprovalControls approvalInfo={approvalInfo} buildId={buildId} />
      </div>
    </>
  )
}
