import {Size} from '@jetbrains/ring-ui/components/avatar/avatar'
import type {ReactElement} from 'react'

import {useSelector} from 'react-redux'

import {getCurrentUserId, getIsHightlightMyChangesAndInvestigations} from '../../../selectors'
import {AvatarSize, getAvatarSizeKey, UserType} from '../../../types'

import {resolveRelative} from '../../../utils/url'

import {Avatar} from './Avatar'

type Props = {
  user: Partial<UserType> | undefined
  vcsUserName?: string
  size: Size
  srcSize?: AvatarSize
  src2xSize?: AvatarSize
  className?: string
  avatarClassName?: string
  displayPlaceholder?: boolean
  withOutline?: boolean
  relative?: boolean
}

function AvatarContainer({
  user,
  vcsUserName,
  size,
  srcSize = getAvatarSizeKey(size),
  src2xSize = getAvatarSizeKey(2 * size),
  className,
  avatarClassName,
  displayPlaceholder = true,
  withOutline = true,
  relative = true,
}: Props): ReactElement | null {
  const isMine = useSelector(state => user?.id != null && user?.id === getCurrentUserId(state))
  const isHighlightMyChanges = useSelector(getIsHightlightMyChangesAndInvestigations)
  const maybeAvatar = user?.avatars?.[srcSize]
  const avatar = maybeAvatar && relative ? resolveRelative(maybeAvatar) : maybeAvatar
  const maybeAvatar2x = user?.avatars?.[src2xSize]
  const avatar2x = maybeAvatar2x && relative ? resolveRelative(maybeAvatar2x) : maybeAvatar2x
  const username = user?.name ?? user?.username ?? vcsUserName

  return (displayPlaceholder && username != null) || avatar2x ? (
    <Avatar
      className={className}
      withOutline={withOutline}
      mine={isMine}
      highlightMyChanges={isHighlightMyChanges}
      size={size}
      avatarClassName={avatarClassName}
      avatar2x={avatar2x}
      avatar={avatar}
      username={username}
    />
  ) : null
}

export {Size}
export default AvatarContainer
