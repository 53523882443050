const urlParams = new URLSearchParams(window.location.search)

export const pipelinesEnabled = window.internalProps['teamcity.pipelines.enabled']

export const isScreenshotMode = urlParams.has('SCREENSHOT_MODE')

export const showExportToYaml = urlParams.has('EXPORT2YAML')

const reusedJobsParam = urlParams.get('REUSED_JOBS')
export const reusedJobsIds: ReadonlyArray<string> | null =
  reusedJobsParam != null ? reusedJobsParam.split(',') : null

export const showReusedJobAlert = urlParams.has('REUSED_JOB_ALERT')

const runningJobsParam = urlParams.get('RUNNING_JOBS')
export const runningJobsIds: ReadonlyArray<string> | null =
  runningJobsParam != null ? runningJobsParam.split(',') : null
