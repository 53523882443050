import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import * as React from 'react'

import {BuildId} from '../../../../types'
import Popup from '../../Popup/Popup.lazy'
import {useBuildApprove} from '../BuildApproval.hooks'
import BuildApprovalReviewers from '../BuildApproval.reviewers'
import {BuildApprovalType} from '../BuildApproval.types'

import styles from './DetailsApproval.css'

type OwnProps = {
  buildId: BuildId
  approvalInfo: BuildApprovalType
}

export default function DetailsApprovalPopup({buildId, approvalInfo, ...props}: OwnProps) {
  const {approve} = useBuildApprove(buildId)

  return (
    <Popup
      className={classNames(
        styles.popup,
        approvalInfo?.canBeApprovedByCurrentUser && styles.withControls,
      )}
      {...props}
    >
      <div className={styles.popupContent}>
        <div className={styles.reviewers}>
          <BuildApprovalReviewers approvalInfo={approvalInfo} />
        </div>
        {approvalInfo?.canBeApprovedByCurrentUser ? (
          <Button className={styles.approve} onClick={approve}>
            {'Approve'}
          </Button>
        ) : null}
      </div>
    </Popup>
  )
}
