import RingLoader from '@jetbrains/ring-ui/components/loader/loader'

import isVisualTest from '../../../utils/isVisualTest'

const Loader = ({stop, ...restProps}: {readonly stop?: boolean; readonly className?: string}) => {
  const stopProp = isVisualTest() || stop
  return (
    <RingLoader
      {...restProps}
      stop={stopProp}
      {...(isVisualTest()
        ? {
            deterministic: true,
          }
        : {})}
    />
  )
}

export default Loader
