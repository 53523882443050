import {fetchSingleProjectData} from '../../../../actions/projects'
import type {AppThunk} from '../../../../actions/types'
import {getProjectLinks} from '../../../../selectors'
import type {ProjectId} from '../../../../types'

export const fetchProjectLinksIfNeeded =
  (projectId: ProjectId): AppThunk<void> =>
  (dispatch, getState) => {
    if (getProjectLinks(getState(), projectId) == null) {
      dispatch(
        fetchSingleProjectData(projectId, {
          withLinks: true,
        }),
      )
    }
  }
