import {createFetchAction} from '../../../../reducers/fetchable'
import {requestArtifactDependencyExists} from '../../../../rest/builds'
import {requestBuildTypes} from '../../../../rest/buildTypes'
import {restRoot} from '../../../../rest/consts'
import {normalizeBuildTypes} from '../../../../rest/schemata'
import {BuildId} from '../../../../types'

export const fetchBuildTypesWithSnapshotDependencies = createFetchAction(
  'fetchBuildTypesWithSnapshotDependencies',
  (locator: string) =>
    requestBuildTypes(restRoot, locator, {withSnapshotDependencies: true}).then(
      normalizeBuildTypes,
    ),
)
export const fetchArtifactDependenciesExist = createFetchAction(
  'fetchArtifactDependenciesExist',
  (buildId: BuildId) =>
    Promise.all([
      requestArtifactDependencyExists(restRoot, buildId, true),
      requestArtifactDependencyExists(restRoot, buildId, false),
    ]).then(([downloaded, delivered]) => ({downloaded, delivered})),
)
