import type {State} from '../../../../../reducers/types'
import {stringifyId} from '../../../../../types'
import type {BuildId} from '../../../../../types'
import {emptyArray} from '../../../../../utils/empty'

import type {BuildSnippetType} from './BuildSnippets.types'

export const getBuildSnippetKey: (arg0: BuildId) => string = buildId =>
  `build:${stringifyId(buildId)}`
export const getBuildSnippets: (arg0: State, arg1: BuildId) => ReadonlyArray<BuildSnippetType> = (
  state,
  buildId,
) => state.buildSnippets[getBuildSnippetKey(buildId)]?.data || emptyArray
export const getBuildSnippetsReady: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  buildId,
) => state.buildSnippets[getBuildSnippetKey(buildId)]?.ready
