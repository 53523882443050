import {mapProps} from 'recompose'

import compose from 'lodash/flowRight'
import * as React from 'react'

import withHook from '../hocs/withHook'
import type {BuildLineLayoutType, Enhancer} from '../types'
import {withContextAsProp} from '../utils/contexts'

export const BuildLineLayoutContext: React.Context<BuildLineLayoutType> = React.createContext(
  Object.freeze({}),
)
export const defaultBuildLineLayout: BuildLineLayoutType = {
  showBranch: true,
  showAgent: true,
  showDuration: true,
}

type OuterProps = BuildLineLayoutType & {
  children: React.ReactNode
}
type InnerProps = React.ProviderProps<BuildLineLayoutType>

export const BuildLineLayoutProvider = compose(
  mapProps(({children, ...value}: OuterProps) => ({
    children,
    value,
  })),
  withHook(({value}: InnerProps) => ({
    // eslint-disable-next-line react-hooks/exhaustive-deps
    value: React.useMemo(() => ({...defaultBuildLineLayout, ...value}), Object.values(value)),
  })),
)(BuildLineLayoutContext.Provider)
export const withBuildLineLayout: Enhancer<any, any> = withContextAsProp(
  BuildLineLayoutContext,
  'buildLineLayout',
)
