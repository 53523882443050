import {investigation} from '../../rest/investigations'
import processResponse from '../../rest/processResponse'
import request from '../../rest/request'
import {objectToQuery} from '../../utils/queryParams'
import {muteFields} from '../Tests/Tests.rest'

import type {ProblemOccurrencesTreeType} from './BuildProblems.types'

export const problemOccurrenceFields = `id,additionalData,newFailure,currentlyInvestigated,currentlyMuted,${muteFields},type,logAnchor,details,build(id,buildTypeId),problem(id,description,mutes(${muteFields}),investigations(${investigation}))`
const problemOccurrencesTreeFields = `node(type,name,parentId,id,count,newFailedCount,childrenCount),leaf(id,nodeId,problemOccurrences(problemOccurrence(${problemOccurrenceFields})))`
// TODO: migrate to RTK Query when TW-76918 is fixed
export const requestProblemOccurrencesTree = (
  serverUrl: string,
  endpoint: string,
  locator?: string | undefined | null,
  subTreeRootId?: string | undefined | null,
): Promise<ProblemOccurrencesTreeType> =>
  request(
    serverUrl,
    `${endpoint}?${objectToQuery({locator, subTreeRootId, fields: problemOccurrencesTreeFields})}`,
  ).then<ProblemOccurrencesTreeType>(processResponse)
