import type {Tab, TabParamsKey} from '../types'
import {stringifyId} from '../types'
import {base_uri} from '../types/BS_types'
import type {SWGenerator, SWGeneratorReturn} from '../types/generators'
import {CACHE_TABS, REVALIDATION_HEADER_NAME} from '../workers/sw.constants'

import {subscribeOnServiceWorkerMessageOnce} from './caching/events'
import processResponse from './processResponse'
import type {RestRequestOptions} from './request'
import request from './request'

type ResultType = ReadonlyArray<Tab>
export default async function* getTabs(
  key: TabParamsKey,
  restOptions: RestRequestOptions = {},
  cacheTabs: boolean,
): SWGenerator<ResultType, void> {
  let subscription: Promise<SWGeneratorReturn<ResultType>> | null | undefined
  const endpoint = `app/tabs?${stringifyId(key)}`
  const options = {...restOptions}

  if (cacheTabs) {
    options.headers = {
      [REVALIDATION_HEADER_NAME]: CACHE_TABS,
    }
    subscription = subscribeOnServiceWorkerMessageOnce<ResultType, any>({
      serverUrl: base_uri,
      endpoint,
    })
  }

  const response = await request(base_uri, `app/tabs?${stringifyId(key)}`, options)
  const payload: ResultType = await processResponse(response)

  if (subscription != null) {
    yield {payload}
    return await subscription
  } else {
    return {payload}
  }
}
