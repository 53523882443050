import './utils/whyDidYouRender'
import {setClient} from '@jetbrains/ring-ui/components/global/react-render-adapter'
import type {TeamCityAPIType} from '@jetbrains/teamcity-api'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as ReactDOMClient from 'react-dom/client'

import '@jetbrains/ring-ui/components/loader-inline/loader-inline.css'
import './injectConnectionIconStyles'
import {errorAction} from './components/common/ErrorAlerts/ErrorAlerts.actions'
import {HINT_CATEGORY} from './components/Hints/Hints.types'
import Plugin from './plugins/plugins.prototype'
import pluginRegistry from './plugins/plugins.registry'
import ReactUI from './reactui'
import HTTPError from './rest/errors/HTTPError'
import processResponse, {processTextResponse} from './rest/processResponse'
import request from './rest/request'
import type {RestRequestOptions} from './rest/request'
import {base_uri} from './types/BS_types'
import {resolveRelative} from './utils/url'

setClient(ReactDOMClient)

function handleRequestError(omitErrorMessage?: boolean) {
  return (error: Error) => {
    if (omitErrorMessage !== true && error != null && error instanceof HTTPError) {
      ReactUI.store.dispatch(errorAction(error))
    }

    return Promise.reject(error)
  }
}

function requestJSON<T>(
  endpoint: string,
  options?: RestRequestOptions,
  omitErrorMessage?: boolean,
): Promise<T> {
  return request(base_uri, endpoint, options)
    .then<T>(processResponse)
    .catch(handleRequestError(omitErrorMessage))
}

function requestText(
  endpoint: string,
  options?: RestRequestOptions,
  omitErrorMessage?: boolean,
): Promise<string> {
  return request(base_uri, endpoint, options)
    .then(processTextResponse)
    .catch(handleRequestError(omitErrorMessage))
}

function render<P extends {} | null | undefined>(
  elementOrId: HTMLElement | string,
  Type: React.ComponentType<P>,
  props: P & JSX.IntrinsicAttributes,
) {
  ReactUI.renderConnected(elementOrId, Type, props)
}

const Components = {
  AllBuilds: ReactUI.AllBuilds,
  ServiceMessage: ReactUI.ServiceMessage,
  // Just for simple example, will be replaced to others in the future
  BuildNumber: ReactUI.BuildNumberAPI,
  ContentPanel: ReactUI.ContentPanelAPI,
  EntityPath: ReactUI.EntityPathAPI,
  RouterLink: ReactUI.RouterLinkAPI,
  RouterButton: ReactUI.RouterButtonAPI,
}
const Services = {
  AlertService: ReactUI.AlertService,
  HintsService: {
    registerHint: ReactUI.registerHint.bind(ReactUI),
    unregisterHint: ReactUI.unregisterHint.bind(ReactUI),
    categories: HINT_CATEGORY,
  },
  REST: {
    requestJSON,
    requestText,
  },
}
const utils = {
  requestJSON,
  requestText,
  addMarkdownAlert: ReactUI.addMarkdownAlert.bind(ReactUI),
  resolveRelativeURL: resolveRelative,
  isSakuraUI: (): boolean => window.ReactUI.isExperimentalUI,
}

const TC = {
  Components,
  Services,
  render,
  utils,
  pluginRegistry,
  Plugin,
} as TeamCityAPIType
export {
  TC as default,
  render,
  utils,
  pluginRegistry,
  Plugin,
  Components,
  Services,
  React,
  ReactDOM,
  ReactDOMClient,
}
