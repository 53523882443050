import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {castDraft} from 'immer'

import {emptyArray, getEmptyHash} from '../../utils/empty'

import {HINT_CATEGORY, HintCategory, HintId, HintType, stringifyHintId} from './Hints.types'
import type {HintsStateType} from './Hints.types'

const defaultState: HintsStateType = {
  registrations: getEmptyHash(),
  available: emptyArray,
  selected: emptyArray,
  selectedCategory: null,
}

export const hints = createSlice({
  name: 'hints',
  initialState: defaultState,
  reducers: {
    add(state, action: PayloadAction<HintType>) {
      const hint = action.payload
      const registrations = state.registrations[hint.id]
      const hintExists = registrations != null && registrations > 0

      if (hintExists) {
        state.registrations[stringifyHintId(hint.id)] = registrations + 1
      } else {
        state.available.push(
          castDraft(hint.category ? hint : {...hint, category: HINT_CATEGORY.miscellaneous}),
        )
        state.registrations[stringifyHintId(hint.id)] = 1
      }
    },
    remove(state, action: PayloadAction<HintId>) {
      const hintId = action.payload
      const currentRegistrations = state.registrations[hintId] ?? 0
      state.registrations[stringifyHintId(hintId)] = currentRegistrations - 1
      if (currentRegistrations === 1 && state.available.some(item => item.id === hintId)) {
        state.available = state.available.filter(item => item.id !== hintId)
      }
    },
    show(state, action: PayloadAction<HintId>) {
      if (!state.selected.includes(action.payload)) {
        state.selected.push(action.payload)
      }
    },
    hide(state, action: PayloadAction<HintId>) {
      if (state.selected.includes(action.payload)) {
        state.selected = state.selected.filter(id => id !== action.payload)
      }
    },
    selectCategory(state, action: PayloadAction<HintCategory>) {
      state.selectedCategory = action.payload
    },
  },
})
