import * as ReactRedux from 'react-redux'

import {fetchArtifactExtensions, fetchArtifactsList, fetchArtifactsSize} from '../../../actions'
import type {State} from '../../../reducers/types'
import {
  getArtifactsExtensions,
  getArtifactsFetchable,
  getAvailableArtifacts,
  getBuild,
  getBuildArtifactsSizes,
  getHasArtifacts,
} from '../../../selectors'
import type {ArtifactExtensions, BuildId, BuildTypeId, FileType} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'
import {objectToQuery} from '../../../utils/queryParams'

type OwnProps = {
  readonly buildId: BuildId
  readonly path?: string
  readonly level?: number
  readonly timeStamp?: number | null | undefined
  readonly labelledBy?: string
  readonly showToggleHidden?: boolean
  readonly showDownloadLink?: boolean
  readonly showStorageInfo?: boolean
  readonly canSelectDirs?: boolean | null | undefined
  readonly autoWidth?: boolean
  readonly expandedNodes?: ReadonlyArray<string> | null | undefined
  readonly onSelect?: ((path: string, type: string) => unknown) | null | undefined
  readonly onExpand?:
    | ((path: string, expanded: boolean | null | undefined) => unknown)
    | null
    | undefined
  readonly urlSync?: boolean
}
type StateProps = {
  readonly level: number
  readonly buildUrl: string | null | undefined
  readonly loading: boolean
  readonly files: ReadonlyArray<FileType>
  readonly buildType: BuildTypeId | null | undefined
  readonly extensions: ArtifactExtensions | null | undefined
  readonly visibleArtifactsSize: string | null | undefined
  readonly totalArtifactsSize: string | null | undefined
  readonly hasArtifacts: boolean | null | undefined
}
type ActionProps = {
  readonly load: (arg0: BuildId, arg1: string, arg2: boolean) => unknown
  readonly loadExtensions: (arg0: BuildId) => unknown
  readonly loadArtifactsSize: (arg0: BuildId) => unknown
}
export type Props = OwnProps & StateProps & ActionProps

const mapStateToProps = (state: State, {buildId, path, level = 0}: OwnProps): StateProps => {
  const build = getBuild(state, buildId)
  const fetchable = getArtifactsFetchable(state, buildId, path, level === 0)
  const artifactsSizes = getBuildArtifactsSizes(state, buildId)
  const hasArtifacts = getHasArtifacts(state, buildId)
  return {
    level,
    buildUrl: resolveWebEntityLink(build),
    loading: fetchable.loading && !fetchable.ready,
    files: getAvailableArtifacts(state, buildId, path),
    buildType: build?.buildType,
    extensions: getArtifactsExtensions(state, buildId),
    hasArtifacts,
    visibleArtifactsSize: artifactsSizes?.visible ?? null,
    totalArtifactsSize: artifactsSizes?.total ?? null,
  }
}

const actionCreators: ActionProps = {
  load: fetchArtifactsList,
  loadExtensions: fetchArtifactExtensions,
  loadArtifactsSize: fetchArtifactsSize,
}
export const getArtifactsHref = (
  buildUrl?: string | null,
  showAll?: boolean | null,
): string | undefined =>
  buildUrl != null
    ? `${buildUrl}&${objectToQuery({
        tab: 'artifacts',
        showAll: showAll === true ? 'true' : null,
      })}`
    : undefined
export default ReactRedux.connect<StateProps, ActionProps, OwnProps, State>(
  mapStateToProps,
  actionCreators,
)
