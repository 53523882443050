import compose from 'lodash/flowRight'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import {BuildLineLayoutProvider} from '../contexts/buildLineLayout'
import {withBuildTypeId} from '../hooks/routes'
import type {State} from '../reducers/types'
import {getBuildType, getBuildtypeFilter} from '../selectors'
import type {BuildLineLayoutType, BuildTypeId} from '../types'

type OwnProps = BuildLineLayoutType & {
  readonly children: React.ReactNode
  readonly buildTypeId?: BuildTypeId
  readonly isDependencies?: boolean
}
type StateProps = {
  readonly isComposite: boolean
  readonly hasBtFilter: boolean
}
type Props = OwnProps & StateProps

const mapStateToProps = (state: State, props: Props): StateProps => {
  const {buildTypeId} = props
  return {
    isComposite:
      props.isDependencies !== true && getBuildType(state, buildTypeId)?.type === 'composite',
    hasBtFilter: getBuildtypeFilter(state) != null,
  }
}

class BuildLineLayout extends React.PureComponent<Props, State> {
  static defaultProps = {
    showAgent: true,
    showExactDuration: true,
    showBranch: true,
    showPath: true,
    showTags: true,
    showComment: true,
  }

  render() {
    const {
      children,
      hasBtFilter,
      isComposite,
      showAgent,
      showExactDuration,
      showBranch,
      showPath,
      showTags,
      showComment,
      artifactDependenciesMode,
    } = this.props
    const layout = {
      showAgent: showAgent === true && !isComposite,
      showExactDuration,
      showBranch,
      showPath: showPath === true && !hasBtFilter,
      showTags,
      showComment,
      artifactDependenciesMode,
    }
    return <BuildLineLayoutProvider {...layout}>{children}</BuildLineLayoutProvider>
  }
}

export default compose(withBuildTypeId, ReactRedux.connect(mapStateToProps))(BuildLineLayout)
