import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import classNames from 'classnames'
import * as React from 'react'

import {useDistanceToNow} from '../../../hooks/useTimeSelector'
import {parseDateToObj} from '../../../utils/dateTimeUtils'
import IconButton from '../IconButton/IconButton'
import Link from '../Link/Link'
import Popup from '../Popup/Popup.lazy'
import SvgIcon from '../SvgIcon/SvgIcon'

import styles from './BuildWarning.css'

const DEFAULT_POPUP_DIRECTIONS = [Directions.RIGHT_BOTTOM]
type DefaultProps = {
  readonly isAutodetectTimeZone: boolean
}
type Props = DefaultProps & {
  readonly running?: boolean | null | undefined
  readonly composite?: boolean | null | undefined
  readonly detachedFromAgent?: boolean | null | undefined
  readonly agentConnected?: boolean | null | undefined
  readonly agentName?: string | null | undefined
  readonly lastActivityTime?: string | null | undefined
  readonly probablyHanging?: boolean | null | undefined
  readonly outdated?: boolean | null | undefined
  readonly outdatedReasonBuildNumber?: string | null | undefined
  readonly outdatedReasonBuildUrl?: string | null | undefined
  readonly className?: string
  readonly inline?: boolean | null | undefined
}

function BuildWarning(props: Props) {
  const {
    className,
    running,
    composite,
    detachedFromAgent,
    agentConnected,
    probablyHanging,
    outdated,
    inline,
    lastActivityTime,
    isAutodetectTimeZone,
  } = props
  const lastActivityDateObj =
    lastActivityTime != null
      ? parseDateToObj(lastActivityTime, isAutodetectTimeZone)
      : lastActivityTime
  const lastActivityFromNow = useDistanceToNow(lastActivityTime)
  const formattedLastActivityDate =
    lastActivityDateObj != null
      ? `${lastActivityDateObj.format('D MMM YY LT')} (${lastActivityFromNow})`
      : null

  function renderNotConnectedAgentMessage(): React.ReactNode {
    const {agentName} = props
    return (
      <>
        <span className={styles.warning}>{`Build agent ${
          agentName || ''
        } was disconnected while running a build`}</span>
        {
          'Please check the agent and network connectivity. Either ensure the agent is connected or stop the build'
        }
        {formattedLastActivityDate != null &&
          `. Last message was received on: ${formattedLastActivityDate}`}
      </>
    )
  }

  function renderHangingMessage(): React.ReactNode {
    if (composite === true) {
      return <span className={styles.warning}>{'Some dependencies of this build are hanging'}</span>
    } else {
      const agentMention = detachedFromAgent !== true ? ' from the agent' : ''
      return (
        <>
          <span className={styles.warning}>{'This build is hanging'}</span>
          {formattedLastActivityDate != null &&
            `Last message${agentMention} was received on: ${formattedLastActivityDate}`}
        </>
      )
    }
  }

  function renderOutdatedMessage(): React.ReactNode {
    const {outdatedReasonBuildNumber, outdatedReasonBuildUrl} = props
    const buildLabel = outdatedReasonBuildNumber != null ? `#${outdatedReasonBuildNumber}` : null
    return (
      <>
        <span className={styles.warning}>{'This build is outdated'}</span>
        {'More recent build '}
        {buildLabel != null &&
          (outdatedReasonBuildUrl != null ? (
            <Link href={outdatedReasonBuildUrl}>{buildLabel}</Link>
          ) : (
            buildLabel
          ))}
        {' is already finished'}
      </>
    )
  }

  if (running !== true) {
    return null
  }

  let message

  if (composite !== true && detachedFromAgent !== true && agentConnected !== true) {
    message = renderNotConnectedAgentMessage()
  } else if (probablyHanging === true) {
    message = renderHangingMessage()
  } else if (outdated === true) {
    message = renderOutdatedMessage()
  }

  if (message != null) {
    if (inline) {
      return (
        <div className={className}>
          <SvgIcon icon="warning" className={styles.inlineIcon} />
          {message}
        </div>
      )
    }

    const anchor = (
      <IconButton
        icon="warning"
        title=""
        aria-label="Warning"
        className={classNames(styles.button, className)}
        iconClassName={styles.icon}
      />
    )
    return (
      <Dropdown
        anchor={anchor}
        hoverShowTimeOut={300}
        hoverHideTimeOut={300}
        clickMode={false}
        hoverMode
      >
        <Popup directions={DEFAULT_POPUP_DIRECTIONS} left={5}>
          <div className={styles.popup}>{message}</div>
        </Popup>
      </Dropdown>
    )
  }

  return null
}

BuildWarning.defaultProps = {
  isAutodetectTimeZone: false,
}

export default BuildWarning
