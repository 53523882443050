import {BuildTypeId, NormalizedBuildType} from '../../../types'
import {KeyValue} from '../../../utils/object'

import {
  ChangeBuildTypeType,
  ChangeDeploymentType,
  ChangePageTabNamesEnum,
  ChangePageTabNamesEnumType,
  OldChangePageTabNamesEnum,
  OldChangePageTabNamesEnumType,
} from './ChangeDetailsTabs.types'

const MatchingOldToNewTabNamesEnum = {
  [OldChangePageTabNamesEnum.PROBLEMS_TESTS]: ChangePageTabNamesEnum.PROBLEMS_TESTS,
  [OldChangePageTabNamesEnum.BUILDS]: ChangePageTabNamesEnum.BUILDS,
  [OldChangePageTabNamesEnum.DEPLOYMENTS]: ChangePageTabNamesEnum.DEPLOYMENTS,
  [OldChangePageTabNamesEnum.FILES]: ChangePageTabNamesEnum.FILES,
}

const MatchingNewToOldTabNamesEnum = {
  [ChangePageTabNamesEnum.PROBLEMS_TESTS]: OldChangePageTabNamesEnum.PROBLEMS_TESTS,
  [ChangePageTabNamesEnum.BUILDS]: OldChangePageTabNamesEnum.BUILDS,
  [ChangePageTabNamesEnum.DEPLOYMENTS]: OldChangePageTabNamesEnum.DEPLOYMENTS,
  [ChangePageTabNamesEnum.FILES]: OldChangePageTabNamesEnum.FILES,
}

export const matchNewToOldTabName = (tab: ChangePageTabNamesEnumType) =>
  MatchingNewToOldTabNamesEnum[tab] ?? OldChangePageTabNamesEnum.FILES

export const matchOldToNewTabName = (tab: OldChangePageTabNamesEnumType) =>
  MatchingOldToNewTabNamesEnum[tab] ?? ChangePageTabNamesEnum.FILES

export const getBuildsHash = (
  changeBuilds: ReadonlyArray<NormalizedBuildType>,
): KeyValue<BuildTypeId, NormalizedBuildType[]> =>
  changeBuilds.reduce(
    (acc: KeyValue<BuildTypeId, NormalizedBuildType[]>, build) => ({
      ...acc,
      [build.buildType]: (acc[build.buildType] ?? []).concat(build),
    }),
    {},
  )

export const sortBuildTypes = (
  changeBuildTypes: ReadonlyArray<ChangeBuildTypeType | ChangeDeploymentType>,
) => [...changeBuildTypes].sort((bt1, bt2) => (bt1.id > bt2.id ? 1 : bt1.id < bt2.id ? -1 : 0))
