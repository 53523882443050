import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const settings = createSlice({
  name: 'buildLog.settings',
  initialState: {
    softWrapLines: false,
    darkTheme: false,
  },
  reducers: {
    setSoftWrapLines(state, action: PayloadAction<boolean>) {
      state.softWrapLines = action.payload
    },
    setDarkTheme(state, action: PayloadAction<boolean>) {
      state.darkTheme = action.payload
    },
  },
})
