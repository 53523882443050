import cancel20pxIcon from '@jetbrains/icons/cancel-20px.svg'
import exception20pxIcon from '@jetbrains/icons/exception-20px.svg'
import hourglass20pxIcon from '@jetbrains/icons/hourglass-20px.svg'
import ok20pxIcon from '@jetbrains/icons/ok-20px.svg'
import spinner20pxIcon from '@jetbrains/icons/spinner-20px.svg'
import warning20pxIcon from '@jetbrains/icons/warning-20px.svg'
import type {IconType} from '@jetbrains/ring-ui/components/icon/icon'
import classnames from 'classnames'

import type {KeyValue} from '../../../utils/object'

import styles from './Icon.css'

class Node {
  className: string | null | undefined
  withIcon: boolean
  next: Record<string, Node> | null | undefined
  constructor(
    className: string | null | undefined,
    withIcon: boolean = false,
    next?: Record<string, Node>,
  ) {
    this.className = className
    this.withIcon = withIcon
    this.next = next
  }
}

const runningColorBranch = {
  red: new Node(styles.red, false),
  green: new Node(styles.green, false),
}
const buildProgressBranch = {
  canceled: new Node(styles.gray, true),
  failedToStart: new Node(styles.red, true),
  queued: new Node(styles.gray, true),
  running: new Node(styles.spinning, true, {
    ...runningColorBranch,
    detached: new Node(null, true, runningColorBranch),
  }),
  finished: new Node(null, false, {
    red: new Node(styles.red, true),
    green: new Node(styles.green, true),
  }),
}
const root = new Node(null, false, {
  ...buildProgressBranch,

  /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
   * v0.142.0. To view the error, delete this comment and run Flow. */
  running: new Node(styles.spinning, true, {
    ...buildProgressBranch.running.next,
    static: new Node(classnames(styles.runningStatic, styles.green), true),
  }),
  personal: new Node(classnames(styles.personal, styles.secondary), false, buildProgressBranch),
  my: new Node(classnames(styles.my, styles.secondary), false, buildProgressBranch),
  ignored: new Node(styles.gray, true),
  paused: new Node(styles.gray, true),
  muted: new Node(styles.gray, true),
  overtime: new Node(styles.red, true),
  warning: new Node(styles.warning, true),
  help: new Node(styles.gray, true),
  helpBlue: new Node(styles.blue, true),
  tag: new Node(styles.gray, true),
  pin: new Node(styles.blue, true),
  star: new Node(styles.darkblue, true),
  arrow: new Node(styles.gray, true, {
    down: new Node(null),
    right: new Node(styles.arrow_right),
  }),
  branch: new Node(styles.gray, true),
  dropdownTrigger: new Node(styles.dropdownTrigger),
  dropdownTriggerSpecial: new Node(styles.dropdownTriggerSpecial),
  unknown: new Node(null, false, {
    os: new Node(null, true),
  }),
})
const icons: KeyValue<string, IconType | string> = {
  running: 'spinner',
  running_detached: 'spinner-arrow',
  // until added to @jetbrains/icons repo
  my_running: 'user-spinner',
  my_running_detached: 'user-spinner-arrow',
  finished_green: 'ok',
  my_finished_green: 'user-ok',
  finished_red: 'exception',
  my_finished_red: 'user-exception',
  canceled: 'cancel',
  my_canceled: 'user-cancel',
  failedToStart: 'warning',
  my_failedToStart: 'user-warning',
  queued: 'hourglass',
  my_queued: 'user-hourglass',
  unknown_os: 'unknown-os',
  pin: 'pin-filled',
  star: 'star-filled',
  help: 'help',
  helpBlue: 'help',
  arrow: 'caret-down-10px',
  branch: 'branches',
  running_static: 'spinner-static',
}
const bigIcons: KeyValue<string, IconType | string> = {
  running: spinner20pxIcon,
  running_detached: 'spinner-arrow-20px',
  my_running: 'user-spinner-20px',
  my_running_detached: 'user-spinner-arrow-20px',
  finished_green: ok20pxIcon,
  my_finished_green: 'user-ok-20px',
  finished_red: exception20pxIcon,
  my_finished_red: 'user-exception-20px',
  canceled: cancel20pxIcon,
  my_canceled: 'user-cancel-20px',
  failedToStart: warning20pxIcon,
  my_failedToStart: 'user-warning-20px',
  queued: hourglass20pxIcon,
  my_queued: 'user-hourglass-20px',
  help: 'help-20px',
  helpBlue: 'help-20px',
}

function getIcon(namePrefix: string, big: boolean): IconType | string | undefined {
  const key = namePrefix.replace('personal', 'my')
  return big ? bigIcons[key] : icons[key]
}

function getAllIconNames(node: Node): string[] {
  if (node.next == null) {
    return []
  }

  const names = []

  for (const modifier in node.next) {
    if (node.next.hasOwnProperty(modifier) && node.next != null) {
      const newNames = getAllIconNames(node.next[modifier]).map(suffix => `${modifier}_${suffix}`)

      if (newNames.length > 0) {
        names.push(...newNames)
      } else {
        names.push(modifier)
      }
    }
  }

  return names
}

export const allIconNames: string[] = getAllIconNames(root)
export function getIconAndClassNames(
  iconName: string,
  big: boolean,
): [IconType | string | undefined, string] {
  const modifiers = iconName.split('_')
  let node = root
  let iconKey = ''
  let icon
  const classNames = []

  if (big) {
    classNames.push(styles.big)
  }

  for (let i = 0; i < modifiers.length; i++) {
    const currentModifier = modifiers[i]
    const len = i === 0 ? currentModifier.length : iconKey.length + currentModifier.length + 1
    iconKey = iconName.substr(0, len)

    if (node.next != null && node.next[currentModifier] != null) {
      node = node.next[currentModifier]
      classNames.push(node.className)

      if (node.withIcon) {
        icon = getIcon(iconKey, big)
      }
    } else {
      break
    }
  }

  return [icon, classnames(classNames)]
}
