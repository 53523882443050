import {createSelector} from 'reselect'

import type {State} from '../../reducers/types'
import {stringifyId} from '../../types'
import type {BuildTypeId, DslOptions, DslOptionType} from '../../types'

export const getDslOptions = (state: State, controlId: string): DslOptions | undefined =>
  state.dslOptions[controlId]
export const getDslRequestEndpoint: (arg0: State, arg1: BuildTypeId, arg2: string) => string = (
  state,
  buildTypeOrTemplateId,
  controlId,
) => {
  const options = getDslOptions(state, controlId)
  return `generateSettings.html?id=${stringifyId(buildTypeOrTemplateId)}&showDSLVersion=${
    options?.version
  }&showDSLPortable=${options?.portable ? 'true' : 'false'}`
}
export const getBuildTypeDsl: (arg0: State, arg1: string) => string | null | undefined = (
  state,
  endpoint,
) => state.html[endpoint]
export const getFragmentDsl: (arg0: State) => string | null | undefined = state => state.dslFragment
export const getAvailableDslOptions: (
  arg0: State,
) => ReadonlyArray<DslOptionType> | null | undefined = state =>
  state.availableDslOptions && state.availableDslOptions.ready && state.availableDslOptions.data
    ? state.availableDslOptions.data
    : null
export const getDefaultDslOptions: (arg0: State) => DslOptions = createSelector(
  getAvailableDslOptions,
  availableDslOptions => {
    const defaultOptionFromBackend = availableDslOptions
      ? availableDslOptions.find(options => options.current) || availableDslOptions[0]
      : null
    return {
      show: false,
      portable: defaultOptionFromBackend ? defaultOptionFromBackend.portable : true,
      version: defaultOptionFromBackend ? defaultOptionFromBackend.version : 'v2018_2',
    }
  },
)
export const extractText = (
  data: string | null | undefined,
  name: string,
): string | null | undefined => {
  if (data == null) {
    return null
  }

  const xml: Document = new window.DOMParser().parseFromString(data, 'text/xml')
  const nodes = xml.getElementsByTagName(name)

  if (!nodes) {
    return null
  }

  const node = nodes[0]

  if (!node) {
    return null
  }

  const content = node.textContent ?? ''
  return content.length > 0 ? content : null
}
