import {combineReducers} from 'redux'

import {hoveredJob, pipelineDraft} from './EditPipelinePage/EditPipelinePage.slices'

export const pipelines = combineReducers({
  pipelineDraft: pipelineDraft.reducer,
  hoveredJob: hoveredJob.reducer,
})

export type PipelinesState = ReturnType<typeof pipelines>
