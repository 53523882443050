import * as React from 'react'
import {createPortal} from 'react-dom'

import {Props} from './Header'

import styles from './Header.css'

const Header = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Header", webpackPrefetch: true */
      './Header'
    ),
)

const HeaderLazy = (props: Props) => {
  const portal = document.getElementById('header-portal')
  const node = React.useMemo(
    () => (
      <React.Suspense
        fallback={<div data-suspense-fallback className={styles.headerPlaceholder} />}
      >
        <Header {...props} />
      </React.Suspense>
    ),
    [props],
  )

  if (portal) {
    return createPortal(node, portal)
  }

  return node
}

export default React.memo(HeaderLazy)
