import type {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'

import {KeyValue} from '../../utils/object'

export const HINT_CATEGORY = {
  tabs: 'Tabs',
  buildLog: 'Build Log',
  sidebar: 'Sidebar',
  dependencyChain: 'Dependencies',
  header: 'Header',
  navigation: 'Navigation',
  miscellaneous: 'Miscellaneous',
  whatsNew: "What's new",
  projectOverview: 'Project',
  buildOverview: 'Build',
  agents: 'Agents',
  administration: 'Administration',
  guide: 'Guide',
} as const
type HintCategoryType = typeof HINT_CATEGORY
export type HintCategory = HintCategoryType[keyof HintCategoryType]
export type HintId = string
export const toHintId = (id: string): HintId => id
export const stringifyHintId = (id: HintId): string => (id != null ? String(id) : '')
type HintImage = {
  readonly src: string
  readonly alt: string
}
export type HintType = {
  readonly id: HintId
  readonly name: string
  readonly text: string
  readonly category?: HintCategory
  readonly className?: string
  readonly directions?: ReadonlyArray<Directions>
  readonly offset?: number
  readonly viewed?: boolean
  readonly lessonId?: string
  readonly highlightTop?: boolean
  readonly helpLink?: string
  readonly selector?: string
  readonly image?: HintImage
}
export type HintPopupProps = {
  readonly id: HintId
  readonly dark?: boolean
  readonly hoverOverDark?: boolean
  readonly btnPrevText?: string
  readonly btnNextText?: string
  readonly currentIndex?: number
  readonly total?: number
  readonly onClickPrev?: () => void
  readonly onClickNext?: () => void
  readonly onClose?: () => void
}
export type HintDataHookType = {
  onSelectNext: () => unknown
  onSelectPrevious: () => unknown
  onSelectCategory: (category: HintCategory) => unknown
  currentCategoryHints: {
    category?: HintCategory | null
    collection: Array<HintType>
  }
  registeredCategories: HintCategory[]
  selectedHints: ReadonlyArray<HintId>
}
export type HintsStateType = {
  registrations: KeyValue<HintId, number>
  available: ReadonlyArray<HintType>
  selected: ReadonlyArray<HintId>
  selectedCategory: HintCategory | null
}
