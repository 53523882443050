import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {QueueCountersByPool} from './QueueSidebar.types'

export const counters = createSlice({
  name: 'queueSidebar.counters',
  initialState: {},
  reducers: {
    receive: (_, action: PayloadAction<QueueCountersByPool>) => action.payload,
  },
})
