import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {TabId} from '../../../types'

export const projectTab = createSlice({
  name: 'projectTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})
