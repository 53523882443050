import type {AppThunk} from '../../actions/types'

import {getDefaultDslOptions} from './Dsl.selectors'
import {dslFragment, dslOptions, dslPortable, dslVersion, toggleDsl} from './Dsl.slices'

export const showDsl = (controlId: string) => toggleDsl.actions.show({controlId})
export const hideDsl = (controlId: string) => toggleDsl.actions.hide({controlId})
export const setDslVersion = (controlId: string, version: string) =>
  dslVersion.actions.set({controlId, version})
export const setDslPortable = (controlId: string, portable: boolean) =>
  dslPortable.actions.set({
    controlId,
    portable,
  })
export const initDslOptions =
  (controlId: string): AppThunk<any> =>
  (dispatch, getState) =>
    dispatch(
      dslOptions.actions.init({
        controlId,
        options: getDefaultDslOptions(getState()),
      }),
    )
export const storeDslFragment = dslFragment.actions.store
