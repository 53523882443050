/**
 * @generated SignedSource<<09c944817b8f3551b382abdbb8a2a281>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AgentsScreenPoolAuthorizePermissionsQuery$variables = {
  agentPoolId: string;
};
export type AgentsScreenPoolAuthorizePermissionsQuery$data = {
  readonly agentPool: {
    readonly id: string;
    readonly permissions: {
      readonly authorizeAgents: boolean | null;
      readonly manageAgents: boolean | null;
    } | null;
  } | null;
};
export type AgentsScreenPoolAuthorizePermissionsQuery = {
  response: AgentsScreenPoolAuthorizePermissionsQuery$data;
  variables: AgentsScreenPoolAuthorizePermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentPoolId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "agentPoolId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentPoolPermissions",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorizeAgents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageAgents",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsScreenPoolAuthorizePermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentsScreenPoolAuthorizePermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c0035b4340b0a59afd057b901a27e80",
    "id": null,
    "metadata": {},
    "name": "AgentsScreenPoolAuthorizePermissionsQuery",
    "operationKind": "query",
    "text": "query AgentsScreenPoolAuthorizePermissionsQuery(\n  $agentPoolId: ID!\n) {\n  agentPool(id: $agentPoolId) {\n    __typename\n    id\n    permissions {\n      authorizeAgents\n      manageAgents\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e67f147ea43f0100d24f51e749c6fb05";

export default node;
