import {Location} from 'history'
import compose from 'lodash/flowRight'
import * as React from 'react'
import {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {PathMatch, useLocation, useNavigate, useSearchParams} from 'react-router-dom'

import {setUserProperty} from '../actions'
import {EntityContext} from '../contexts/entity'
import {useDerivedState} from '../hocs/withDerivedState'
import withHook from '../hocs/withHook'
import {mergeIfDifferent} from '../reducers/utils'
import Routes, {
  ALL_PROJECTS_HASH,
  getBuildHref,
  getFavoriteBuildsHref,
  getFavoriteProjectsHref,
  getHrefWithQueryParams,
  HistoryContext,
  matchRoute,
} from '../routes'
import {getBuild, getCurrentUserLoaded, getUserProperty} from '../selectors'
import type {
  AgentId,
  AgentPoolId,
  AgentTypeId,
  BranchType,
  BuildId,
  BuildTypeId,
  Enhancer,
  HOC,
  ProjectId,
  ProjectOrBuildTypeNode,
  TestId,
} from '../types'
import {
  ChangeId,
  getProjectBuildTypeFilter,
  toAgentId,
  toAgentPoolId,
  toAgentTypeId,
  toBuildId,
  toBuildTypeId,
  toChangeId,
  toCustomSidebarItemId,
  toProjectId,
  toTestId,
} from '../types'
import {SidebarActiveItem} from '../types/projectTrees'
import {parseBranch} from '../utils/branchNames'
import type {QueryParams} from '../utils/queryParams'
import {objectToQuery, queryToObject, searchParamsToObject} from '../utils/queryParams'
import type {UserProperty} from '../utils/userProperties'

export function useBuildHref(buildId: BuildId | null | undefined, isAllProjects?: boolean) {
  const buildTypeId = useSelector(state => getBuild(state, buildId)?.buildType)
  return buildId != null ? getBuildHref(buildId, buildTypeId, isAllProjects) : null
}
export function useBuildHrefWithSaveParams(
  location: Location,
  buildId: BuildId | null | undefined,
  savedParams: ReadonlyArray<string>,
  isAllProjects: boolean = false,
): string {
  const href = useBuildHref(buildId, isAllProjects)
  return getHrefWithQueryParams(location, href, params =>
    savedParams.reduce(
      (acc, paramName) =>
        params[paramName] != null ? {...acc, [paramName]: String(params[paramName])} : acc,
      {},
    ),
  )
}
type SyncedQueryParamArg = {
  readonly param: string
  readonly userProperty: UserProperty
  readonly values?: ReadonlyArray<string>
  readonly defaultValue?: string | null
  readonly skip?: boolean
}

const useLocationSelector = <T>(selector: (location: Location) => T): T => selector(useLocation())

export const useMatchSelector = <T>(route: string, selector: (match: PathMatch | null) => T): T =>
  useLocationSelector(location => selector(matchRoute(route, location)))

const useProjectIdFromUrl = (): ProjectId | undefined =>
  useMatchSelector(Routes.PROJECT, matched => {
    const id = matched?.params.projectId
    return id != null ? toProjectId(id) : undefined
  })

export const useProjectId = (): ProjectId | undefined => {
  const {projectId} = React.useContext(EntityContext)
  const fromUrl = useProjectIdFromUrl()
  return projectId ?? fromUrl
}
type ProjectProps = {
  readonly projectId: ProjectId
}
export const withProjectId: Enhancer<ProjectProps | null | undefined, any> = withHook(() => {
  const projectId = useProjectId()
  return projectId != null
    ? {
        projectId,
      }
    : null
})

const useBuildTypeIdFromUrl = (): BuildTypeId | null | undefined => {
  const matchSelector = (matched: PathMatch | null) => {
    const id = matched?.params.buildTypeId
    return id != null ? toBuildTypeId(id) : undefined
  }

  const fromBuildTypePath = useMatchSelector(Routes.BUILD_TYPE, matchSelector)
  const fromBuildPath = useMatchSelector(Routes.BUILD, matchSelector)
  return fromBuildTypePath ?? fromBuildPath
}

export const useBuildTypeId = (): BuildTypeId | null | undefined => {
  const {buildTypeId} = React.useContext(EntityContext)
  const fromUrl = useBuildTypeIdFromUrl()
  return buildTypeId ?? fromUrl
}
type BuildTypeProps = {
  readonly buildTypeId: BuildTypeId
}
export const withBuildTypeId: Enhancer<BuildTypeProps | null | undefined, any> = withHook(() => {
  const buildTypeId = useBuildTypeId()
  return buildTypeId != null
    ? {
        buildTypeId,
      }
    : null
})
export const useBuildIdFromPath = (): BuildId | null | undefined => {
  const matchSelector = (matched: PathMatch | null) => {
    const id = matched?.params.buildId
    return id != null ? toBuildId(id) : undefined
  }

  const fromBuildPath = useMatchSelector(Routes.BUILD, matchSelector)
  const fromBuildUnknownBuildTypePath = useMatchSelector(
    Routes.BUILD_UNKNOWN_BUILDTYPE,
    matchSelector,
  )
  return fromBuildPath ?? fromBuildUnknownBuildTypePath
}
export const useBuildId = (): BuildId | null | undefined => {
  const {buildId} = React.useContext(EntityContext)
  const fromPath = useBuildIdFromPath()
  return buildId ?? fromPath
}
type BuildProps = {
  readonly buildId: BuildId
}
export const withBuildId: Enhancer<BuildProps | null | undefined, any> = withHook(() => {
  const buildId = useBuildId()
  return buildId != null
    ? {
        buildId,
      }
    : null
})
export const useAgentId = (): AgentId | null | undefined => {
  const {agentId} = React.useContext(EntityContext)
  const fromUrl = useMatchSelector(Routes.AGENT, matched => {
    const id = matched?.params.agentId
    return id != null ? toAgentId(id) : undefined
  })
  return agentId ?? fromUrl
}
const withAgentId = withHook<
  | {
      readonly agentId: AgentId
    }
  | null
  | undefined,
  any
>(() => {
  const agentId = useAgentId()
  return agentId != null
    ? {
        agentId,
      }
    : null
})
export const useAgentPoolId = (): AgentPoolId | null | undefined => {
  const {agentPoolId} = React.useContext(EntityContext)
  const fromUrl = useMatchSelector(Routes.AGENT_POOL, matched => {
    const id = matched?.params.agentPoolId
    return id != null ? toAgentPoolId(id) : undefined
  })
  return agentPoolId ?? fromUrl
}
const withAgentPoolId = withHook<
  | {
      readonly agentPoolId: AgentPoolId
    }
  | null
  | undefined,
  any
>(() => {
  const agentPoolId = useAgentPoolId()
  return agentPoolId != null
    ? {
        agentPoolId,
      }
    : null
})
export const useAgentTypeId = (): AgentTypeId | null | undefined => {
  const {agentTypeId} = React.useContext(EntityContext)
  const fromUrl = useMatchSelector(Routes.CLOUD_IMAGE, matched => {
    const id = matched?.params.agentTypeId
    return id != null ? toAgentTypeId(id) : undefined
  })
  return agentTypeId ?? fromUrl
}
const withAgentTypeId = withHook<
  | {
      readonly agentTypeId: AgentTypeId
    }
  | null
  | undefined,
  any
>(() => {
  const agentTypeId = useAgentTypeId()
  return agentTypeId != null
    ? {
        agentTypeId,
      }
    : null
})
export const withActiveEntityId = compose(
  withProjectId,
  withBuildTypeId,
  withBuildId,
  withAgentId,
  withAgentPoolId,
  withAgentTypeId,
)

function useProjectOrBuildTypeNode(): ProjectOrBuildTypeNode | null {
  const projectId = useProjectId()
  const buildTypeId = useBuildTypeId()
  return React.useMemo(
    () =>
      getProjectBuildTypeFilter({
        projectId,
        buildTypeId,
      }),
    [buildTypeId, projectId],
  )
}

export const withProjectOrBuildTypeNode = withHook(() => ({
  projectOrBuildTypeNode: useProjectOrBuildTypeNode(),
}))
export function useProjectOrBuildTypeNodeFromUrl(): ProjectOrBuildTypeNode | null | undefined {
  const projectId = useProjectIdFromUrl()
  const buildTypeId = useBuildTypeIdFromUrl()
  return React.useMemo(
    () =>
      getProjectBuildTypeFilter({
        projectId,
        buildTypeId,
      }),
    [buildTypeId, projectId],
  )
}
export const useIsOverviewLinkSelected = (): boolean =>
  useMatchSelector(Routes.FAVORITE_PROJECTS, matched => matched != null)
export const useIsFavoriteLinkSelected = (): boolean =>
  useMatchSelector(Routes.FAVORITE_BUILDS, matched => matched != null)

const useIsAgentSelected = () => useMatchSelector(Routes.AGENT, matched => matched != null)

export const useIsAgentsRootSelected = (): boolean =>
  useMatchSelector(Routes.AGENTS, matched => matched != null)

const useIsAgentsPoolSelected = () =>
  useMatchSelector(Routes.AGENT_POOL, matched => matched != null)

export const useIsUnauthorizedAgentsSelected = (): boolean =>
  useMatchSelector(Routes.AGENTS_UNAUTHORIZED, matched => matched != null)
export const useIsAgentsOverviewSelected = (): boolean =>
  useMatchSelector(Routes.AGENTS_OVERVIEW, matched => matched != null)

const useIsCloudImageSelected = () =>
  useMatchSelector(Routes.CLOUD_IMAGE, matched => matched != null)

export const useIsAgentsPartSelected = (): boolean => {
  const agent = useIsAgentSelected()
  const overview = useIsAgentsOverviewSelected()
  const unauthorizedAgents = useIsUnauthorizedAgentsSelected()
  const root = useIsAgentsRootSelected()
  const pool = useIsAgentsPoolSelected()
  const cloud = useIsCloudImageSelected()
  return agent || overview || unauthorizedAgents || root || pool || cloud
}
export const useIsQueueSelected = (): boolean =>
  useMatchSelector(Routes.QUEUE, matched => matched != null)
export const useIsGuidesOverviewSelected = (): boolean =>
  useMatchSelector(Routes.GUIDES, matched => matched != null)
export const useTestId = (): TestId | null | undefined =>
  useMatchSelector(Routes.TEST, matched => {
    const id = matched?.params.testId
    return id != null ? toTestId(id) : undefined
  })

export const useChangeId = (): ChangeId | null | undefined =>
  useMatchSelector(Routes.CHANGE, matched => {
    const id = matched?.params.changeId
    return id != null ? toChangeId(id) : undefined
  })

export const useIsChangesSelected = (): boolean =>
  useMatchSelector(Routes.CHANGES, matched => matched != null)

export const useIsPipelinesSelected = (): boolean =>
  useMatchSelector(Routes.PIPELINES, matched => matched != null)

export function usePrevLocation() {
  const location = useLocation()
  const history = useContext(HistoryContext)
  const prevLocation = useDerivedState(location, prevState =>
    history.createHref(location) !== history.createHref(prevState) ? location : prevState,
  )
  return history.createHref(prevLocation) !== history.createHref(location) ? prevLocation : null
}
export const useQueryParams = () => {
  const [params] = useSearchParams()
  return searchParamsToObject(params)
}
export const useQueryParam = (param: string, defaultValue?: string | null | undefined) =>
  useQueryParams()[param] ?? defaultValue
export const useBooleanQueryParam = (param: string): boolean => {
  const stringValue = useQueryParam(param)
  return stringValue === 'true'
}
export const withQueryParam: (arg0: string) => HOC<any, any> = param =>
  withHook(() => ({
    [param]: useQueryParam(param),
  }))
type SetQueryParams = (
  params: (QueryParams | null | undefined) | ((prevParams: QueryParams) => QueryParams),
  replace?: boolean,
) => unknown
export const useSetQueryParams = (): SetQueryParams => {
  const history = React.useContext(HistoryContext)
  return React.useCallback(
    (value, replace = true) => {
      const queryParams = queryToObject(history.location.search)
      const to = {
        hash: history.location.hash,
        search: objectToQuery(
          typeof value === 'function' ? value(queryParams) : mergeIfDifferent(queryParams, value),
        ),
      }
      replace ? history.replace(to) : history.push(to)
    },
    [history],
  )
}
type SetQueryParam = (value: string | null | undefined, replace?: boolean) => unknown
export const useSetQueryParam = (
  param: string,
  defaultValue?: string | null | undefined,
): SetQueryParam => {
  const setQueryParams = useSetQueryParams()
  return React.useCallback(
    (value, replace) =>
      setQueryParams(
        {
          [param]: value === defaultValue ? null : value,
        },
        replace,
      ),
    [defaultValue, param, setQueryParams],
  )
}
type SetBooleanQueryParam = (value: boolean, replace?: boolean) => unknown

function useSetBooleanQueryParam(
  param: string,
  defaultTrue: boolean = false,
): SetBooleanQueryParam {
  const setQueryParam = useSetQueryParam(param, String(defaultTrue))
  return React.useCallback(
    (
      value,
      replace, // omit param if its value is default
    ) => setQueryParam(String(value), replace),
    [setQueryParam],
  )
}

export const useQueryParamState = (
  param: string,
  defaultValue?: string | null | undefined,
): [string | null | undefined, SetQueryParam] => [
  useQueryParam(param, defaultValue),
  useSetQueryParam(param, defaultValue),
]
export const useBooleanQueryParamState = (param: string): [boolean, SetBooleanQueryParam] => [
  useBooleanQueryParam(param),
  useSetBooleanQueryParam(param),
]
export const useHash = (): string => useLocationSelector(location => location.hash.slice(1))
type SetHash = (value: string, replace?: boolean) => unknown
export const useSetHash = (): SetHash => {
  const location = useLocation()
  const navigate = useNavigate()
  return React.useCallback(
    (value, replace = true) =>
      navigate(getHrefWithQueryParams(location, null, undefined, value), {
        replace,
      }),
    [location, navigate],
  )
}
export const useBranch = (): BranchType | null | undefined => parseBranch(useQueryParam('branch'))
export type BranchProps = {
  branch?: BranchType
}
export const withBranchProps: Enhancer<BranchProps, any> = withHook(() => {
  const branch = useBranch()
  return branch != null
    ? {
        branch,
      }
    : {}
})
const favoriteBuildsItem = {
  nodeType: 'link',
  id: toCustomSidebarItemId(getFavoriteBuildsHref()),
} as const
const overviewProjectsItem = {
  nodeType: 'link',
  id: toCustomSidebarItemId(getFavoriteProjectsHref()),
} as const
export const withActivatedByUrlItem = withHook(() => {
  const isFavoriteLinkSelected = useIsFavoriteLinkSelected()
  const isOverviewLinkSelected = useIsOverviewLinkSelected()
  const projectOrBuildTypeNode = useProjectOrBuildTypeNode()
  const hash = useHash()
  const isAllProjects = hash === ALL_PROJECTS_HASH
  const activeItem: SidebarActiveItem | null = React.useMemo(() => {
    if (isFavoriteLinkSelected) {
      return favoriteBuildsItem
    }

    if (isOverviewLinkSelected) {
      return overviewProjectsItem
    }

    return (
      projectOrBuildTypeNode && {
        ...projectOrBuildTypeNode,
        group: isAllProjects ? 'all' : 'favorites',
      }
    )
  }, [isAllProjects, isFavoriteLinkSelected, isOverviewLinkSelected, projectOrBuildTypeNode])
  return {
    activeItem,
  }
})
export function useSyncedQueryParam({
  param,
  userProperty,
  values,
  defaultValue,
  skip,
}: SyncedQueryParamArg): [
  string | undefined,
  (newValue: string, skipNavigate?: boolean) => unknown,
] {
  const validate = (value: string | null | undefined) =>
    values != null ? values.find(item => item === value) : value

  const [paramValue, setParamValue] = useQueryParamState(param)
  const validatedParamValue = validate(paramValue)
  const currentUserLoaded = useSelector(getCurrentUserLoaded)
  const valueWithUserDefault = useSelector(
    state => validatedParamValue ?? validate(getUserProperty(state, userProperty)),
  )
  const dispatch = useDispatch()
  const finalValue = valueWithUserDefault ?? defaultValue ?? values?.[0]
  React.useEffect(() => {
    if (
      !skip &&
      currentUserLoaded &&
      finalValue != null &&
      finalValue !== paramValue &&
      // TODO remove when https://github.com/remix-run/react-router/pull/8851 is merged
      process.env.NODE_ENV !== 'test'
    ) {
      setParamValue(finalValue)
    }
  }, [currentUserLoaded, finalValue, paramValue, setParamValue, skip])
  return [
    finalValue,
    React.useCallback(
      (newValue: string, skipNavigate: boolean = false) => {
        if (!skipNavigate) {
          setParamValue(newValue)
        }

        dispatch(setUserProperty(userProperty, newValue))
      },
      [dispatch, setParamValue, userProperty],
    ),
  ]
}

type SyncedBooleanQueryParamArg = {
  readonly param: string
  readonly userProperty: UserProperty
  readonly defaultTrue?: boolean
  readonly skip?: boolean
}
export function useSyncedBooleanQueryParam({
  param,
  userProperty,
  defaultTrue = false,
  skip,
}: SyncedBooleanQueryParamArg): [boolean, (newValue: boolean, skipNavigate?: boolean) => unknown] {
  const [value, setValue] = useSyncedQueryParam({
    param,
    userProperty,
    values: ['true', 'false'],
    defaultValue: String(defaultTrue),
    skip,
  })
  return [
    value === 'true',
    React.useCallback(
      (newValue, skipNavigate) => setValue(String(newValue), skipNavigate),
      [setValue],
    ),
  ]
}

export const useAgentPoolIdParam = () => {
  const activeAgentPoolId = useQueryParam('agentPoolId')
  return activeAgentPoolId != null ? toAgentPoolId(activeAgentPoolId) : undefined
}
