import processResponse from '../../../../../rest/processResponse'
import request from '../../../../../rest/request'
import {BuildTypeHierarchyType} from '../../../../../types'

export const requestChangeBuildTypeHierarchy = (
  serverUrl: string,
  locator: string,
): Promise<BuildTypeHierarchyType> =>
  request(serverUrl, `app/changeFilesByBuildTypeHierarchy?${locator}`).then<BuildTypeHierarchyType>(
    processResponse,
  )
