import {createAction} from '@reduxjs/toolkit'

import {PagerGroup, PagerType} from './Pager.types'

export const submitPager = createAction(
  'submitPager',
  (pager: Partial<PagerType>, group: PagerGroup = PagerGroup.BUILD) => ({
    payload: pager,
    meta: {group},
  }),
)
