import {createFetchAction} from '../../../../../reducers/fetchable'
import {restRoot} from '../../../../../rest/consts'
import {stringifyId} from '../../../../../types'
import type {ChangeId} from '../../../../../types'

import {requestChange} from './BuildStatusTooltip.rest'
import {getChangeData} from './BuildStatusTooltip.selectors'

export const fetchPersonalChange = createFetchAction(
  'fetchPersonalChange',
  (changeId: ChangeId, {getState}) => {
    const state = getState()
    if (getChangeData(state, changeId).inited) {
      return null
    }
    return requestChange(restRoot, `id:${stringifyId(changeId)},personal:true`).then(data => data)
  },
)
