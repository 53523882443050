import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {addHint, removeHint} from './Hints.actions'
import type {HintType} from './Hints.types'

export default function useHint(hint: (HintType | null | undefined) | boolean) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (hint == null || typeof hint === 'boolean') {
      return () => {}
    }

    dispatch(addHint(hint))
    return () => dispatch(removeHint(hint.id))
  }, [dispatch, hint])
}
