import {useMemo, useReducer} from 'react'

import {mergeIfDifferent} from '../reducers/utils'
import {objectFromEntries, objectKeys, mapValues} from '../utils/object'

// PM = payload map

type Action<PM, T extends keyof PM> = {
  readonly type: T
  readonly payload: PM[T]
}
type ActionCreator<P> = (arg0: P) => unknown
type ActionCreators<PM> = {
  [K in keyof PM]: ActionCreator<PM[K]>
}
type Reducer<S, A> = (state: S, action: A) => S
type PartialReducer<S extends {}, A> = (state: S, action: A) => Partial<S> | null | undefined
type Reducers<S, PM> = {
  [K in keyof PM]: Reducer<S, PM[K]>
}
type PartialReducers<S extends {}, PM> = {
  [K in keyof PM]: PartialReducer<S, PM[K]>
}
export function useSlice<S, PM extends {}>(
  reducers: Reducers<S, PM>,
  initialState: S,
): [S, ActionCreators<PM>] {
  const [state, dispatch] = useReducer(
    <T extends keyof PM>(prevState: S, {type, payload}: Action<PM, T>): S => {
      const reducer = reducers[type]
      return reducer != null ? reducer(prevState, payload) : prevState
    },
    initialState,
  )
  const types = objectKeys(reducers)
  const actions = useMemo(
    () =>
      objectFromEntries<ActionCreators<PM>>(
        types.map(type => [
          type,
          payload =>
            dispatch({
              type,
              payload,
            }),
        ]),
      ) as ActionCreators<PM>, // eslint-disable-next-line react-hooks/exhaustive-deps
    types,
  )
  return [state, actions]
}
export function useObjectSlice<S extends {}, PM extends {}>(
  reducers: PartialReducers<S, PM>,
  initialState: S,
): [S, ActionCreators<PM>] {
  return useSlice(
    mapValues<PartialReducers<S, PM>, Reducers<S, PM>>(
      reducers,
      <K extends keyof PM>(reducer: PartialReducer<S, PM[K]>) =>
        (state, payload: PM[K]) =>
          mergeIfDifferent(state, reducer(state, payload)),
    ),
    initialState,
  )
}
export function useBoolean(initialState: boolean): [boolean, () => unknown, () => unknown] {
  const [state, {setTrue, setFalse}] = useSlice<boolean, {setTrue: void; setFalse: void}>(
    {
      setTrue: () => true,
      setFalse: () => false,
    },
    initialState,
  )
  return [state, setTrue, setFalse]
}
