import * as Redux from 'redux'

import {keyValueFetchable} from '../../reducers/fetchable'
import {emptyArray} from '../../utils/empty'

import {fetchInvestigationHistoryAction} from './InvestigationHistory.actions'

const investigationHistoryReducer = Redux.combineReducers({
  investigations: keyValueFetchable(
    arg => arg.locator,
    fetchInvestigationHistoryAction,
    emptyArray,
    (_, action) => action.payload,
  ),
})
export default investigationHistoryReducer
