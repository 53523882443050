import Button from '@jetbrains/ring-ui/components/button/button'
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service'
import Loader from '@jetbrains/ring-ui/components/loader-inline/loader-inline'

import type {ReactElement} from 'react'

import {lazy, useEffect, Suspense, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {fetchCurrentUserData} from '../../../actions'
import {useSlice} from '../../../hooks/useSlice'
import {getCurrentUserId} from '../../../selectors'
import {restApi} from '../../../services/rest'
import {UserId} from '../../../types'
import Avatar, {Size} from '../Avatar/Avatar.container'

import styles from './AvatarEditor.css'

const AvatarEditorDialog = lazy(
  () =>
    import(
      /* webpackChunkName: "AvatarEditorDialog", webpackPrefetch: true */
      './AvatarEditorDialog'
    ),
)

type Props = {
  userId: UserId
  relative?: boolean
  readOnly?: boolean
}

type State = {
  blob?: Blob | null
  dataURI?: string | null
}
type PayloadMap = {
  setBlob: Blob | null
  setDataURI: string | null
}

function AvatarEditor({userId, relative, readOnly}: Props): ReactElement | null {
  const {user, ready} = restApi.endpoints.getUser.useQuery(
    {
      userLocator: `id:${userId}`,
      fields: 'id,name,username,avatars',
    },
    {
      selectFromResult: ({data, isSuccess}) => ({
        user: data,
        ready: isSuccess,
      }),
    },
  )
  const myId = useSelector(getCurrentUserId)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCurrentUserData())
  }, [dispatch])
  const [{blob, dataURI}, {setBlob, setDataURI}] = useSlice<State, PayloadMap>(
    {
      setBlob: (_, payload) => ({blob: payload, dataURI: null}),
      setDataURI: (state, payload) => ({...state, dataURI: payload}),
    },
    {},
  )
  const objectURL = useMemo(() => blob && URL.createObjectURL(blob), [blob])
  const src = dataURI ?? objectURL
  useEffect(
    () => () => {
      if (objectURL != null) {
        URL.revokeObjectURL(objectURL)
      }
    },
    [objectURL],
  )
  const [deleteAvatar] = restApi.endpoints.deleteAvatar.useMutation()

  return (
    <div className={styles.container}>
      {ready ? (
        <>
          {user && (
            <Avatar
              user={user}
              className={styles.avatarWrapper}
              avatarClassName={styles.avatar}
              size={Size.Size40}
              displayPlaceholder={false}
              withOutline={false}
              relative={relative}
            />
          )}
          <label className={styles.fileInputWrapper}>
            <input
              disabled={readOnly}
              type="file"
              accept="image/*"
              id="avatar"
              name="avatar"
              className={styles.fileInput}
              onChange={e => {
                const file = e.currentTarget.files?.[0]
                if (file != null) {
                  setBlob(file)
                  e.currentTarget.value = ''
                }
              }}
            />
            <span className={styles.fileInputLabel}>{`Upload ${
              user?.avatars != null ? 'new ' : ''
            }image...`}</span>
          </label>
          {user?.avatars != null && (
            <Button
              disabled={readOnly}
              onClick={() =>
                confirm({
                  text:
                    userId === myId
                      ? 'Delete your avatar?'
                      : `Delete the avatar of ${user.name ?? user.username}?`,
                  description: 'This cannot be undone',
                  confirmLabel: 'Delete',
                }).then(
                  () =>
                    deleteAvatar({userLocator: `id:${userId}`}).then(() => {
                      if (userId === myId) {
                        return dispatch(fetchCurrentUserData(true))
                      }
                      return undefined
                    }),
                  () => {},
                )
              }
            >
              {'Delete...'}
            </Button>
          )}
          {src != null && (
            <Suspense fallback="">
              <AvatarEditorDialog
                userId={userId}
                src={src}
                onCloseAttempt={() => setBlob(null)}
                onError={() => {
                  if (blob != null) {
                    const reader = new FileReader()
                    reader.onload = () => setDataURI(reader.result as string)
                    reader.readAsDataURL(blob)
                  }
                }}
              />
            </Suspense>
          )}
        </>
      ) : (
        <Loader>{'Loading...'}</Loader>
      )}
    </div>
  )
}

export default AvatarEditor
