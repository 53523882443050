import processResponse from '../../rest/processResponse'
import request from '../../rest/request'

import type {InvestigationHistoryType} from './InvestigationHistory.types'

export default (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<InvestigationHistoryType>> =>
  request(serverUrl, `viewInvestigationHistory.html?${locator}`).then<
    readonly InvestigationHistoryType[]
  >(processResponse)
