import {createReducer} from '@reduxjs/toolkit'
import {castDraft} from 'immer'
import * as Redux from 'redux'

import {keyValueFetchable} from '../../../../../reducers/fetchable'
import {BuildId, toBuildId, UniqueRevisionId} from '../../../../../types'
import {emptyArray} from '../../../../../utils/empty'
import {KeyValue} from '../../../../../utils/object'

import {fetchBuildChangesRevisions} from './ChangesRevisions.actions'
import {ChangesRevisionsStateType} from './ChangesRevisions.types'

const changesRevisions = Redux.combineReducers<ChangesRevisionsStateType>({
  buildChangesRevisionsByLocator: keyValueFetchable(
    arg => arg.locator,
    fetchBuildChangesRevisions,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  buildSettingsRevisions: createReducer<KeyValue<BuildId, UniqueRevisionId | null | undefined>>(
    {},
    builder =>
      builder.addCase(fetchBuildChangesRevisions.fulfilled, (state, action) => {
        const {builds} = action.payload.entities

        if (builds == null) {
          return
        }

        for (const [buildId, build] of Object.entries(builds)) {
          state[toBuildId(buildId)] = build?.versionedSettingsRevision
        }
      }),
  ),
  buildRevisions: createReducer<KeyValue<BuildId, ReadonlyArray<UniqueRevisionId>>>({}, builder =>
    builder.addCase(fetchBuildChangesRevisions.fulfilled, (state, action) => {
      const {builds} = action.payload.entities

      if (builds == null) {
        return
      }

      for (const [buildId, build] of Object.entries(builds)) {
        state[toBuildId(buildId)] = castDraft(build?.revisions?.revision ?? [])
      }
    }),
  ),
})

export default changesRevisions
