import throttle from 'lodash/throttle'
import * as React from 'react'

import {withContextAsProp} from '../utils/contexts'

function noop() {}

const UpdateContext: React.Context<() => unknown> = React.createContext(noop)
type UpdateProviderProps = {
  readonly update: () => unknown
  readonly timeout?: number
  readonly children: React.ReactNode
}
const DEFAULT_TIMEOUT = 500 // ms

export const UpdateProvider = ({
  update,
  timeout = DEFAULT_TIMEOUT,
  children,
}: UpdateProviderProps) => {
  const throttledUpdate = React.useMemo(
    () =>
      throttle(update, timeout, {
        leading: true,
        trailing: true,
      }),
    [timeout, update],
  )
  return <UpdateContext.Provider value={throttledUpdate}>{children}</UpdateContext.Provider>
}
export const UpdateConsumer = UpdateContext.Consumer
export const useUpdateResults = (): (() => unknown) => React.useContext(UpdateContext)
export type UpdateResultProps = {
  updateResults(): unknown
}
export const withUpdateResults = withContextAsProp(UpdateContext, 'updateResults')
