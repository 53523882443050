import moment from 'moment'
import {useSelector} from 'react-redux'

import {getIsAutodetectTimeZone} from '../selectors'

export const MS_IN_SEC = 1000
const RADIX = 10
const MS_RADIX = 100

function formatDecimal(num: number, radix: number) {
  let result = `${num}`

  if (num < radix) {
    while (result.length < radix.toString().length) {
      result = `0${result}`
    }
  }

  return result
}

function getDurationPart({
  append,
  value,
  label,
  radix = RADIX,
  delimiter = ':',
}: {
  append: boolean
  value: number
  label: string
  radix?: number
  delimiter?: string
}) {
  if (append) {
    return `${delimiter}${formatDecimal(value, radix)}${label}`
  }

  if (value > 0) {
    return `${value}${label}`
  }

  return ''
}

export function formatDuration(
  durationInSeconds: number | null | undefined,
  {
    alwaysShowAccurateResult,
    alwaysShowSeconds,
    alwaysHideSeconds,
    delimiter = ' ',
    hideZero,
  }: {
    alwaysShowAccurateResult?: boolean
    alwaysShowSeconds?: boolean
    alwaysHideSeconds?: boolean
    hideZero?: boolean
    delimiter?: string
  } = {},
): string {
  if (durationInSeconds == null) {
    return ''
  }

  const duration = moment.duration(durationInSeconds, 'seconds')
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  let result = ''
  result += getDurationPart({
    append: false,
    value: hours,
    label: 'h',
    delimiter,
  })

  if (hideZero !== true || minutes > 0) {
    result += getDurationPart({
      append: Boolean(result),
      value: minutes,
      label: 'm',
      delimiter,
    })
  }

  if (alwaysHideSeconds !== true && (alwaysShowSeconds === true || hours === 0)) {
    const seconds = duration.seconds()

    if (hideZero !== true || seconds > 0) {
      result += getDurationPart({
        append: Boolean(result),
        value: seconds,
        label: 's',
        delimiter,
      })
    }
  }

  if (alwaysShowAccurateResult === true || result) {
    return result
  }

  return '< 1s'
}
export function formatDurationWithMilliseconds(
  durationInMilliseconds: number,
  {
    alwaysShowMilliseconds = true,
    alwaysShowSeconds = true,
    delimiter = ' ',
  }: {
    alwaysShowMilliseconds?: boolean
    alwaysShowSeconds?: boolean
    delimiter?: string
  } = {},
): string {
  const durationInSeconds = Math.floor(durationInMilliseconds / MS_IN_SEC)
  const duration = moment.duration(durationInSeconds, 'seconds')
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  let result = formatDuration(durationInSeconds, {
    alwaysShowAccurateResult: true,
    alwaysShowSeconds,
    delimiter,
  })
  const milliseconds = durationInMilliseconds % MS_IN_SEC

  if (alwaysShowMilliseconds === true || (hours === 0 && minutes === 0 && milliseconds > 0)) {
    result += getDurationPart({
      append: Boolean(result),
      value: milliseconds,
      label: 'ms',
      radix: MS_RADIX,
      delimiter,
    })
  }

  if (result) {
    return result
  }

  return '< 1ms'
}
export function parseDateToObj(
  serverDate: string,
  isAutodetectTimeZone?: boolean | null | undefined,
): moment.Moment {
  return isAutodetectTimeZone !== false ? moment(serverDate || 0) : moment.parseZone(serverDate)
}
export function displayDate(
  serverDate: string,
  isAutodetectTimeZone?: boolean | null,
  withTime?: boolean | null,
  withDate: boolean = true,
): string {
  const date = parseDateToObj(serverDate, isAutodetectTimeZone)
  const sameYear = date.year() === moment().year()
  const dateFormat = `D MMM${sameYear ? '' : ' YY'}`
  return date.format(`${withDate ? dateFormat : ''}${withTime === true ? ' LT' : ''}`)
}

export const useDisplayDate = (
  serverDate: string | null | undefined,
  withTime?: boolean | null,
  withDate: boolean = true,
) => {
  const isAutodetectTimeZone = useSelector(getIsAutodetectTimeZone)
  return serverDate != null
    ? displayDate(serverDate, isAutodetectTimeZone, withTime, withDate)
    : null
}
