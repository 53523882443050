import {createAction} from '@reduxjs/toolkit'

import type {AppThunk} from '../../../actions/types'
import {EXPAND_SIDEBAR_STATE_KEY, setStoredExpandState} from '../AgentsScreen.effects'

import {agentsTreeSearchState} from '../AgentsScreen.slices'

import type {AgentBranchId, AgentBranchType} from './AgentsSidebar.types'

export const setAgentsTreeItemExpandStateAction = createAction<{
  readonly agentItemId: AgentBranchId
  readonly agentItemType: AgentBranchType
  readonly expanded: boolean
}>('setAgentsTreeItemExpandState')

export const setAgentsTreeItemExpandState =
  (agentItemId: AgentBranchId, agentItemType: AgentBranchType, expanded: boolean): AppThunk<any> =>
  (dispatch, getState) => {
    dispatch(
      setAgentsTreeItemExpandStateAction({
        agentItemId,
        agentItemType,
        expanded,
      }),
    )
    setStoredExpandState(EXPAND_SIDEBAR_STATE_KEY, getState().agentsPage.agentsTreeExpandState)
  }
export const searchInAgentsTree = agentsTreeSearchState.actions.search
